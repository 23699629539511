$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//



.container_p-SavedAiItineraries{
    width: 100%;
    background: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 6%;
}

// .container_s-SavedAiItineraries{
//     width: 64%;
// }

@media (min-width: 0px) and (max-width: 499px){
    .container_s-SavedAiItineraries{
        width: 100%;
    // border: 1px solid olivedrab;
    // display: flex;
    // align-items: center;
    }
}

@media (min-width: 500px) and (max-width: 1000px){
    .container_s-SavedAiItineraries{
        width: 100%;
    // border: 1px solid olivedrab;
    // display: flex;
    // align-items: center;
    }
}

@media (min-width: 1000px){
    .container_s-SavedAiItineraries{
        width: 70%;
    // border: 1px solid olivedrab;
    // display: flex;
    // align-items: center;
    }
}



//*text
.container-text-SavedAiItineraries{
    width: 100%;
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: center;
}
.heading-1-SavedAiItineraries{
    // font-family: $font-6;
    font-family: $font-6;
    font-weight: bold;
    font-size: 26px;
    color: #867dff;
    letter-spacing: 0.6px;
    word-spacing: 0.4px;
    margin-left: 5.6%;
    // background: lightgoldenrodyellow;
}
.img-1-bg-text-SavedAiItineraries{
    width: 40px;
    height: 40px;
    // transform: rotate(10deg);
    margin-left: 16px;
}

//*Itinerary Content
.container_p-itineraryContent-SavedAiItineraries{
    // display: flex;
    // flex-direction: row;
    margin-top: 2%;
    padding: 8px;
    // border: 2px lightgray solid;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
}



.container_s-itineraryContent-SavedAiItineraries{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    width: 90%;
    // border: 2px solid peru;
    position: relative;
    background: none;
}
.container_s-itineraryContent-SavedAiItineraries::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}
.container_t-itineraryContent-SavedAiItineraries {
    display: flex;
    flex-direction: column;
    width: 360px;
    min-width: 360px; 
    height: fit-content;
    margin-right: -30px;
    // padding: 30px;
    border-radius: 8px;
    // border: 1px solid orchid;
    border: none;
    background: none;
    align-items: flex-start;
    text-align: start;
    position: relative;
}

.img-delete-SavedAiItineraries{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 24%;
    bottom: 34%;
    border: none;
    background: none;
    z-index: 1;
    // background: white;

    &:hover{
        box-shadow: 0 0 8px rgba(182, 180, 180, 0.824); 
        width: 22px;
        height: 22px;
    }
    &:active{
        box-shadow: 0 0 8px rgba(255, 0, 0, 0.548); 
    }
}
.img-1-bg-itineraryContent-SavedAiItineraries{
    // position: absolute;
    width: 100%;
    // height: fit-content;
}
.container_t-content-itineraryContent-SavedAiItineraries{
    position: absolute;
    display: flex;
    flex-direction: column;
    // border: 1px solid yellowgreen;
    top: 14%;
    left: 14%;
    width: 60%;
}
.text-1-itineraryContent-SavedAiItineraries{
    font-family: $font-6;
    color: orangered;
    font-size: 18px;
    // position: absolute;
}
.text-2-itineraryContent-SavedAiItineraries{
    font-family: $font-6;
    color: rgb(114, 114, 114);
    margin-top: 6%;
    font-size: 16px;
}

//
.scroll-content-SavedAiItineraries{
    width: 4%;
    z-index: 3;
    align-items: center;
    text-align: center;
    align-self: center;
    font-size: 30px;
    border: none;
    background-image: url('https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/ai-mixed-color-bomb-1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right -2px top 0px; 

    &:hover{
        color: red;
        transition: color 0.3s ease;
        // background: whitesmoke;
        // border-radius: 50px;
        // padding: 2px 0px 0px 2px;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}


.no-itineraries-message {
    font-size: 18px;
    color: #555;
    text-align: center;
    margin: 20px;
    padding: 15px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

//popup alert - delete the saved itinerary
/* Overlay to freeze the background */
/* Overlay to freeze the background */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Popup container */
.popup {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 280px;
    max-width: 90%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Header text style */
.popup h3 {
    margin-bottom: 15px;
    font-size: 16px;
    color: #333;
}

/* Button container */
.buttons {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

/* Button style */
.cancel-button,
.confirm-button {
    border: none;
    border-radius: 4px;
    padding: 8px 15px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

/* Cancel button style */
.cancel-button {
    background-color: #f44336;
    color: white;
}

/* Confirm button style */
.confirm-button {
    background-color: #4caf50;
    color: white;
}

/* Button hover effect */
.cancel-button:hover {
    background-color: #c62828;
}

.confirm-button:hover {
    background-color: #388e3c;
}




//before login
.container_p-LoginBefore-SavedAiItineraries{
    background: whitesmoke;
    margin-top: 4%;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid rgb(215, 215, 215);
}
.text-1-LoginBefore-SavedAiItineraries{
    font-weight: normal;
    margin-bottom: 12px;
    font-family: $font-6;
}