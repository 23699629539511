$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

//!main div of the componenet
.container_p-Entities_TD_Mobile{
    margin: 8% 0% 1% 0%;
    background-color: rgb(255, 255, 255);
    display: flex;
    border-radius: 10px;
    padding: 1% 1% 1% 1%;
    display: flex;
    flex-direction: column;
}

//icon
// .icon-delete-Checklist_TD{
//     border: none;
//     font-size: 26px;
//     background: none;
//     margin-right: 10px;

//     &:hover{
//         color: red;
//         transition: color 0.3s ease;
//     }
//     &:active{
//         color: orange;
//         transition: color 0.3s ease;
//     }
// }

/* Background overlay */
.popup-background-1-Entities_TD {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.popup-hotel-tp-background-1-Entities_TD {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

// body {
//     overflow: hidden; /* Prevent scrolling on the whole page */
//   }

.text-index-Entities_TD_Mobile{
    position: absolute;
    // background: brown;
    color: brown;//white;
    padding: 0px 4px 1px 4px;
    border-radius: 50px;
    // right: 0px;
    // bottom: 0px;
    // margin-top: -20px;
    font-size: 14px;
    font-weight: bold;
    top: 4px;
    left: 2px;
}

//
.container_p-entity_meta_buttons-Entities_TD_Mobile{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin-top: 4%;
}


//?Buttons
.container_p-addButtons-Entities_TD{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 96%;
    margin: 12px 0px 12px 13px;
    // justify-content: space-around;
}
.button-note-addButton-Entities_TD{
    margin: 0px 4px 0px 2px;
    padding: 4px 8px 4px 8px;
    background: rgb(101, 189, 0);
    border: none;
    color: #fff;
    border-radius: 8px;
    font-size: 12px;
    font-family: $font-content-2;
    letter-spacing: 0.4px;
    width: max-content;

    &:hover{
        background-color: rgba(101, 189, 0, 0.881);
        transition: background-color 0.3s ease;
        transition: border-radius 0.3s ease;
        border-radius: 5px;
    }
    &:active{
        background-color: rgb(174, 179, 174);
        transition: background-color 0.3s ease;
    }
}
.button-budget-addButton-Entities_TD{
    margin: 0px 4px 0px 2px;
    padding: 4px 8px 4px 8px;
    background: rgb(18, 95, 147);
    border: none;
    color: #fff;
    border-radius: 8px;
    font-size: 12px;
    font-family: $font-content-2;
    letter-spacing: 0.4px;
    width: max-content;

    &:hover{
        background-color: rgba(18, 95, 147, 0.796);
        transition: background-color 0.3s ease;
        transition: border-radius 0.3s ease;
        border-radius: 5px;
    }
    &:active{
        background-color: rgb(174, 179, 174);
        transition: background-color 0.3s ease;
    }

}
.button-timeInterval-addButton-Entities_TD{
    margin: 0px 4px 0px 2px;
    padding: 4px 8px 4px 8px;
    background: rgb(245, 71, 9);
    border: none;
    color: #fff;
    border-radius: 8px;
    font-size: 12px;
    font-family: $font-content-2;
    letter-spacing: 0.4px;
    width: max-content;

    &:hover{
        background-color: rgba(245, 72, 9, 0.816);
        transition: background-color 0.3s ease;
        transition: border-radius 0.3s ease;
        border-radius: 5px;
    }
    &:active{
        background-color: rgb(174, 179, 174);
        transition: background-color 0.3s ease;
    }
}


//!Data
.container_p-Data-Entities_TD_new_Mobile{
    // border: 2px solid wheat;
    display: flex;
    flex-direction: column;
    // flex-direction: row-reverse;
    // justify-content: space-around;
    margin-top: 2px;
}
.container_s-1-Data-Entities_TD_Mobile{
    display: flex;
    flex-direction: column;
    // border: 1px solid goldenrod;
    width: 100%;
    align-items: center;
    text-align: center;
}
.container_s-1-noNote-Data-Entities_TD{
    display: flex;
    flex-direction: row;
    // border: 1px solid goldenrod;
    width: 100%;
    align-items: center;
    text-align: center;
}

.container_s-2-Data-Entities_TD_Mobile{
    display: flex;
    flex-direction: column;
    // border: 1px solid rgb(135, 66, 10);
    width: 60%;
    background: whitesmoke;
    border-radius: 6px;
    margin-top: 4%;
    align-items: center;
    align-self: center;
}

.handleEmpty-timeInterval-Entities_TD{
    width: 34%;
}

//?Budget
.container_p-Budget-Entities_TD{
    display: flex;
    flex-direction: column;
    padding: 4px 12px 4px 12px;
    margin: 4px 12px 4px 12px;
    border-radius: 8px;
    background: whitesmoke;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    // min-width: 30%;
    // min-width: 60%;
    width: fit-content;
}
//
.heading-1-budgetData-Entities_TD{
    color: gray;
    font-family: $font-6;
    font-size: 14px;
    // width: fit-content;
    // min-width: 10%;
    // max-width: 40%;
    // background: rgb(101, 0, 0);
}
.container_t-budgetData-Entities_TD{
    display: flex;
    flex-direction: row;   
    padding: 2px 12px 2px 12px;
    margin: 8px 12px 8px 12px;
    border-radius: 6px;
    background-color: rgb(255, 255, 255); 
    align-items: center;
    text-align: center;
    // min-width: 30%;
    // max-width: 70%;
    justify-content: space-around;
}
.container_t-data-1-budget-Entities_TD{
    color: rgb(0, 0, 0); 
    font-family: $font-6;
    margin-right: 14px;
}
.container_t-data-2-budget-Entities_TD{
    color: rgb(93, 91, 91); 
    font-family: $font-content-1;
    margin-right: 10px;
}
//
.container_t-icons-budget-Entities_TD{
    display: flex;
    flex-direction: row;
    // width: 20%;
}
.button-icon-edit-Entities_TD{
    background: none;
    border: none;
    font-size: 18px;
    margin: 0px 10px 0px 10px;
    color: rgb(110, 106, 106);

    &:hover{
        color: rgb(209, 115, 15);
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}
.button-icon-delete-Entities_TD{
    background: none;
    border: none;
    font-size: 18px;
    margin: 0px 10px 0px 10px;
    color: rgb(110, 106, 106);

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}
//?Trip Status 
.container_p-tripStatus-Entities_TD{
    // background: whitesmoke;
    // padding: 2px 30px 2px 30px;
    padding: 4px 30px 2px 0px;
    border-radius: 10px;
    width: fit-content;
    margin-left: 20px;
    // margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}
.heading-1-tripStatus-Entities_TD{
    color: rgb(109, 107, 107);
    font-family: $font-6;
    font-size: 14px;
    // margin-right: 12px;
    // margin-bottom: 12px;
    width: 100%;
}
.container_p-select-tripStatus-Entities_TD_Mobile{
    // display: inline-block;
    margin: 10px 0;
    // display: flex;
}
.select-tripStatus-Entities_TD_Mobile{
    width: fit-content;
    max-width: 200px;
    padding: 4px 8px 2px 8px;
    margin: 0px 4px 0px 4px;
    font-family: $font-6;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;

    &:focus{
        border-color: #255499;
        box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
        outline: none;
    }
}
.select-tripStatus-Entities_TD_Mobile option{
    padding: 8px;
}


//?Time Interval
.container_p-timeInterval-Entities_TD{
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    background: whitesmoke;
    width: fit-content;
    padding: 8px 8px 8px 8px;
    margin: 16px 8px 8px 8px;
    border-radius: 8px;
}
.conatiner_s-1-timeInterval-Entities_TD{
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    margin-top: 2%;
    display: flex;
    align-items: center;
    text-align: center;
    width: fit-content;
}
.heading-1-timeInterval-Entities_TD{
    color: rgb(109, 107, 107);
    font-family: $font-6;
    font-size: 14px;
    margin-right: 12px;
}
//time 
.container_p-time-timeInterval-Entities_TD{
    display: flex;
    margin-top: 12px;
    margin-left: 12px;
    flex-direction: row;
    // align-items: start;
    // text-align: start;
    // justify-content: flex-start;
}
.text-time-timeInterval-Entities_TD{
    color: orangered;
    font-family: $font-6;
    background-color: white;
    font-size: 14px;
    padding: 5px 6px 5px 6px;
    border-radius: 8px;
    margin: 0px 10px 0px 10px;
}
.content-time-timeInterval-Entities_TD_Mobile{
    color: rgb(189, 8, 8);
    font-size: 14px;
    padding: 2px 4px 2px 4px;
    // margin-left: 28px;
    margin-top: 22px;
    font-family: $font-3;
    font-weight: 600;
}


//!Note
.conatiner_s-1-addNote-Entities_TD{
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.heading-1-addNote-Entities_TD{
    color: rgb(101, 189, 0);
    font-family: $font-6;
    margin-right: 10px;
}


//!Add from user saved
.text-or-addSaved-Entities_TD{
    margin-left: 16px;
    margin-top: 4px;
    margin-bottom: 10px;
}
.container_p-addSaved-Entities_TD{
    border: 1px solid orange;
    margin-left: 12px;
    // background: none;
    border: none;
    background: whitesmoke;
    padding: 2px 6px 2px 6px;
    border-radius: 6px;
}

.container_s-1-addSaved-Entities_TD{

}
.container_s-2-addSaved-hover-Entities_TD{
    // background: white;   
}

.conatiner_t-singleSavedEntity-hover-Entities_TD{
    margin: 4px 2px;
    border: none;
    background: none;
    background: rgb(232, 230, 230);
    padding: 4px 6px 4px 6px;
    border-radius: 4px;
    

    &:hover{
        background-color: rgb(255, 213, 145);
        transition: background-color 0.3s ease;
    }
}

//?Hover - select from the your saved
.container_p-addSaved-hover-Entities_TD{
    border: 1px solid orange;
    margin-left: 12px;
}