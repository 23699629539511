$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//





//!popup container
//?1
.popup-background-1-CreateItinerary_AI{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.529);
    z-index: 1000;
}

// .popup_p-2-addNote-CreateItinerary_AI{
//     width: 70%;
//     height: 92%;
//     position: fixed;
//     top: 4%;
//     left: 16%;
//     border: none;
//     border-radius: 8px;
//     background: #ffffff;
//     padding: 20px;
//     box-shadow: 0 0 12px rgba(18, 75, 120, 0.835);
//     z-index: 1010;
//     overflow: auto;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
// }


@media (min-width: 0px) and (max-width: 499px){
    .popup_p-2-addNote-CreateItinerary_AI{
        width: 99%;
        height: 92%;
        position: fixed;
        top: 4%;
        left: 0.6%;
        border: none;
        border-radius: 8px;
        background: #ffffff;
        padding: 2px;
        box-shadow: 0 0 12px rgba(18, 75, 120, 0.835);
        z-index: 1010;
        // overflow: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .popup_s-2-1-addNote-CreateItinerary_AI{
        width: 60%;
        height: 100%;
        top: 10%;
        left: 20%;
        // border: 1px solid #000;
        background: #ffffff;
        padding: 20px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1015;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        text-align: start;
    }
    .popup_s-2-2-Image-CreateItinerary_AI{
        display: none;
    }
    .button-close-CreateItinerary_AI{
        background: none;
        border: none;
        font-size: 34px;
        position: absolute;
        right: 4%;
        top: -10px;
        z-index: 10000000000000px;
    
        &:hover{
            color: red;
            transition: color 0.3s ease;
        }
        &:active{
            color: orange;
            transition: color 0.3s ease;
        }
    }
    .container_p-popup-searchDestinations-CreateItinerary_AI{
        display: flex;
        position: absolute;
        flex-direction: column;
        // margin-top: 3.4%;
        // border: 1px solid olivedrab;
        z-index: 4px;
        // min-width: 0%;
        // max-width: 34%;
        width: 80%;
        text-align: start;
        align-items: self-start;
        margin-left: 4%;
        // background: white;
        background: rgb(246, 243, 243);
        box-shadow: rgb(185, 230, 255) 0px 0px 6px;
        border: none;
        padding: 8px 6px 4px 6px;
        border-radius: 6px;
    }
}
@media (min-width: 500px) and (max-width: 1000px){
    .popup_p-2-addNote-CreateItinerary_AI{
        width: 96%;
        height: 96%;
        position: fixed;
        top: 2%;
        left: 2%;
        border: none;
        border-radius: 8px;
        background: #ffffff;
        padding: 20px;
        box-shadow: 0 0 12px rgba(18, 75, 120, 0.835);
        z-index: 1010;
        overflow: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .popup_s-2-2-Image-CreateItinerary_AI{
        width: 38%;
        height: 100%;
        top: 10%;
        left: 20%;
        // border: 1px solid #000;
        background: #ffffff;
        padding: 20px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000000000000000000000;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        text-align: start;
    }
    .button-close-CreateItinerary_AI{
        background: none;
        border: none;
        font-size: 34px;
        position: absolute;
        right: 30%;
        top: 26px;
        z-index: 10000000000000px;
    
        &:hover{
            color: red;
            transition: color 0.3s ease;
        }
        &:active{
            color: orange;
            transition: color 0.3s ease;
        }
    }
    .container_p-popup-searchDestinations-CreateItinerary_AI{
        display: flex;
        position: absolute;
        flex-direction: column;
        // margin-top: 3.4%;
        // border: 1px solid olivedrab;
        z-index: 4px;
        // min-width: 0%;
        // max-width: 34%;
        width: 60%;
        text-align: start;
        align-items: self-start;
        margin-left: 0%;
        // background: white;
        background: rgb(246, 243, 243);
        box-shadow: rgb(185, 230, 255) 0px 0px 6px;
        border: none;
        padding: 8px 6px 4px 6px;
        border-radius: 6px;
    }
}
@media (min-width: 1000px){
    .popup_p-2-addNote-CreateItinerary_AI{
        width: 70%;
        height: 92%;
        position: fixed;
        top: 4%;
        left: 16%;
        border: none;
        border-radius: 8px;
        background: #ffffff;
        padding: 20px;
        box-shadow: 0 0 12px rgba(18, 75, 120, 0.835);
        z-index: 1010;
        overflow: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .popup_s-2-2-Image-CreateItinerary_AI{
        width: 38%;
        height: 100%;
        top: 10%;
        left: 20%;
        // border: 1px solid #000;
        background: #fff;
        padding: 20px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000000000000000000000;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        text-align: start;
    }
    .button-close-CreateItinerary_AI{
        background: none;
        border: none;
        font-size: 34px;
        position: absolute;
        right: 30%;
        top: 26px;
        z-index: 10000000000000px;
    
        &:hover{
            color: red;
            transition: color 0.3s ease;
        }
        &:active{
            color: orange;
            transition: color 0.3s ease;
        }
    }
    .container_p-popup-searchDestinations-CreateItinerary_AI{
        display: flex;
        position: absolute;
        flex-direction: column;
        // margin-top: 3.4%;
        // border: 1px solid olivedrab;
        z-index: 4px;
        // min-width: 0%;
        // max-width: 34%;
        width: 50%;
        text-align: start;
        align-items: self-start;
        margin-left: 0%;
        // background: white;
        backgrosund: rgb(246, 243, 243);
        box-shadow: rgb(185, 230, 255) 0px 0px 6px;
        border: none;
        padding: 8px 6px 4px 6px;
        border-radius: 6px;
    }
}


.popup_s-2-1-addNote-CreateItinerary_AI{
    width: 100%;
    height: 100%;
    top: 10%;
    left: 20%;
    // border: 1px solid #000;
    background: #ffffff;
    padding: 20px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1015;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    text-align: start;
}
.popup_s-2-1-addNote-CreateItinerary_AI::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.popup_s-2-1-addNote-CreateItinerary_AI::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.popup_s-2-1-addNote-CreateItinerary_AI::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  

// .popup_s-2-2-Image-CreateItinerary_AI{
//     width: 38%;
//     height: 100%;
//     top: 10%;
//     left: 20%;
//     // border: 1px solid #000;
//     background: #fff;
//     padding: 20px;
//     // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//     z-index: 1000000000000000000000;
//     overflow: auto;
//     display: flex;
//     flex-direction: column;
//     align-items: self-start;
//     text-align: start;
// }

.image-ai-main-CreateItinerary_AI{
    width: 100%;
    height: 90%;
    object-fit: cover;
}

// .button-close-CreateItinerary_AI{
//     background: none;
//     border: none;
//     font-size: 34px;
//     position: absolute;
//     right: 30%;
//     top: 26px;
//     z-index: 10000000000000px;

//     &:hover{
//         color: red;
//         transition: color 0.3s ease;
//     }
//     &:active{
//         color: orange;
//         transition: color 0.3s ease;
//     }
// }


//!Step 1
.container_p-searchDestination-CreateItinerary_AI{
    // background-color: whitesmoke;

}
.container_p-propmt-1-CreateItinerary_AI{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}
.img-stars-search-CreateItinerary_AI{
    width: 40px;
    height: 40px;
    margin-left: -22px;
    margin-top: -10px;
}
.img-stars-2-search-CreateItinerary_AI{
    width: 40px;
    height: 40px;
    margin-left: 12px;
    margin-top: 0px;
    z-index: -1;
}
.text-propmt-1-CreateItinerary_AI{
    font-family: $font-6;
    font-size: 16px;
    color: rgb(111, 29, 29);
    letter-spacing: 0.4px;
    align-self: self-start;
    text-align: start;
}

.text-suggest-searchInput-CreateItinerary_AI{
    color: gray;
    font-size: 14px;
    font-family: $font-3;
    margin-left: 20px;
    margin-bottom: -4px;
}

.container_p-searchInput-CreateItinerary_AI{
    // width: 80%;
    width: 300px;
    margin-top: 4%;
    background: white;
    padding: 4px 20px 4px 20px;
    border-radius: 10px;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 18px;
    box-shadow: rgba(160, 160, 160, 0.418) 0px 0px 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // position: relative;
    height: 40px;
    margin-left: 4%;
}
.input-searchInput-CreateItinerary_AI{
    // padding: 8px 8px 8px 8px; /* Padding inside the input */
    // border: 1px solid #ffc7c7;
    border: none;
    border-radius: 8px; 
    font-size: 16px; 
    color: #333; 
    background-color: #ffffff; 
    outline: none; 
    transition: border-color 0.3s, box-shadow 0.3s; 
    width: 400%;
    height: 100%;
    // font-family: $font-6;
    font-family: $font-3;
    font-weight: normal;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: rgb(122, 81, 10);
}
.input-searchInput-CreateItinerary_AI:focus{
    border-color: none;  
}
.input-searchInput-CreateItinerary_AI::placeholder{
    color: rgb(192, 192, 192);
    font-family: $font-6;
    font-weight: normal;
}
// .container_p-popup-searchDestinations-CreateItinerary_AI{
//     display: flex;
//     position: absolute;
//     flex-direction: column;
//     // margin-top: 3.4%;
//     // border: 1px solid olivedrab;
//     z-index: 4px;
//     // min-width: 0%;
//     // max-width: 34%;
//     width: 50%;
//     text-align: start;
//     align-items: self-start;
//     margin-left: 0%;
//     // background: white;
//     background: rgb(246, 243, 243);
//     box-shadow: rgb(185, 230, 255) 0px 0px 6px;
//     border: none;
//     padding: 8px 6px 4px 6px;
//     border-radius: 6px;
// }
.container_s-popup-searchDestinations-CreateItinerary_AI{
    padding: 4px 8px 4px 8px;
    margin: 0px 0px 6px 0px;
    border: none;
    // background: rgb(246, 243, 243);
    background: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;

    &:hover{
        border-radius: 8px;
        background-color: rgb(255, 234, 234);
    }
    &:active{
        background-color: rgb(255, 194, 194); 
        transition: background-color 0.3s ease;
    }
}
.text-1-popup-searchDestinations-CreateItinerary_AI{
    font-family: $font-6;
    color: rgb(0, 0, 0);
    font-size: 16px;
}
.text-2-popup-searchDestinations-CreateItinerary_AI{
    font-family: $font-6;
    color: rgb(98, 98, 98); 
    font-size: 16px;
}
.text-3-popup-searchDestinations-CreateItinerary_AI{
    font-family: $font-6;
    color: rgb(159, 157, 157);
    font-size: 16px;
}











//!Step 2
.container_p-propmt-2-CreateItinerary_AI{
    display: flex;
    flex-direction: row;
    // align-items: center;
    // text-align: center;
    margin-top: 6%;
}

.container-buttons-prompt-2-CreateItinerary_AI{
    margin-top: 4%;
}
.button-active-prompt-2-CreateItinerary_AI{
    padding: 2px 6px 2px 6px;
    border-radius: 8px;
    // border: 2px gainsboro solid;
    // border: none;
    background: none;
    // background: rgb(255, 238, 241);
    border: 2px solid rgb(255, 180, 81);
    color: #3c3c3c;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: -2px;
    letter-spacing: 0.2px;
    font-family: $font-heading-3;

    &:hover{
        box-shadow: 0 0 8px rgba(255, 210, 210, 0.68); 
        border-radius: 12px;
        transition: border-radius 0.3s ease;
    }
    &:active{
        box-shadow: 0 0 6px rgba(255, 164, 164, 0.735); 
        border-radius: 4px;
        transition: border-radius 0.3s ease;
    }
}
.button-unActive-prompt-2-CreateItinerary_AI{
    padding: 4px 6px 4px 6px;
    border-radius: 8px;
    // border: 2px gainsboro solid;
    // border: none;
    background: none;
    // background: rgb(255, 238, 241);
    border: 1px solid rgb(226, 128, 1);
    color: #454545;
    margin-right: 12px;
    margin-left: 12px;
    letter-spacing: 0.2px;
    font-family: $font-6;

    &:hover{
        box-shadow: 0 0 8px rgba(255, 210, 210, 0.68); 
        border-radius: 12px;
        transition: border-radius 0.3s ease;
    }
    &:active{
        box-shadow: 0 0 6px rgba(255, 164, 164, 0.735); 
        border-radius: 4px;
        transition: border-radius 0.3s ease;
    }
}


.container_p-buttons-prompt2-CreateItinerary_AI{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.container_p-seasons-buttons-prompt2-CreateItinerary_AI{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 3%;
    margin-top: 1%;
}
.button-active-category-prompt2-CreateItinerary_AI{
    margin-right: 12px;
    margin-top: 12px;
    border-radius: 8px;
    padding: 4px 8px 4px 8px;
    border: none;
    background: rgb(230, 230, 230);
    color: rgb(0, 0, 0);
    font-family: $font-6;
    transition: background 0.3s ease, transform 0.3s ease;
    font-weight: bold;

    &:hover{
        background: rgb(230, 230, 230);
        transform: translateY(-2px);
    }
    &:active{
        background: rgb(210, 210, 210);
        transform: translateY(0px);
    }
}
.button-unActive-category-prompt2-CreateItinerary_AI{
    margin-right: 12px;
    margin-top: 12px;
    border-radius: 10px;
    padding: 4px 8px 4px 8px;
    border: none;
    color: #333;
    background: whitesmoke;
    font-family: $font-6;
    transition: background 0.3s ease, transform 0.3s ease;

    &:hover{
        background: whitesmoke;
        transform: translateY(-2px);
    }
    &:active{
        background: rgb(210, 210, 210);
        transform: translateY(0px);
    }
}
.container_p-1-categories-CreateItinerary_AI{
    // background: lightpink;
    margin-left: 2.2%;
    margin-top: 2%;
    transition: height 5s ease-out;
}
.container_p-2-categories-CreateItinerary_AI{
    // background: lightpink;
    margin-left: 2.6%;
    margin-top: 1%;
}
.text-1-category-prompt2-CreateItinerary_AI{
    font-family: $font-6;
    margin-top: 4%;
    color: rgb(121, 121, 121);
    font-size: 15px;
}

.button-1-submit-CreateItinerary_AI{
    background: none;
    border: none;
    font-family: $font-heading-3;
    padding: 2px 30px 2px 30px;
    border-radius: 10px;
    margin-top: 3%;
    margin-left: 3%;
    font-size: 18px;
    color: yellowgreen;
    background: rgb(255, 234, 234);
    // width: 100%;
    align-items: self-start;
    text-align: start;

    &:hover{
        background: rgb(226, 226, 226);
    }
    &:active{
        background: rgb(255, 180, 180);
    }
}

//days
.days-CreateItinerary_AI {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 2%;
    margin-left: 3%;
}

.days-CreateItinerary_AI button {
    border: none;
    border-radius: 4px;
    background-color: #f0f0f0;
    color: #333;
    font-size: 16px;
    padding: 1px 12px 2px 12px;
    // border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.days-CreateItinerary_AI button:hover {
    background-color: #e0e0e0;
    transform: translateY(-2px);
}

.days-CreateItinerary_AI button:active {
    background-color: #d0d0d0;
    transform: translateY(0);
}

.days-CreateItinerary_AI input[type="text"] {
    width: 40px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px;
    font-size: 16px;
    background-color: #fff;
}
