
$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

.days-mainContainer-DaySelector_TD{
    display: flex;
    flex-direction: row;
}
.day-mainConatiner-DaySelector_TD{
    background-color: rgb(255, 255, 255);
    margin: 2px;
    padding: 2px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
}


//!Add Button
.conatiner_p-addDay-DaySelector_TD{
    width: 100%;
    // border: 1px solid lightcoral;
    margin-bottom: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.button-addDay-DaySelector_TD{
    display: flex;
    flex-direction: row;
    background: none;
    border: none;
    // border: 1px solid lightcoral;
    margin-left: 4px;
    border: 6px;
    padding: 4px;
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    transition: background-color 0.3s ease;
    
    &:hover{
        background-color: rgb(252, 240, 231);
        border-radius: 6px;
        border: 6px;
        padding: 4px;
    }
    &:active{
        background-color: rgb(238, 237, 237);
        border-radius: 6px;
        border: 6px;
        padding: 4px;
        transition: background-color 0.3s ease;
    }
}
.heading-addDay-DaySelector_TD{
    font-family: $font-5;
    font-size: 16px;
    color: gray;
}
.image-addDay-DaySelector_TD{
    width: 20px;
    height: 20px;
    margin-left: 12px;
    // filter: brightness(0.6);
    filter: brightness(0.2) contrast(0.6)
}

//!Days Container
.conatiner_p-Days-DaySelector_TD{
    display: flex;
    flex-direction: row;
    // border: gray 1px solid;
    margin: 1% 0% 4% 0%;
    overflow-y: hidden;
    overflow-x: scroll;
    padding-bottom: 3%;
    background: white;
    padding: 4px 0px 4px 0px;
    border-radius: 6px;
}

.conatiner_s-Days-DaySelector_TD{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 10px;
    // border: rgb(214, 214, 214) 1px solid;
    border: none;
    border-radius: 6px;
    padding: 8px 8px 8px 8px;
    background: none;
}
//
.conatiner_p-active-Day_Delete-DaySelector_TD{
    // border: rgb(214, 214, 214) 2px solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 2px 6px 2px 6px;
    border-radius: 6px;
    transition: all 0.3s ease;
    background: rgb(245, 114, 0);
}
.conatiner_p-unActive-Day_Delete-DaySelector_TD{
    border: rgb(214, 214, 214) 1px solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 2px 6px 2px 6px;
    border-radius: 6px;
}
.heading-Active-Day-DaySelector_TD{
    font-family: $font-6;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    font-size: 18px;
    padding: 4px 4px 2px 4px;
}
.heading-unActive-Day-DaySelector_TD{
    font-family: $font-6;
    color: rgb(75, 74, 74);
    white-space: nowrap;
    font-size: 18px;
    padding: 4px 4px 2px 4px;
}
.button-delete-Day-DaySelector_TD{
    border: none;
    background: none;
    margin: 3px 4px 0px 12px;
}
//day edit/add
.button-edit_add_date-DaySelector_TD{
    margin: 10px 0px 0px 0px;
    // border: none;
    font-size: 12px;
    background: rgb(248, 246, 246);
    border: 1px solid rgb(231, 230, 230);
    color: rgb(69, 67, 67);
    border-radius: 10px;
    padding: 1px 6px 1px 6px;
    font-family: $font-8;
    // letter-spacing: 0.4px;

    &:hover{
        // background: rgb(198, 160, 125);
        // color: white;
        color: rgb(90, 87, 87);
        border: 2px solid rgb(231, 230, 230);
        // border: none;
        background: whitesmoke;
        transition: background-color 0.3s ease;
        box-shadow: 0 0 2px rgb(255, 225, 197);
    }

    &:active{
        background-color: white;
        transition: background-color 0.3s ease;
        border: none;

    }
}
//input
.conatiner_p-inputDate-DaySelector_TD{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 8px 4px 4px 4px;
}
.input-inputDate-DaySelector_TD {
    padding: 2px 4px 2px 4px; /* Padding inside the input */
    border: 1px solid #ccc; /* Border color */
    border-radius: 4px; /* Rounded corners */
    font-size: 14px; /* Font size */
    color: #333; /* Text color */
    background-color: #f9f9f9; /* Background color */
    outline: none; /* Remove default outline on focus */
    transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for focus state */
}

.input-inputDate-DaySelector_TD:focus {
    border-color: #007bff; /* Border color on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Box shadow on focus */
}
.button-DaySelector_TD{
    font-size: 22px;
    margin: 0px 4px 0px 4px;

    &:hover{
        color: red;
    }

    &:active{
        color: lightcoral;
    }
}


//ask ai
.container-askAi-DaySelector_TD{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin-right: 1%;
    background: white;
    // align-items: center;
    // text-align: center;
    // justify-content: center;

    border: rgba(128, 0, 128, 0.405) 1px solid;
    border-radius: 10px;
    padding: 2px 6px 2px 6px;

    transition: background-color 0.3s ease;

    &:hover{
        background-color: rgb(245, 240, 255);
        border: rgb(135, 0, 135) 1px solid;
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(251, 248, 255);
        border: rgb(235, 0, 235) 1px solid;
        transition: background-color 0.3s ease;
    }
}
.text-trackAiQCount-DaySelector_TD{
    color: gray;
    font-family: $font-3;
    margin-top: -2px;
    margin-right: 8px;
    font-size: 12px;
}
.button-askAi-DaySelector_TD{
    border: none;
    background: none;
    font-family: $font-6;
    color: purple;
    margin-right: 12px;
}