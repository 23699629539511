$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


//!main div of the componenet
.container_p-Checklist_TD{
    margin: 2% 0% 1% 0%;
    background-color: rgb(255, 255, 255);
    display: flex;
    border-radius: 10px;
    padding: 1% 1% 1% 1%;
    display: flex;
    flex-direction: column;
}

//head - the heading that has the topic name, icon, and the delete icon
.conatiner_p-head-Checklist_TD{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    // border: 1px silver solid;
}
.conatiner_t-displayName_Icon-head-Checklist_TD{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
}
// .text-head-Checklist_TD{
//     font-family: $font-6;
//     margin-right: 16px;
//     margin-left: 16px;
// }


@media (min-width: 0px) and (max-width: 799px){
    .text-head-Checklist_TD{
        font-family: $font-6;
        margin-right: 16px;
        margin-left: 24px;
    }
    //delete icon for the topic
    .icon-delete-Checklist_TD{
        border: none;
        font-size: 18px;
        background: none;
        margin-right: 2px;
    
        &:hover{
            color: red;
            transition: color 0.3s ease;
        }
        &:active{
            color: orange;
            transition: color 0.3s ease;
        }
    }
}
@media (min-width: 800px){
    .text-head-Checklist_TD{
        font-family: $font-6;
        margin-right: 16px;
        margin-left: 16px;
    }
    //delete icon for the topic
    .icon-delete-Checklist_TD{
        border: none;
        font-size: 26px;
        background: none;
        margin-right: 10px;
    
        &:hover{
            color: red;
            transition: color 0.3s ease;
        }
        &:active{
            color: orange;
            transition: color 0.3s ease;
        }
    }
}


.icon-checklist-Checklist_TD{
    width: 25px;
    height: 25px;
}
// .icon-delete-Checklist_TD{
//     border: none;
//     font-size: 26px;
//     background: none;
//     margin-right: 10px;

//     &:hover{
//         color: red;
//         transition: color 0.3s ease;
//     }
//     &:active{
//         color: orange;
//         transition: color 0.3s ease;
//     }
// }

//? Input - description
.input-1-description-Checklist_TD{
    border: 1px solid rgb(171, 167, 167);
    width: 460px;
    padding: 8px 6px 4px 6px;
    margin: 8px 6px 4px 12px;
    border-radius: 4px;

    font-family: $font-3;
    font-weight: 600;
    letter-spacing: 0.4px;

    &:focus{
        outline: none;
        padding: 10px 4px 8px 12px;
        border: none;
        border-radius: 8px;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgba(122, 123, 124, 0.36) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
}
//
.conatiner_p-addCheckList-Checklist_TD{
    display: flex;
    flex-direction: row;
    padding: 4px 8px 4px 8px;
    margin: 8px 4px 8px 12px;
    border-radius: 6px;
    background: none;
    border: none;
    align-items: center;
    text-align: center;

    &:hover{
        background-color: whitesmoke;
        transition: background-color 0.3s ease;
        // padding: 4px 8px 4px 8px;
    }
    &:active{
        background-color: rgb(255, 201, 181);
        transition: background-color 0.5s ease;
        // padding: 0px 8px 0px 8px;
    }
}
.text-addCheckList-Checklist_TD{
    font-family: $font-content-2;
    margin-right: 12px;
    letter-spacing: 0.3px;
}
.icon-add-Checklist_TD{
    width: 24px;
    height: 24px;
}

//!Popup
.container_p-Popup-Checklist_TD{
    width: 96%;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    // border: 1px solid orangered;
    // margin-left: 8px;
}

@media (min-width: 0px) and (max-width: 799px){
    .container_s-active-Popup-Checklist_TD{
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: row;
        background: whitesmoke;
        width: 90%;
        justify-content: space-between;
        border-radius: 8px;
        padding: 4px 6px 4px 12px;
        margin: 8px 6px 8px 12px;
        box-shadow: 0 0 2px rgb(253, 224, 202);
    }
    .container_s-unActivePopup-Checklist_TD{
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: row;
        background: whitesmoke;
        width: 90%;
        justify-content: space-between;
        border-radius: 8px;
        padding: 4px 6px 4px 12px;
        margin: 8px 6px 8px 12px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
}
@media (min-width: 800px){
    .container_s-active-Popup-Checklist_TD{
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: row;
        background: whitesmoke;
        width: 46%;
        justify-content: space-between;
        border-radius: 8px;
        padding: 4px 6px 4px 12px;
        margin: 8px 6px 8px 12px;
        box-shadow: 0 0 2px rgb(253, 224, 202);
    }
    .container_s-unActivePopup-Checklist_TD{
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: row;
        background: whitesmoke;
        width: 46%;
        justify-content: space-between;
        border-radius: 8px;
        padding: 4px 6px 4px 12px;
        margin: 8px 6px 8px 12px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
}

// .container_s-active-Popup-Checklist_TD{
//     display: flex;
//     text-align: center;
//     align-items: center;
//     flex-direction: row;
//     background: whitesmoke;
//     width: 46%;
//     justify-content: space-between;
//     border-radius: 8px;
//     padding: 4px 6px 4px 12px;
//     margin: 8px 6px 8px 12px;
//     box-shadow: 0 0 2px rgb(253, 224, 202);
// }
// .container_s-unActivePopup-Checklist_TD{
//     display: flex;
//     text-align: center;
//     align-items: center;
//     flex-direction: row;
//     background: whitesmoke;
//     width: 46%;
//     justify-content: space-between;
//     border-radius: 8px;
//     padding: 4px 6px 4px 12px;
//     margin: 8px 6px 8px 12px;
//     // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
// }
//?conatiner 1 out of 2 - for checklist and description

//? conatiner 1
.container_t-1-Popup-Checklist_TD{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    // background: #d1cbcb;
    width: 90%;
}
.button-check-Popup-Checklist_TD{
    background: none;
    border: none;
    margin: 4px 4px 4px 4px;
    padding-bottom: 2px;

    border: 1px solid rgb(255, 176, 147);
    background: rgb(255, 255, 255);

    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    text-align: center;
}
.button-active-unCheck-Popup-Checklist_TD{
    margin: 4px 4px 4px 4px;
    border: 2px solid orangered;
    background: rgb(255, 255, 255);
    width: 20px;
    height: 20px;
    border-radius: 4px;
}
.button-unActive-unCheck-Popup-Checklist_TD{
    margin: 4px 4px 4px 4px;
    border: 2px solid rgb(183, 109, 82);
    background: rgb(255, 255, 255);
    width: 20px;
    height: 20px;
    border-radius: 4px;
}
.img-checklist-Popup-Checklist_TD{
    width: 26px;
    height: 26px;
}
.input-1-checklist-description-Popup-Checklist_TD{
    border: 1px solid rgb(255, 236, 236);
    border-bottom: 1px solid #d1cbcb;
    width: 100%;
    padding: 8px 6px 4px 6px;
    margin: 4px 4px 4px 4px;
    border-radius: 6px;

    font-family: $font-3;
    font-weight: 600;
    letter-spacing: 0.4px;

    &:focus{
        outline: none;
        background: rgb(255, 255, 255);
        border-bottom: 2px solid #ff7878;
        box-shadow: 0 0 4px rgb(244, 180, 148);
        // box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
}

//? conatiner 2
.icon-delete-Popup-Checklist_TD{
    border: none;
    font-size: 18px;
    background: none;
    margin: 4px 4px 4px 12px;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}