$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


.container_p-input-Search_Locations {
  position: relative;
}

.results-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px; /* Adjust height as needed */
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.result-item {
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
}

.result-item button {
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
}

.result-item button:hover {
  background-color: #f1f1f1;
}

.result-item span {
  display: inline;
}
