$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


//*#1 Hotel
// .container_s-Search_TravelCategories{
//     width: 60%;
//     // border: 2px orange solid;
//     border: none;
//     background: none;
//     cursor:default;
// }

@media (min-width: 0px) and (max-width: 499px){
    .img-hotel-Search_TravelCategories {
        /* width: 100%; */
        width: 100%;
        /* border: 2px orange solid; */
        border: none;
        background: none;
        cursor: pointer;

        &:hover {
            box-shadow: rgba(255, 213, 108, 0.878) 0px 0px 6px;
            transition: box-shadow 0.3s ease;
        }
    }
    .container_s-Search_TravelCategories{
        width: 100%;
        // border: 2px orange solid;
        border: none;
        background: none;
        cursor:default;
    }
}

@media (min-width: 500px) and (max-width: 1000px){
    .img-hotel-Search_TravelCategories{
        width: 100%;
        // border: 2px orange solid;
        border: none;
        background: none;
        cursor: pointer;
        
        &:hover{
            box-shadow: rgba(255, 213, 108, 0.878) 0px 0px 6px;
            transition: box-shadow 0.3s ease;
        }
    }
    .container_s-Search_TravelCategories{
        width: 60%;
        // border: 2px orange solid;
        border: none;
        background: none;
        cursor:default;
    }
}

@media (min-width: 1000px){
    .img-hotel-Search_TravelCategories{
        width: 60%;
        // border: 10px orange solid;
        border: none;
        background: none;
        cursor: pointer;
        
        &:hover{
            box-shadow: rgba(255, 213, 108, 0.878) 0px 0px 6px;
            transition: box-shadow 0.3s ease;
        }
    }
    .container_s-Search_TravelCategories{
        width: 60%;
        // border: 2px orange solid;
        border: none;
        background: none;
        cursor:default;
    }
}

