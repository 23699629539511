$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


/* Background overlay */
.popup-background-1-Hotel_ViewMore {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
}

//!popup container
//?1
.popup_p-2-addNote-Hotel_ViewMore{
    width: 64%;
    height: 90%;
    position: fixed;
    top: 4%;
    left: 18%;
    border: none;
    border-radius: 8px;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(34, 92, 137, 0.835);
    z-index: 10010;
    overflow: auto;
    // display: flex;
    // align-items: center;
    // text-align: center;
    // justify-content: center;
}
.popup_s-2-addNote-Hotel_ViewMore{
    width: 98%;
    height: 98%;
    top: 10%;
    left: 20%;
    // border: 1px solid #000;
    background: #ffffff;
    padding: 20px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10020;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.popup_s-2-addNote-Hotel_ViewMore::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.popup_s-2-addNote-Hotel_ViewMore::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.popup_s-2-addNote-Hotel_ViewMore::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  


.heading-1-heading-Hotel_ViewMore{
    font-family: $font-6;
    font-size: 22px;
    color: brown;
    // color: rgb(88, 87, 87);
}

//
.container_p-Reviews-Hotel_ViewMore{
    // display: flex;
    // flex-direction: row;
    // border: 1px solid red;
    margin-top: 12px;
}
.container_s-Reviews-Hotel_ViewMore{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 4%;
}
.container_t-1-Reviews-Hotel_ViewMore{
    border: none;
    background: none;
    margin-left: 20px;
    margin-top: 6px;
    font-size: 22px;
    width: 8%;
    // border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
        color: orange;
    }
    &:active{
        color: red;
    }
}
.container_t-2-Reviews-Hotel_ViewMore{
    width: 90%;
    // border: 1px lightblue solid;

}
.content-Review-Hotel_ViewMore{
    align-items: start;
    text-align: start;
    // font
}

.container_t-3-Reviews-Hotel_ViewMore{
    border: none;
    background: none;
    margin-left: 20px;
    margin-top: 6px;
    font-size: 22px;
    width: 8%;

    &:hover{
        color: orange;
    }
    &:active{
        color: red;
    }
}