$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//




.container_p-HowToReach_Initial{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4%;
    position: relative;
    // text-align: center;
}
// .container_s-HowToReach_Initial{
//     width: 60%;
//     // border: 1px solid olivedrab;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
// }

@media (min-width: 0px) and (max-width: 499px){
    .container_s-HowToReach_Initial{
        // width: 44%;
        width: 90%;
        margin-top: 8%;
        background: white;
        padding: 8px 20px 8px 20px;
        border-radius: 12px;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 18px;
        box-shadow: rgba(143, 141, 136, 0.418) 0px 0px 6px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        // position: relative;
        // height: 40px;
    }
    .img-1-design-HowToReach_Initial{
        width: 140px;
        height: 60px;
        position: absolute;
        top: -30px;
        left: 6%;
        z-index: -1;
    }
    .img-2-design-HowToReach_Initial{
        width: 160px;
        height: 160px;  
        position: absolute;
        z-index: -2;
        right: -0%;
        top: -30px;  
        display: none;
    }
    .img-3-design-HowToReach_Initial{
        width: 40px;
        height: 40px;  
        position: absolute;
        z-index: -1;
        right: 240px;
        bottom: -20px;  
        display: none;
    }
    .text2-HowToReach_Initial {
        color: rgb(0, 0, 0);
        font-family: $font-6;
        margin-right: 12px;
        opacity: 1;
        transition: opacity 0.3s ease;
        display: none;
    }
}
@media (min-width: 500px) and (max-width: 1000px){
    .container_s-HowToReach_Initial{
        // width: 44%;
        width: fit-content;
        margin-top: 4%;
        background: white;
        padding: 8px 20px 8px 20px;
        border-radius: 12px;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 18px;
        box-shadow: rgba(143, 141, 136, 0.418) 0px 0px 6px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        // position: relative;
        // height: 40px;
    }
    .img-1-design-HowToReach_Initial{
        width: 140px;
        height: 60px;
        position: absolute;
        top: -20px;
        left: 20%;
        z-index: -1;
    }
    .img-2-design-HowToReach_Initial{
        width: 160px;
        height: 160px;  
        position: absolute;
        z-index: -2;
        right: 200px;
        top: -30px;  
        display: none;
    }
    .img-3-design-HowToReach_Initial{
        width: 40px;
        height: 40px;  
        position: absolute;
        z-index: -1;
        right: 240px;
        bottom: -20px;  
        display: none;
    }
    .text2-HowToReach_Initial {
        color: rgb(0, 0, 0);
        font-family: $font-6;
        margin-right: 12px;
        opacity: 1;
        transition: opacity 0.3s ease;
    }
}
@media (min-width: 1000px){
    .container_s-HowToReach_Initial{
        width: 44%;
        // width: fit-content;
        margin-top: 4%;
        background: white;
        padding: 8px 20px 8px 20px;
        border-radius: 12px;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 18px;
        box-shadow: rgba(143, 141, 136, 0.418) 0px 0px 6px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        // position: relative;
        // height: 40px;
    }
    .img-1-design-HowToReach_Initial{
        width: 140px;
        height: 60px;
        position: absolute;
        top: -8px;
        left: 280px;
        z-index: -1;
    }
    .img-2-design-HowToReach_Initial{
        width: 160px;
        height: 160px;  
        position: absolute;
        z-index: -2;
        right: 200px;
        top: -30px;  
    }
    .img-3-design-HowToReach_Initial{
        width: 40px;
        height: 40px;  
        position: absolute;
        z-index: -1;
        right: 240px;
        bottom: -20px;  
    }
    .text2-HowToReach_Initial {
        color: rgb(0, 0, 0);
        font-family: $font-6;
        margin-right: 12px;
        opacity: 1;
        transition: opacity 0.3s ease;
    }
}





.text1-HowToReach_Initial{
    color: red;
    font-family: $font-6;
    margin-right: 20px;
    font-size: 16px;
}
// .container_p-modeButton-HowToReach_Initial{
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-right: 8px;
//     border-radius: 12px;
//     border: none;
//     background: rgb(255, 194, 170);
//     padding: 4px 6px 4px 6px;
//     font-size: 16px;
// }
// .text2-HowToReach_Initial{
//     color: rgb(0, 0, 0);
//     font-family: $font-6;
//     margin-right: 12px;
// }
// .icon1-HowToReach_Initial{
    
// }

.container_p-unactive-modeButton-HowToReach_Initial {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 12px;
    border: none;
    // background: rgb(255, 194, 170);
    background: none;
    border: none;
    padding: 4px 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    position: relative;
}

.container_p-modeButton-HowToReach_Initial {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 12px;
    border: none;
    background: rgb(255, 194, 170);
    padding: 4px 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    position: relative;
}

.container_p-modeButton-HowToReach_Initial:hover {
    background-color: rgb(245, 184, 160);
    transform: translateY(-2px);
}

// .text2-HowToReach_Initial {
//     color: rgb(0, 0, 0);
//     font-family: $font-6;
//     margin-right: 12px;
//     opacity: 1;
//     transition: opacity 0.3s ease;
// }

.icon1-HowToReach_Initial {
    font-size: 20px;
    color: #000000;
    transition: color 0.3s ease;
}

// .container_p-modeButton-HowToReach_Initial:hover .icon1-HowToReach_Initial,
// .container_p-modeButton-HowToReach_Initial.active .icon1-HowToReach_Initial {
//     color: #333;
// }



.icon2-HowToReach_Initial{
    font-size: 24px;
}

//
// .img-1-design-HowToReach_Initial{
//     width: 140px;
//     height: 60px;
//     position: absolute;
//     top: -8px;
//     left: 280px;
//     z-index: -1;
// }
// .img-2-design-HowToReach_Initial{
//     width: 160px;
//     height: 160px;  
//     position: absolute;
//     z-index: -2;
//     right: 200px;
//     top: -30px;  
// }
// .img-3-design-HowToReach_Initial{
//     width: 40px;
//     height: 40px;  
//     position: absolute;
//     z-index: -1;
//     right: 240px;
//     bottom: -20px;  
// }