$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//



/* Overlay: semi-transparent background */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent black screen */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000000000; /* Ensure it stays above all other content */
  }
  
  /* Spinner container */
  .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Spinner animation */
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.1); /* Lighter border */
    border-left-color: #3498db; /* Spinner color */
    border-radius: 50%;
    width: 50px;  /* Adjusted size */
    height: 50px; /* Adjusted size */
    animation: spin 1s linear infinite;
  }
  
  /* Text below spinner */
  .spinner-container p {
    color: white;
    font-size: 16px;
    margin-top: 10px;
    font-family: Arial, sans-serif;
  }
  
  /* Keyframes for spinner rotation */
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  









//
.conatiner_p-HotelCards-Content_Hotels{
    display: flex; 
    flex-direction: row;
    flex-wrap: wrap;
    // border: 1px solid red;
    background: whitesmoke;
    padding: 8px;
    border-radius: 6px;
    margin-top: 2%;
    justify-content: center;
}
.conatiner_s-HotelCard-Content_Hotels{
    // border: 2px solid gray;
    background: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: none;
    border-radius: 8px;
    margin: 0px 0px 24px 0px;
    width: 100%;
    padding: 6px;
    display: flex;
    flex-direction: row;
}
.conatiner_t-HotelCard-Content_Hotels{
    // border: 2px solid gray;
    background: white;
    border: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

//
//container 1
.conatiner_t-1-Content-HotelCard-Content_Hotels{
    width: 34%;
    // height: 100%;
    // border: 2px solid salmon;
    // display: flex;
    // flex-direction: column;
    // text-align: center;
    // justify-content: center;
    // align-items: center;
    // justify-content: space-around;
}
.conatiner_t-1-2-Content-HotelCard-Content_Hotels{
    display: flex;
    flex-direction: column;
    // border: 2px solid gray;
    width: 100%;
}
.image-HotelCard-Content_Hotels{
    // border: 2px solid rgb(149, 102, 9);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 99%;
    height: 180px;
    // margin-left: -0.6%;
    // margin-top: -0.6%;
    // height: 190px;
    object-fit: cover;
    // margin-top: 0.5%;
    border-radius: 10px;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    // border-radius: 10px;
}
.conatiner_p-imageIcons-HotelCard-Content_Hotels{
    display: flex;
    flex-direction: row;
    margin-top: -40px;
    // border: 1px salmon solid;
    justify-content: center;
    z-index: 10px;
}
.icon-left-image-HotelCard-Content_Hotels{
    border: none;
    background: none;
    margin-right: 20px;
    margin-top: 6px;
    font-size: 18px;
    color: white;

    &:hover{
        color: orange;
    }
    &:active{
        color: red;
    }
}
.icon-right-image-HotelCard-Content_Hotels{
    border: none;
    background: none;
    margin-left: 20px;
    margin-top: 6px;
    font-size: 18px;
    color: white;

    &:hover{
        color: orange;
    }
    &:active{
        color: red;
    }
}
.heading-1-reviews-Content_Hotels{
    padding: 3px 20px 2px 20px;
    border-radius: 6px;
    border: none;
    background: rgb(255, 255, 255);
    font-family: $font-6;
    margin: 12px 2px 6px 2px;
    box-shadow: 0 0 2px rgba(133, 132, 132, 0.475);

    &:hover{
        color: white;
        background-color: orange;
        transition: background-color 0.2s ease;
    }
    &:hover{
        color: white;
        background-color: red;
        transition: background-color 0.3s ease;
    }
}
.container_p-minPrice-Content_Hotels{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: whitesmoke;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 24px;
}
.text-1-minPrice-Content_Hotels{
    color: rgb(110, 106, 106);
    font-size: $font-4;
    font-size: 15px;
}
.text-2-minPrice-Content_Hotels{
    color: orangered;
    font-size: $font-6;
    font-size: 16px;
    margin-left: 8px;
}


.container_p-reviews-Content_Hotels{
    border: 2px solid purple;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: scroll;
}
.container_s-review-Content_Hotels{
    display: flex;
    flex-direction: column;
    border: 1px solid greenyellow;
}

//!
//container 2
.conatiner_t-2-Content-HotelCard-Content_Hotels{
    display: flex;
    flex-direction: column;
    // border: 2px solid gray;
    width: 65%; //other 36%
    // height: 300px;
    // overflow-y: scroll;
    // overflow-x: hidden;
}

//content containers //*#1
.container_p-heading-Content_Hotels{

}
.container_t-1-Content_Hotels{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 6px;
    margin-left: 12px;
    // background-color: white;
    text-align: center;
    align-items: center;
}
.container_t-2-Content_Hotels{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    // margin-top: 10px;
    margin-left: 12px;
}
.heading-1-Content_Hotels{
    color: brown;
    margin-right: 12px;
    font-size: 15px;
    font-family: $font-6;
    align-items: self-start;
    text-align: start;
}
.heading-2-Content_Hotels{
    color: rgb(52, 51, 51);
    margin-right: 6px;
    font-family: $font-4;
    margin-left: 2px;
}
.heading-3-Content_Hotels{
    color: rgb(0, 0, 0);
    margin-right: 12px;
    font-family: $font-6;
    margin-left: 4px;
}
.text-1-Content_Hotels{
    font-family: $font-heading-1;
    color: rgb(145, 95, 1);
    margin-top: -4px;
    margin-left: 2px;
}
.text-2-Content_Hotels{
    font-family: $font-heading-1;
    color: rgb(58, 58, 56);
    margin-top: -4px;
    margin-left: 8px;
}



//content containers //*#2
.container_p-amenities-Content_Hotels{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // border: 1px solid orchid;
    align-items: start;
    text-align: start;
    margin-top: 12px;
    width: 90%;
    margin-left: 13px;
    align-self: start;
    // justify-content: center;
}
.icon-1-amenities-Content_Hotels{
    margin-right: 4px;
    position: relative;
    font-size: 20px;
    color: rgb(137, 135, 135);

    &:hover{
        color: yellowgreen;
    }
}
.hover-text-icon-amenities-Content_Hotels{
    position: absolute;
    padding: 2px 4px 3px 4px;
    border-radius: 8px;
    background: gray;
    color: white;
    font-family: $font-5;
    font-size: 14px;
    white-space: nowrap;
}

//content containers //*#3
.container_p-bookDirect-Content_Hotels{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid rgb(38, 61, 193);
    // width: 10%;
}


//* for #4 and #5
// .test-Content_Hotels{
//     display: flex;
//     flex-direction: row;
//     background: red;
// }

//content containers //*#4
.container_p-bestDeal-Content_Hotels{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border: 1px solid orchid;
    // width: 30%;
}
.conatiner_t-metaData-bestDeal-Content_Hotels{
    display: flex;
    flex-direction: row;
}

//content containers //*#5
.container_p-otherPrices-Content_Hotels{
    // width: 70%;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    // border: 1px solid rgb(108, 107, 108);
    // background: rgb(255, 234, 234);
    // background: whitesmoke;
    padding: 6px 6px 0px 6px;
    margin: 0px 0px 0px 0px;
    // margin: 6px;
    border-radius: 6px;
}
//?Best Deal
.container_s-card-bestDeal-Content_Hotels{
    display: flex;
    flex-direction: column;
    // border: 2px solid rgb(255, 89, 0);
    box-shadow: 0 0 4px rgba(255, 176, 147, 0.907);
    background: white;
    margin: 0px 20px 0px 4px;
    padding: 6px 4px 4px 4px;
    border-radius: 20px;
    min-width: 180px;
    max-width: 180px;
    // min-height: 140px;
    // max-height: 100%;
}
.heading-1-bestDeal-Content_Hotels{
    font-size: 16px;
    // color: rgb(75, 36, 2);
    color: rgb(143, 205, 21);
    // font-family: $font-content-2;
    font-family: $font-6;
    // margin-left: 6px;
}
.text-1-bestDeal-Content_Hotels{
    font-family: $font-6;
    color: brown;
    font-size: 16px;
    margin-top: 6px;
}
.text-2-bestDeal-Content_Hotels{
    margin-top: 12px;
    font-family: $font-6;
    color: rgb(45, 100, 202);
    font-size: 18px;
}
.link-1-MetaData-bestDeal-Content_Hotels{
    color: rgb(23, 81, 126);
    margin-top: 6px;
    font-family: $font-5;
    letter-spacing: 0.6px;

    &:hover{
        color: yellowgreen;
        transition: color 0.3s ease;
    }
    &:active{
        color: yellow;
        transition: color 0.3s ease;
    }
}


//?Other Deals
.container_s-cards-otherPrices-Content_Hotels{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 4px;
    // border: 4px solid rgb(31, 63, 128);
    overflow-y: hidden;
    overflow-x: scroll;
    align-items: center;
    // justify-content: center;
}
.container_s-card-otherPrices-Content_Hotels{
    display: flex;
    flex-direction: column;
    // border: 2px solid rgb(255, 241, 171);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    background: white;
    margin: 0px 20px 0px 4px;
    padding: 6px 4px 4px 4px;
    border-radius: 10px;
    min-width: 180px;
    max-width: 180px;
    height: fit-content;
}
.text-1-otherPrices-Content_Hotels{
    font-family: $font-6;
    color: brown;
    font-size: 16px;
    margin-top: 6px;
}
.text-2-otherPrices-Content_Hotels{
    margin-top: 12px;
    font-family: $font-6;
    color: rgb(45, 100, 202);
    font-size: 18px;
}
.link-1-MetaData-otherPrices-Content_Hotels{
    color: rgb(23, 81, 126);
    margin-top: 6px;
    // margin-bottom: 6px;
    font-family: $font-5;
    letter-spacing: 0.6px;

    &:hover{
        color: yellowgreen;
        transition: color 0.3s ease;
    }
    &:active{
        color: yellow;
        transition: color 0.3s ease;
    }
}

.container_t-MetaData-otherPrices-Content_Hotels{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid rgb(255, 246, 242);
    background: white;
    margin: 12px 0px 0px 4px;
    padding: 4px;
    border-radius: 6px;
    justify-content: center;
}
.conatiner_t-amenities-otherPrices-Content_Hotels{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    margin-right: 4px;
    margin-top: 4px;
    padding: 1px 4px 2px 4px;
    border-radius: 8px;
    background: rgb(247, 246, 246);
}
.icon-1-amenities-otherPrices-Content_Hotels{
    font-size: 12px;
    font-family: $font-4;
    color: rgb(108, 105, 105);
    margin-right: 4px;
}
.text-1-amenities-otherPrices-Content_Hotels{
    font-size: 12px;
    font-family: $font-4;
    color: rgb(108, 105, 105);
    // white-space: nowrap;
}


//content containers //*#6
.container_p-viewMore-Content_Hotels{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid orchid;
}















.mainContainer-ContentHotels{
    display: flex;
    flex-wrap: wrap;
    padding: 4px;
    border: 2px solid lightsalmon;
    border-radius: 8px;
    margin-top: 8%;
}
.mainHotelCard-ContentHotels{
    padding: 2px;
    border: 1px solid rgb(159, 159, 159);
    border-radius: 4px;
}

.temp-Content_Hotels{
    border: 2px solid rgb(209, 209, 209);
    border-radius: 8px;
    padding: 6px;
    height: 100px;
    overflow: hidden;
}