$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

//!Document
.conatiner_p-Documents-TripDocuments{
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}
.conatiner_s-Documents-TripDocuments{
    display: flex;
    flex-direction: column;
    border: 1px orangered solid;
    border-radius: 8px;
    padding: 8px 4px 8px 12px;
    margin: 12px 0px 12px 0px;
    width: 96%;
}
//*Container 1
.conatiner_p-1-Document-TripDocuments{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}
.conatiner_t-1_1-deleteButton-Document-TripDocuments{
    // border: 1px rgb(255, 0, 115) solid;
    width: 96%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}
.conatiner_t-1_2-deleteButton-Document-TripDocuments{
    // border: 1px rgb(240, 209, 197) solid;
    width: 4%;
    display: flex;
    align-items: center;
    text-align: center;
}
//description
//link
.heading-1-Heading-Document-TripDocuments{
    // border: 1px rgb(240, 209, 197) solid;      
    width: 42%;     
    align-items: start;
    text-align: start;
    background: whitesmoke;
    border-radius: 6px;
    margin: 2px;
    padding: 4px 4px 4px 6px;
    font-family: $font-5;
    font-size: 16px;
}
.conatiner_t-link_open-Document-TripDocuments{
    // border: 1px rgb(240, 209, 197) solid;   
    width: 30%;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
}
.link-Document-TripDocuments{
    padding: 2px 6px 2px 6px;
    border-radius: 8px;
    font-family: $font-content-1;
    font-size: 14px;
    color: rgb(12, 65, 122);
    border: 1px solid lightskyblue;
    width: 200px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
}
.img-link-Document-TripDocuments{
    width: 24px;
    width: 24px;
    margin-top: 4px;
}
.button-detail-Documents-TripDocuments{
    // border: 1px rgb(240, 209, 197) solid;   
    border: none;
    background: none;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    padding: 1px 8px 1px 10px;
    border-radius: 6px;
    // background-color: whitesmoke;
    background: rgb(248, 248, 248);
    font-family: $font-content-1;

    &:hover{
        background-color: rgb(255, 229, 229);
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(255, 255, 255);
        transition: background-color 0.3s ease;
    }
}

//*Conatiner 2
.conatiner_p-2-Document-TripDocuments{
    width: 96%;
    // border: 1px solid rgb(104, 216, 59);
}
.heading-detail-Documents-TripDocuments{
    font-family: $font-6;
    color: gray;
    margin: 16px 10px 10px 6px;
}
.text-1-notFound-Documents-TripDocuments{
    font-family: $font-5;
    margin: 16px 10px 10px 6px;
    font-size: 16px;
    align-self: center;
    color: rgb(89, 89, 87);
}
.text-2-notFound-Documents-TripDocuments{
    font-family: $font-4;
    margin: 16px 10px 10px 6px;
    font-size: 16px;
    align-self: center;
    color: rgb(120, 120, 116);
}
.button-icon-delete-Document-TripDocuments{
    border: none;
    background: none;
    font-size: 18px;
    align-items: end;
    color: rgb(117, 113, 113);

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: rgb(198, 116, 116);
        transition: color 0.3s ease;
    }
}
.content-detail-Documents-TripDocuments{
    width: 60%;
    word-wrap: break-word;
    margin-top: 10px;
    padding: 12px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid rgb(164, 166, 164);
    color: rgb(146, 143, 143);
    font-family: $font-content-1;
}


//! Popup

.popup-addNote-popup-TripDocuments{
    width: 60%;
    height: 80%;
    position: fixed;
    top: 10%;
    left: 20%;
    border: 1px solid #cec8c8;
    border-radius: 12px;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000000000000000000000;
    overflow: auto;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.message-text-popup-TripDocuments{
    font-size: 12px;
    font-family: $font-4;
    letter-spacing: 0.4px;
    color: rgb(247, 178, 51);
    margin-bottom: 12px;
    width: 96%;
}
.button-close-popup-TripDocuments{
    font-size: 22px;
    border: none;
    background: none;
    position: fixed;
    right: 16.2%;
    top: 7%;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}
//? Input - description
.input-1-description-popup-TripDocuments{
    border: 1px solid rgb(171, 167, 167);
    width: 460px;
    padding: 14px 4px 8px 12px;
    margin: 30px 6px 20px 12px;
    border-radius: 4px;

    font-family: $font-3;
    font-weight: 600;
    letter-spacing: 0.4px;

    &:focus{
        outline: none;
        padding: 14px 4px 8px 12px;
        border: none;
        border-radius: 8px;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgba(122, 123, 124, 0.36) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
}
.container_t-linkName-popup-TripDocuments{
    position: absolute;
    top: 10px;
    left: 60px;
    background: white;
    padding: 0px 6px 0px 6px;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
}
.text-1-linkName-popup-TripDocuments{
    margin-right: 10px;
    font-family: $font-6;
    color: rgb(125, 127, 129);
    font-size: 14px;
}
.icon-link-linkName-popup-TripDocuments{
    color: rgb(70, 126, 145);
    font-size: 20px;
    margin-top: 2px;
}
.text-1-description-popup-TripDocuments{
    color: rgb(98, 93, 93);
    font-family: $font-6;
    margin-left: 12px;
    margin-top: 6px;
}
.input-2-description-popup-TripDocuments{
    border: 1px solid rgb(171, 167, 167);
    width: 520px;
    height: 100px;
    padding: 14px 4px 8px 12px;
    margin: 14px 6px 20px 8px;
    border-radius: 4px;

    font-family: $font-3;
    font-weight: 600;
    letter-spacing: 0.4px;

    &:focus{
        outline: none;
        padding: 14px 4px 8px 12px;
        border: none;
        border-radius: 8px;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgba(122, 123, 124, 0.36) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
}
.conatiner_t-3-heading-TripDocuments{
    width: 20%;
    font-family: $font-4;
    font-size: 14px;
    color: gray;
}
// .input-3-description-popup-TripDocuments{
//     border: 1px solid rgb(171, 167, 167);
//     width: 460px;
//     padding: 14px 4px 8px 12px;
//     margin: 14px 6px 20px 8px;
//     border-radius: 4px;

//     font-family: $font-3;
//     font-weight: 600;
//     letter-spacing: 0.4px;

//     &:focus{
//         outline: none;
//         padding: 14px 4px 8px 12px;
//         border: none;
//         border-radius: 8px;
//         box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgba(122, 123, 124, 0.36) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
//     }
// }