$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//


@media (min-width: 0px) and (max-width: 799px){
  .container_p-destinationHeading-DestinationCreativeHeader_D {
    position: relative;
    width: 100%;
    // height: 280px; // Adjust height as needed
    margin: auto; // Center the container horizontally
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgb(208, 61, 61);
  }
  
  .image-destinationHeading-DestinationCreativeHeader_D {
    width: 100%;
    // height: 100%;
    height: 360px;
    object-fit: cover;
    border-radius: 8px;
    z-index: 0;
  }
  
  .overlay-imageFilterDiv-DestinationCreativeHeader_D {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.44); // Slightly transparent black filter
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 8px; // Match the image's border radius
    z-index: 1;
  }
  
  .container_t-textConatiner-DestinationCreativeHeader_D {
    position: absolute;
    top: 16%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    //  background: rgb(208, 61, 61);
  }
  
  .text-aboutDestination-DestinationCreativeHeader_D {
    // font-family: 'Montserrat', sans-serif;
    font-family: $font-6;
    font-size: 24px;
    color: white;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
  }
  .text-2-aboutDestination-DestinationCreativeHeader_D {
    // font-family: 'Montserrat', sans-serif;
    font-family: $font-3;
    // font-weight: bold;
    font-size: 18px;
    color: rgb(236, 236, 236);
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    white-space: nowrap;
  }
  
  .container_t-2-textConatiner-DestinationCreativeHeader_D {
    position: absolute;
    // height: 100px;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 98%;
    overflow: hidden;
    // border: 1px solid black;
  }
  .text-3-aboutDestination-DestinationCreativeHeader_D {
    // font-family: $font-content-1;
    font-family: $font-3;
    font-size: 16px;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    letter-spacing: 1.2px;
    word-spacing: 2px;
  }
  
}
// @media (min-width: 300px) and (max-width: 499px){
// }
// @media (min-width: 450px) and (max-width: 799px){
// }
@media (min-width: 800px){
  .container_p-destinationHeading-DestinationCreativeHeader_D {
    position: relative;
    width: 100%;
    // height: 280px; // Adjust height as needed
    margin: auto; // Center the container horizontally
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-destinationHeading-DestinationCreativeHeader_D {
    width: 100%;
    // height: 100%;
    height: 360px;
    object-fit: cover;
    border-radius: 8px;
    z-index: 0;
  }
  
  .overlay-imageFilterDiv-DestinationCreativeHeader_D {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.44); // Slightly transparent black filter
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 8px; // Match the image's border radius
    z-index: 1;
  }
  
  .container_t-textConatiner-DestinationCreativeHeader_D {
    position: absolute;
    top: 28%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  
  .text-aboutDestination-DestinationCreativeHeader_D {
    // font-family: 'Montserrat', sans-serif;
    font-family: $font-6;
    font-size: 30px;
    color: white;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
  }
  .text-2-aboutDestination-DestinationCreativeHeader_D {
    // font-family: 'Montserrat', sans-serif;
    font-family: $font-3;
    // font-weight: bold;
    font-size: 18px;
    color: rgb(236, 236, 236);
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    // white-space: nowrap;
  }
  
  .container_t-2-textConatiner-DestinationCreativeHeader_D {
    position: absolute;
    height: 100px;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 60%;
    // border: 1px solid black;
  }
  .text-3-aboutDestination-DestinationCreativeHeader_D {
    // font-family: $font-content-1;
    font-family: $font-3;
    font-size: 18px;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    letter-spacing: 1.2px;
    word-spacing: 2px;
  }
  
}


