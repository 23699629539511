$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//

.CategoriesPlaces-TouristPlaces{
    display: flex;
    flex-direction: row;
}
.conatiner_p-heading-TravelCategory-TouristPlaces{
    // width: 100%;
    // display: flex;
    // align-items: start;
    // text-align: start;
    margin: 6px;
}
.heading-TravelCategory-TouristPlaces{
    font-size: 24px;
    color: rgb(22, 22, 22);
    font-family: $font-6;
    // align-self: self-start;
    // margin-left: 70px;
}


//! main tourist places navigation
.container_p-Navigation-TouristPlaces{
    margin: 4% 4px 4% 4px;
    // background: rgb(203, 247, 156);
}
.heading-active-Navigation-TouristPlaces{
    background: rgb(236, 236, 236);
    color: rgb(50, 49, 49);
    padding: 6px 16px 6px 16px;
    border-radius: 8px;
    border: 1px dotted rgb(199, 196, 196);
    margin: 6px;
    font-size: 16px;
    // font-family: $font-content-2;
    cursor: pointer;
    font-family: $font-4;
    font-weight: bold;
}
.heading-unActive-Navigation-TouristPlaces{
    background: rgb(255, 255, 255);
    color: rgb(94, 91, 91);
    padding: 6px 16px 6px 16px;
    border-radius: 8px;
    border: 1px dotted rgb(206, 201, 201);
    margin: 6px;
    font-size: 16px;
    // font-family: $font-content-2;
    cursor: pointer;
    font-family: $font-4;
    font-weight: bold;
}

.touristPlaceCategoryActive-PlaceCards04{
    margin: 4px;
    padding: 4px;
    border-radius: 4px;
    background-color: rgb(211, 150, 19); /* Green color when active */
    color: white;
    border: 1px solid #e6c018;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle box shadow when active */
}
.touristPlaceCategory-PlaceCards04{
    margin: 2px;
     padding: 4px;
    border-radius: 4px;
    background-color: #f2f2f2; /* Light grey color when inactive */
    color: #333;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Subtle box shadow when inactive */
}

.touristPlace_CategoryActive-PlaceCards04{
    margin: 2px;
    padding: 4px;
    border-radius: 4px;
    background-color: #4CAF50; /* Green color when active */
    color: white;
    border: 1px solid #4CAF50;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle box shadow when active */
}
.touristPlace_Category-PlaceCards04{
    margin: 2px;
    padding: 4px;
    border-radius: 4px;
    background-color: #f2f2f2; /* Light grey color when inactive */
    color: #333;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Subtle box shadow when inactive */
}

//!
//!App
@media (min-width: 0px) and (max-width: 849px){
    .container_t-placeType-TouristPlaces{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0px 20px 0px;
        // border: 4px rgb(235, 212, 61) dotted;
    }
    .heading-placeType-TouristPlaces{
        font-size: 18px;
        // font-family: $font-6;
        font-family: 'Satisfy';
        letter-spacing: 2px;
        word-spacing: 0.4px;
        font-weight: bold;
        // color: rgb(242, 83, 83);
        color: rgb(216, 67, 47);
        margin-left: 6%;
        // margin-bottom: 2%;
    }
}
//!Website
@media (min-width: 850px){
    .container_t-placeType-TouristPlaces{
        display: flex;
        align-items: start;
        justify-content: start;
        margin: 10px 0px 20px 0px;
        // border: 4px rgb(235, 212, 61) dotted;
    }
    .heading-placeType-TouristPlaces{
        font-size: 18px;
        // font-family: $font-6;
        font-family: 'Satisfy';
        letter-spacing: 2px;
        word-spacing: 0.4px;
        font-weight: bold;
        // color: rgb(242, 83, 83);
        color: rgb(216, 67, 47);
        margin-left: 6%;
        // margin-bottom: 2%;
    }
}

//!Map - Mobile
.continer_p-Map-Mobile-TouristPlaces{
    width: 100%;
    // border: 3px solid rgb(5, 99, 134);
    position: absolute;
    // display: flex;
    // align-items: center;
    // text-align: center;
    // justify-content: center;
    z-index: 1000;
    // margin-left: -6%;
}
.continer_s-Map-Mobile-TouristPlaces{
    width: 100%;
    height: 100%;
    
    border-radius: 8px;
    // right: 1%;
    top: 1%;
    position: absolute;
    box-shadow: 0 0 2px rgb(97, 97, 97); 
    // border: 3px solid rgb(54, 5, 134);
    overflow: hidden;
}
.button-close-Mobile-TouristPlaces{
    background: none;
    border: none;
    font-size: 32px;
    position: fixed;
    // bottom: 600px;
    z-index: 10000;
    top: 30%;
    right: 2%;  
    // top: -360px;
    // right: 40px;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}
.button-close-Hotel-Mobile-TouristPlaces{
    background: none;
    border: none;
    font-size: 32px;
    position: fixed;
    // bottom: 600px;
    z-index: 100000;
    top: 16%;
    left: 2%;  
    // top: -360px;
    // right: 40px;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}
.container_p-view_hotels-Hotels_Mobile{
    width: 100%;
    background: whitesmoke;
    padding: 8px 2px 8px 2px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    margin-bottom: 8%;
}
.button-view_hotels-Mobile-TouristPlaces{
    border: none;
    background: none;
    // margin-top: 1%;
    // margin-left: 2%;
}
.img-view_hotels-Mobile-TouristPlaces{
    width: 120px;
    // height: 80px;
    border-radius: 8px;
}
.button-2-view_hotels-Hotels_Mobile{
    border: none;
    // background: none;
    font-family: $font-6;
    background: rgb(255, 255, 255);
    padding: 6px 8px 4px 8px;
    border-radius: 6px;
}

//navigate tourist places
.button-active-navigateTouristPlaces-TouristPlaces{
    background-color: #4a9f0c; /* Green shade for active */
    color: white;
    padding: 4px 8px;
    margin-right: 10px;
    margin-bottom: 12px;
    border-radius: 6px;
    font-size: 14px;
    font-family: $font-4;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover{
        background-color: #4a9f0c; /* Green on hover */
        color: white;
    }
}
.button-unActive-navigateTouristPlaces-TouristPlaces{
    background-color: #fff0f0; /* Light grey for inactive */
    color: black;
    margin-right: 10px;
    margin-bottom: 12px;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 14px;
    font-family: $font-4;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease, color 0.3s ease;
    
    &:hover{
        background-color: #4a9f0c; /* Green on hover */
        color: white;
    }
}

// .container_t-placeType-TouristPlaces{
//     display: flex;
//     align-items: start;
//     justify-content: start;
//     margin: 10px 0px 20px 0px;
//     // border: 4px rgb(235, 212, 61) dotted;
// }
// .heading-placeType-TouristPlaces{
//     font-size: 18px;
//     // font-family: $font-6;
//     font-family: 'Satisfy';
//     letter-spacing: 2px;
//     word-spacing: 0.4px;
//     font-weight: bold;
//     // color: rgb(242, 83, 83);
//     color: rgb(216, 67, 47);
//     margin-left: 6%;
//     // margin-bottom: 2%;
// }

//!Categories
.container_p-Categories-TouristPlaces{
    width: 100%;
    // border: 10px rgb(235, 212, 61) dotted;
    display: flex;
    justify-content: center;
    margin: 0px 4px 4% 4px;
}
.container_s-Categories-TouristPlaces{
    width: 90%;
    // border: 1px lightgreen dotted;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;    

//     align-self: center;
//     text-align: center;
//     justify-content: space-around;
}
.conatiner_t-SingleCategory-TouristPlaces{

}
.button-active-Category-TouristPlaces{
    margin: 8px 12px 4px 12px;
    padding: 8px 12px 8px 12px;
    white-space: nowrap;
    border-radius: 8px;
    font-family: $font-content-2;

    background-color: #4CAF50; /* Green color when active */
    color: white;
    border: 1px solid #4CAF50;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle box shadow when active */
}
.button-unActive-Category-TouristPlaces{
    margin: 8px 12px 4px 12px;
    padding: 8px 12px 8px 12px;
    white-space: nowrap;
    border-radius: 8px;
    font-family: $font-content-2;

    background-color: #ffffff; /* Light grey color when inactive */
    color: #333;
    border: 1px solid #ffffff;
    box-shadow: 0 0 5px rgba(15, 14, 14, 0.1); /* Subtle box shadow when inactive */
}


//Destinations
.text-destination-TouristPlaces{
    font-family: $font-6;
    font-size: 16px;
    color: rgb(136, 136, 136);
    margin-bottom: 24px;
}
.container_s-Destinations-TouristPlaces{
    margin-top: 12%;
    width: 100%;
    border: rgb(164, 162, 162) 1px solid;
    border-radius: 10px;
    margin: 4px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 16px;
    padding: 6px 0px 6px 0px;
    color: rgb(66, 66, 66);
    background-color: none;
    background:transparent;
    align-items: start;
    cursor:auto;
}


//under km
.button-active-underKm-PlaceCards04{
    margin: 2px;
    padding: 4px;
   border-radius: 6px;
   background-color: #a5a4a4; /* Green color when active */
   color: rgb(255, 255, 255);
   /* border: 1px solid #8d918d; */
   border: none;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle box shadow when active */
}
.button-unactive-underKm-PlaceCards04{
    margin: 2px;
    padding: 4px;
   border-radius: 6px;
   background-color: #f2f2f2; /* Light grey color when inactive */
   color: #333;
   border: 1px solid #ccc;
   box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Subtle box shadow when inactive */
}