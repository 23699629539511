$image-heading-width: 1000px;
$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//

/* Main Container Css */

.mainContainer-TripPlanning{
    width: 100%;
    display: flex;
    flex-direction: row;
}

//!Mobile
@media (min-width: 0px) and (max-width: 849px){
    .Container1-TripPlanning{
        // margin-left: 2%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        // background: rgb(213, 213, 138);
    }

    //header
    .conatiner_p-headerPlaces-TripPlanning{
        width: 100%;
        display: flex;
        flex-direction: row;
        // border: 1px solid red;
        margin: 4px;
    }
    .card-headerPlaces-TripPlanning{
        width: 60%;
        // height: 300px;
        // border: 1px solid red;
        margin: 4px;
        overflow: hidden;
        border-radius: 8px;
        position: relative;
    }
    .overlay-headerPlaces-TripPlanning{
        z-index: 1;
        background: linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.1) 100%);
        position: absolute;
        // border: 10px yellowgreen solid;
        width: 100%;
        height: 160px;
        border-radius: 8px;
        // border-bottom-right-radius: 20px;
    }
    .image-card-headerPlaces-TripPlanning{
        object-fit: cover;
        border-radius: 8px;
        width: 100%;
        height: 160px;
        // border-bottom-right-radius: 40px;
    }
}
//!Webiste
@media (min-width: 850px){
    .Container1-TripPlanning{
        margin-left: 2%;
        width: 57%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    //header
    .conatiner_p-headerPlaces-TripPlanning{
        width: 100%;
        display: flex;
        flex-direction: row;
        // border: 1px solid red;
        margin: 4px;
    }
    .card-headerPlaces-TripPlanning{
        width: 34%;
        // height: 300px;
        margin: 4px;
        overflow: hidden;
        border-radius: 8px;
        position: relative;
    }
    .overlay-headerPlaces-TripPlanning{
        z-index: 1;
        background: linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.1) 100%);
        position: absolute;
        // border: 10px yellowgreen solid;
        width: 100%;
        height: 160px;
        border-radius: 8px;
        // border-bottom-right-radius: 20px;
    }
    .image-card-headerPlaces-TripPlanning{
        object-fit: cover;
        border-radius: 8px;
        width: 100%;
        height: 160px;
        // border-bottom-right-radius: 40px;
    }
}


// .Container1-TripPlanning{
//     margin-left: 2%;
//     width: 57%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     text-align: center;
// }

.Container2-TripPlanning{
    width: 40%;
}


//!!

//* the locations on the top
// .conatiner_p-headerPlaces-TripPlanning{
//     width: 100%;
//     display: flex;
//     flex-direction: row;
//     // border: 1px solid red;
//     margin: 4px;
// }
// .card-headerPlaces-TripPlanning{
//     width: 34%;
//     // height: 300px;
//     margin: 4px;
//     overflow: hidden;
//     border-radius: 8px;
//     position: relative;
// }

// .overlay-headerPlaces-TripPlanning{
//     z-index: 1;
//     background: linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.1) 100%);
//     position: absolute;
//     // border: 10px yellowgreen solid;
//     width: 100%;
//     height: 160px;
//     border-radius: 8px;
//     // border-bottom-right-radius: 20px;
// }
// .image-card-headerPlaces-TripPlanning{
//     object-fit: cover;
//     border-radius: 8px;
//     width: 100%;
//     height: 160px;
//     // border-bottom-right-radius: 40px;
// }
.card_data-headerPlaces-TripPlanning{
    z-index: 2;
    position: absolute;
    // border: 1px solid rgb(255, 0, 0);
    width: 100%;
    height: 160px;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.heading-1-card-headerPlaces-TripPlanning{
    color: rgb(237, 237, 237);
    font-family: $font-6;
    font-size: 16px;
    align-self:flex-start;
    text-align: start;
    margin: 12px 2px 2px 10px;
}
.heading-2-card-headerPlaces-TripPlanning{
    color: rgb(255, 255, 255);
    font-family: $font-3;
    font-size: 14px;
    align-self:flex-start;
    margin: 9px 2px 2px 6px;
}
.heading-3-card-headerPlaces-TripPlanning{
    color: rgb(237, 237, 237);
    font-family: $font-content-1;
    font-size: 14px;
    align-self:flex-start;
    margin: 6px 2px 2px 12px;
}
.heading-4-card-headerPlaces-TripPlanning{
    color: rgb(237, 237, 237);
    font-family: $font-4;
    font-size: 16px;
    align-self:flex-start;
    margin: 6px 2px 2px 12px;
}
.container_t-card-headerPlaces-TripPlanning{
    display: flex;
    flex-direction: row;
    // justify-content: center; 
    // align-items: center;
    // text-align: center;
}
.container_t-2-card-headerPlaces-TripPlanning{
    display: flex;
    flex-direction: row;
    // text-align: center;
    // align-items: center;
    // align-content: center;
    position: absolute;
    bottom: 4px;
}

//*Destination Name
.container_p-destinationName-TripPlanning{
    display: flex;
    flex-direction: row;
    margin: 4px 0px 12px 0px;
    align-items: center;
    text-align: center;
    align-self: self-start;
    margin-left: 12px;
    // width: 96%;
    // justify-content: space-between;
}
.heading-destinationName-TripPlanning{
    font-size: 26px;
    color: rgb(146, 8, 8);
    // font-weight: bold;
    font-family: $font-6;
    margin-right: 24px;
}
.img-destinationName-TripPlanning{
    width: 50px;
    height: 50px;
    // border-radius: 100px;
    object-fit: cover;
}

//*Travel Categories
.container_p-TravelCategories-TripPlanning{
    background: rgb(238, 194, 194);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 10px;
    padding: 8px;
    justify-content: space-around;
    margin: 2%;
}




//pre loading - view more popup

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it stays on top of other elements */
  }
  
  .spinner {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  