$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


//? Input - description
.input-1-description-Note_TD{
    border: 1px solid rgb(171, 167, 167);
    width: 460px;
    padding: 8px 6px 4px 6px;
    margin: 8px 6px 4px 12px;
    border-radius: 4px;

    font-family: $font-3;
    font-weight: 600;
    letter-spacing: 0.4px;

    &:focus{
        outline: none;
        padding: 10px 4px 8px 12px;
        border: none;
        border-radius: 8px;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgba(122, 123, 124, 0.36) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
}

.button-clearNote-Note_TD{
    background: none;
    border: none;
    padding: 2px 8px 2px 8px;
    margin: 14px 2px 4px 18px;
    border-radius: 8px;
    background: whitesmoke;
    color: #535251;
    font-family: $font-content-1;

    &:hover{
        // background-color: rgb(255, 197, 197);
        background-color: rgb(255, 155, 96);
        color: whitesmoke;
        transition: background-color 0.6s ease;
    }
    &:active{
        // background-color: rgb(255, 123, 8);
        background-color: rgb(252, 60, 60);
        color: whitesmoke;
        transition: background-color 0.3s ease;
    }
}

@media (min-width: 0px) and (max-width: 799px){
    .button-close-popup-Note_TD{
        font-size: 32px;
        border: none;
        background: none;
        position: fixed;
        right: 10.4%;
        top: 3%;
    
        &:hover{
            color: red;
            transition: color 0.3s ease;
        }
        &:active{
            color: orange;
            transition: color 0.3s ease;
        }
    }
}
@media (min-width: 800px){
    .button-close-popup-Note_TD{
        font-size: 22px;
        border: none;
        background: none;
        position: fixed;
        right: 10.4%;
        top: 4%;
    
        &:hover{
            color: red;
            transition: color 0.3s ease;
        }
        &:active{
            color: orange;
            transition: color 0.3s ease;
        }
    }
}

// .button-close-popup-Note_TD{
//     font-size: 22px;
//     border: none;
//     background: none;
//     position: fixed;
//     right: 10.4%;
//     top: 4%;

//     &:hover{
//         color: red;
//         transition: color 0.3s ease;
//     }
//     &:active{
//         color: orange;
//         transition: color 0.3s ease;
//     }
// }

//!Notes display container
//?single note
.container_p-note-singleNote-displayNotes-Note_TD{
    border: 4px solid rgb(240, 236, 236);
    border-radius: 6px;
    padding: 10px 10px 10px 10px;
    margin: 8px 2px 8px 2px;
    background: white;
    width: 91%;
    word-wrap: break-word;
    // min-height: 10px;
    // max-height: 500px;

    // margin-top: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    align-self: center;

    // background: hidden;
    overflow-y: hidden;
    overflow-x: hidden;
    overflow: hidden;
}
.container_t-note-singleNote-displayNotes-Note_TD{
    border-radius: 4px;
    // margin: 8px 8px 8px 8px;
    // padding: 8px 8px 8px 8px;
    // border: 2px solid lightcyan;
    width: 98%;
    min-height: 30px;
    max-height: 100px;
    border: none;
    
    font-size: 14px;
    font-family: $font-4;
    color: rgb(72, 72, 72);
    letter-spacing: 0.2px;
    
    align-items: start;
    text-align: start;
    // overflow-x: hidden;
    // overflow-y: scroll;
    overflow-x: hidden;
    overflow-y: hidden;
}

.conatiner_p-addNote-Note_TD{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: whitesmoke;
    width: 100%;
    margin-top: 8px;
    // margin-left: 3%;
    // justify-content: space-between;
    &:hover{
        // color: red;
        background-color: rgb(229, 213, 213);
        transition: color 0.3s ease;
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(255, 213, 198);
        transition: background-color 0.3s ease;
    }
}
.button-addNote-Note_TD{
    border: none;
    // margin: 4px;
    // margin-left: 28px;
    padding: 4px 30% 4px 30%;
    border-radius: 4px;
    background-color: rgb(226, 224, 224);
    color: rgb(104, 103, 103);
    font-family: $font-6;
    white-space: nowrap;
    letter-spacing: 1px;
    
    &:hover{
        color: red;
        background-color: whitesmoke;
        transition: color 0.3s ease;
        transition: background-color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}

.container_t-note-singleNote-displayNotes-Note_TD::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.container_t-note-singleNote-displayNotes-Note_TD::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.container_t-note-singleNote-displayNotes-Note_TD::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  