$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


//head - the heading that has the topic name, icon, and the delete icon
//!1
.conatiner_p-head-Itinerary_TO_Components{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    // border: 1px silver solid;
    margin-top: 8px;
}
.conatiner_t-displayName_Icon-head-Itinerary_TO_Components{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: space-between;
}
.conatiner_s-1-head-Itinerary_TO_Components{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}
.text-head-Itinerary_TO_Components{
    font-family: $font-6;
    margin-right: 16px;
    margin-left: 12px;
    color: rgb(95, 94, 94);
}
.icon-checklist-Itinerary_TO_Components{
    width: 25px;
    height: 25px;
}
//?Trip Status 
.container_p-tripStatus-Itinerary_TO_Components{
    // background: whitesmoke;
    // padding: 2px 30px 2px 30px;
    padding: 4px 30px 2px 0px;
    border-radius: 10px;
    width: fit-content;
    margin-left: 20px;
    // margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}
.heading-1-tripStatus-Itinerary_TO_Components{
    color: rgb(148, 147, 147);
    font-family: $font-4;
    font-size: 14px;
    margin-right: 12px;
    width: 100%;
}
.heading-2-tripStatus-Itinerary_TO_Components{
    margin-right: 12px;
    font-family: $font-6;
    white-space: nowrap;
    color: rgb(54, 53, 53);
}
.emoji-tripStatus-Itinerary_TO_Components{
    margin-top: -4px;
}

//!2 
.input-1-description-Itinerary_TO_Components{
    border: 2px solid whitesmoke;
    // background: whitesmoke;
    // border-bottom: 1px solid rgb(255, 209, 209);
    color: rgb(255, 33, 33);
    // width: 460px;
    width: 90%;
    padding: 6px 6px 6px 6px;
    margin: 12px 6px 4px 8px;
    border-radius: 4px;
    text-align: start;
    align-items: self-start;
    font-family: $font-4;
    font-weight: bold;
    letter-spacing: 0.6px;

    &:focus{
        outline: none;
        padding: 10px 4px 8px 12px;
        border: none;
        border-radius: 8px;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgba(122, 123, 124, 0.36) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
}

//!3
.conatiner_p-addNote-Itinerary_TO_Components{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    // background: whitesmoke;
    width: 90%;
    margin-left: 3%;
    margin-top: 2%;
    // justify-content: space-between;
}
.heading-1-addNote-Itinerary_TO_Components{
    color: rgb(101, 189, 0);
    font-family: $font-6;
    margin-right: 10px;
    align-items: self-start;
    text-align: start;
    // margin-left: 20px;
    // margin-top: 20px;
}
.button-addNote-Itinerary_TO_Components{
    border: none;
    margin: 4px;
    margin-left: 28px;
    padding: 6px 6px 4px 6px;
    border-radius: 8px;
    background-color: orangered;
    color: white;
    font-family: $font-6;
    
    &:hover{
        color: red;
        background-color: whitesmoke;
        transition: color 0.3s ease;
        transition: background-color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}

.container_p-note-singleNote-displayNotes-Itinerary_TO_Components{
    border: 4px solid rgb(246, 246, 246);
    border-radius: 6px;
    padding: 10px 10px 10px 10px;

    width: 92%;
    // min-height: 10px;
    // max-height: 500px;

    // margin-top: 1%;
    margin: 8px 2px 4px 11px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    background: none;
    // overflow: hidden;
}
.container_t-note-singleNote-displayNotes-Itinerary_TO_Components{
    border-radius: 4px;
    // margin: 8px 8px 8px 8px;
    // padding: 8px 8px 8px 8px;

    width: 98%;
    // height: 50%;
    min-height: 10px;
    max-height: 100px;
    border: none;
    
    font-size: 14px;
    font-family: $font-4;
    color: rgb(72, 72, 72);
    letter-spacing: 0.2px;
    
    align-items: start;
    text-align: start;
    // overflow-x: hidden;
    // overflow-y: scroll;

    overflow-x: hidden;
    overflow-y: hidden;
}

.container_t-note-singleNote-displayNotes-Itinerary_TO_Components::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.container_t-note-singleNote-displayNotes-Itinerary_TO_Components::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.container_t-note-singleNote-displayNotes-Itinerary_TO_Components::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  



//!5
//?Time Interval
.container_s-2-Data-Itinerary_TO_Components{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background: whitesmoke;
    border-radius: 10px;
    padding: 4px 8px 4px 8px;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-left: 10px;
    margin-top: 20px;
    width: fit-content;
}

.heading-1-timeInterval-Itinerary_TO_Components{
    color: rgb(109, 107, 107);
    font-family: $font-6;
    font-size: 14px;
    margin-right: 4px;
    white-space: nowrap;
    // margin-top: 12px;
    width: fit-content;
}
.container_p-time-timeInterval-Itinerary_TO_Components{
    display: flex;
    // margin-top: 12px;
    // margin-left: 12px;
    flex-direction: row;
    // align-items: start;
    // text-align: start;
    // justify-content: flex-start;
    width: fit-content;
}
.container_t-3-timeInterval-Itinerary_TO_Components{
    width: fit-content;
}

.text-time-timeInterval-Itinerary_TO_Components{
    color: orangered;
    font-family: $font-6;
    background-color: white;
    // background-color: whitesmoke;
    font-size: 14px;
    padding: 5px 6px 5px 6px;
    border-radius: 8px;
    margin: 0px 10px 0px 10px;
    white-space: nowrap;
}
.content-time-timeInterval-Itinerary_TO_Components{
    color: rgb(103, 101, 101);
    font-size: 12px;
    padding: 2px 4px 2px 4px;
    margin-left: 28px;
    // margin-top: 14px;
    font-family: $font-3;
    font-weight: 600;
}




//!!Budget
.container_p-Data-Itinerary_TO_Components{

    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background: whitesmoke;
    border-radius: 10px;
    // padding: 4px 8px 4px 8px;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-left: 10px;
    margin-top: 20px;
    width: fit-content;
}
.container_s-1-Data-Itinerary_TO_Components{
    display: flex;
    flex-direction: column;
    // border: 1px solid goldenrod;
    // width: 60%;
}
.container_p-Budget-Itinerary_TO_Components{
    display: flex;
    flex-direction: row;
    // padding: 4px 12px 4px 12px;
    padding: 0px 0px 0px 12px;
    border-radius: 8px;
    // background: rgb(237, 28, 28);
    text-align: center;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    // min-width: 30%;
    // min-width: 60%;
    // width: fit-content;
}
//
.heading-1-budgetData-Itinerary_TO_Components{
    color: gray;
    font-family: $font-6;
    font-size: 14px;
    // width: fit-content;
    // min-width: 10%;
    // max-width: 40%;
    // background: rgb(101, 0, 0);
}
.container_t-budgetData-Itinerary_TO_Components{
    display: flex;
    flex-direction: row;   
    padding: 2px 12px 2px 12px;
    margin: 4px 12px 4px 12px;
    border-radius: 8px;
    background-color: rgb(255, 255, 255); 
    align-items: center;
    text-align: center;
    // min-width: 30%;
    // max-width: 70%;
    justify-content: space-around;
}
.container_t-data-1-budget-Itinerary_TO_Components{
    color: rgb(0, 0, 0); 
    font-family: $font-6;
    margin-right: 14px;
}
.container_t-data-2-budget-Itinerary_TO_Components{
    color: rgb(93, 91, 91); 
    font-family: $font-content-1;
    margin-right: 12px;
}


//!Popup

.container_p-note-popup-Itinerary_TO_Components{
    border: 4px solid rgb(255, 147, 147);
    border-radius: 6px;
    // padding: 10px 10px 10px 10px;

    width: 80%;
    height: 70%;
    // min-height: 10px;
    // max-height: 500px;

    // margin-top: 1%;
    margin: 8px 2px 4px 11px;
    // display: flex;
    // align-items: center;
    // text-align: center;
    // justify-content: center;

    background: none;
    // overflow: hidden;
}
.container_t-note-popup-Itinerary_TO_Components{
    // border: 2px solid rgb(255, 147, 147);
    border-radius: 6px;
    padding: 16px;
    margin-top: 12px;
    width: 80%;
    // height: 70%;

    border-radius: 8px;
    
    font-size: 14px;
    font-family: $font-4;
    color: rgb(72, 72, 72);
    letter-spacing: 0.2px;
    
    align-items: start;
    text-align: start;
    // overflow-x: hidden;
    // overflow-y: scroll;
}
.container_t-note-popup-Itinerary_TO_Components::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.container_t-note-popup-Itinerary_TO_Components::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.container_t-note-popup-Itinerary_TO_Components::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  



