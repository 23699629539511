$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//



// .container_p-mobile-header{
//     display: flex;
//     width: 100%;
//     // background: rgb(255, 241, 223);
//     padding: 4px 0;
//     border-radius: 14px;
//     margin-top: 4px;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center; 
// }

// .conatiner-header-header_mobile{
//     position: absolute;
//     border: red 1px solid;
//     background: white;
//     margin-top: 22%;
//     align-items: flex-start;
// }


@media(max-width: 499px){
    .mobile-notWrapped-header{
        display: none;
    }
    .mobile-notWrapped-header-2{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}
@media(min-width: 500px){
    .mobile-notWrapped-header{
        display: flex;
    }
    .mobile-notWrapped-header-2{
        display: none;
    }
}

.button-moreOptions-header-Header_mobile{
    font-size: 22px;
    border: none;
    background: none;
    // position: fixed;
    // right: 21%;
    margin-top: 1%;

    &:hover{
        color: rgb(12, 63, 126);
        transition: color 0.3s ease;
    }
    &:active{
        color: rgb(255, 126, 51);
        transition: color 0.3s ease;
    }
}
.button-close-header-Header_mobile{
    font-size: 22px;
    border: none;
    background: none;
    // position: fixed;
    // right: 21%;
    margin-top: 8%;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}


.overlay-header_mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 100;
    display: flex;
    // flex-direction: column;
    justify-content: flex-end;
}
.container-header-mobile {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%; 
    height: 100%;
    transform: translateX(100%); 
    transition: transform 2s ease-in-out; 
}
.container-header-mobile.active {
    transform: translateX(0); /* Slide in */
}
.container-header-mobile.close {
    transform: translateX(100%); /* Slide out */
}
  