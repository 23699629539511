$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//



//!popup container
//?1
.popup_p-2-addNote-ViewPrices_CH{
    width: 64%;
    height: 90%;
    position: fixed;
    top: 4%;
    left: 18%;
    border: none;
    border-radius: 8px;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(180, 212, 255, 0.819);
    z-index: 10000;
    overflow: auto;
    // display: flex;
    // align-items: center;
    // text-align: center;
    // justify-content: center;
}
.popup_s-2-addNote-ViewPrices_CH{
    width: 98%;
    height: 98%;
    top: 10%;
    left: 20%;
    // border: 1px solid #000;
    // background: #ffdcdc;
    padding: 20px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10010;
    overflow: auto;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // text-align: center;
}
.popup_s-2-addNote-ViewPrices_CH::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.popup_s-2-addNote-ViewPrices_CH::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.popup_s-2-addNote-ViewPrices_CH::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  


.heading-1-heading-ViewPrices_CH{
    font-family: $font-6;
    font-size: 22px;
    color: rgb(30, 79, 169);
    margin-left: 6%;
    // color: rgb(88, 87, 87);
}


//?Best Deal
.container_s-card-bestDeal-ViewPrices_CH{
    display: flex;
    flex-direction: column;
    // border: 2px solid rgb(255, 89, 0);
    box-shadow: 0 0 4px rgba(244, 196, 179, 0.907);
    background: white;
    margin: 20px 20px 0px 4px;
    padding: 10px 6px 10px 6px;
    border-radius: 6px;
    // width: 80%;
    width: fit-content;
    margin-left: 6%;
    // min-width: 180px;
    // max-width: 180px;
    // min-height: 140px;
    // max-height: 100%;
}
.conatiner_t-content-bestDeals-ViewPrices_CH{
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    text-align: center;
    align-items: center;
}
.heading-1-bestDeal-ViewPrices_CH{
    font-size: 16px;
    // color: rgb(75, 36, 2);
    color: rgb(143, 205, 21);
    // font-family: $font-content-2;
    font-family: $font-6;
    // margin-left: 6px;
}
.text-1-bestDeal-ViewPrices_CH{
    font-family: $font-6;
    // color: brown;
    color: rgb(45, 100, 202);
    font-size: 16px;
    margin-right: 12px;
    // margin-top: 6px;
}
.text-2-bestDeal-ViewPrices_CH{
    // margin-top: 12px;
    font-family: $font-6;
    // color: rgb(45, 100, 202);
    color: brown;
    font-size: 18px;
    margin-right: 12px;
}
.link-1-MetaData-bestDeal-ViewPrices_CH{
    color: rgb(23, 81, 126);
    // margin-top: 6px;
    // background: whitesmoke;
    font-family: $font-5;
    letter-spacing: 0.6px;

    &:hover{
        color: yellowgreen;
        transition: color 0.3s ease;
    }
    &:active{
        color: yellow;
        transition: color 0.3s ease;
    }
}
.text-1-officialLink-bestDeal-ViewPrices_CH{
    font-family: $font-7;
    border: 1px solid rgb(255, 246, 242);
    background: rgb(255, 232, 232);
    border-radius: 6px;
    padding: 0px 0px 1px 4px;
}
.text-1-link-bestDeal-ViewPrices_CH{
    font-family: $font-7;
    border: 1px solid rgb(255, 246, 242);
    background: rgb(255, 255, 255);
    border-radius: 6px;
}



.container_t-MetaData-bestDeal-ViewPrices_CH{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid rgb(255, 246, 242);
    background: rgb(255, 255, 255);
    margin: 0px 0px 0px 10px;
    // padding: 4px;
    border-radius: 6px;
    justify-content: start;
}
.container_t-amenities-otherPrices-ViewPrices_CH{
    display: flex;
    flex-direction: row;
    padding: 2px 5px 2px 5px;
    border-radius: 8px;
    margin-right: 4px;
    margin-top: 4px;
    // background: rgb(255, 240, 230);
}
.icon-1-amenities-otherPrices-ViewPrices_CH{
    font-size: 12px;
    font-family: $font-4;
    color: rgb(108, 105, 105);
    margin-right: 4px;
}
.text-1-amenities-otherPrices-ViewPrices_CH{
    font-size: 12px;
    font-family: $font-4;
    color: rgb(108, 105, 105);
    // white-space: nowrap;
}


//!Other Prices
.container_p-card-otherPrices-ViewPrices_CH{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 94%;
    text-align: start;
    // justify-content: center;
    margin-left: 6%;
    margin-top: 1%;
    // box-shadow: 0 0 4px rgba(122, 120, 120, 0.588);
}
.container_s-card-otherPrices-ViewPrices_CH{
    display: flex;
    flex-direction: column;
    // border: 2px solid rgb(155, 155, 152);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    background: white;
    margin: 20px 20px 0px 4px;
    padding: 6px 4px 4px 4px;
    border-radius: 10px;
    min-width: 180px;
    max-width: 180px;
    height: fit-content;
}
.text-1-otherPrices-ViewPrices_CH{
    font-family: $font-6;
    color: rgb(45, 100, 202);
    // color: brown;
    font-size: 16px;
    margin-top: 6px;
}
.text-2-otherPrices-ViewPrices_CH{
    margin-top: 12px;
    font-family: $font-6;
    // color: rgb(45, 100, 202);
    color: brown;
    font-size: 18px;
}
.link-1-MetaData-otherPrices-ViewPrices_CH{
    color: rgb(23, 81, 126);
    margin-top: 6px;
    // margin-bottom: 6px;
    font-family: $font-5;
    letter-spacing: 0.6px;

    &:hover{
        color: yellowgreen;
        transition: color 0.3s ease;
    }
    &:active{
        color: yellow;
        transition: color 0.3s ease;
    }
}
.icon-1-amenities-otherPrices-ViewPrices_CH{
    font-size: 12px;
    font-family: $font-4;
    color: rgb(108, 105, 105);
    margin-right: 4px;
}
.text-1-amenities-otherPrices-ViewPrices_CH{
    font-size: 12px;
    font-family: $font-4;
    color: rgb(108, 105, 105);
    // white-space: nowrap;
}
.container_t-MetaData-otherPrices-ViewPrices_CH{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid rgb(255, 246, 242);
    background: rgb(255, 255, 255);
    margin: 12px 0px 0px 0px;
    // padding: 4px;
    border-radius: 6px;
    justify-content: start;
}