$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//



// .container_p-cards-TouristPlace-Hotels_TP{
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     align-items: start;
//     text-align: start;
//     justify-content: space-around;
//     width: fit-content;
// }
.card-searchTool-Hotels_TP{
    // width: 28%;
    // margin: 2%;

    // width: 700px;
    width: 80%;
    margin-bottom: 4%;
    // border: rgb(215, 212, 212) 2px solid;
    // border-radius: 10px;
    // margin: 4px;
    // margin-bottom: 10px;
    // margin-left: 10px;
    // font-size: 16px;
    // background-color: none;
    // background:transparent;
    // align-items: start;
    // cursor:auto;

    // text-align: start;
}
@media (min-width: 0px) and (max-width: 299px){
    .container_p-cards-TouristPlace-Hotels_TP{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: start;
        text-align: start;
        justify-content: space-around;
        width: fit-content;
    }
    .card-searchTool-Hotels_TP{
        width: 80%;
        margin-bottom: 4%;
    }
    .card-TouristPlace-Hotels_TP{
        margin-bottom: 6%;
        width: 200px;
        border-radius: 10px;
        font-size: 16px;
        background-color: none;
        background:transparent;
        align-items: start;
        cursor:auto;
        text-align: start;
    }
}
@media (min-width: 300px) and (max-width: 399px){
    .container_p-cards-TouristPlace-Hotels_TP{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: start;
        text-align: start;
        justify-content: space-around;
        width: fit-content;
    }
    .card-searchTool-Hotels_TP{
        width: 80%;
        margin-bottom: 4%;
    }
    .card-TouristPlace-Hotels_TP{
        margin-bottom: 6%;
        width: 300px;
        border-radius: 10px;
        font-size: 16px;
        background-color: none;
        background:transparent;
        align-items: start;
        cursor:auto;
        text-align: start;
    }
}
@media (min-width: 400px) and (max-width: 499px){
    .container_p-cards-TouristPlace-Hotels_TP{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: start;
        text-align: start;
        justify-content: space-around;
        width: fit-content;
    }
    .card-searchTool-Hotels_TP{
        width: 80%;
        margin-bottom: 4%;
    }
    .card-TouristPlace-Hotels_TP{
        margin-bottom: 6%;
        width: 400px;
        border-radius: 10px;
        font-size: 16px;
        background-color: none;
        background:transparent;
        align-items: start;
        cursor:auto;
        text-align: start;
    }
}
@media (min-width: 500px) and (max-width: 599px){
    .container_p-cards-TouristPlace-Hotels_TP{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        justify-content: center;
        justify-content: space-around;
        // width: fit-content;
    }
    .card-searchTool-Hotels_TP{
        width: 80%;
        margin-bottom: 4%;
        // background: yellowgreen;
    }
    .card-TouristPlace-Hotels_TP{
        margin-bottom: 6%;
        width: 500px;
        // width: 100%;
        border-radius: 10px;
        font-size: 16px;
        background-color: none;
        background:transparent;
        // align-items: start;
        cursor:auto;
        // text-align: start;
    }
}
@media (min-width: 600px) and (max-width: 699px){
    .container_p-cards-TouristPlace-Hotels_TP{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        justify-content: center;
        justify-content: space-around;
        // width: fit-content;
    }
    .card-searchTool-Hotels_TP{
        width: 80%;
        margin-bottom: 4%;
        // background: yellowgreen;
    }
    .card-TouristPlace-Hotels_TP{
        margin-bottom: 6%;
        width: 500px;
        // width: 100%;
        border-radius: 10px;
        font-size: 16px;
        background-color: none;
        background:transparent;
        // align-items: start;
        cursor:auto;
        // text-align: start;
    }
}
@media (min-width: 700px) and (max-width: 849px){
    .container_p-cards-TouristPlace-Hotels_TP{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: start;
        text-align: start;
        justify-content: space-around;
        width: fit-content;
    }
    .card-searchTool-Hotels_TP{
        width: 80%;
        margin-bottom: 4%;
        // background: yellowgreen;
    }
    .card-TouristPlace-Hotels_TP{
        margin-bottom: 6%;
        // width: 700px;
        width: 80%;
        border-radius: 10px;
        font-size: 16px;
        background-color: none;
        background:transparent;
        align-items: start;
        cursor:auto;
        text-align: start;
    }
}
@media (min-width: 850px) and (max-width: 900px){
    .container_p-cards-TouristPlace-Hotels_TP{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: start;
        text-align: start;
        justify-content: space-around;
        width: fit-content;
    }
    .card-searchTool-Hotels_TP{
        width: 58%;
        margin-bottom: 4%;
    }
    .card-TouristPlace-Hotels_TP{
        margin-bottom: 6%;
        width: 400px;
        border-radius: 10px;
        font-size: 16px;
        background-color: none;
        background:transparent;
        align-items: start;
        cursor:auto;
        text-align: start;
    }
}
@media (min-width: 900px) and (max-width: 1100px){
    .container_p-cards-TouristPlace-Hotels_TP{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: start;
        text-align: start;
        justify-content: space-around;
        width: fit-content;
    }
    .card-searchTool-Hotels_TP{
        width: 80%;
        margin-bottom: 4%;
    }
    .card-TouristPlace-Hotels_TP{
        margin-bottom: 6%;
        width: 500px;
        border-radius: 10px;
        font-size: 16px;
        background-color: none;
        background:transparent;
        align-items: start;
        cursor:auto;
        text-align: start;
    }
}
@media (min-width: 1100px) and (max-width: 1300px){
    .container_p-cards-TouristPlace-Hotels_TP{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: start;
        text-align: start;
        justify-content: space-around;
        width: fit-content;
    }
    .card-searchTool-Hotels_TP{
        width: 80%;
        margin-bottom: 4%;
    }
    .card-TouristPlace-Hotels_TP{
        margin-bottom: 6%;
        width: 600px;
        border-radius: 10px;
        font-size: 16px;
        background-color: none;
        background:transparent;
        align-items: start;
        cursor:auto;
        text-align: start;
    }
}
@media (min-width: 1300px) and (max-width: 1600px){
    .container_p-cards-TouristPlace-Hotels_TP{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: start;
        text-align: start;
        justify-content: space-around;
        width: fit-content;
    }
    .card-searchTool-Hotels_TP{
        width: 80%;
        margin-bottom: 4%;
    }
    .card-TouristPlace-Hotels_TP{
        margin-bottom: 6%;
        width: 800px;
        border-radius: 10px;
        font-size: 16px;
        background-color: none;
        background:transparent;
        align-items: start;
        cursor:auto;
        text-align: start;
    }
}
@media (min-width: 1600px){
    .container_p-cards-TouristPlace-Hotels_TP{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: start;
        text-align: start;
        justify-content: space-around;
        width: fit-content;
    }
    .card-searchTool-Hotels_TP{
        width: 80%;
        margin-bottom: 4%;
    }
    .card-TouristPlace-Hotels_TP{
        margin-bottom: 6%;
        width: 900px;
        border-radius: 10px;
        font-size: 16px;
        background-color: none;
        background:transparent;
        align-items: start;
        cursor:auto;
        text-align: start;
    }
}


// .card-TouristPlace-Hotels_TP{
//     margin-bottom: 6%;
//     width: 700px;
//     border-radius: 10px;
//     font-size: 16px;
//     background-color: none;
//     background:transparent;
//     align-items: start;
//     cursor:auto;
//     text-align: start;
// }

.conatiner_t-HotelCard-Hotels_TP{
    // border: 2px solid gray;
    background: white;
    border: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    border-radius: 10px;
    // box-shadow: 0 0 2px rgb(97, 97, 97); 
    box-shadow: rgba(0, 0, 0, 0.323) 0px 1px 4px;
}


//!
.conatiner_p-component-content_map-Hotels_TP{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    // border: 1px solid orangered;
}
.continer_p-0-Hotels_TP{
    width: 4%;
    // border: 1px solid rgb(86, 86, 86);
    background: whitesmoke;
}
.continer_p-1-Hotels_TP{
    width: 30%;
    margin-left: 3000px;
    // width: 96%;
    border: 1px solid rgb(157, 183, 106);
    // padding: 12px;
    border-radius: 8px;
}
.continer_p-2-Hotels_TP{
    width: 40%;
    // border: 3px solid rgb(5, 99, 134);
    position: relative;
    // display: flex;
    // align-items: center;
    // text-align: center;
    // justify-content: center;
}
.component-map-Hotels{
    width: 38%;
    height: 96%;
    
    // width: 100px;
    // height: 100px;

    border-radius: 8px;
    right: 1%;
    top: 2%;
    position: fixed;
    box-shadow: 0 0 2px rgb(97, 97, 97); 
    // border: 3px solid rgb(54, 5, 134);
    overflow: hidden;
}

//!Heading
.conatiner_p-heading-Hotels{
    width: 100%;
    // height: 20%;
    margin-top: 1%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    overflow: hidden;
    // border: 1px solid orangered;
    justify-content: space-around;
}
.conatiner_s-1-heading-Hotels{
    // margin-left: 12px;
    //  border: 1px solid orangered;
}
.conatiner_s-2-heading-Hotels{
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    // overflow: hidden;
    //  border: 1px solid orangered;
}
.heading-1-heading-Hotels{
    font-family: $font-6;
    font-size: 32px;
    // font-weight: bold;
    // color: rgb(255, 81, 0);
    color: olivedrab;
    // color: rgb(225, 63, 4);
    margin-top: 2%;
    letter-spacing: 0.4px;
    white-space: nowrap;
}
.heading-2-heading-Hotels{
    font-family: $font-6;
    font-size: 22px;
    // color: rgb(147, 194, 28);
    color: rgb(255, 146, 3);
    margin-top: 6%;
    padding: 4px 8px 4px 8px;
    
    border-radius: 12px;
    background: rgb(248, 248, 248);
}
.heading-3-heading-Hotels{
    margin-top: 6px;
    font-family: $font-5;
    font-size: 16px;
    // color: rgb(255, 81, 0);
    color: rgb(0, 0, 0);
    margin-top: 12%;
    letter-spacing: 1.6px;
    white-space: nowrap;

    border-bottom: 1px solid lightgray;
    padding-bottom: 4px;
}
.img-1-heading-Hotels{
    // width: 100%;
    width: 350px;
    height: 250px;
    // height: 100%;
    // object-fit: cover;
    // border: 1px solid orangered;
    border-radius: 8px;
    // position: absolute;
    z-index: -2;
    // box-shadow: 0 0 2px rgb(97, 97, 97); 
}

//
.conatiner_p-2-heading-Hotels{
    width: 100%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
}
.text-1-note-heading-Hotels{
    font-family: $font-3;
    font-size: 12px;
    color: rgb(201, 200, 200);
    width: 80%;
    margin-top: 4%;
}