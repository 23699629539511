$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//



.heading-1-Overview_VM{
    font-family: $font-4;
    font-weight: bold;
    color: rgb(255, 140, 0);
}
.heading-2-Overview_VM{
    font-family: $font-6;
    color: rgb(165, 161, 161);
    margin: 20px;
}

//Timming
.conatiner_t-timming-Overview_VM{
    margin: 10px;
}
.time-timming-Overview_VM{
    font-family: $font-6;
    color: rgb(165, 161, 161);
    margin: 20px;
}

//
.conatiner_t-3-Overview_VM{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 2%;
}
.conatiner_p-GoogleUrl-Overview_VM{
    // border: 1px solid red;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 4px;
    
    &:hover{
        background-color: whitesmoke;
        transition: background-color 0.3s ease;
        padding: 4px 8px 4px 8px;
        border-radius: 8px;
        border: 1px solid white;
    }
    &:active{
        border: 1px solid rgb(211, 161, 125);
        transition: border 0.3s ease;
        padding: 4px;
        border-radius: 6px;
    }
}
.link-1-Overview_VM{
    font-family: $font-6;
    color: rgb(165, 161, 161);
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
    margin-top: 4px;
}