$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//

//trave mode navigation header
.container_p-navigation-Reach_D{
    width: 100%;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
}
// .main_p-travelModes-Reach_D{
//     background: rgb(255, 255, 255);
//     border: 2px solid palevioletred;
//     border-radius: 10px;
//     height: 600px;
//     overflow-y: scroll;
//     overflow-x: hidden;
//     margin-top: 2%;
// }

@media (min-width: 0px) and (max-width: 799px){
    .main_p-travelModes-Reach_D{
        background: rgb(255, 255, 255);
        border: 2px solid palevioletred;
        border-radius: 10px;
        // height: 600px;
        // overflow-y: scroll;
        // overflow-x: hidden;
        margin-top: 2%;
    }
}
@media (min-width: 800px){
    .main_p-travelModes-Reach_D{
        background: rgb(255, 255, 255);
        border: 2px solid palevioletred;
        border-radius: 10px;
        height: 600px;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-top: 2%;
    }
}

.active-button-Reach_D{
    display: flex;
    flex-direction: row;
    margin: 2px;
    padding: 2px 10px 2px 10px;
    border-radius: 16px;
    border: none;
    background: rgb(213, 91, 4);
    color: white;
    text-align: center;
    align-items: center;

    &:hover {
        background-color: #e5855e;
        transition: background-color 0.3s ease;
    }

    &:active {
        background-color: #cdc4c4;
        transition: background-color 0.3s ease;
    }
}
.unActive-button-Reach_D{
    display: flex;
    flex-direction: row;
    margin: 2px; 
    padding: 1px 10px 1px 10px;
    border-radius: 16px;
    border: none;
    background: none;
    // background: rgb(229, 232, 234);
    text-align: center;
    align-items: center;

    &:hover {
        background-color: #e5855e;
        transition: background-color 0.3s ease;
    }

    &:active {
        background-color: #cdc4c4;
        transition: background-color 0.3s ease;
    }
}
.heading-modeName-Reach_D{
    font-family: $font-content-2;
    font-size: 18px;
    margin: 4px;
}
.icon-modeName-Reach_D{
    margin: 4px;
    font-size: 20px;
}

//
.heading-1-mode-Reach_D{
    align-self: center;
    font-size: 20px;
    font-family: $font-content-2;
    margin: 4% 4% 4% 4%;
    color: rgb(213, 91, 4);
    font-weight: bold;
}

//*Air
.container_p-Cards-Air-Reach_D{
    margin: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
.card-Air-Reach_D{
    margin: 8px;
    padding: 4% 4px 4% 4px;
    border-radius: 6px;
    width: 45%;
    box-shadow: 0 4px 8px rgba(93, 92, 92, 0.2);
    border: none;
    background: rgb(255, 255, 255);
}
.container_p-Cards-Air-Reach_D_Mobile{
    margin: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
}
.card-Air-Reach_D_Mobile{
    margin: 8px;
    padding: 4% 4px 4% 4px;
    border-radius: 6px;
    width: 92%;
    box-shadow: 0 4px 8px rgba(93, 92, 92, 0.2);
    border: none;
    background: rgb(255, 255, 255);
}
.text-1-air-Reach_D{
    font-family: $font-6;
    margin: 4px;
    font-size: 16px;
} 
.text-2-air-Reach_D{
    font-family: $font-4;
    margin: 6% 0px 4% 0px;
} 
.text-3-air-Reach_D{
    margin: 4% 0px 4% 0px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
} 
.text-3-1-air-Reach_D{
    font-family: $font-content-1;
    color: rgb(128, 124, 124);
} 
.text-4-air-Reach_D{
    font-family: $font-heading-1;
    color: #7b7979;
    margin: 4% 0px 4% 0px;
    // background: rgb(233, 228, 228);
}
.text-5-air-Reach_D{
    font-family: $font-content-1;
    color: rgb(192, 90, 0);
    margin: 6% 0px 4% 0px;
} 