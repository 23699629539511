$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

// .container_p-CreateItinerary{
//     width: 100%;
//     height: 100%;
//     display: flex;
//     flex-direction: row;
//     border: 1px red dotted;
// }


@media (min-width: 0px) and (max-width: 799px){

    .container_p-CreateItinerary{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 6%;
        // border: 1px red dotted;
    }
  
    //!Conatiner 2
    .container_p-MainItineraryContainer-CreateItinerary{
        width: 100%;
        // border: 1px palevioletred dotted;
        // background-color: rgb(208, 117, 90);
        background: none;
        padding: 1%;
        border-radius: 6px;
    }
    //!Conatiner 3
    .container_p-Map_SavedPlaces-CreateItinerary{
        position: relative;
        width: 100%;
        height: 530px;
        // border: 1px rgb(90, 34, 194) dotted;
        // background-color: rgb(223, 219, 219);
        // padding: 1%;
        border-radius: 6px;
        background: none;
    }
    // //!Main Fixed 
    .fixedContainer-Map_SavedPlaces-CreateItinerary {
        position: fixed;
        width: 100%;
        // border: 1px rgb(0, 0, 0) dotted;
    }
}
@media (min-width: 800px){

    .container_p-CreateItinerary{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        // border: 1px red dotted;
    }

    //!Conatiner 1
    .container_p-LeftConatiner-CreateItinerary{
        width: 4%;
        /* border: 1px palevioletred dotted; */
        background-color: rgb(255, 255, 255);
        padding: 1%;
        // border-radius: 6px;
    }

    //!Conatiner 2
    .container_p-MainItineraryContainer-CreateItinerary{
        width: 62%;
        /* border: 1px palevioletred dotted; */
        // background-color: rgb(247, 228, 222);
        background: none;
        padding: 1%;
        border-radius: 6px;
    }

    //!Conatiner 3
    .container_p-Map_SavedPlaces-CreateItinerary{
        position: relative;
        width: 34%;
        // height: 100%;
        // border: 1px rgb(90, 34, 194) dotted;
        // background-color: rgb(223, 219, 219);
        // padding: 1%;
        border-radius: 6px;
        background: none;
    }
    // //!Main Fixed 
    .fixedContainer-Map_SavedPlaces-CreateItinerary {
        position: fixed;
        width: 33%;
        // border: 1px rgb(0, 0, 0) dotted;
    }
}


// //!Conatiner 1
// .container_p-LeftConatiner-CreateItinerary{
//     width: 4%;
//     /* border: 1px palevioletred dotted; */
//     background-color: rgb(255, 255, 255);
//     padding: 1%;
//     // border-radius: 6px;
// }

// //!Conatiner 2
// .container_p-MainItineraryContainer-CreateItinerary{
//     width: 62%;
//     /* border: 1px palevioletred dotted; */
//     // background-color: rgb(247, 228, 222);
//     background: none;
//     padding: 1%;
//     border-radius: 6px;
// }

// //!Conatiner 3
// .container_p-Map_SavedPlaces-CreateItinerary{
//     position: relative;
//     width: 34%;
//     // height: 100%;
//     // border: 1px rgb(90, 34, 194) dotted;
//     // background-color: rgb(223, 219, 219);
//     // padding: 1%;
//     border-radius: 6px;
//     background: none;
// }
// //!Main Fixed 
// .fixedContainer-Map_SavedPlaces-CreateItinerary {
//     position: fixed;
//     width: 33%;
//     // border: 1px rgb(0, 0, 0) dotted;
// }

/* Default height */
.fixedContainer-Map_SavedPlaces-CreateItinerary {
    height: 92%;
}

/* Zoom level proxy 50% - Viewport width increased */
@media (min-width: 1500px) {
    .fixedContainer-Map_SavedPlaces-CreateItinerary {
        height: 92%;
    }
}

/* Zoom level proxy 75% */
@media (min-width: 1200px) and (max-width: 1499px) {
    .fixedContainer-Map_SavedPlaces-CreateItinerary {
        height: 90%;
    }
}

/* Zoom level proxy 100% */
@media (min-width: 900px) and (max-width: 1199px) {
    .fixedContainer-Map_SavedPlaces-CreateItinerary {
        height: 89%;
    }
}

/* Zoom level proxy 125% */
@media (min-width: 700px) and (max-width: 899px) {
    .fixedContainer-Map_SavedPlaces-CreateItinerary {
        height: 86%;
        // border: 1px rgb(0, 0, 0) dotted;
    }
}


//!other

//navigation
.container_p-NavigationButtons-Map-CreateItinerary{
    // width: 10px;
    height: 6%;
    // background: white;
    // border: 1px solid red;
    border-radius: 6px;
    background: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    align-self: flex-start;
    align-self: self-start;
    // padding: 3% 1% 3% 1%;   
    // margin-left: 2%;
}
.container_p-NavigationButtons-Map-CreateItinerary{
    width: 96%;
    background: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    // justify-content: center;
    padding: 3% 1% 3% 1%;
    margin-top: 1%;
    margin-left: 2%;
}
.button-active-MapNavigation-CreateItinerary{
    border: none;
    padding: 4px 12px 4px 12px;
    margin: 4px;
    border-radius: 6px;
    font-weight: bold;
    font-family: $font-4;
    background: rgb(224, 7, 7);
    color: white;
}
.button-unActive-MapNavigation-CreateItinerary{
    border: none;
    padding: 4px 12px 4px 12px;
    margin: 4px;
    border-radius: 6px;
    // font-weight: bold;
    font-family: $font-4;
    color: rgb(224, 7, 7);
    background: none;
    font-weight: bold;
}
//Components - Map and Saved Places
.component-Map-CreateItinerary{
    width: 98%;
    margin-top: 3%;
    height: 90%;
    border-radius: 6px;
    margin-left: 1%;
    align-self: center;
    display: flex;
    border: 1px rgb(220, 216, 214) solid;
    filter: saturate(130%);
    filter:contrast(120%);
    box-shadow: 0 4px 8px #ffffff;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    // box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    // box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.2);
} 
.component-SavedPlaces-CreateItinerary{
    width: 100%;
    margin-top: 2%;
    height: 92%;
    border-radius: 12px;
    margin-left: 1%;
    // border: 1px rgb(190, 190, 190) solid
    filter: saturate(130%);
    filter:contrast(120%);
    box-shadow: 0 4px 8px rgba(122, 122, 122, 0.2);
} 



.Itinerary-CI-Component-CreateItinerary{
    width: 60%;
    /* border: 1px palevioletred dotted; */
    background-color: rgb(247, 228, 222);
    padding: 1%;
    border-radius: 6px;
}
.MainContainer-SavedPlaces_Map-CreateItinerary{
    width: 34%;
    /* border: 3px palevioletred dotted; */
    background-color: rgb(189, 189, 189);
    /* padding: 1%; */
    border-radius: 6px;
}

/*  */
/*  */
/*  */
.Saved_Places-CI-Component-CreateItinerary{
    width: 100%;
    height: 94%;
    border: 1px rgb(174, 174, 174) dotted;
    /* padding: 1%; */
    border-radius: 6px;
}

.Map-CI-Component-CreateItinerary{
    /* position: fixed; */
    /* width: 34%;
    height: 74%; */
    width: 100%;
    height: 90%;
    // border: 2px orangered solid;
    border-radius: 6px;
    margin-top: 2%;
    margin-left: 2%;
}

/* Temp */
.active-CreateItinerary{
    font-weight: bold;
}
.unActive-CreateItinerary{
    font-weight: lighter;
}


//!
.footer-bottomSpace-CreateItinerary{
    background-color: whitesmoke;
    height: 200px;
}






//!Not login - create itinerary
.conatiner_p-notLogin-CreateItinerary{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: white;
    cursor: pointer;
}
.image-notLogin-CreateItinerary{
    width: 40%;
    height: 40%;
    border-radius: 8px;
}
.button-heading-1-CreateItinerary{
    position: absolute;
    font-size: 34px;
    color: rgb(255, 0, 0);
    font-family: $font-6;
    font-weight: bold;
    border: none;
    background: none;
    background: white;
}





