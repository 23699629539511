$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

.conatiner_p-CreateTrip{
    position: absolute;
    left: 30px;
    top: 10px;
}


/* Background overlay */
.popup-background-1-CreateTrip {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
}


.conatiner_p-nameIcon-CreateTrip{
    display: flex;
    // flex-direction: row;
    background: lightcoral;
    margin-top: 6px;
    padding: 0px 4px;
    border-radius: 4px;

    &:hover{
        background-color: rgb(255, 160, 77);
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(255, 0, 0);
        transition: background-color 0.3s ease;
    }
}
.button-heading-nameIcon-CreateTrip{
    border: none;
    background: none;
    font-family: $font-content-2;
    margin-right: 10px;
    color: white;
}

//

@media (min-width: 0px) and (max-width: 299px){
    .container_p-popup-CreateTrip{
        width: 96%;
        height: fit-content;
        position: fixed;
        top: 10%;
        left: 2%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        box-shadow: 0 0 4px rgb(243, 158, 21);
        z-index: 100010;
        overflow: auto;
        display: flex;
        // align-items: center;
        text-align: center;
        // justify-content: center;
        flex-direction: column;
        // justify-content: space-around;
    }
}
@media (min-width: 300px) and (max-width: 499px){
    .container_p-popup-CreateTrip{
        width: 90%;
        height: fit-content;
        position: fixed;
        top: 10%;
        left: 5%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        box-shadow: 0 0 4px rgb(243, 158, 21);
        z-index: 100010;
        overflow: auto;
        display: flex;
        // align-items: center;
        text-align: center;
        // justify-content: center;
        flex-direction: column;
        // justify-content: space-around;
    }
}
@media (min-width: 450px) and (max-width: 799px){
    .container_p-popup-CreateTrip{
        width: 90%;
        height: fit-content;
        position: fixed;
        top: 10%;
        left: 5%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        box-shadow: 0 0 4px rgb(243, 158, 21);
        z-index: 100010;
        overflow: auto;
        display: flex;
        // align-items: center;
        text-align: center;
        // justify-content: center;
        // flex-direction: column;
        // justify-content: space-around;
    }
}
@media (min-width: 800px){
    .container_p-popup-CreateTrip{
        width: 50%;
        height: fit-content;
        position: fixed;
        top: 10%;
        left: 26%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        box-shadow: 0 0 4px rgb(243, 158, 21);
        z-index: 100010;
        overflow: auto;
        display: flex;
        text-align: center;
        // align-items: center;
        // justify-content: center;
        // flex-direction: column;
        // justify-content: space-around;
    }
}

// .container_p-popup-CreateTrip{
//     width: 50%;
//     height: fit-content;
//     position: fixed;
//     top: 10%;
//     left: 26%;
//     border: none;
//     border-radius: 8px;
//     background: #fff;
//     padding: 20px;
//     box-shadow: 0 0 4px rgb(243, 158, 21);
//     z-index: 100010;
//     overflow: auto;
//     display: flex;
//     text-align: center;
// }

//!
.conatiner_p-tripProgressStatus{
    background-color: whitesmoke;
    padding: 16px;
    border-radius: 8px;
    text-align: center;
    height: fit-content;
}
.text-1-tripProgressStatus{
    font-size: 18px;
    font-weight: bold;
    font-family: $font-3;
    margin-bottom: 12px;
    color: red;
}
.active-tripProgressStatus-CreateTrip{
    background-color: rgb(248, 136, 96);
    color: white;
    // border: 2px solid rgb(200, 100, 80);
    border: none;
    margin: 4px;

    &:hover{
        opacity: 0.9;
    }
    &:active{
        transform: scale(0.98);
    }
}
.unActive-tripProgressStatus-CreateTrip{
    background-color: white;
    color: rgb(50, 48, 48);
    // border: 2px solid lightgray;
    border: none;
    margin: 4px;

    &:hover{
        opacity: 0.9;
    }
    &:active{
        transform: scale(0.98);
    }
}

//!
.container_s-popup-CreateTrip{
    width: 100%;
    height: 100%;
    // position: fixed;
    top: 12%;
    left: 26%;
    border: none;
    border-radius: 8px;
    background: #fff;
    padding: 20px;
    // box-shadow: 0 0 10px rgb(71, 124, 248);
    z-index: 1000000000000000000000;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    height: fit-content;
}

.heading-1-popup-CreateTrip{
    font-family: $font-6;
    font-size: 24px;
    color: orangered;
}
.input-1-popup-CreateTrip{
    border: 1px solid rgb(255, 0, 0);
    width: 70%;
    padding: 8px 8px 4px 8px;
    margin: 16px 6px 4px 12px;
    border-radius: 6px;

    font-family: $font-6;
    font-size: 18px;
    letter-spacing: 0.4px;
    color: gray;
    
    align-items: center;
    text-align: center;

    &:focus{
        outline: none;
        padding: 10px 4px 8px 12px;
        border: none;
        border-radius: 8px;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgba(134, 202, 81, 0.36) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
}
.length-input-1-popup-CreateTrip{
    color: rgb(153, 147, 147);
    font-family: $font-4;
    margin-top: 16px;
    font-size: 14px;
}
.button-1-popup-CreateTrip{
    display: flex;
    flex-direction: row;
    background: none;
    border: none;
    // border: 1px solid lightcoral;
    font-family: $font-content-2;
    font-size: 16px;
    margin-left: 4px;
    border-radius: 14px;
    padding: 2px 14px;
    color: white;
    background-color: rgb(255, 56, 56);
    
    &:hover{
        background-color: rgb(255, 0, 0);
        transition: background-color 0.3s ease;
        font-size: 16px;
        padding: 3px 16px;
        // border-radius: 6px;
        // border: 6px;
        // padding: 4px 20px;
    }
    &:active{
        background-color: rgb(255, 174, 98);
        transition: background-color 0.3s ease;
        // border-radius: 6px;
        // border: 6px;
        // padding: 4px 20px;
    }
}