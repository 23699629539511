.custom-iconDD-MapAiItinerary{
    border-radius: 50px;
    background-color: rgb(204, 77, 14);
    display: flex;
    align-items: center;
    justify-content: center;
    /* z-index: 100000000; */
}
.custom-iconDD-MapAiItinerary_2{
    border-radius: 50px;
    background-color: rgb(255, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    /* z-index: 100000000; */
}
.number-icon-MapAiItinerary{
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: bold;
    z-index: 1000000000000000000000000000000;
}
