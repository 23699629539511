$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


//!View Prices Popup
.conatiner_p-viewPrice-Popup-Content_Hotels_2{
    width: 100%;
    text-align: center;
    // background: rgb(173, 176, 177);
    box-shadow: 0 0 4px rgba(81, 81, 81, 0.524);
    margin: 10px 2px 2px 2px;
    // margin: 2px;
    padding: 2px;
    border-radius: 4px;

    &:hover{
        background: orange;
        transition: background-color 0.3s ease;
    }
    &:active{
        background: rgb(251, 116, 116);
        transition: background-color 0.3s ease;
    }
}
.button-1-viewPrice-Popup-Content_Hotels_2{
    font-family: $font-6;
    color: orangered;
    background: none;
    border: none;
    width: 100%;

    &:hover{
        color: white;
        transition: color 0.1s ease;
    }
    &:active{
        color: rgb(244, 238, 238);
        transition: color 0.1s ease;
    }
}


//!Show More
.text-1-ShowMore-Content_Hotels_2{
    font-family: $font-6;
    letter-spacing: 3px;
    word-spacing: 3px;
    color: rgb(0, 0, 0);
    border: none;
    background: rgb(255, 255, 255);
    padding: 4px 20px 4px 20px;
    border-radius: 8px;
    margin-top: 12px;
    margin-left: 12px;
    box-shadow: 0 0 4px rgb(235, 176, 156);

    &:hover{
        background: orange;
        color: white;
    }
    &:active{
        background: orangered;
        color: white;
    }
}