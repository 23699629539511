$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

.MainMainConatiner-MapCI{
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

//
.toolTip-destinationName{
    background-color: rgba(255, 255, 255, 0.797); 
    color: rgb(124, 0, 0);    
    font-family: $font-6;
    // padding: 0px;
    padding: 0px 3px 0px 3px; 
    border-radius: 8px;   
    font-size: 12px; 
}


.map-pin-active{
    padding: 40px;
    border-radius: 50px;
    // border: 1.6px solid orange;
    border: 2px solid rgb(255, 0, 0); 
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.map-pin-unActive{
    padding: 40px;
    border-radius: 50px;
    // border: 1.6px solid orange;
    border: 1px solid rgb(185, 0, 0); 
    background-color: white;
}

.customIcon-ToolTip-active{
    background-color: rgba(170, 15, 15, 0.797); 
    color: rgb(255, 255, 255);       
    padding: 2px 5px 2px 5px; 
    border-radius: 8px;   
    // border: 1px solid rgb(255, 255, 255); 
    // font-family: $font-6;
    font-size: 10px;    
    line-height: 1; 
    margin-top: 10px;
    // margin-left: 8px;
    // margin-top: -8px;   
}  
.customIcon-ToolTip-unActive{
    background-color: rgb(255, 0, 0); 
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    color: rgb(255, 255, 255);       
    padding: 1px 5px 2px 5px; 
    border-radius: 8px;   
    // border: 1px solid rgb(255, 255, 255); 
    // font-family: $font-6;
    font-size: 14px;    
    line-height: 1; 
    margin-left: 2px;
    margin-top: 2px;   
}

.savedPlaceName-ToolTip-active{
    background-color: rgba(136, 50, 0, 0.797); 
    color: rgb(230, 230, 230);       
    padding: 1px 5px 2px 5px; 
    border-radius: 8px;   
    // border: 1px solid rgb(255, 255, 255); 
    // font-family: $font-6;
    font-size: 12px;    
    line-height: 1; 
    margin-left: 8px;
    margin-top: -8px;   
}  
