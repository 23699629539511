@mixin primary-button-plantrip {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    transition: background-color 0.3s;

    &:hover {
        background-color: darken(#007bff, 10%);
    }

    &:active {
        background-color: lighten(#007bff, 10%);
    }
}
  
  @mixin secondary-button-plantrip {
    background-color: #28a745;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: darken(#28a745, 10%);
    }
  
    &:active {
      background-color: lighten(#28a745, 10%);
    }
  }
  
  @mixin info-button-plantrip {
    background-color: #17a2b8;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: darken(#17a2b8, 10%);
    }
  
    &:active {
      background-color: lighten(#17a2b8, 10%);
    }
  }
  
  @mixin light-button-plantrip {
    background-color: #f8f9fa;
    color: #000;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: darken(#f8f9fa, 10%);
    }
  
    &:active {
      background-color: lighten(#f8f9fa, 10%);
    }
  }
  