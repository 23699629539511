$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//




@media (min-width: 0px) and (max-width: 299px){
  //? Input - description
  .input-1-searchPlaceName-GoogleSearchPlaces{
    border: 1px solid rgb(171, 167, 167);
    width: 160px;
    padding: 8px 6px 4px 6px;
    margin: 8px 6px 4px 12px;
    border-radius: 4px;

    font-family: $font-3;
    font-weight: 600;
    letter-spacing: 0.4px;

    &:focus{
        outline: none;
        padding: 10px 4px 8px 12px;
        border: none;
        border-radius: 8px;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgba(122, 123, 124, 0.36) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
  }
}
@media (min-width: 300px) and (max-width: 499px){
  //? Input - description
  .input-1-searchPlaceName-GoogleSearchPlaces{
    border: 1px solid rgb(171, 167, 167);
    width: 260px;
    padding: 8px 6px 4px 6px;
    margin: 8px 6px 4px 12px;
    border-radius: 4px;

    font-weight: bold;

    font-family: $font-6;
    // font-weight: 600;
    letter-spacing: 0.4px;

    &:focus{
        outline: none;
        padding: 10px 4px 8px 12px;
        border: none;
        border-radius: 8px;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgba(122, 123, 124, 0.36) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
  }
}
@media (min-width: 500px) and (max-width: 799px){
  //? Input - description
  .input-1-searchPlaceName-GoogleSearchPlaces{
    border: 1px solid rgb(171, 167, 167);
    width: 460px;
    padding: 8px 6px 4px 6px;
    margin: 8px 6px 4px 12px;
    border-radius: 4px;

    font-family: $font-3;
    font-weight: 600;
    letter-spacing: 0.4px;

    &:focus{
        outline: none;
        padding: 10px 4px 8px 12px;
        border: none;
        border-radius: 8px;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgba(122, 123, 124, 0.36) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
  }
}
@media (min-width: 800px){
  //? Input - description
  .input-1-searchPlaceName-GoogleSearchPlaces{
    border: 1px solid rgb(171, 167, 167);
    width: 460px;
    padding: 8px 6px 4px 6px;
    margin: 8px 6px 4px 12px;
    border-radius: 4px;

    font-family: $font-3;
    font-weight: 600;
    letter-spacing: 0.4px;

    &:focus{
        outline: none;
        padding: 10px 4px 8px 12px;
        border: none;
        border-radius: 8px;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgba(122, 123, 124, 0.36) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
  }
}


// //? Input - description
// .input-1-searchPlaceName-GoogleSearchPlaces{
//   border: 1px solid rgb(171, 167, 167);
//   width: 460px;
//   padding: 8px 6px 4px 6px;
//   margin: 8px 6px 4px 12px;
//   border-radius: 4px;

//   font-family: $font-3;
//   font-weight: 600;
//   letter-spacing: 0.4px;

//   &:focus{
//       outline: none;
//       padding: 10px 4px 8px 12px;
//       border: none;
//       border-radius: 8px;
//       box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgba(122, 123, 124, 0.36) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
//   }
// }



.input-1-results-searchPlaceName-GoogleSearchPlaces{
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-left: 20px;
    padding: 10px;
    max-width: 400px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .no-results, .loading {
    color: #914e0f;
    font-style: italic;
    padding: 5px 0;
    font-size: 14px;
  }
  
  .info-text {
    color: #7a7a7a;
    font-style: italic;
    font-size: 12px;
    margin-bottom: 10px;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .place-suggestion-button {
    display: block;
    width: 100%;
    background-color: #fff;
    // border: 1px solid #ddd;
    color: #888;
    font-family: $font-6;
    border: none;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 4px;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .place-suggestion-button:hover {
    background-color: #f0f0f0;
  }
  
  .place-suggestion-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(100, 149, 237, 0.5);
  }
  