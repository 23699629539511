.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
  }
  
  .privacy-policy-back {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  section {
    margin-bottom: 20px;
  }
  
  b {
    font-weight: bold;
  }
  