.SavedPlaces_TO-Main-SavedPlaces_TO{
    width: 100%;
    height: 100%;
    padding: 1%;
    border: 1px solid lightgreen;
}


.text-index-SavedPlaces_TO{
    position: absolute;
    background-color: rgba(107, 24, 24, 0.917);
    color: rgb(218, 218, 218);
    // width: 20px;
    // height: 20px;
    // border-radius: 50%;
    // padding: 4px;
    border-radius: 4px;
    top: 2px;
    left: 2px;
    padding-left: 2px;
    // text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    z-index: 10;
}


.img-notFound-SavedPlacesCI{
    border-radius: 8px;
    width: 400px;
    height: 300px;
    object-fit: cover;
    margin-top: 20px;
    // margin-left: -100%;
    // background: greenyellow;
    // width: 100%;
}