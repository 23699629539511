$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

//inner hotel navigation
.container-navigation_hotels-Search_Tool_Hotel_PT{
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // text-align: center;
    box-shadow: rgb(180, 178, 178) 0px 0px 5px;
    width: fit-content;
    padding: 6px 6px 4px 6px;
    border-radius: 6px;
}
.container-saved-navigation_hotels-Search_Tool_Hotel_PT{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    box-shadow: rgb(255, 210, 189) 0px 0px 10px;
    width: fit-content;
    padding: 2px 6px 2px 6px;
    border-radius: 6px;
}
.button-navigation_hotels-Search_Tool_Hotel_PT{
    border: none;
    background: none;
    font-family: $font-6;
    font-size: 18px;
}
.button-2-navigation_hotels-Search_Tool_Hotel_PT{
    margin-left: 30px;
    border: none;
    background: none;
    font-size: 30px;
    color: red;
}

.active_search_type-SearchTool-Hotel{
    font-weight: bold;
}

.unactive_search_type-SearchTool-Hotel{
    font-weight: normal;
}

.tempMain-SearchTool{
    display: flex;
    flex-direction: row;
    background-color: rgb(203, 237, 203);
    width: 80%;
    overflow-x: scroll;
    /* overflow-y: scroll; */
    /* flex-wrap: nowrap; */
}
.tempMain2-SearchTool{
    display: flex;
    flex-direction: row;
    background-color: rgb(183, 184, 183);
    width: 100%;
}
.temp3-SearchTool{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
}

//!App
.container_p-Search_Tool_Mobile{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    // border: 4px solid rgb(246, 228, 72);
    width: 100%;
    margin-top: 4%;
}
.container_s-Search_Tool_Mobile{
    // background: rgb(251, 250, 250);
    border-radius: 16px;
    width: 90%;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-around;
    // box-shadow: 0 0 2px #b4b0b0; 
    box-shadow: rgb(255, 210, 189) 0px 0px 5px;
}
.container_s-1-Search_Tool_Mobile{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: start;
    text-align: start;
    align-self: start;
    width: 30%;
    // margin-top: 10px;
    height: 100%;
    padding: 10px 0px 16px 0px;
    margin-left: 6%;
    // background: rgb(255, 246, 246);
    border-radius: 16px;   
}
.heading-1-searchHotel-Search_Tool_Mobile{
    color: rgb(132, 181, 34);
    font-size: 22px;
    font-family: $font-6;
    // margin-left: 24px;
    margin-top: 24px;
    white-space: nowrap;
    margin-left: 10px;
    margin-top: -0px;
}
.container_s-2-Search_Tool_Mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 16px 0px 16px 0px;
    // flex-wrap: wrap;
    // justify-content: space-between;
    background: rgb(255, 234, 234);
    border-radius: 12px;
    // border: 2px solid rgb(58, 96, 0);
    width: 90%;
    align-self: center;
}
.container_t-2-Search_Tool_Mobile{
    display: flex;
    flex-direction: column;
    // flex-direction: column;
    flex-wrap: wrap;
    // width: 44%;
    width: 100%;
    justify-content: space-around;
    // border: #ef6700 1px solid;
}



//!
.container_p-Search_Tool{
    display: flex;
    flex-direction: column;
    // border: 4px solid rgb(246, 228, 72);
    width: 100%;
    margin-top: 4%;
}
.container_s-Search_Tool{
    // background: rgb(251, 250, 250);
    border-radius: 16px;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-around;
    // box-shadow: 0 0 2px #b4b0b0; 
    box-shadow: rgb(255, 210, 189) 0px 0px 5px;
}

//*heading
//container 1
.container_s-1-Search_Tool{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    align-self: start;
    // background-color: white;
    // width: fit-content;
    // margin-bottom: 20px;
    // border: 2px solid rgb(58, 96, 0);
    width: 30%;
    // margin-top: 10px;
    height: 100%;
    padding: 10px 0px 16px 0px;
    // background: rgb(255, 246, 246);
    border-radius: 16px;   
}
.img-searchHotel-Search_Tool{
    width: 30px;
    height: 30px;
}
.heading-1-searchHotel-Search_Tool{
    color: rgb(132, 181, 34);
    font-size: 22px;
    font-family: $font-6;
    // margin-left: 24px;
    margin-top: 24px;
}

//container 2
.container_s-2-Search_Tool{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px 0px 16px 0px;
    // flex-wrap: wrap;
    // justify-content: space-between;
    background: rgb(255, 234, 234);
    border-radius: 12px;
    // border: 2px solid rgb(58, 96, 0);
    width: 66%;
}
.container_t-2-Search_Tool{
    display: flex;
    flex-direction: row;
    // flex-direction: column;
    flex-wrap: wrap;
    // width: 44%;
    width: 90%;
    justify-content: space-around;
    // border: #ef6700 1px solid;
}
.container_t-3-Search_Tool{
    width: 70%;
    margin-top: 20px;
    // margin-left: 18px;
    // border: #01e8c9 1px solid;
}

//button
.button-active-SearchTool{
    font-weight: normal;
    font-family: $font-3;
    font-weight: bold;
    background-color: rgb(244, 93, 38);
    color: white;
    font-size: 16px;
    // background: none;
    border: none;
    margin-right: 4px;
    margin-top: 10px;
    padding: 1px 6px 2px 6px;
    height: fit-content;
    border-radius: 10px;
    letter-spacing: 0.4px;
    // white-space: nowrap;

    &:hover{
        background-color: rgb(255, 133, 89);
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(237, 14, 14);
        transition: background-color 0.3s ease;
    }
}
.button-unactive-SearchTool{
    font-weight: normal;
    font-family: $font-3;
    font-weight: bold;
    color: orangered;
    font-size: 16px;
    background: none;
    border: none;
    margin-right: 4px;
    margin-top: 10px;
    padding: 1px 6px 2px 6px;
    height: fit-content;
    letter-spacing: 0.4px;
    // white-space: nowrap;

    &:hover{
        background-color: #eeadad;
        transition: background-color 0.3s ease;
        padding: 1px 6px 2px 6px;
        border-radius: 10px;
        // letter-spacing: 0.4px;
        color: rgb(255, 255, 255);
    }
    &:active{
        background-color: rgb(237, 14, 14);
        transition: background-color 0.3s ease;
    }
}

.container_p-heading-Search_Tool{
    width: 100%;
    background: rgb(251, 249, 249);
    text-align: center;
}
.text-1-placeName-Search_Tool{
    font-family: $font-6;
    color: brown;
    margin-top: 2%;
    margin-left: 1%;
    font-size: 18px;
    letter-spacing: 0.8px;
    font-weight: bold;
}

//
.buttton-clearAll-Search_Tool{
    font-size: 12px;
    border: none;
    background: none;
    margin-top: 2%;
    margin-left: 1%;
    margin-bottom: -1%;
    background: rgb(244, 242, 242);
    padding: 2px 6px 2px 6px;
    border-radius: 6px;
    font-family: $font-8;
s
    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}


//!Navigation
.container_p-navigation_1-Search_Tool{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    flex-wrap: wrap;
    border: 1px solid rgb(232, 232, 232);
    // background: rgb(250, 250, 250);
    border-radius: 16px;
    margin-top: 2%;
    width: 100%;
    padding-bottom: 1%;
}

//*Navigation 1
.conatiner_t-active-navigation_1-Search_Tool{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;

    font-family: $font-6;
    // background: rgb(255, 70, 70);
    background: whitesmoke;
    // border: none;
    // background: none;
    border: red 4px solid;
    border-top: none;
    border-left: none;
    border-right: none;
    // border-bottom: none;
    margin: 12px 8px 2px 0px;
    padding: 4px 8px 4px 8px;
    border-radius: 10px;

    // padding-bottom: 12px;
    // border-bottom: 1px solid lightgray;

    &:hover{
        // background-color: orange;
        background-color: whitesmoke;
        transition: background-color 0.3s ease;
        border-radius: 8px;
    }
    &:active{
        background-color: red;
        transition: background-color 0.3s ease;
    }
}
.conatiner_t-unActive-navigation_1-Search_Tool{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;

    font-family: $font-6;
    border: none;
    background: none;
    margin: 12px 8px 2px 0px;
    padding: 8px 8px 8px 8px;
    border-radius: 10px;
    // background-color: whitesmoke;

    &:hover{
        background-color: whitesmoke;
        transition: background-color 0.3s ease;
        border-radius: 8px;
    }
    &:active{
        background-color: rgb(255, 221, 221);
        transition: background-color 0.3s ease;
    }
}

.heading-1-active-navigation_1-Search_Tool{
    font-family: $font-6;
    font-size: 14px;
    // color: rgb(0, 0, 0);
    color: orangered;
    background: white;
    border: none;
    // margin: 12px 20px 2px 0px;
    padding: 1px 8px 1px 8px;
    border-radius: 6px;
}
.heading-1-unActive-navigation_1-Search_Tool{
    font-family: $font-6;
    font-size: 14px;
    color: rgb(77, 76, 76);
}
.img-1-navigation_1-Search_Tool{
    width: 22px;
    height: 22px;
    margin-left: 10px;
}

