$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


.container_p-createItinerary-AISection{
    width: 100%;
    // background: whitesmoke;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 4%;
}
.container_s-createItinerary-AISection{
    width: 60%;
    // border: 2px orange solid;
    border: none;
    background: none;
}
.container_p-metaData-createItinerary-AISection{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
// .container_s-metaData-createItinerary-AISection{
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     width: 50%;
//     align-items: center;
//     text-align: center;
//     justify-content: center;
//     // border: 2px orange solid;
//     // justify-content: space-around;
// }


// .button-1-metaData-createItinerary-AISection{
//     padding: 4px 6px 4px 6px;
//     border-radius: 8px;
//     background: rgb(244, 244, 244);
//     color: rgb(151, 151, 151);
//     letter-spacing: 0.6px;
//     font-family: $font-6;
//     font-size: 12px;
//     border: none;
//     margin-right: 10px; 
//     margin-top: 10px;
// }



@media (min-width: 0px) and (max-width: 499px){
    .img-createItinerary-AISection{
        margin-top: 4%;
        width: 96%;
        box-shadow: rgba(198, 198, 198, 0.739) 0px 0px 4px;
        border-radius: 12px;
        cursor: pointer;

        &:hover{
            box-shadow: rgba(139, 176, 234, 0.739) 0px 0px 6px;
            border-radius: 12px;
            transition: box-shadow 0.3s ease;
        }
    }    
    .container_s-metaData-createItinerary-AISection{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 96%;
        align-items: center;
        text-align: center;
        justify-content: center;
        // border: 2px orange solid;
        // justify-content: space-around;
    }
    .button-1-metaData-createItinerary-AISection{
        padding: 4px 6px 4px 6px;
        border-radius: 8px;
        background: rgb(247, 247, 247);
        color: rgb(194, 194, 194);
        letter-spacing: 0.6px;
        font-family: $font-6;
        font-size: 10px;
        border: none;
        margin-right: 10px; 
        margin-top: 10px;
    }
}

@media (min-width: 500px) and (max-width: 1000px){
    .img-createItinerary-AISection{
        width: 70%;
        box-shadow: rgba(198, 198, 198, 0.739) 0px 0px 4px;
        border-radius: 12px;
        cursor: pointer;
    
        &:hover{
            box-shadow: rgba(139, 176, 234, 0.739) 0px 0px 6px;
            border-radius: 12px;
            transition: box-shadow 0.3s ease;
        }
    }    
    .container_s-metaData-createItinerary-AISection{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 96%;
        align-items: center;
        text-align: center;
        justify-content: center;
        // border: 2px orange solid;
        // justify-content: space-around;
    }
    .button-1-metaData-createItinerary-AISection{
        padding: 4px 6px 4px 6px;
        border-radius: 8px;
        background: rgb(244, 244, 244);
        color: rgb(151, 151, 151);
        letter-spacing: 0.6px;
        font-family: $font-6;
        font-size: 12px;
        border: none;
        margin-right: 10px; 
        margin-top: 10px;
    }
}

@media (min-width: 1000px){
    .img-createItinerary-AISection{
        width: 70%;
        box-shadow: rgba(198, 198, 198, 0.739) 0px 0px 4px;
        border-radius: 12px;
        cursor: pointer;
    
        &:hover{
            box-shadow: rgba(139, 176, 234, 0.739) 0px 0px 6px;
            border-radius: 12px;
            transition: box-shadow 0.3s ease;
        }
    }    
    .container_s-metaData-createItinerary-AISection{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 50%;
        align-items: center;
        text-align: center;
        justify-content: center;
        // border: 2px orange solid;
        // justify-content: space-around;
    }
    .button-1-metaData-createItinerary-AISection{
        padding: 4px 6px 4px 6px;
        border-radius: 8px;
        background: rgb(244, 244, 244);
        color: rgb(151, 151, 151);
        letter-spacing: 0.6px;
        font-family: $font-6;
        font-size: 12px;
        border: none;
        margin-right: 10px; 
        margin-top: 10px;
    }
}

