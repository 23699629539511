$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


.popup_p-3-HowToReach_Mobile{
    width: 99%;
    height: 99%;
    position: fixed;
    top: 0.5%;
    left: 0.5%;
    border: none;
    border-radius: 8px;
    background: #ffffff;
    padding: 0px;
    box-shadow: 0 0 4px rgb(253, 195, 124);
    z-index: 10010;
    overflow: auto;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.popup_s-addNote-HowToReach_Mobile{
    width: 98%;
    height: 98%;
    top: 10%;
    left: 20%;
    border: 1px solid #000;
    // background: #ffdcdc;
    padding: 0px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: none;
    z-index: 10010;
    overflow: auto;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // text-align: center;
}


//2 poup for HTR AI Overview
.popup_p-3-2-HowToReach_Mobile{
    width: 88%;
    height: 86%;
    position: fixed;
    top: 4%;
    left: 6%;
    border: none;
    border-radius: 8px;
    background: #fff;
    padding: 10px;
    box-shadow: 0 0 4px rgb(253, 195, 124);
    z-index: 10010;
    overflow: auto;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.popup_s-2-addNote-HowToReach_Mobile{
    width: 98%;
    height: 98%;
    top: 10%;
    left: 20%;
    border: 1px solid #000;
    // background: #ffdcdc;
    padding: 2px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: none;
    z-index: 10010;
    overflow: auto;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // text-align: center;
}





.button-close-HowToReach_Mobile{
    background: none;
    border: none;
    font-size: 24px;
    position: absolute;
    left: 1%;
    top: -12%;
    // top: -360px;
    // right: 40px;
    z-index: 30px;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}

.button-2-close-HowToReach_Mobile{
    background: none;
    border: none;
    font-size: 34px;
    // position: absolute;
    // right: 0%;
    // top: 0%;
    z-index: 10000000000000px;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}



//!MAP
.container_p-map-HowToReach_Mobile{
    width: 100%;
    height: 100%;
    // position: relative;
    // border: 10px solid orchid;
    border-radius: 8px;
    z-index: 10;
}
.container_s-map-HowToReach_Mobile{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    // position: fixed;
    box-shadow: 0 0 2px rgb(97, 97, 97); 
    // overflow: hidden;
}


//!Content
.container_p-Main-Content-HowToReach_Mobile{
    position: absolute;
    // border: 1px yellowgreen solid;
    bottom: 2%;
    z-index: 20;
}




//!Navigation
.container_p-navigation-HowToReach_Mobile{
    display: flex;
    // width: 60%;
    flex-direction: row;
    flex-wrap: wrap;    
    background: rgb(255, 247, 247);
    // opacity: 1;
    z-index: 20;
    justify-content: space-around;
    padding: 2px 0px 2px 0px;
    margin-bottom: 1px;
    margin-top: 1px;
}
.button-active-aiOverview-HTR_M{
    align-self: center;
    font-size: 14px;
    font-family: $font-content-2;
    // margin: 4% 4% 4% 4%;
    color: rgb(113, 158, 16);
    // font-weight: bold;
    border: none;
    background: rgb(244, 244, 244);
    border-radius: 4px;
    padding: 0px 4px 0px 4px;
    margin-right: 1%;
}
.button-active-navigation-HTR_M{
    align-self: center;
    font-size: 14px;
    font-family: $font-6;
    // margin: 4% 4% 4% 4%;
    color: rgb(213, 91, 4);
    font-weight: bold;
    border: none;
    background: none;
    margin-right: 1%;
}
.button-unActive-navigation-HTR_M{
    align-self: center;
    font-size: 14px;
    font-family: $font-6;
    // margin: 4% 4% 4% 4%;
    color: rgb(165, 108, 67);
    // font-weight: bold;
    border: none;
    background: none;
    margin-right: 1%;
}





//!
.content-1-data-HowToReach{
    // background: white;
    width: 90%;
    background: rgb(255, 255, 255);
    color: rgb(56, 54, 54);
    // border: 1px solid olive;
    align-items: self-start;
    text-align: start;
    padding: 10px;
    margin: 4%;
    border-radius: 12px;
    font-family: $font-6;
    // font-family: $font-heading-2;
    font-size: 16px;
    letter-spacing: 0.4px;
    word-spacing: 0.6px;
    line-height: 1.6;  

    // border: 2px solid rgb(255, 235, 225);
    box-shadow: rgba(163, 194, 123, 0.825) 0px 1px 2px 0px;

}


/* Adding styles for ul and li elements */
.content-1-data-HowToReach ul {
    padding-left: 20px; /* Adjust as needed */
    margin-bottom: 20px; /* Adjust as needed */
}
.content-1-data-HowToReach ol {
    padding-left: 20px; /* Adjust as needed */
    margin-bottom: 20px; /* Adjust as needed */
    font-family: $font-6;
}

.content-1-data-HowToReach li {
    margin-bottom: 10px; /* Space between list items */
    line-height: 1.6; /* Adjust for spacing within list items */
    // font-family: $font-6;
    // color: rebeccapurple;
    // font-weight: normal;
    // font-family: $font-4;
}

.content-1-data-HowToReach h1 {
    // font-family: $;
    font-size: 17px;
    margin-top: 8px;
}

.content-1-data-HowToReach h2 {
    // font-family: $;
    font-size: 17px;
    color: rgb(52, 51, 51);
    margin-top: 8px;
    margin-bottom: 6px;
    font-family: $font-heading-1;
    letter-spacing: 0.8px;
}

.content-1-data-HowToReach h3 {
    // font-family: $;
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 8px;
    font-family: $font-6;
    letter-spacing: 0.8px;
    font-weight: normal;
    // color: gray;
    text-decoration: underline;
}
//? Rich Text - End






//!Content
.conatiner_p-Places-HowToReach_Mobile{
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    // overflow-y: hidden;
    height: 300px;
    // min-height: 190px;
    // border: 10px solid orchid;
    // height: fit-content;
    z-index: 40;
    // height: 300px;
    // border: 1px solid greenyellow;
    // background: rgb(184, 71, 71);
}
.conatiner_p-Places-HowToReach_Mobile{
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    // overflow-y: hidden;
    height: 300px;
    // min-height: 190px;
    // border: 10px solid orchid;
    // height: fit-content;
    z-index: 40;
    // height: 300px;
    // border: 1px solid greenyellow;
    // background: rgb(184, 71, 71);
}
.conatiner_p-2-Places-HowToReach_Mobile{
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    // overflow-y: hidden;
    height: 160px;
    // min-height: 190px;
    // border: 10px solid orchid;
    // height: fit-content;
    z-index: 40;
    // height: 300px;
    // border: 1px solid greenyellow;
    // background: rgb(184, 71, 71);
}

.container_p-Cards-Air-HowToReach_Mobile{
    // margin: 4px;
    display: flex;
    flex-direction: row;
    // justify-content: space-around;
    // flex-wrap: wrap;
    width: 99%;
    // width: 100%;
    // width: 300px;
    // margin-left: -6%;
    overflow-x: scroll;
    // overflow: scroll;
    background: rgb(184, 71, 71);
    // display: none;
}
.card-Air-HowToReach_Mobile{
    margin: 0px 4px 0px 0px;
    // padding: 4% 4px 4% 4px;
    border-radius: 6px;
    min-width: 70%;
    max-width: 70%;
    height: 100%;
    box-shadow: 0 4px 8px rgba(93, 92, 92, 0.2);
    border: none;
    background: rgb(255, 255, 255);
}
.text-1-air-Reach_D{
    font-family: $font-6;
    margin: 4px;
    font-size: 14px;
} 
.text-2-air-Reach_D{
    font-family: $font-4;
    margin: 6% 0px 4% 0px;
} 
.text-3-air-Reach_D{
    margin: 4% 0px 4% 0px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
} 
.text-3-1-air-Reach_D{
    font-family: $font-content-1;
    color: rgb(128, 124, 124);
} 
.text-4-air-Reach_D{
    font-family: $font-heading-1;
    color: #7b7979;
    margin: 4% 0px 4% 0px;
    // background: rgb(233, 228, 228);
}
.text-5-air-Reach_D{
    font-family: $font-content-1;
    color: rgb(192, 90, 0);
    margin: 6% 0px 4% 0px;
} 