$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


//!MAP
.container_p-map-DayWise_Mobile{
    width: 100%;
    height: 100%;
    // position: relative;
    // border: 10px solid orchid;
    border-radius: 8px;
    z-index: 10;
}
.container_s-map-DayWise_Mobile{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    // position: fixed;
    box-shadow: 0 0 2px rgb(97, 97, 97); 
    // overflow: hidden;
}

//close button
.button-close-DayWise_Mobile{
    background: none;
    border: none;
    font-size: 32px;
    position: absolute;
    // bottom: 600px;
    z-index: 10000;
    top: 1%;
    right: 1%;
    // top: -360px;
    // right: 40px;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}


.conatiner_p-Places-DayWise_Mobile{
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    // overflow-y: hidden;
    // height: 30%;
    min-height: 190px;
    // border: 10px solid orchid;
    height: fit-content;
    z-index: 40;
    // height: 300px;
    // border: 1px solid greenyellow;
}
.conatiner_s-Places-DayWise_Mobile{
    display: flex;
    flex-direction: row;
    height: fit-content;
    // background: yellowgreen;
}

@media (min-width: 0px) and (max-width: 499px){
    .popup_p-3-Entities_TO_Popup{
        width: 90%;
        height: fit-content;
        position: fixed;
        top: 14%;
        left: 5%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 6px;
        box-shadow: 0 0 4px rgb(253, 195, 124);
        z-index: 10010;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .popup_s-addNote-Entities_TO_Popup{
        width: 98%;
        // height: 98%;
        top: 10%;
        left: 20%;
        // border: 1px solid #000;
        // background: #ffdcdc;
        padding: 6px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border: none;
        z-index: 10010;
        overflow: auto;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // text-align: center;
    }
    .button-optimizedRoute-DayWise{
        font-size: 18px;
        border: none;
        background: none;
        // position: fixed;
        // right: 21%;
        // top: 8%;
        text-align: start;
        font-family: $font-6;
        margin-left: 12%;
        margin-top: 5%;
        border-radius: 6px;
        border: 1px solid rgb(223, 255, 200);
        padding: 1px 8px 2px 8px;

        &:hover{
            color: red;
            transition: color 0.3s ease;
        }
        &:active{
            color: orange;
            transition: color 0.3s ease;
        }
    }
    .button-createItinerary-DayWise{
        padding: 4px 16px 4px 16px;
        background: white;
        border: whitesmoke dotted 3px;
        text-align: start;
        border-radius: 2px;
        color: rgb(8, 71, 160);
        font-size: 17px;
        margin-top: 20px;
        margin-left: 12%;
        font-family: $font-6;
        display: none;
    
        &:hover{
            border-radius: 10px;
            border: 2px rgb(240, 122, 19) dotted;
            transition: border 0.3s ease;
        }
        &:active{
            border: rgb(255, 0, 0) dotted 3px;
            border-radius: 2px;
            transition: border border-radius 0.3s ease;
            transition: border-radius 0.3s ease;
    
        }
    }
}
@media (min-width: 500px) and (max-width: 1000px){
    .popup_p-3-Entities_TO_Popup{
        width: 80%;
        height: fit-content;
        position: fixed;
        top: 14%;
        left: 5%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 6px;
        box-shadow: 0 0 4px rgb(253, 195, 124);
        z-index: 10010;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .popup_s-addNote-Entities_TO_Popup{
        width: 98%;
        // height: 98%;
        top: 10%;
        left: 20%;
        // border: 1px solid #000;
        // background: #ffdcdc;
        padding: 6px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border: none;
        z-index: 10010;
        overflow: auto;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // text-align: center;
    }
    .button-optimizedRoute-DayWise{
        font-size: 20px;
        border: none;
        background: none;
        // position: fixed;
        // right: 21%;
        // top: 8%;
        font-family: $font-6;
        margin-left: 6%;
        margin-top: 4%;
        border-radius: 14px;
        border: 1px solid rgb(223, 255, 200);
        padding: 1px 8px 2px 8px;

        &:hover{
            color: red;
            transition: color 0.3s ease;
        }
        &:active{
            color: orange;
            transition: color 0.3s ease;
        }
    }
    .button-createItinerary-DayWise{
        padding: 4px 16px 4px 16px;
        background: white;
        border: whitesmoke dotted 3px;
        border-radius: 2px;
        color: rgb(8, 71, 160);
        font-size: 17px;
        margin-top: 20px;
        margin-left: 10px;
        font-family: $font-6;

        &:hover{
            border-radius: 10px;
            border: 2px rgb(240, 122, 19) dotted;
            transition: border 0.3s ease;
        }
        &:active{
            border: rgb(255, 0, 0) dotted 3px;
            border-radius: 2px;
            transition: border border-radius 0.3s ease;
            transition: border-radius 0.3s ease;

        }
    }
}
@media (min-width: 1000px){
    .popup_p-3-Entities_TO_Popup{
        width: 40%;
        height: fit-content;
        position: fixed;
        top: 14%;
        left: 30%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        box-shadow: 0 0 4px rgb(253, 195, 124);
        z-index: 10010;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .popup_s-addNote-Entities_TO_Popup{
        width: 98%;
        height: 98%;
        top: 10%;
        left: 20%;
        // border: 1px solid #000;
        // background: #ffdcdc;
        padding: 20px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border: none;
        z-index: 10010;
        overflow: auto;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // text-align: center;
    }
    .button-optimizedRoute-DayWise{
        font-size: 22px;
        border: none;
        background: none;
        // position: fixed;
        // right: 21%;
        // top: 8%;
        font-family: $font-6;
        margin-left: 6%;
        margin-top: 4%;
        border-radius: 14px;
        border: 1px solid rgb(223, 255, 200);
        padding: 1px 8px 2px 8px;

        &:hover{
            color: red;
            transition: color 0.3s ease;
        }
        &:active{
            color: orange;
            transition: color 0.3s ease;
        }
    }
    .button-createItinerary-DayWise{
        padding: 4px 16px 4px 16px;
        background: white;
        border: whitesmoke dotted 3px;
        border-radius: 2px;
        color: rgb(8, 71, 160);
        font-size: 17px;
        margin-top: 20px;
        margin-left: 10px;
        font-family: $font-6;

        &:hover{
            border-radius: 10px;
            border: 2px rgb(240, 122, 19) dotted;
            transition: border 0.3s ease;
        }
        &:active{
            border: rgb(255, 0, 0) dotted 3px;
            border-radius: 2px;
            transition: border border-radius 0.3s ease;
            transition: border-radius 0.3s ease;

        }
    }
}

// .button-optimizedRoute-DayWise{
//     font-size: 22px;
//     border: none;
//     background: none;
//     // position: fixed;
//     // right: 21%;
//     // top: 8%;
//     font-family: $font-6;
//     margin-left: 6%;
//     margin-top: 4%;
//     border-radius: 14px;
//     border: 1px solid rgb(223, 255, 200);
//     padding: 1px 8px 2px 8px;

//     &:hover{
//         color: red;
//         transition: color 0.3s ease;
//     }
//     &:active{
//         color: orange;
//         transition: color 0.3s ease;
//     }
// }


// .button-createItinerary-DayWise{
//     padding: 4px 16px 4px 16px;
//     background: white;
//     border: whitesmoke dotted 3px;
//     border-radius: 2px;
//     color: rgb(8, 71, 160);
//     font-size: 17px;
//     margin-top: 20px;
//     margin-left: 10px;
//     font-family: $font-6;

//     &:hover{
//         border-radius: 10px;
//         border: 2px rgb(240, 122, 19) dotted;
//         transition: border 0.3s ease;
//     }
//     &:active{
//         border: rgb(255, 0, 0) dotted 3px;
//         border-radius: 2px;
//         transition: border border-radius 0.3s ease;
//         transition: border-radius 0.3s ease;

//     }
// }
//create itinerary
.text-1-createI-DayWise{
    font-family: $font-4;
    color: rgba(10, 83, 162, 0.867);
}
.input-createI-DayWise{
    padding: 8px 8px 8px 8px; /* Padding inside the input */
    border: 1px solid #ffc7c7; /* Border color */
    border-radius: 8px; /* Rounded corners */
    font-size: 14px; /* Font size */
    color: #333; /* Text color */
    background-color: #ffffff; /* Background color */
    outline: none; /* Remove default outline on focus */
    transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for focus state */
    width: 100%;
    // height: 120px;
    margin-top: 4%;
    font-family: $font-4;
    letter-spacing: 0.3px;
    color: gray;
}
.input-createI-DayWise:focus{
    border-color: none; /* Border color on focus */
    box-shadow: 0 0 5px wheat; /* Box shadow on focus */
}
.input-createI-DayWise::placeholder{
    color: lightgray;
}
.input-length-DayWise{
    color: rgb(137, 135, 135);
    font-family: $font-3;
    font-size: 16px;
    margin-top: 4px;
}
.button-done-DayWise{
    font-size: 18px;
    border: none;
    background: none;
    // position: fixed;
    // right: 21%;
    font-family: $font-6;
    margin-top: 8%;
    background: lightskyblue;
    color: white;
    padding: 4px;
    border-radius: 4px;

    &:hover{
        color: rgb(0, 96, 207);
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}
.button-close-DayWise{
    font-size: 18px;
    border: none;
    background: none;
    // position: fixed;
    // right: 21%;
    font-family: $font-6;
    margin-top: 8%;
    margin-left: 24px;
    background: rgb(250, 194, 135);
    color: white;
    padding: 4px;
    border-radius: 4px;

    &:hover{
        color: rgb(169, 1, 1);
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}
///////////////////////////////////////////////////////////////////
.heading-1-Day-DayWise_Mobile{
    font-size: 18px;
    border: none;
    height: fit-content;
    // background: none;
    // position: fixed;
    // right: 21%;
    // top: 8%;
    white-space: nowrap;
    color: #7eb30c;
    font-family: $font-6;
    font-weight: bold;
    // margin-left: 4%;
    // margin-top: 4%;
    border-radius: 4px;
    // width: fit-content;
    width: 90%;
    background: rgb(255, 239, 239);
    padding: 1px 8px 2px 8px;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}


.container-Gap_Dist_Dur-DayWise_Mobile{
    // background: #c47003;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}

//*#1
.icon-dottedLine-Places-DayWise_Mobile{
    color: brown;
    width: 32px;
    // margin-top: 20px;
    display: flex;
    flex-direction: column;
    // margin-left: 7%;
    margin-top: 1%;
}

//distance and duration
.container_p-Dist_Dur-DayWise_Mobile{
    // margin-left: 3%;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    // text-align: center;
    // flex-wrap: wrap;
}
.container_t-1-Dist_Dur-DayWise_Mobile{
    color: rgb(147, 143, 143);
    white-space: nowrap;
}
.container_t-2-Dist_Dur-DayWise_Mobile{
    color: rgb(139, 134, 134);
    margin-top: 1%;
    white-space: nowrap;
}




//*#2
.text-1-number-Places-DayWise_Mobile{
    border-radius: 100px;
    // color: white;
    // border: 1px rgb(252, 115, 10) solid;
    font-weight: bold;
    color: rgb(169, 66, 65);
    font-family: $font-6;
    // margin-top: 10px;
    width: fit-content;
    padding: 4px 6px 4px 6px;
    // margin-left: 6.6%;
}

//*#3
//?#P
.conatiner_p-Info-Places-DayWise_Mobile{
    width: 100%;
    height: 100%;
    // border: 1px rgb(138, 125, 115) solid;
    // box-shadow: rgb(180, 181, 179) 0px 0px 5px;
    display: flex;
    flex-direction: column;
    // margin-bottom: 12px;
    // margin-top: 8px;
    // align-items: center;
    // text-align: center;
}
//?#S
.conatiner_s-1-Info-Places-DayWise_Mobile{
    display: flex;
    flex-direction: row;
    // background: rgb(175, 132, 24);
    // width: 500px;
    width: max-content;
    // box-shadow: rgb(180, 181, 179) 0px 0px 5px;
    // padding: 4px;
    border-radius: 6px;
    // justify-content: space-around;
}
.conatiner_s-2-Info-Places-DayWaise{
    // display: flex;
    // flex-direction: row;
}
//?#T
//t1
.conatiner_t-1-Info-Places-DayWaise{
    width: 70%;
    // border: 10px silver solid;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
}
.image-Card-Places-DayWise{
    overflow: hidden;
    width: 100%;
    height: 140px;
    object-fit: cover;
    // margin-top: 0.5%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-radius: 12px;
    box-shadow: rgba(250, 233, 205, 0.932) 0px 2px 2px 0px;
}
.conatiner_t-ratings_review-SavedPlacesCI{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 12px;
    background: rgb(242, 245, 245);
    width: 100%;
    align-items: center;
    text-align: center;
}
.heading-rating-DayWise{
    font-family: $font-6;
    margin-left: 8px;
    color: rgb(88, 87, 87);
}
.heading-2-placeName-SavedPlacesCI{
    font-family: $font-6;
    color: rgb(88, 87, 87);
    margin-left: 4px;
}
.heading-3-placeName-SavedPlacesCI{
    font-family: $font-6;
    margin-left: 8px;
    color: brown;
}
//t2
.conatiner_t-2-Info-Places-DayWaise{
    width: 50%;
    // border: 1px rgb(113, 194, 37) solid;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 3%;
}
.heading-1-placeName-info-Places-DayWaise_Mobile{
    font-family: $font-6;
    margin: 8px 2px 2px 12px;
    font-size: 15px;
    display: flex;
    align-self: flex-start;
    text-align: start;
}
.text-1-category-info-Places-DayWaise_Mobile{
    font-family: $font-4;
    // margin: 8px 2px 2px 12px;
    font-size: 12px;
    color: rgb(126, 46, 46);
    margin-top: 12px;
    margin-left: 12px;
    display: flex;
    align-self: flex-start;
}
.text-1-exploreDuration-info-Places-DayWaise_Mobile{
    font-family: $font-4;
    // margin: 0px 0px 0px 30px;
    font-size: 12px;
    text-align: start;
    margin-top: 8px;
    margin-left: 12px;
    color: rgb(126, 46, 46);
    // display: flex;
    // align-self: flex-end;
}
.button-viewTimming-DayWaise_Mobile{
    border: none;
    background: rgb(248, 248, 246);
    border-radius: 6px;
    padding: 2px 6px 2px 6px;
    width: fit-content;
    font-family: $font-4;
    margin-top: 10px;
    margin-left: 10px;
    color: black;

    &:hover{
        letter-spacing: 0.4px;
        color: gray;
    }
}
//timmings
.container_p-timming-DayWaise{
    position: absolute;
    left: 60%;
    top: -50%;
    z-index: 100;
    background: whitesmoke;
    padding: 4px 10px 10px 10px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
}
.heading-1-DayWaise{
    font-family: $font-4;
    font-weight: bold;
    color: rgb(255, 140, 0);
}
.conatiner_t-timming-DayWaise{
    // margin: 10px;
    display: flex;
    flex-direction: column;
    // overflow-y: scroll;
    // overflow-x: hidden;
    background: rgb(255, 255, 255);
    // height: 70px;
    border-radius: 8px;  
    margin-top: 2px; 
}
.time-timming-DayWaise{
    font-family: $font-6;
    color: rgb(165, 161, 161);
    margin: 6px 2px 14px 6px;
    font-size: 15px;
    white-space: nowrap;
}


//view more button
.button-viewMore-card-DayWaise_Mobile {
    border-radius: 8px;
    font-family: $font-content-2;
    letter-spacing: 0.4px;
    word-spacing: 0.4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.188);
    position: absolute; /* Position this element absolutely */
    bottom: 8px; /* Align it to the bottom of the parent container */
    left: 0; /* Align it to the left if needed, or adjust as necessary */
    right: 0; /* Align it to the right if needed, or adjust as necessary */
    justify-self: flex-end;
    margin-top: -20px;
    font-size: 12px;
    width: 80%;
    color: #c47003;
    background: none;
    border: none;
    width: 100%;
    padding: 4px 0px 4px 0px;
    // margin: 4px 0px 10px 0px;
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
    text-shadow: 1px 1px 6px rgb(255, 220, 167); 
    letter-spacing: 1.2px;

    &:hover {
        color: #db7f06;
        text-shadow: 1px 1px 10px rgb(243, 191, 114); 
        font-family: $font-content-2;
        font-weight: 16px;
    }

    &:active {
        background-color: lighten(#e6d6bd, 10%);
    } 
}
