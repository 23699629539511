$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//
.container_p-main-Overview_D{
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // text-align: center;

    width: 88%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // border: solid gray 1px;
    overflow: hidden;
}

//
.container_t-OverViewPlace-Overview_D{
    margin: 4px 0px 4px 0px;
    width: 70%;
    align-items: start;
    text-align: start;
    // background-color: lightblue;
}
.heading-text-1-OverViewPlace-Overview_D{
    font-family: $font-heading-1;
    margin: 2% 0px 2% 0px;
    font-size: 20px;
}
.content-text-1-OverViewPlace-Overview_D{
    font-family: $font-content-1;
    margin: 2% 0px 2% 0px;
    font-size: 20px;
}

//!Mobile
@media (min-width: 0px) and (max-width: 799px){
    .container_p-bestTime-Overview_D{
        width: 96%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 5% 0% 0% 0%;
    }
    .container_s-content-bestTime-Overview_D{
        width: 100%;
        margin-top: 4%;
        display: flex;
        border-radius: 8px;
        padding: 1.5% 2% 1.5% 2%;
        box-shadow: 0 4px 8px rgba(93, 92, 92, 0.2);
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .heading-bestTime-Overview_D{
        font-size: 18px;
        font-weight: bold;
        font-family: $font-heading-1;  
        // margin: 4px 0px 0px -90px; 
        // background: yellow;
        width: 1000px;
        color: rgb(114, 64, 4);
        // white-space: nowrap;
    }
}
@media (min-width: 800px){
    .container_p-bestTime-Overview_D{
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 5% 0% 0% 0%;
    }
    .container_s-content-bestTime-Overview_D{
        width: 70%;
        display: flex;
        border-radius: 8px;
        padding: 1.5% 2% 1.5% 2%;
        box-shadow: 0 4px 8px rgba(93, 92, 92, 0.2);
    }
    .heading-bestTime-Overview_D{
        font-size: 19px;
        font-weight: bold;
        font-family: $font-heading-1;  
        margin: 4px 0px 0px 0px;
    }
}

//!Best Time
// .container_p-bestTime-Overview_D{
//     width: 80%;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     margin: 5% 0% 0% 0%;
// }
.container_s-imageText-bestTime-Overview_D{
    width: 26%;
    display: flex;
    flex-direction: column;
    // border: 10px solid red;
    flex-direction: column;
    align-items: center;
    text-align: center;
    // justify-content: center;
}
// .container_s-content-bestTime-Overview_D{
//     width: 70%;
//     display: flex;
//     border-radius: 8px;
//     padding: 1.5% 2% 1.5% 2%;
//     box-shadow: 0 4px 8px rgba(93, 92, 92, 0.2);
// }
// .heading-bestTime-Overview_D{
//     font-size: 19px;
//     font-weight: bold;
//     font-family: $font-heading-1;  
//     margin: 4px 0px 0px 0px; 
//     width: 100%;
//     white-space: nowrap;
// }
.image-bestTime-Overview_D{
    width: 160px;
    height: 150px;
    border-radius: 200px;
    border: 4px rgb(239, 239, 239) solid;
}
.content-1-bestTime-Overview_D{
    margin-top: 2%;
    font-family: $font-content-2;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    // text-align: start;
}
.icon-season-bestTime-AboutDestination{
    // font-size: 28px;
    width: 30px;
    // margin: 0px 6px 0px 6px;
    text-align: start;
}
.content-2-bestTime-aboutDestination{
    margin: 6%;
    font-size: 16px;
    font-family: $font-6;
    color: rgb(62, 61, 60);
}
.content-3-bestTime-aboutDestination{
    margin: 6% 4% 4% 4%;
    font-size: 16px;
}
//list
.content-3-bestTime-aboutDestination ul {
    list-style-type: disc; /* or any other style you prefer */
    margin-left: 20px; /* adjust as needed */
}

.content-3-bestTime-aboutDestination li {
    margin-bottom: 10px; /* space between list items */
    line-height: 1.5; /* adjust line height for readability */
    font-family: $font-content-1;
    font-weight: 560;
    font-size: 18px;
    // $font-content-1: 'Nunito Sans';
    // $font-content-2: 'Rubik';
}
  

//!Duration
.container_p-duration-aboutDestination{
    width: 55%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;  
    margin: 8% 0% 0% 0%;
    border: 4px rgb(239, 239, 239) solid;
}
.image-duration-aboutDestination{
    width: 150px;
    height: 140px;
    border-radius: 200px;
    border: 4px rgb(239, 239, 239) solid;
}
.content-1-duration-aboutDestination{
    margin: 6%;
    font-size: 17px;
    color: rgb(94, 91, 91);
}
.heading-duration-aboutDestination{
    font-size: 20px;
    font-family: $font-heading-1;  
    margin: 4px 0px 0px 0px; 
}

