$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

//from different component
.popup-background-1-AiItinerary_Chat {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100000000000000000000;
}
.popup_p-2-addNote-AiItinerary_Chat{
    width: 70%;
    height: 98%;
    position: fixed;
    top: 1%;
    left: 14%;
    border: none;
    border-radius: 8px;
    background: #ffffff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000000000000000000000;
    // overflow: hidden;
    // display: flex;
    // align-items: center;
    // text-align: center;
    // justify-content: center;
}

.container_p-AiItinerary_Chat{
    display: flex;
    flex-direction: column;
    height: 100%;
    // background: red;
    justify-content: space-between;
}

.conatiner_s-1-answer-AiItinerary_Chat{
    height: 88%;
    width: 100%;
    // border: 1px solid brown;
    padding: 8px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: rgb(236, 236, 236);
}
.conatiner_s-1-answer-AiItinerary_Chat::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.conatiner_s-1-answer-AiItinerary_Chat::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.conatiner_s-1-answer-AiItinerary_Chat::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #878684;
    border-radius: 60px;  
}  



.conatiner_s-2-answer-AiItinerary_Chat{
    height: 10%;
    width: 100%;
    // border: 1px solid brown;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    // background-color: whitesmoke;
}

.input-askAi-AiItinerary_Chat{
    padding: 8px 4px 4px 12px; 
    border: 1px solid #d4d4d4; 
    border-radius: 16px; 
    height: 40px;
    font-size: 14px; 
    background-color: #ffffff; 
    outline: none; 
    transition: border-color 0.3s, box-shadow 0.3s; 
    box-shadow: rgba(136, 136, 136, 0.58) 0px 0px 2px;
    width: 100%;
    font-family: $font-content-2;
    // font-size: 16px;
    letter-spacing: 0.1px;
    color: gray;
    word-break: break-all;
    overflow-wrap: break-word;
    white-space: pre-wrap; 
    resize: vertical;
    resize: none;
}
.input-askAi-AiItinerary_Chat::-webkit-scrollbar{
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.input-askAi-AiItinerary_Chat::-webkit-scrollbar-track-piece{
    background-color: #ffffff;
}
.input-askAi-AiItinerary_Chat::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #9b9b9b;
    border-radius: 60px;  
}  

.input-askAi-AiItinerary_Chat:focus{
    border-color: none; 
    // box-shadow: 0 0 5px wheat; 
    box-shadow: rgba(136, 136, 136, 0.58) 0px 0px 4px;
    border: none;
}
.input-askAi-AiItinerary_Chat::placeholder{
    color: rgb(84, 82, 82);
}


.container_p-iButton-AiItinerary_Chat{
    background: none;
    border: none;
    position: relative;
}
.img-iButton-AiItinerary_Chat{
    width: 24px;
    height: 24px;
}
.text-iButton-popup-AiItinerary_Chat{
    position: absolute;
    width: 300px;
    padding: 4px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    top: -60px;
    left: 00px;
    text-align: left;
    font-family: $font-4;
    color: rgb(101, 101, 101);
    box-shadow: rgba(199, 199, 199, 0.58) 0px 0px 4px;
}


.button-send-AiItinerary_Chat{
    // font-size: 32px;
    background: none;
    border: none;
    // color: brown;
    padding: 4px;
    border-radius: 8px;

    &:hover{
        box-shadow: rgba(199, 199, 199, 0.58) 0px 0px 4px;
        transition: background-color 0.3s ease;
    }
    &:active{
        box-shadow: rgba(255, 153, 153, 0.58) 0px 0px 6px;
        transition: background-color 0.3s ease;
    }
}
.img-sendButton-AiItinerary_Chat{
    width: 24px;
    height: 24px;
}

.conatiner_t-answer-AiItinerary_Chat{
    width: 100%;
    background-color: ebebeb;
}

.container-t-question-AiItinerary_Chat{
    text-align: left;
    align-self: left;
    float: right;
    justify-content: right;
    margin-top: 1%;
    background-color: white;
    max-width: 70%;
    min-width: 40%;
    font-family: $font-content-1;
    letter-spacing: 0.2px;
    color: gray;
    padding: 8px 4px 4px 4px;
    border-radius: 6px;
}
.container-t-answer-AiItinerary_Chat{
    text-align: left;
    align-self: left;
    float: left;
    margin-top: 2%;
    background-color: white;
    max-width: 90%;
    min-width: 40%;
    padding: 8px 4px 4px 12px;
    border-radius: 6px;
}

.text-1-answer-AiItinerary_Chat{
    font-family: $font-content-1;
    letter-spacing: 0.2px;
    color: rgb(0, 0, 0);
}
.text-1-question-AiItinerary_Chat{
    font-family: $font-content-1;
    letter-spacing: 0.2px;
    color: gray;
    margin-left: 12px;
}


//limit exceede
.text-askQlimit-AiItinerary_Chat{
    font-family: $font-5;
    color: gray;
} 
.text-limitExceeded-AiItinerary_Chat{
    font-family: $font-6;
    border: red 1px solid;
    color: rgb(146, 146, 146);
    font-size: 14px;
    width: 90%;
    padding: 8px;
    // margin-top: 2px;
    border-radius: 4px;
    // margin-top: -2px;
}