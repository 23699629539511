$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//




// .Map-TripPlanning-Map{
//     position: fixed;
//     width: 39%;
//     height: 91%;
//     margin-left: 1%;
//     border: 1px solid #dadada;
//     border-radius: 10px; /* Rounded corners */
//     /* border-radius: 6px; */
//     /* margin-top: 6%; */
//     /* border: 1px burlywood solid; */
//     /* box-shadow: 0 0 10px rgba(138, 138, 138, 0.1); Shadow effect */
//     /* background-color: #f0f0f0; Background color */
// }
.Map-TripPlanning-Map:hover{
    border-color: 1.4px solid #dadada; /* Border color on hover */
}

/* Zoom level proxy 50% - Viewport width increased */
@media (min-width: 1500px) {
    .Map-TripPlanning-Map{
        position: fixed;
        width: 40%;
        height: 91%;
        margin-left: 0.4%;
        border: 1px solid #dadada;
        border-radius: 10px; /* Rounded corners */
        /* border-radius: 6px; */
        /* margin-top: 6%; */
        /* border: 1px burlywood solid; */
        /* box-shadow: 0 0 10px rgba(138, 138, 138, 0.1); Shadow effect */
        /* background-color: #f0f0f0; Background color */
    }
}

/* Zoom level proxy 75% */
@media (min-width: 1200px) and (max-width: 1499px) {
    .Map-TripPlanning-Map{
        position: fixed;
        width: 39%;
        height: 90.4%;
        margin-left: 1%;
        // border: 10px solid #dadada;
        border-radius: 10px; /* Rounded corners */
        /* border-radius: 6px; */
        /* margin-top: 6%; */
        /* border: 1px burlywood solid; */
        /* box-shadow: 0 0 10px rgba(138, 138, 138, 0.1); Shadow effect */
        /* background-color: #f0f0f0; Background color */
    }
}

/* Zoom level proxy 100% */
@media (min-width: 900px) and (max-width: 1199px) {
    .Map-TripPlanning-Map{
        position: fixed;
        width: 40.6%;
        height: 89%;
        // margin-left: 1%;
        border: 1px solid #dadada;
        border-radius: 10px; /* Rounded corners */
        /* border-radius: 6px; */
        /* margin-top: 6%; */
        /* border: 1px burlywood solid; */
        /* box-shadow: 0 0 10px rgba(138, 138, 138, 0.1); Shadow effect */
        /* background-color: #f0f0f0; Background color */
    }
}

/* Zoom level proxy 125% */
// @media (min-width: 700px) and (max-width: 899px) {
//     .Map-TripPlanning-Map{
//         position: fixed;
//         width: 39%;
//         height: 84%;
//         margin-left: 1%;
//         margin-top: 0.4%;
//         border: 1px solid #dadada;
//         border-radius: 10px; /* Rounded corners */
//         /* border-radius: 6px; */
//         /* margin-top: 6%; */
//         /* border: 1px burlywood solid; */
//         /* box-shadow: 0 0 10px rgba(138, 138, 138, 0.1); Shadow effect */
//         /* background-color: #f0f0f0; Background color */
//     }
// }

@media (min-width: 0px) and (max-width: 899px) {
  .Map-TripPlanning-Map{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    // margin-top: 4%;
    border: 1px solid #dadada;
    border-radius: 10px;
    // margin-left: -20px;
  }
  .Map-2-TripPlanning-Map{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    // margin-top: 4%;
    border: 1px solid #dadada;
    border-radius: 10px;
    margin-left: -60px;
  }
}

//!
@media (min-width: 0px) and (max-width: 849px){
  .conatiner_p-data-map{
    position: fixed;
    margin-left: 2%;
    margin-top: 1%;
    top: 0.2%;
    z-index: 1000000;
    background: white;
    // opacity: 0.4px;
    padding: 2px 6px 2px 6px;
    border-radius: 6px;
    width: 98%;
    align-items: start;
    text-align: start;
  }
}
@media (min-width: 850px){
  .conatiner_p-data-map{
    z-index: 100;
    position: fixed;
    margin-left: 4%;
    margin-top: 0.2%;
    background: white;
    opacity: 0.4px;
    padding: 2px 6px 2px 6px;
    border-radius: 6px;
    width: 30%;
  }
}





.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Adjust this as per your container */
  }
  
  .spinner {
    width: 30px;
    height: 30px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top-color: #3498db; /* Color for the rotating part */
    border-radius: 50%;
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }



  //distance between locatoins
  .route-info-container {
    display: flex;
    flex-wrap: wrap; /* Enable wrapping */
    gap: 6px; /* Spacing between elements */
    margin: 10px;
    // width: 10%;
  }
  
  .route-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 8px;
    // min-width: 120px; /* To ensure elements have consistent width */
  }
  
  .route-label {
    // font-weight: bold;
    font-family: $font-6;
    color: #333;
    margin-bottom: 5px;
  }
  
  .route-value {
    font-family: $font-6;
    color: #007bff;
  }
  
  .distance-info {
    width: 100%;
    // font-weight: bold;
    // text-align: center;
    font-family: $font-4;
    // margin-bottom: 10px;
  }
  
  .clear-btn {
    padding: 8px 16px;
    background-color: #ff4d4f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .clear-btn:hover {
    background-color: #e53e3e;
  }
  
  


//
  .popup-content-popup-map {
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    // min-width: 200px; /* Adjust as needed */
  }
  
  .name-popup-map {
    color: gray;
    font-family: $font-4;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .view-more-btn-popup-map, .start-btn-popup-map, .find-distance-btn-popup-map {
    padding: 5px 10px;
    font-size: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 4px 4px 4px 0px;
    width: fit-content;
  }
  
  .view-more-btn-popup-map {
    background-color: #dedede;
    color: rgb(0, 0, 0);
    margin-top: -2px;
    border-radius: 6px;
  }
  
  .view-more-btn-popup-map:hover {
    background-color: #b4b4b4;
  }
  
  .start-btn-popup-map {
    background-color: #28a745;
    color: white;
  }
  
  .start-btn-popup-map:hover {
    background-color: #218838;
  }
  
  .find-distance-btn-popup-map {
    background-color: #dc3545;
    color: white;
  }
  
  .find-distance-btn-popup-map:hover {
    background-color: #c82333;
  }
  
  .distance-section-popup-map {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
  
  .find-distance-text-popup-map {
    font-weight: bold;
    margin-top: 4px;
    font-family: $font-6;
  }
  