$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

//!main div of the componenet
.container_p-Entities_TD{
    margin: 2% 0% 1% 0%;
    background-color: rgb(255, 255, 255);
    display: flex;
    border-radius: 10px;
    padding: 1% 1% 1% 1%;
    display: flex;
    flex-direction: column;
}
//!
.container_p-Data-DayInfo_Itinerary_TO{

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: whitesmoke;
    border-radius: 10px;
    // padding: 4px 8px 4px 8px;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-left: 10px;
    margin-top: 20px;
    width: fit-content;
}
.container_s-1-Data-DayInfo_Itinerary_TO{
    display: flex;
    flex-direction: column;
    // border: 1px solid goldenrod;
    // width: 60%;
}