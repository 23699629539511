$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


.popup-background-1-Header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.652);
    z-index: 100000000000000000000;
    // opacity: 0.6;
}
.popup_p-2-addNote-Header{
    width: 40%;
    height: 60%;
    position: fixed;
    top: 14%;
    left: 30%;
    border: none;
    border-radius: 8px;
    background: #ffffff;
    padding: 20px;
    box-shadow: 0 0 12px rgba(92, 92, 93, 0.835);
    z-index: 1000000000000000000000;
    overflow: auto;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    text-align: center;
}


//!Popup Manage Login
.line-1-manageLogin-Header{
    width: 60%;
    border-bottom: 1px rgb(225, 225, 225) solid;
    margin-top: 5%;
    margin-bottom: 2%;
}
.text-0-manageLogin-Header {
    font-size: 16px;
    color: #0d6df4;
    font-family: $font-6;
    margin-bottom: 8px;
    line-height: 1.5;
    margin-top: 4%;
    width: 70%;
}
.text-1-manageLogin-Header {
    font-size: 16px;
    color: #975300;
    font-family: $font-6;
    margin-bottom: 8px;
    line-height: 1.5;
    margin-top: 4%;
    width: 70%;
}
.text-2-manageLogin-Header {
    font-size: 16px;
    color: #ff8c00;
    font-family: $font-6;
    margin-bottom: 16px;
    line-height: 1.5;
    letter-spacing: 0.6px;
}
.text-3-manageLogin-Header{
    font-size: 18px;
    color: #494949;
    font-family: $font-6;
}
.text-4-manageLogin-Header{
    font-size: 16px;
    color: #747474;
    font-family: $font-5;
}

.button-joined-manageLogin-Header {
    padding: 4px 20px;
    border: none;
    border-radius: 8px;
    background-color: #d27474;
    color: #fff;
    cursor: default;
}

.button-notJoined-manageLogin-Header {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    background-color: #ff6f61;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-notJoined-manageLogin-Header:hover {
    background-color: #ff8a80;
}
  


//!Main
// .container_p-header{
//     display: flex;
//     width: 100%;
//     // background: rgb(255, 241, 223);
//     padding: 4px 0;
//     border-radius: 14px;
//     margin-top: 4px;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center; 
// }
// .container_p-mobile-header{
//     display: flex;
//     width: 100%;
//     // background: rgb(255, 241, 223);
//     padding: 4px 0;
//     border-radius: 14px;
//     margin-top: 4px;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center; 
// }

@media (max-width: 799px) {
    .container_p-header{
        display: none;
    }
    .container_p-mobile-header{
        display: flex;
        width: 100%;
        // background: rgb(255, 241, 223);
        padding: 4px 0;
        border-radius: 14px;
        margin-top: 4px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; 
    }
}
@media (min-width: 800px) {
    .container_p-header{
        display: flex;
        width: 100%;
        // background: rgb(255, 241, 223);
        padding: 4px 0;
        border-radius: 14px;
        margin-top: 4px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; 
    }
    .container_p-mobile-header{
       display: none;
    }
}


.container_t-1-logo_tag-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    // border: rgb(9, 118, 145) 2px solid;
    width: fit-content;
    // display: none;
}
.container_t-2-site_navigation-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    // background: white;
    // border: red 2px solid;
    width: fit-content;
    // display: none;
}
// @media (min-width: ) {
    
// }

//images
.img-logo-site-1-header{
    border-radius: 8px;
    width: 36px;
    height: 36px;
    margin-left: 14px;
}
.img-icon-1-header{
    width: 18px;
    height: 18px;
    margin-left: 6px;
    // border: 1px solid rgb(0, 0, 0);
}
.icon-account-1-header{
    font-size: 20px;
    // margin-left: 6px;
    // border: 1px solid rgb(0, 0, 0);
    // height: fit-content;
    // width: fit-content;
    // margin-top: -4px;
    width: 20px;
    height: 20px;
}

//*container_t_1
//container_t_1_1
// @media (max-resolution: 189dpi) {
//   //container_t_1_2
//     .container_t-1_2-tag-header{
//         display: flex;
//         flex-direction: column; 
//         text-align: start;
//         margin-left: 12px;
//     }
// }
// @media (min-resolution: 190dpi) {
//    //container_t_1_2
//     .container_t-1_2-tag-header{
//         display: flex;
//         flex-direction: column; 
//         text-align: start;
//         margin-left: 12px;
//         display: none;
//     }
// }

.container_t-1_2-tag-header{
    display: flex;
    flex-direction: column; 
    text-align: start;
    margin-left: 12px;
}
  

.container_t-1_1-logo-header{
}
.heading-prepareTrip-1-tag-header{
    color: orangered;
    font-family: $font-heading-3;
    font-weight: normal;
    font-size: 17px;
}
.heading-prepareTrip-2-tag-header{
    font-weight: normal;
    color: rgb(83, 165, 5);
    font-size: 15px;
    margin-top: 6px;
    font-family: $font-6;
}
.heading-prepareTrip-3-tag-header{
    font-weight: normal;
    color: rgb(41, 124, 240);
    font-size: 15px;
    font-family: $font-6;
    margin-top: -6px;
}



//*container_t_2
@media (max-resolution: 139dpi) {
    .container_t-discover-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        // border: rgb(44, 34, 137) 2px solid;
    }
}
@media (min-resolution: 140dpi) {
    .container_t-discover-header {
      display: none;
    }
}
.heading-discover-header{
    color: rgb(82, 139, 3);
    font-family: $font-6;
    // font-family: $font-content-1;
    font-weight: normal;
    letter-spacing: 0.4px;
    text-decoration: underline;
    margin-right: 12px;
    font-size: 14px;
    margin-top: 3px;
}
.button-listHotel-header{
    font-family: $font-6;
    color: rgb(115, 92, 27);
    border: none;
    background: none;
    margin-right: 7px;
    font-size: 16px;
    padding: 4px 6px 4px 6px;
    border-radius: 8px;

    &:hover{
        background-color: rgba(237, 231, 223, 0.747);
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(229, 203, 171);
        transition: background-color 0.3s ease;
    }
}
.button-1-stays-header{
    font-family: $font-6;
    color: rgb(82, 139, 3);
    border: none;
    background: none;
    margin-right: 7px;
    font-size: 16px;
    padding: 4px 6px 4px 6px;
    border-radius: 8px;

    &:hover{
        background-color: rgba(113, 140, 40, 0.198);
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(255, 165, 165);
        transition: background-color 0.3s ease;
    }
}

@media (max-resolution: 140dpi) {
    .container_t-discover-header-2{
      display: none;
    }
}

@media (min-resolution: 141dpi) {
    .container_t-discover-header-2{
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        // border: rgb(44, 34, 137) 2px solid;
        position: relative;
    }
    .container_t-discoverContent-header-1{
        display: flex;
        flex-direction: column;
        position: absolute;
        border: 2px solid yellowgreen;
        margin-top: 130px;
        background: white;
        text-align: start;
        border-radius: 8px;
        margin-left: -4px;
    }
    .button-1-stays-header{
        font-family: $font-6;
        color: rgb(82, 139, 3);
        border: none;
        background: none;
        margin-right: 7px;
        font-size: 16px;
        padding: 4px 6px 2px 6px;
        border-radius: 8px;
        white-space: nowrap;
        z-index: 1;
    
        &:hover{
            background-color: rgba(113, 140, 40, 0.198);
            transition: background-color 0.3s ease;
        }
        &:active{
            background-color: rgb(255, 165, 165);
            transition: background-color 0.3s ease;
        }
    }
}

// .heading-discover-hover-header-2{

// }


.heading-saved-1-header{
    color: rgb(82, 139, 3);
    font-family: $font-6;
    font-size: 16px;
    font-weight: bold;
    background: none;
    border: none;
    margin-left: 8px;
    margin-right: 16px;
    white-space: nowrap;
    
    transition: color 0.3s ease;

    &:hover{
        // background-color: orange;
        color: rgb(104, 203, 65);
    }
    &:active{
        // color: white;
        // background-color: rgb(255, 212, 188);
        // transition: color background-color 0.3s ease;
        color: rgb(255, 149, 0);
    }
}
.container_t-startPlanning-header{
    display: flex;
    flex-direction: row;
    background: none;
    border: none;
    height: 24px;
    width: fit-content;
    // align-items: center;
    // text-align: center;
    padding: 4px 6px 4px 6px;
    border-radius: 16px;
    background: rgb(255, 211, 150);
    margin-right: 8px;

    &:hover{
        background-color: orange;
        transition: background-color 0.3s ease;
    }
    &:active{
        color: white;
        background-color: rgb(255, 212, 188);
        transition: color background-color 0.3s ease;
    }
}

.container_t-2-startPlanning-header{
    display: flex;
    flex-direction: row;
    background: none;
    border: none;
    height: 24px;
    align-items: center;
    text-align: center;
    width: fit-content;
    padding: 4px 6px 4px 6px;
    border-radius: 16px;
    background: rgb(255, 211, 150);
    margin-right: 8px;

    &:hover{
        background-color: orange;
        transition: background-color 0.3s ease;
    }
    &:active{
        color: white;
        background-color: rgb(255, 212, 188);
        transition: color background-color 0.3s ease;
    }
}

.heading-startPlanning-header-header{
    font-weight: normal;
    color: rgb(82, 139, 3);
    letter-spacing: 0.6px;
    font-family: $font-6;
    white-space: nowrap;
    font-size: 16px;
}



