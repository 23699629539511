/* box6 */
.box6-Home3{
    width: 100%;
    /* height: 100px; */
    /* border: 2px solid lightcoral; */
    /* background: #FFF3DF; */
    padding: 2% 0% 2% 0%;
    /* border-bottom: 1px lightcoral solid; */
    display: flex;
    flex-direction: row;
    margin-top: 6%;
    align-items: center;
    justify-content: center;
}
.feedbackMainContainer-box6-Home3{
    width: 50%;
    padding: 2% 1% 2% 1%;
    /* border: 3px solid orangered; */
    border: rgb(255, 122, 39) 1px solid;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    align-items: center;
    /* display: flex; */
    /* justify-content: center; */
}
.feedback1Container-box6-Home3{
    /* border: lightgray 1px solid; */
    /* width: 60%;  */
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
}
.feedback1_1Container-box6-Home3{
    /* border: rgb(255, 122, 39) 1px solid; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
     width: 60%; 
     padding: 4% 4% 4% 4%;
     border-radius: 10px;
}
.mainText1-feedback1-box6-Home3{
    font-weight: normal;
    font-size: 16px;
}

.feedback2Container-box6-Home3{
    /* border: lightgray 1px solid; */
    /* width: 60%;  */
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    margin-top: 3%;
}
.feedback2_1Container-box6-Home3{
    /* border: rgb(255, 122, 39) 1px solid; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
     width: 60%; 
     padding: 4% 4% 4% 4%;
     border-radius: 10px;
}                                                               
.mainText2-feedback1-box6-Home3{
    font-weight: normal;
    font-size: 18px;
}
.feedback2Button-box6-Home3{            
    border: none;
    /* background: none;    */
    margin: 1%;    
    font-size: 14px;     
    background: rgb(245, 253, 255);    
    border-radius: 10px;      
    padding: 1% 2% 1% 2%; 
}
/* .feedback2Button-box6-Home3:hover{       */
    /* background: rgb(255, 255, 255);   */
    /* background: rgb(233, 251, 255);     */
/* } */
.activeFeedback2Button-box6-Home3{
    border: none;
    /* background: none;      */
    background: rgb(181, 236, 255); 
    margin: 1%;
}
.handleSubmit1Button-Feedback1-Home3{
    border: none;
    /* background: none; */
    padding: 1% 2% 1% 2%;
    border-radius: 10px;
    color: rgb(0, 89, 255);
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}
.handleSubmit1Button2-Feedback1-Home3{
    border: none;
    /* background: none; */
    padding: 0.5% 1% 0.5% 1%;
    margin-top: 1%;
    border-radius: 10px;
    /* color: rgb(0, 89, 255); */
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}
.slider_rating-MainContainer-box6-Home3{
    /* border: 1px purple solid; */
    justify-content: space-around;
    display: flex;
    flex-direction: row;
    /* width: 80%; */
    padding: 0% 12% 0% 12%;
}
.afterFeedbackText-Home3{
    font-weight: normal;
}

/* box7 */
.box7-Footer-Home3{
    width: 100%;
    /* height: 100px; */
    border-top: 2px solid lightcoral;
    background: #ffd182;
    padding: 2% 0% 2% 0%;
    /* border-bottom: 1px lightcoral solid; */
    display: flex;
    flex-direction: column;
    margin-top: 6%;
    align-items: center;
    justify-content: center;
}
.container-Footer-Home3{
    display: flex;
    flex-direction: row;
    width: 100%;
    /* border: 2px solid rgb(128, 156, 240); */
    justify-content: center;
}
.container4-Footer-Home3{
    display: flex;
    flex-direction: row;  
    align-items: center;  
    width: 100%;
    text-align: center;
    justify-content: center;
    /* border: 2px solid rgb(128, 156, 240); */
    background: lightgray;
}
.logo-Home-Footer-Home3{
    width: 30px;
    height: 30px;
    border-radius: 10px;
    border: 2px rgb(255, 255, 255) solid;
    box-shadow: 0 0 8px rgba(55, 55, 55, 0.3); 
}
.container1-Footer-Home3{
    width: 22%;
    /* border: 1px solid orange; */
    border-radius: 10px;
    padding: 1%;
    margin: 2%;
    margin-left: 12%;
}
/* .copyrightTextContainer-Footer-Home3{

} */
.iconContainer-Footer-Home3{
    display: flex;
    flex-direction: row;
}
.container2-Footer-Home3{
    width: 20%;
    /* border: 1px solid orange; */
    border-radius: 10px;
    padding: 1%;
    margin: 2% 1% 2% 1%;
}
.buttons1-footer-Home3{
    text-shadow: 2px 2px 2px rgba(255, 169, 169, 0.5);
    border: none;
    background: none;
    margin: 2%;
    font-size: 18px;
}
.buttons1-footer-Home3:hover{
    text-decoration: underline;
}

.content1-conatiner2-Home3{
    text-decoration: none;
    color: black;
}
.content1-conatiner2-Home3:hover{
    color: rgb(155, 155, 155);            
    /* color: blue; */
}

.container3_5_box-Home3{
    width: 20%;
    margin: 2% 1% 2% 1%;
}
.container3-Footer-Home3{
    width: 100%;
    /* border: 1px solid orange; */
    border-radius: 10px;
    padding: 1%;
    margin: 2% 1% 2% 1%;
    text-align: start;
    height: fit-content;
}
.container5-Footer-Home3{
    width: 100%;
    /* border: 1px solid orange; */
    border-radius: 10px;
    padding: 1%;
    margin: 6% 1% 2% 1%;
    text-align: start;
    height: fit-content;
}
.heading1-Footer-Home3{
    font-weight: normal;
    margin-left: 2.6%;
}
.content1-Footer-Home3{
    font-weight: normal;
    margin-left: 2.6%;  
}

.bookmarkDestination-Home3{
    width: 20%;
    border: none; 
    width: fit-content;
    padding: 1.5%;
    margin-left: 4%;
    border-radius: 8px;
    margin-top: 1%;
}

.about-seasonMainContainer-text-Home3{
    width: 80%;
    margin-left: 6%;
    margin-bottom: 1%;
    margin-top: 4%;
}
.about-season-text-Home3{
    /* font-weight: bold; */
    letter-spacing: 0.4px;
    word-spacing: 0.2px;
    font-size: 18px;
    margin-bottom: 2%;
    margin-top: 2%;

    /* font-family: 'Space Grotesk', sans-serif; */
    /* font-family: 'Karla', sans-serif; */
    /* font-family: 'Mitr', sans-serif; */
    /* font-family: 'Rubik', sans-serif; */
    /* font-family: 'Montserrat', sans-serif; */
    /* font-family: 'Finlandica', sans-serif; */
    /* font-family: 'Ysabeau Office', sans-serif; */
    font-family: 'Assistant', sans-serif;
    /* font-family: 'Ysabeau Office', sans-serif; */
}
.text-Destinations-Home3{
    color: gray;
    font-size: 24px;
    margin-left: 2%;
}

.destinations-ExploreIndia-Home3{
    /* color: gray; */
    font-size: 20px;
     font-family: 'Space Grotesk', sans-serif;
     padding: 2%;
     border-radius: 8px;
     background-color: rgb(241, 241, 241);
     margin: 3%;
}
.uniqueThingsContent-box6{
    font-family: 'Space Grotesk', sans-serif;
    letter-spacing: 0.4px;
    word-spacing: 0.1px;
    font-size: 17px;
}

/* Bucket List */
.type-bucketlist-Home3{
    padding: 0.2% 0.6% 0.2% 0.6%;
    border-radius: 8px;
    background-color: rgb(221, 221, 221);
    width: fit-content;
}
.bucketListContainer-card-Home3{
    /* padding: 1%; */
    /* border: 1px lightgray solid; */
    border-radius: 8px;
    margin: 4%;
    width: 40%;
    box-shadow: h-shadow v-shadow blur spread color inset;
}
.bucketListText-Home3{
    margin-top: 2%;
    font-size: 28px;
    color: brown;
    font-weight: bold;
    margin-left: 4%;
    /* font-family: 'Space Grotesk', sans-serif; */
    font-family: 'Karla', sans-serif;
}
.bucketListCards-MainContainer-Home3{
    display: flex;
    flex-wrap: wrap;
}
.bucketList-card-image-Home3{
    width: 100%;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}
.stateName-bucketlist-Home3{
    font-size: 24px;
    margin: 1%;
      font-family: 'Space Grotesk', sans-serif;
}
.noteButton-BucketList-Home3{
    border: none;
    padding: 1%;
    border-radius: 6px;
    background-color: rgb(214, 214, 214);
    color: rgb(69, 69, 69);
    margin: 2%;
}
.noteButton-BucketList-Home3:hover{
   background-color: rgb(231, 231, 231);
   color: rgb(41, 41, 41);
}

.HiXCirclecssOutSide-Home3{
    border: none;
    outline: none;
    /* background-color: rgb(244, 246, 247); */
    background: none;
    position: absolute;
    /* right: 1.5%; */
    margin-left: 90%;
    margin-top: -1%;
    color: none;
    font-size: 36px;
    z-index: 100000;

  }
  .HiXCirclecssOutSide-Home3:hover{
    color: red;
    /* z-index: 100000; */
  }
  .textArea-Bucklist-Home3 {
    border: 2px solid #ddd; /* Add a border */
    border-radius: 8px; /* Rounded corners */
    padding: 4px; /* Add padding */
    font-size: 16px; /* Adjust font size */
    width: 94%; /* Take up the full width of its container */
    /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); Add a subtle shadow */
    transition: border 0.3s, box-shadow 0.3s; /* Add transitions for visual effects */
    margin-left: 2px;
  
    /* Add additional styles to make it beautiful */
    /* You can customize the colors, font, and other styles as per your design preferences */
  }
  
  /* .textArea-Bucklist-Home3::placeholder {
    color: #999; 
  } */
  
  .textArea-Bucklist-Home3:focus {
    border: 2px solid #797979; /* Change border color on focus */
    box-shadow: 2px 2px 10px rgba(181, 181, 181, 0.4); /* Add a stronger shadow on focus */
    outline: none; 
  }  