$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//





.conatiner_p-map-Map_Hotels{
    width: 100%;
    height: 100%;
    // border: 2px saddlebrown solid;
}

//buttons
.container_p-buttons-Map_Hotels{
    z-index: 100000;
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 10px;
    left: 60px;
    border-radius: 10px;
    padding: 4px 8px 4px 8px;
    background: rgba(99, 99, 99, 0.64);
    // opacity: 1px;
}
.button-fitAll-Map_Hotels{
    // z-index: 100000;
    // position: absolute;
    margin-left: 8px;
    border-radius: 6px;
    padding: 2px 4px 2px 4px;
    font-family: $font-content-2;
    color: rgb(62, 62, 62);
    border: none;
    background: rgb(237, 237, 237);
    letter-spacing: 0.4px;

    &:hover{
        background-color: rgb(225, 225, 225);
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(245, 245, 245);
        transition: background-color 0.3s ease;
    }
}
.button-destination-Map_Hotels{
    // z-index: 100000;
    // position: absolute;
}


//destination name
.container-tooltip-destination-Map_Hotels{
    background-color: white;
    box-shadow: none;
    border-radius: 8px;
    border: 1px solid rgb(190, 190, 190);
    padding: 2px 6px 0px 6px;
}
.text-tooltip-destination-Map_Hotels{
    font-family: $font-6;
    font-size: 13px;
    color: brown;
    background: white;
}


//Hover Active Hotels
.container-tooltip-active_hover-Map_Hotels{
    // z-index: 1000000 !important; /* Adjust the value to be above other elements */
    background-color: rgb(255, 255, 255);
    background: white;
    border-radius: 8px;
    border: 2px solid rgb(59, 131, 197);
    // padding: 5px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(26, 111, 202, 0.869);
    text-align: center;
}
.icon-1-tooltip-active_hover-Map_Hotels{
    width: 24px;
    height: 24px;
}
.text-1-tooltip-active_hover-Map_Hotels{
    color: gray;
    font-family: $font-6;
    // font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}
.text-2-tooltip-active_hover-Map_Hotels{
    color: red;
    font-family: $font-6;
    font-size: 16px;
    font-weight: bold;
}

.special-marker {
    z-index: 9999000000000 !important; /* High z-index value to ensure it is above other elements */
}
  

//Hotels
.container-tooltip-hotels-Map_Hotels{
    border-radius: 4px;
    background: rgb(255, 255, 255);
    padding: 2px 4px 2px 4px;
}
.container-hotels-Map_Hotels{
    display: flex;
    flex-direction: column;
    // background: rebeccapurple;
    padding: 0px;
}
.text-1-hotels-Map_Hotels{
    font-size: 10px;
    color: rgb(69, 69, 69);
}
.text-2-hotels-Map_Hotels{
    color: brown;
    font-family: $font-6;
    font-size: 14px;
    // font-weight: bold;
    margin-left: 4px;
}

.text-3-hotels-Map_Hotels{
    color: brown;
    font-family: $font-5;
    font-size: 15px;
    font-weight: bold;
    margin-left: 4px;
    background: white;
}
  




//view more info - hotels
.text-1-hotels-popup-Map_Hotels{
    font-family: $font-6;
    width: 110px;
    font-size: 13px;
    word-break: break-all;
    // background: lightgray;
}
.heading-2-Map_Hotels{
    color: rgb(52, 51, 51);
    margin-right: 6px;
    font-family: $font-4;
    margin-left: 2px;
    font-size: 13px;
    margin-top: 2px;
}
.heading-3-Map_Hotels{
    color: rgb(0, 0, 0);
    margin-right: 12px;
    margin-top: 2px;
    font-family: $font-6;
    // margin-left: 4px;
}

.line-1-hotels-popup-Map_Hotels{
    border-bottom: 0.4px solid lightgray;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
}
.container-button-hotels-popup-Map_Hotels{
    display: flex;
    flex-direction: column;
}
.button-1-hotels-popup-Map_Hotels{
    font-family: $font-6;
    font-size: 14px;
    border: none;
    margin-bottom: 4px;
    width: fit-content;

    &:hover{
        color: rgb(71, 71, 71);
        background-color: rgb(255, 240, 240);
    }
    &:active{
        color: orangered;
    }
}
.container_p-deal-hotels-popup-Map_Hotels{
    background-color: whitesmoke;
    width: fit-content;
    padding: 2px;
    border-radius: 4px;
    text-align: center;
    align-items: center;
}

.heading-1-deal-hotels-popup-Map_Hotels{
    text-decoration: underline;
    color: gray;
    font-family: $font-content-1;
    margin-bottom: 3px;
}
.container_t-1-ratings-popup-Map_Hotels{
    // width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 6px;
    width: 120px;
    // margin-left: 12px;
    // background-color: rgb(240, 240, 240);
    // text-align: start;
    // align-items: start;
    // justify-content: flex-start;
    // align-self: self-start;
    
}
.heading-2-deal-hotels-popup-Map_Hotels{
    font-family: $font-6;
    color: brown;
    font-size: 14px;
    // margin-top: 2px;
}