 /* box3 */
 .box3-Home{
    width: 100%;
    /* height: 100px; */
    /* border: 2px solid lightcoral; */
    /* background: #FFF3DF; */
    /* border-bottom: 1px lightcoral solid; */
    display: flex;
    flex-direction: row;
    margin-top: 6%;
    align-items: center;
    justify-content: center;
 }
 
 .mainText-Box3-Home2{
  width: 80%;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
 }

 .text1-mainText-Box3-Home2{
  font-weight: 600;
  font-family: 'Ysabeau Office', sans-serif;
  font-size: 22px;
  letter-spacing: 0.3px;
 }
 .text2-mainText-Box3-Home2{
  font-weight: normal;
  font-family: 'Work Sans', sans-serif;
  margin-left: 1%;
 }

 .mainContainer-Box3-Home2{
  display: flex;
  flex-direction: column;
  width: 80%;
  /* border: rgb(234, 234, 234) 4px solid; */
  /* background: #e9e9e9; */
  padding: 4%;
  border-radius: 10px;
  
 }
 .mainContainer2-Box3-Home2{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 3%;
}







.placesContainer-box3-Home2{
  /* border: #8c7add 4px solid; */
  display: flex;
  width: 100%;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  }
  .placesContainer-box3-Home2::-webkit-scrollbar {
    width: 0; /* This makes the scrollbar extremely thin */
  }
  
  .placesContainer-box3-Home2::-webkit-scrollbar-thumb {
    background-color: transparent; /* This makes the thumb of the scrollbar transparent */
  }
  .card_Button_MainContainer-box3-Home2{
    display: flex;
    flex-direction: column;
    margin-left: 1%;
    margin-right: 4%;
    
  }
  .places-box3-Home2{
    min-width: 400px;
    max-width: 400px;
    height: 260px;
    /* border: #EC5929 solid 1px; */
    /* background: #f52217; */
    border: none;
    margin: 1%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    /* padding: 1%; */
    /* z-index: 20000000000000; */
    align-items: start;
    text-align: start;
    box-shadow: 0 0 8px rgba(255, 167, 3, 3); 
    }
.savedCardActionButtonMainContainer-box3-Home2{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}   
.createdOnMainContainer-box3-Home2{
  display: flex;
  flex-direction: column;
  align-items: baseline;
} 
.createdOnMainContainer2-box3-Home2{
  display: flex;
  flex-direction: row;
  align-items: baseline;
} 
.createdOnText-box3-Home2{
  font-weight: normal;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 6px;
  white-space: nowrap;
  font-family: 'Quicksand', sans-serif;
}
.date-box3-Home2{
  font-weight: 360;
  font-size: 16px;
  margin-left: 9px;
  white-space: nowrap;
  font-family: 'Nunito Sans', sans-serif;
}
.date2-box3-Home2{
  font-weight:lighter;  
  font-size: 14px;
  margin-top: 4px;
  margin-left: 11px;
  white-space: nowrap;
  font-family: 'Nunito Sans', sans-serif;
}
.iconConatainer-box3-Home2{
  display: flex;
  flex-direction: row;

}
.shareIcon-box3-Home2{
  border: none;
  background: none;
  margin: 1px 2px 1px 2px;
  font-size: 20px;
  margin-right: 8px;
}
.deleteIcon-box3-Home2{
  border: none;
  background: none;
  font-size: 20px;
  /* margin-right: 8px; */
}

.text1-Place-box3-Home2{
  /* position: absolute; */
  bottom: 0;    
  font-weight: normal; 
  font-size: 22px;
  z-index: 21;  
  color: #ffffff;   
  margin: 2%;
  margin-left: 4%;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Space Grotesk', sans-serif;
  /* font-family: 'Karla', sans-serif; */
  font-weight: bold;
  /* font-family: 'Mitr', sans-serif; */
  /* font-family: 'Rubik', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: 'Finlandica', sans-serif; */
  /* font-family: 'Assistant', sans-serif; */
  /* font-family: 'Ysabeau Office', sans-serif; */

}    
.text2-Place-box3-Home2{
  /* position: absolute; */
  bottom: 0;    
  font-weight: normal; 
  font-size: 20px;
  z-index: 21;  
  color: #ffffff;  
  margin: 2%; 
  font-family: 'Space Grotesk', sans-serif;
  /* font-family: 'Karla', sans-serif; */
  /* font-family: 'Ysabeau Office', sans-serif; */
  /* font-family: 'Nanum Gothic', sans-serif; */
  margin-left: 4.5%;
  letter-spacing: 0.2px;
}  
.text3-Place-box3-Home2{
  /* position: absolute; */
  bottom: 0;    
  font-weight: normal; 
  font-size: 20px;
  z-index: 21;  
  color: #ffffff;  
  margin: 2%;    
  /* font-family: 'Space Grotesk', sans-serif; */
  /* font-family: 'Karla', sans-serif; */
  font-family: 'Nanum Gothic', sans-serif;
  /* font-weight: bold; */
  margin-left: 4.5%;
  letter-spacing: 0.2px;
}  
.text4-Place-box3-Home2{
  position: absolute;
  bottom: 0;    
  font-weight: normal; 
  z-index: 21;  
  color: #C6C7C6; 
  font-size: 18px; 
  margin: 3%;  
  /* font-family: 'Nunito Sans', sans-serif;   */
  letter-spacing: 0.2px;
  /* font-family: 'Kodchasan', sans-serif;    */
  font-family: 'Nunito Sans', sans-serif;


}   

/* .absoluteBoxDesign-savedStatesCards-Home2{
  position: absolute;
  border: 2px solid rgb(189, 186, 185);
  border-top: none;
  width: 92%;
  height: 70%;
  margin-left: -4.4%;
  margin-top: 20%;   
  border-radius: 10px; 
  box-shadow: 0 0 4px rgb(118, 118, 118); 
}  */
.absoluteImage-Home2{
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* margin-top: 32px;
  margin-left: -160px; */
  z-index: 1;
  object-fit: fit; 
  /* margin-left: -50%; */
  /* filter: linear-gradient(to right, rgba(219, 0, 0, 0.5), rgba(0, 0, 0, 0.3)) */
} 
.absoluteImage2Filter-Home2{
  position: absolute;
  width: 100%;
  height: 100%;
  /* margin-top: 32px;
  margin-left: 9px; */
  border-radius: 10px;
  z-index: 20;
  /* background: red; */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1))
}

.navigationButton-Box3-Home2{
  border: none;
  background: none;
  font-size: 40px;
}

.text2-PlaceDestinationContainer-box3-Home2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* You can uncomment this if you want to wrap content */
  word-wrap: break-word;
  white-space: nowrap; /* Prevent text from wrapping */
  width: 100%;
  /* border: 10px solid rgb(235, 0, 0); */
  z-index: 100;
  overflow-x: hidden; /* Control horizontal overflow */
  box-sizing: border-box; /* Include padding and borders in the width calculation */
}

.text1-Place2Destination-box3-Home2{
  /* position: absolute; */
  bottom: 0;    
  font-weight: normal; 
  font-size: 18px;
  z-index: 21;  
  color: #ffffff;   
  margin: 2%; 
  margin-left: 3%;  
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Space Grotesk', sans-serif;             
  /* font-family: 'Karla', sans-serif; */
  /* font-weight: bold; */
  /* white-space: nowrap; */  
}


/* ------------------------------------- */
 /* box4 */
 .box4-Home{
    width: 100%;
    /* height: 100px; */
    /* border: 2px solid lightcoral; */
    /* background: #FFF3DF; */
    /* border-bottom: 1px lightcoral solid; */
    display: flex;
    flex-direction: row;     
    margin-top: 8%;
    align-items: center;
    justify-content: center;
 }
.mainContainer-Box4-Home2{
    display: flex;
    flex-direction: column;
    width: 80%;
    /* border: rgb(234, 234, 234) 4px solid; */
    border-radius: 10px;
    padding: 2%;
} 
.mainText-Box4-Home2{
  width: 80%;
  margin-left: 5%;
  /* font-size: 18px; */
  /* font-family: 'Poppins', sans-serif; */
  font-weight: 600;
  /* font-family: 'Kodchasan', sans-serif; */
  /* font-family: 'Ysabeau Office', sans-serif; */
  font-family: 'Karla', sans-serif;
  /* color: gray; */
  font-size: 20px;
  letter-spacing: 0.1px;
 }
 .mainText2-Box4-Home2{
  color: gray;
  font-weight: normal;
  margin-top: 8px;
  font-size: 12px;
  letter-spacing: 0.1px;
 }
.mainContainer2-Box4-Home2{
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  /* width: 98%; */
  /* border: rgb(234, 234, 234) 10px solid; */
} 
.placesContainer-box4-Home2{
  /* border: #8c7add 4px solid; */
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: none;
  scrollbar-width: none;
  margin: 1%;
} 
.placesContainer-box4-Home2::-webkit-scrollbar {
  width: 0; /* This makes the scrollbar extremely thin */
}
                                                                  
.placesContainer-box4-Home2::-webkit-scrollbar-thumb {
  background-color: transparent; /* This makes the thumb of the scrollbar transparent */
}
.places-box4-Home2{
  min-width: 280px;
  max-width: 280px;
  height: 240px;
  /* border: #000000 solid 40px; */
  border: none;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 4px 6px rgb(255, 124, 9);
  /* padding: 1%; */
}
.absoluteImage-box4-Home2{
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* margin-top: 32px;
  margin-left: -160px; */
  z-index: 1;
  object-fit: fit; 
  /* background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)) */
  /* margin-left: -50%; */
  /* filter: linear-gradient(to right, rgba(219, 0, 0, 0.5), rgba(0, 0, 0, 0.3)) */
} 
.absoluteImage-blank-box4-Home2{
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* margin-top: 32px;
  margin-left: -160px; */
  z-index: 10;
  object-fit: fit; 
  background-image: linear-gradient(to left, rgba(254, 173, 66, 0.358), rgba(191, 191, 191, 0.372))
  /* margin-left: -50%; */
  /* filter: linear-gradient(to right, rgba(219, 0, 0, 0.5), rgba(0, 0, 0, 0.3)) */
} 
.absoluteImage2Filter-box4-Home2{
  position: absolute;
  width: 100%;
  height: 100%;
  /* margin-top: 32px;
  margin-left: 9px; */
  border-radius: 10px;
  z-index: 20;
  /* background: red; */
  background-image: linear-gradient(to right, rgba(255, 141, 27, 0.375), rgba(4, 2, 0, 0.152))
}
.text1-Place-box4-Home2{
  position: absolute;
  /* background: orange; */
    /* font-family: 'Mitr', sans-serif; */
/* font-family: 'Montserrat', sans-serif; */
font-family: 'Poppins', sans-serif;
/* font-family: 'Kodchasan', sans-serif; */
/* font-family: 'Ysabeau Office', sans-serif; */
  top: 0;
  margin: 4% 1% 1% 1%;
  font-size: 33px;
  z-index: 22;
  margin: 20px;
  color: rgb(0, 0, 0);
  font-weight: normal;
}
.text2-Place-box4-Home2{
  position: absolute;
  top: 120px;
  margin: 4% 1% 1% 1%;
  font-size: 16px;
  z-index: 22;
  margin: 20px;
  color: rgb(0, 0, 0);
  /* font-family: 'Assistant', sans-serif;*/
   /* font-family: 'Mitr', sans-serif; */
   font-family: 'Montserrat', sans-serif;
  /* font-family: 'Nanum Gothic', sans-serif; */
}
.text3-Place-box4-Home2{
  position: absolute;
  /* background: orange; */
  /* font-family: 'Mitr', sans-serif; */
font-family: 'Montserrat', sans-serif;
 /* font-family: 'Poppins', sans-serif; */
 /* font-family: 'Kodchasan', sans-serif; */
 /* font-family: 'Ysabeau Office', sans-serif; */
 font-family: 'Karla', sans-serif;
  bottom: 8px;
  margin: 1% 1% 10% 1%;
  font-size: 33px;
  align-items: start;
  text-align: start;
  z-index: 22;
  margin: 20px;
  font-weight: bold;
}

 /* box5 */
 .box5-Home{
    width: 100%;
    /* height: 100px; */
    /* border: 2px solid lightcoral; */
    background: #FFF3DF;
    padding: 2% 0% 2% 0%;
    /* border-bottom: 1px lightcoral solid; */
    display: flex;
    flex-direction: row;
    margin-top: 6%;
    align-items: center;
    justify-content: center;
 }
 .mainContainer-box5-Home2{
    display: flex;
    flex-direction: column;
    width: 74%;
    /* border: rgb(255, 0, 0) 10px solid; */
 }
 .month_ViewAll-mainContainer-box5-Home2{
  display: flex;
  flex-direction: row;
  /* border: 1px blue solid; */
  margin: 1%;
  margin-left: 4%;
  width: 80%;
  align-items: center;
  justify-content: space-between;
 }
 .monthsMainContainer-box5-Home2{
   padding: 0% 1% 0% 1%;
  background: rgb(239, 239, 239);
  border-radius: 10px;
  width: 90%;
  display: flex;
  justify-content: space-around;
  margin: 1%;
  /* transition: background 0.3s; */
 }
 .monthButton-box5-Home2{
   border: none;
   background: none;
   margin: 2%;
   font-size: 18px;
   padding: 1%;
   border-radius: 10px;
   font-family: 'Karla', sans-serif;

   /* padding: 2%;
   background: lightgray;
   border-radius: 10px; */
  }
  .monthButton-box5-Home2:hover{
   background: rgb(255, 217, 178);
   padding: 1%;
   border-radius: 10px;
  }
  .monthButton-box5-Home2:active{
    background: rgb(255, 229, 202);
    padding: 1%;
    border-radius: 10px;
   }
  .monthButtonActive-box5-Home2{
    border: none;
    background: none;
    margin: 2%;
    font-size: 18px;
    /* background:  rgb(255, 209, 161); */
    background:  rgb(214, 214, 214);
    /* padding: 1.5% 2% 1.5% 2%; */
    padding: 1%;
    border-radius: 10px;
    font-weight: 600;
    /* padding: 2%;
    background: lightgray;
    border-radius: 10px; */
   }
 .viewAllButton-box5-Home2{
  font-size: 16px;
  border-radius: 16px;
  border: none;
  padding: 1% 4% 1% 4%;           
  height: 60%;
  background: orangered;
  color: white;
 }

 .mainContainer-Box5-Home2{
  display: flex;
  flex-direction: row;
  width: 98%;
  /* border: rgb(234, 234, 234) 10px solid; */
} 
.placesContainer-box5-Home2{
/* border: #8c7add 4px solid; */
display: flex;
width: 100%;
flex-direction: row;
overflow-x: scroll;
overflow-y: hidden;
  scrollbar-width: none;
}
.placesContainer-box5-Home2::-webkit-scrollbar {
  width: 0; /* This makes the scrollbar extremely thin */
}

.placesContainer-box5-Home2::-webkit-scrollbar-thumb {
  background-color: transparent; /* This makes the thumb of the scrollbar transparent */
}
.places-box5-Home2{
min-width: 400px;
max-width: 400px;
min-height: 300px;
/* border: #EC5929 solid 1px; */
border: none;
margin: 1%;
border-radius: 10px;
display: flex;
flex-direction: column;
position: relative;
/* padding: 1%; */
}

.text1-Place-box5-Home2{
/* position: absolute; */
/* background: orange; */
/* top: 0; */
/* margin: 4% 1% 1% 1%; */
margin: 1%;
margin-left: 4%;
font-size: 28px;
align-items: start;
text-align: start;
z-index: 22;
color: white;
/* font-family: 'Lato'; */
/* font-family: 'Nanum Gothic', sans-serif; */
font-family: 'Karla', sans-serif;
letter-spacing: 0.4px;
}
.famousText_content-box5-Home2{
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  align-items:first baseline;
  text-align: start;
  z-index: 22;
  color: white;
}
.text2-Place-box5-Home2{
/* position: absolute; */
/* background: orange; */
/* top: 50px; */
/* margin: 4% 1% 1% 1%; */
z-index: 22;
margin: 6% 1% 1% 4.2%;
font-size: 12px;
align-items: start;
text-align: start;
width: 20%;
/* border: #8c7add 1px solid; */
color: white;
font-family: 'Quicksand', sans-serif;
}
.text2_2-Place-box5-Home2{
  /* position: absolute; */
  /* background: orange; */
  /* top: 50px; */
  /* margin: 4% 1% 1% 1%; */
  z-index: 22;
  margin: 6% 1% 1% 4%;
  font-size: 12px;
  align-items: start;
  text-align: start;
  width: 100%;
  /* border: #8c7add 1px solid; */
  color: white;
  font-family: 'Quicksand', sans-serif;
  }
.text3-Place-box5-Home2{
/* position: absolute; */
/* background: orange; */
/* bottom: 0; */
/* margin: 1% 1% 10% 1%; */
z-index: 22;
margin: 1%;
margin-left: 4%;
font-size: 20px;
align-items: start;
text-align: start;
width: 80%;
/* border: #dd7aa5 1px solid; */
color: white;
font-family: 'Karla', sans-serif;
}
.durationContent-box5-Home2{
  z-index: 22;
}
.text3_2-Place-box5-Home2{
  /* position: absolute; */
  /* background: orange; */
  /* bottom: 0; */
  /* margin: 1% 1% 10% 1%; */
  margin: 1%;
  margin-bottom: 3%;
  font-size: 18px;
  align-items: start;
  text-align: start;
  width: 100%;
  /* border: #dd7aa5 1px solid; */
  color: white;
  /* font-family: 'Assistant', sans-serif; */
  font-family: 'Karla', sans-serif;
  font-weight: bold;
  letter-spacing: 0.2px;
  }
.duration_content-box5-Home2{
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  align-items:first baseline;
  text-align: start;
}
.discoverDestinationText-box5-Home2{
  margin: 1%;
  margin-left: 5%;
  font-weight: 600;
  font-family: 'Kodchasan', sans-serif;
  font-size: 22px;
}

.absoluteBoxDesign-box5-savedStatesCards-Home2{
  position: absolute;
  border: 2px solid rgb(189, 186, 185);
  border-top: none;
  width: 92%;
  height: 70%;
  margin-left: -4.4%;
  margin-top: 20%;   
  border-radius: 10px; 
  box-shadow: 0 0 4px rgb(118, 118, 118); 
} 
.absoluteImage-box5-Home2{
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* margin-top: 32px;
  margin-left: -160px; */
  z-index: 1;
  object-fit: fit; 
  /* margin-left: -50%; */
  /* filter: linear-gradient(to right, rgba(219, 0, 0, 0.5), rgba(0, 0, 0, 0.3)) */
} 
.absoluteImage2Filter-box5-Home2{ 
  position: absolute;
  width: 100%;
  height: 100%;
  /* margin-top: 32px;
  margin-left: 9px; */
  border-radius: 10px;
  z-index: 20;
  /* background: red; */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2))
}


  /* box6 */
  .box6-Home{
    width: 100%;
    /* height: 100px; */
    /* border: 2px solid lightcoral; */
    /* background: #FFF3DF; */
    /* border-bottom: 1px lightcoral solid; */
    margin-top: 10%;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .mainContainerText-box6-Home{
    width: 50%;
    font-size: 22px;
  }
  .mainContainer-box6-Home{
    width: 50%;
    border: #C6C7C6 3px solid;
    /* box-shadow: 0 0 8px rgba(55, 55, 55, 0.3);  */
    border-radius: 14px;
    margin: 0.6% 1% 1% 1%;
    padding: 1%;
    /* height: 400px; */
    display: flex;
    flex-direction: row;
  }
  .stateNameMainContainer-box6{
    border-right: 1px solid orange;
    width: 36%;
  }
  .stateContentMainContainer-box6{      
    width: 56%;
    padding: 1% 1% 1% 2%;
  }
  .stateName-box6{
    cursor: pointer;
    font-weight: bold;
    /* font-weight: 600;   */
    margin-bottom: 18px;
    font-size: 24px;
    color: rgb(55, 55, 55);
    font-family: 'Ysabeau Office', sans-serif;
    /* letter-spacing: 0.2px; */
  }
  .stateNameActive-box6{
    cursor: pointer;
    font-weight: bold;
    font-family: 'Montserrat';
    font-weight: 600;
    margin-bottom: 18px;
    font-size: 20px;
    font-family: 'Ysabeau Office', sans-serif;
    /* letter-spacing: 0.2px; */
    background: rgb(255, 234, 191);
    color: rgb(55, 55, 55);
    width: fit-content;
    padding: 1%;
  }
  .NameActionButton-box6{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .stateName2-box6{
    font-family: 'Comfortaa';
    font-weight: bold;
    letter-spacing: 0.2px;
  }
  .startPlanningButton-box6{
    border-radius: 12px;
    background: #EC5929;
    color: white;
    padding: 0.6% 3% 1% 3%;
    font-size: 16px;
    border: none;
    letter-spacing: 0.2px;
  }
  .startPlanningButton-box6:hover{
    background: #f38b68;
    /* color: rgb(158, 158, 158); */
  }
  .startPlanningButton-box6:active{
    /* background: #f38b68; */
    background:  #ff6531;
  }

  .imageTextMainConatiner-box6{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* border: gold 1px solid; */
    margin-top: 8px;
  }
  
  .uniqueThingsText-box6{
      font-weight: 500;
      /* font-family: 'Mitr'; */
    }

.scroll_Image_Container-box6{
    display: flex; 
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    /* background: #EC5929; */
    width: 100%;
    /* border: 10px soild green; */
}
  .iconFamousPlace1-box6{
    margin-right: 4px;
    border: none;
    background: none;
  }
  .imageFamousPlace-box6{
    width: 60%;
    /* height: 200px; */
    margin: 4px;
  }
  .iconFamousPlace2-box6{
    margin-left: 4px;
    border: none;
    background: none;
  } 

  .imageTextMainContainer-box6{
    width: 100%;
    align-items: center;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    padding: 1%;
  }
  .imageTextContainer-box6{
    width: 60%;

  }

  /* box end -------------------------------------- */

  .loginContainer-Home2{
    /* position: absolute; */
    /* width: 18%;
    height: 36%; */
    border: 20px solid rgb(42, 41, 41);
    z-index: 10000000;
    /* margin-left: 78%; */
    /* margin-top: 8%; */
    padding: 1%;
    border-radius: 10px;
    background-color: antiquewhite;
}

.login-MainContainer-Home2{
  border: #EC5929 1px solid;
  border-radius: 10px;
  width: fit-content;
  margin-left: 10%;
  padding: 2%;
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  align-items: start;
  /* text-align: center; */
}
.loginText-Home2{
  font-weight: normal;
}
.loginComponentContainer-Home2{
  margin-top: 4%;
}


