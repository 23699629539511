$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//

.container_p-2-cards-TouristPlace-MustVisit{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: center;
    // justify-content: space-between;
    border: rgb(11, 116, 190) 2px solid;
    min-width: 60%;
    max-width: 100%;
    // margin-top: 1%;
}
.container_p-cards-TouristPlace-MustVisit{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    text-align: start;
    justify-content: space-around;
    // border: rgb(208, 1, 1) 2px solid;
    // min-width: 60%;
    // max-width: 100%;
    width: fit-content;
    // margin-top: 1%;
}
// .container_p-cards-TouristPlace-MustVisit{
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     align-items: start;
//     text-align: start;
//     // justify-content: space-between;
//     border: rgb(208, 1, 1) 2px solid;
//     min-width: 60%;
//     max-width: 100%;
//     // margin-top: 1%;
// }
.card-TouristPlace-MustVisit{
    // width: 28%;
    margin: 2%;

    width: 230px;
    border: rgb(215, 212, 212) 2px solid;
    border-radius: 10px;
    margin: 4px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 16px;
    background-color: none;
    background:transparent;
    align-items: start;
    cursor:auto;

    text-align: start;
}

.container_t-icon-card-TouristPlace-MustVisit{
    position: absolute;
    bottom: 8%;
    right: 4%;

    border-radius: 8px;
    padding: 2px 3px 0px 3px;
    width: fit-content;
    height: fit-content;
    border: none;
    background: none;
    background-color: rgba(255, 255, 255, 0.732);
    text-align: center;
    align-items: center;
}
.icon-card-TouristPlace-MustVisit{
    font-size: 20px;
    // color: white;
    // font-weight: bold;
}

.image-Card-TouristPlace-MustVisit{
    overflow: hidden;
    width: 101.4%;
    margin-left: -0.6%;
    margin-top: -0.6%;
    height: 190px;
    object-fit: cover;
    // margin-top: 0.5%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    // border-radius: 10px;
}
.heading-placeName-TouristPlace-MustVisit{
    font-family: $font-6;
    margin: 8px 2px 2px 12px;
    display: flex;
    align-self: flex-start;
}
.container_t-placeName-TouristPlace-MustVisit{
    margin: 4px 2px 2px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.heading-1-placeName-TouristPlace-MustVisit{
    font-family: $font-6;
    // margin: 0px 0px 0px 4px;
    color: rgb(88, 87, 87);
    // font-size: 100px;
}
.heading-2-placeName-TouristPlace-MustVisit{
    font-family: $font-6;
    margin: 0px 0px 0px 4px;
    color: rgb(88, 87, 87);
}
.heading-3-placeName-TouristPlace-MustVisit{
    font-family: $font-6;
    // margin: 6px 2px 2px 12px;
    color: brown;
}
.container_t-2-placeName-TouristPlace-MustVisit{
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // text-align: center;
}
.heading-4-card-placeName-TouristPlace-MustVisit{
    font-family: $font-4;
    margin: 8px 0px 0px 10px;
    color: rgb(126, 46, 46);
    font-size: 12px;
}
.heading-4-card-duration-TouristPlace-MustVisit{
    font-family: $font-content-1;
    margin: 4px 0px 8px 10px;
    color: rgb(159, 154, 154);
    font-size: 16px;
}

.line-card-MustVisit{
    border-top: 0.4px dotted #ddd5d5;
    width: 90%;
    // margin-top: 4%;
    margin: 10px 0px 8px 0px;
}
.button-viewMore-card-MustVisit{
    border-radius: 8px;
    font-family: $font-content-2;
    letter-spacing: 0.4px;
    word-spacing: 0.4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    color: #c47003;
    background: none;
    border: none;
    padding: 4px 60px 4px 60px;
    margin: 4px 0px 10px 0px;
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Transition for smooth effect */
    text-shadow: 1px 1px 6px rgb(255, 220, 167); 


    &:hover {
        // background-color: darken(#ebaa65, 10%);
        // color: white;
        // color: rgb(0, 0, 0);
        color: #db7f06;
        text-shadow: 1px 1px 10px rgb(243, 191, 114); 
        // font-family: $font-5;
        font-family: $font-content-2;
        font-weight: 16px;
    }

    &:active {
        // background-color: lighten(#ff9203, 10%);
        background-color: lighten(#e6d6bd, 10%);
    } 
}

.container_s-line-MustVisit{
    display: flex;
    align-items: center;
    justify-content: center;
}
.container_s-viewMore-MustVisit{
    display: flex;
    align-items: center;
    justify-content: center;
}



.text-index-MustVisit{
    position: absolute;
    background: rgba(170, 170, 170, 0.514);
    color: rgb(107, 9, 9);
    // width: 20px;
    // height: 20px;
    // border-radius: 50%;
    border-radius: 6px;
    top: 2px;
    left: 2px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
