$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


.popup-background-1-AddTripDetails_TO {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
}


@media (min-width: 0px) and (max-width: 799px){
    .popup_p-addNote-AddTripDetails_TO{
        width: 96%;
        height: 94%;
        position: fixed;
        top: 2%;
        left: 2%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 12px 4px 12px 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000000;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .popup_s-addNote-AddTripDetails_TO{
        width: 100%;
        height: 100%;
        top: 10%;
        // left: 20%;
        // border: 1px solid #000;
        // background: #ffdcdc;
        // padding: 20px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000015;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    //close
    // .button-close-popup-AddNotes_TO_Popup{
    //     font-size: 28px;
    //     border: none;
    //     background: none;
    //     position: fixed;
    //     right: 16%;
    //     top: 4%;
    
    //     &:hover{
    //         color: red;
    //         transition: color 0.3s ease;
    //     }
    //     &:active{
    //         color: orange;
    //         transition: color 0.3s ease;
    //     }
    // }
}
@media (min-width: 800px){
    .popup_p-addNote-AddTripDetails_TO{
        width: 70%;
        height: 90%;
        position: fixed;
        top: 6%;
        left: 14%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000000;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .popup_s-addNote-AddTripDetails_TO{
        width: 98%;
        height: 98%;
        top: 10%;
        left: 20%;
        // border: 1px solid #000;
        // background: #ffdcdc;
        padding: 20px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000015;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    //close
    // .button-close-popup-AddNotes_TO_Popup{
    //     font-size: 22px;
    //     border: none;
    //     background: none;
    //     position: fixed;
    //     right: 16.2%;
    //     top: 7%;
    
    //     &:hover{
    //         color: red;
    //         transition: color 0.3s ease;
    //     }
    //     &:active{
    //         color: orange;
    //         transition: color 0.3s ease;
    //     }
    // }
}



.active-AddTripDetais_TO{
    font-weight: bold;
}
.unActive-AddTripDetais_TO{
    font-weight: lighter;
}

.conatiner_p-AddTripDetails_TO{
    margin: 10% 0% 4% 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container_p-heading-tripDetails-AddTripDetails_TO{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.heading-TripDetails-AddTripDetails_TO{
    font-family: $font-6;
    font-size: 18px;
    color: orangered;
}
.button-edit-tripDetails-AddTripDetails_TO{
    border: none;
    background: none;
}
.image-TripDetails-AddTripDetails_TO{
    width: 16px;
    height: 16px;
    margin-left: 10px;

    background: rgb(255, 237, 223);
    border-radius: 8px;
    box-shadow: 0 4px 200px rgb(242, 180, 180);

    &:hover{
        background-color: rgb(234, 189, 156);
        border-radius: 8px;
        box-shadow: 0 4px 200px rgb(242, 180, 180);
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(241, 239, 239);
        border-radius: 8px;
        box-shadow: 0 4px 10px rgb(244, 209, 161);
        transition: background-color 0.3s ease;
    }
}

//!
.container_p-data-tripDetails-AddTripDetails_TO{
    // flex-wrap: wrap;
    margin-top: 2%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: fit-content;
    align-items: center;
    text-align: center;
    justify-content: center;
}

//!Duration
.container_p-Duration-AddTripDetails_TO{
    border-radius: 8px;
    padding: 4px 6px 2px 6px;
    background: rgb(249, 197, 180);
    display: flex;
    width: fit-content;
    margin: 16px 16px 4px 16px;
    box-shadow: 0 4px 8px #ffe5e5;
}
.conatainer_t-1-Duration-AddTripDetails_TO{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    align-content: center;
    margin-bottom: 1.5px;
}
.conatainer_t-2-Duration-AddTripDetails_TO{

}

.heading-1-Duration-AddTripDetails_TO{
    color: rgb(255, 255, 255);
    margin: -4px 6px 0px 2px;
    // font-family: $font-6;
    font-size: 16px;
    font-style: italic;
}
.heading-2-Duration-AddTripDetails_TO{
    margin: 0px 6px 0px 2px;
    font-family: $font-6;
}
.heading-3-Duration-AddTripDetails_TO{
    margin: 0px 6px 0px 2px;
    font-family: $font-4;
    font-size: 12px;
}

//!Date

//?month_week
.container_t-monthWeek-AddTripDetails_TO{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    align-content: center;
    // justify-content: center;
}
.heading-4-Duration-AddTripDetails_TO{
    font-size: 14px;
    color: rgb(88, 86, 86);
    margin-right: 6px;
    border-radius: 12px;
    padding: 0.4px 4px 0.4px 4px;
    background: rgb(255, 207, 192);
    font-family: $font-heading-2;
}
.heading-5-Duration-AddTripDetails_TO{
    font-size: 16px;
    margin-right: 6px;
    margin: 4px 6px 0px 2px;
    font-family: $font-6;
}

//!Popup

.button-close-popup-AddTripDetails_TO{
    font-size: 24px;
    border: none;
    background: none;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}

//*Common
.heading-1-popup-AddTripDetails_TO{
    font-size: 18px;
    font-family: $font-6;
    color: rgb(98, 94, 94);
}
.button-active-popup-AddTripDetails_TO {
    border: none;
    background: rgb(95, 168, 35);
    margin: 4px;
    border-radius: 8px;
    padding: 1px 8px 3px 8px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;

    &:hover {
        background: rgb(85, 158, 25);
    }

    &:active {
        background: rgb(75, 148, 15);
        transform: scale(0.98);
    }
}

.button-unActive-popup-AddTripDetails_TO {
    border: none;
    background: whitesmoke;
    margin: 4px;
    border-radius: 6px;
    padding: 4px 10px;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;

    &:hover {
        background: rgb(220, 220, 220);
    }

    &:active {
        background: rgb(200, 200, 200);
        transform: scale(0.98);
    }
}

.text-1-popup-AddTripDetails_TO{
    margin-left: 4px;
    font-family: $font-4;
    color: rgb(138, 134, 134);
}
.input-1-popup-AddTripDetails_TO{
    border: none;
    border-bottom: 1px solid #ff7878;
    width: 100%;
    padding: 2px 6px 2px 6px;
    border-radius: 4px;

    &:focus{
        outline: none;
        background: whitesmoke;
        padding: 4px 8px 4px 8px;
        border: none;
        // box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
}
.input-2-popup-AddTripDetails_TO{
    border: none;
    border-bottom: 1px solid #ff7878;
    width: 30%;
    padding: 2px 6px 2px 6px;
    border-radius: 4px;

    &:focus{
        outline: none;
        background: whitesmoke;
        padding: 4px 8px 4px 8px;
        border: none;
        // box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
}
.dateInput-1-popup-AddTripDetails_TO {
    width: 100%;
    margin-top: 4px;
    padding: 4px 8px 4px 8px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: whitesmoke;
    font-size: 14px;
    color: #333;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        border-color: #888;
    }

    &:focus {
        outline: none;
        border-color: #5FA823;
        box-shadow: 0 0 5px rgba(95, 168, 35, 0.5);
    }
}

.dropdown-1-popup-AddTripDetails_TO {
    width: 100%;
    margin-top: 4px;
    padding: 4px 8px 4px 8px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: whitesmoke;
    font-size: 12px;
    color: #333;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        border-color: #888;
    }

    &:focus {
        outline: none;
        border-color: #5FA823;
        box-shadow: 0 0 5px rgba(95, 168, 35, 0.5);
    }
}
/* Option styling */
.dropdown-1-popup-AddTripDetails_TO option {
    padding: 4px 8px 4px 8px;
    background-color: white;
    color: #333;
}

//************** ****
//*Duration #1
.conatiner_p-Duration-popup-AddTripDetails_TO{
    border: 2px solid rgb(176, 176, 179);
    border-radius: 6px;
    padding: 14px 8px 14px 8px;
    margin: 4px 0px 12px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.conatiner_t-Duration_Date-popup-AddTripDetails_TO{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.select-popup-AddTripDetails_TO{
    border-radius: 4px;
    border: none;
    font-family: $font-8;
    margin: 12px 6px 0px 6px;
    padding: 2px 4px 2px 4px;
    background: whitesmoke;
}
.option-popup-AddTripDetails_TO{

}
.container_t-Duration-popup-AddTripDetails_TO{
    display: flex;
    margin-top: 4%;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: center;
}
//?Meta Data
.container_t-MetaInfo-Duration-popup-AddTripDetails_TO{
    margin-top: 4%;
}

//*Date
.conatiner_p-Date-popup-AddTripDetails_TO{
    border: 2px solid rgb(176, 176, 179);
    border-radius: 6px;
    padding: 14px 8px 14px 14px;
    margin: 4px 0px 12px 0px;
}
.conatainer_t-DateInputType-popup-AddTripDetails_TO{
    border: 0.6px solid rgb(255, 178, 84);
    border-radius: 12px;
    padding: 14px 8px 14px 14px;
    margin: 4px 0px 12px 0px;
}
.text-1-DateInputType-popup-AddTripDetails_TO{
    font-family: $font-4;
    margin: 8px 0px 4px 0px;
    font-size: 14px;
}

//*Budget
.conatiner_p-Budget-popup-AddTripDetails_TO{
    border: 2px solid rgb(176, 176, 179);
    border-radius: 6px;
    padding: 14px 8px 14px 14px;
    margin: 4px 0px 12px 0px;
}