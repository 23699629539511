$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

@media (min-width: 0px) and (max-width: 799px){
    //!popup container
    //?1
    .popup_p-2-addNote-AddNotes_TO_Popup{
        width: 100%;
        height: 94%;
        position: fixed;
        top: 2%;
        left: 0%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 2px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 10000;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .popup_s-2-addNote-AddNotes_TO_Popup{
        width: 100%;
        height: 98%;
        top: 10%;
        left: 0%;
        // border: 1px solid #000;
        // background: #ffdcdc;
        padding: 6px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 10000;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}
@media (min-width: 800px){
    //!popup container
    //?1
    .popup_p-2-addNote-AddNotes_TO_Popup{
        width: 90%;
        height: 94%;
        position: fixed;
        top: 2%;
        left: 4%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 10000;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .popup_s-2-addNote-AddNotes_TO_Popup{
        width: 98%;
        height: 98%;
        top: 10%;
        left: 20%;
        // border: 1px solid #000;
        // background: #ffdcdc;
        padding: 20px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 10000;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

// //!popup container
// //?1
// .popup_p-2-addNote-AddNotes_TO_Popup{
//     width: 90%;
//     height: 94%;
//     position: fixed;
//     top: 2%;
//     left: 4%;
//     border: none;
//     border-radius: 8px;
//     background: #fff;
//     padding: 20px;
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//     z-index: 10000;
//     overflow: auto;
//     display: flex;
//     align-items: center;
//     text-align: center;
//     justify-content: center;
// }
// .popup_s-2-addNote-AddNotes_TO_Popup{
//     width: 98%;
//     height: 98%;
//     top: 10%;
//     left: 20%;
//     // border: 1px solid #000;
//     // background: #ffdcdc;
//     padding: 20px;
//     // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//     z-index: 10000;
//     overflow: auto;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     text-align: center;
// }
.popup_s-2-addNote-AddNotes_TO_Popup::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.popup_s-2-addNote-AddNotes_TO_Popup::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.popup_s-2-addNote-AddNotes_TO_Popup::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  


.popup-background-1-AddNotes_TO_Popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
}


@media (min-width: 0px) and (max-width: 799px){
    .popup_p-addNote-AddNotes_TO_Popup{
        width: 96%;
        height: 94%;
        position: fixed;
        top: 2%;
        // left: -2%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 12px 2px 2px 2px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000000;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .popup_s-addNote-AddNotes_TO_Popup{
        width: 100%;
        height: 100%;
        top: 10%;
        // left: 20%;
        // border: 1px solid #000;
        // background: #ffdcdc;
        // padding: 20px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000015;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    //close
    .button-close-popup-AddNotes_TO_Popup{
        font-size: 28px;
        border: none;
        background: none;
        position: fixed;
        right: 16%;
        top: 4%;
    
        &:hover{
            color: red;
            transition: color 0.3s ease;
        }
        &:active{
            color: orange;
            transition: color 0.3s ease;
        }
    }
}
@media (min-width: 800px){
    .popup_p-addNote-AddNotes_TO_Popup{
        width: 70%;
        height: 90%;
        position: fixed;
        top: 6%;
        left: 14%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000000;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .popup_s-addNote-AddNotes_TO_Popup{
        width: 98%;
        height: 98%;
        top: 10%;
        left: 20%;
        // border: 1px solid #000;
        // background: #ffdcdc;
        padding: 20px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000015;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    //close
    .button-close-popup-AddNotes_TO_Popup{
        font-size: 22px;
        border: none;
        background: none;
        position: fixed;
        right: 16.2%;
        top: 7%;
    
        &:hover{
            color: red;
            transition: color 0.3s ease;
        }
        &:active{
            color: orange;
            transition: color 0.3s ease;
        }
    }
}

// .popup_p-addNote-AddNotes_TO_Popup{
//     width: 70%;
//     height: 90%;
//     position: fixed;
//     top: 6%;
//     left: 14%;
//     border: none;
//     border-radius: 8px;
//     background: #fff;
//     padding: 20px;
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//     z-index: 1000000;
//     overflow: auto;
//     display: flex;
//     align-items: center;
//     text-align: center;
//     justify-content: center;
// }
// .popup_s-addNote-AddNotes_TO_Popup{
//     width: 98%;
//     height: 98%;
//     top: 10%;
//     left: 20%;
//     // border: 1px solid #000;
//     // background: #ffdcdc;
//     padding: 20px;
//     // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//     z-index: 1000015;
//     overflow: auto;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     text-align: center;
// }
.popup_s-addNote-AddNotes_TO_Popup::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.popup_s-addNote-AddNotes_TO_Popup::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.popup_s-addNote-AddNotes_TO_Popup::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  

// .button-close-popup-AddNotes_TO_Popup{
//     font-size: 22px;
//     border: none;
//     background: none;
//     position: fixed;
//     right: 16.2%;
//     top: 7%;

//     &:hover{
//         color: red;
//         transition: color 0.3s ease;
//     }
//     &:active{
//         color: orange;
//         transition: color 0.3s ease;
//     }
// }

.button-close-AddNotes_TO_Popup{
    background: none;
    border: none;
    font-size: 20px;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }

    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}

//!heading

//?Input
.heading-heading-note-AddNotes_TO_Popup{
    font-size: 18px;
    // font-family: $font-6;
    font-family: 'Satisfy';
    letter-spacing: 2px;
    word-spacing: 0.4px;
    font-weight: bold;
    // color: rgb(242, 83, 83);
    color: rgb(255, 87, 65);
    // margin-left: 6%;
    margin-top: 1%;
}
// .conatiner_p-heading-AddNotes_TO_Popup{
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     text-align: center;
//     justify-content: center;
//     justify-content: space-around;

//     background-color: rgb(255, 247, 241);
//     width: 60%;
//     margin-top: 4%;
//     padding: 6px 20px 6px 20px;
//     border-radius: 6px;
// }
.conatiner_t-1-heading-AddNotes_TO_Popup{
    width: 14%;
    text-align: end;
    align-self: self-end;
    // margin-left: 20px;
    font-size: 20px;
    margin-top: -2px;
}

@media (min-width: 0px) and (max-width: 799px){
    .conatiner_p-heading-AddNotes_TO_Popup{
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        justify-content: center;
        justify-content: space-around;
    
        background-color: rgb(255, 247, 241);
        width: 80%;
        margin-top: 4%;
        padding: 6px 20px 6px 20px;
        border-radius: 6px;
    }
    .conatiner_t-2-heading-AddNotes_TO_Popup{
        min-width: 70%;
        max-width: 70%;
        text-align: start;
        align-self: self-start;
    }
}
@media (min-width: 800px){
    .conatiner_p-heading-AddNotes_TO_Popup{
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        justify-content: center;
        justify-content: space-around;
    
        background-color: rgb(255, 247, 241);
        width: 60%;
        margin-top: 4%;
        padding: 6px 20px 6px 20px;
        border-radius: 6px;
    }
    .conatiner_t-2-heading-AddNotes_TO_Popup{
        min-width: 70%;
        max-width: 70%;
        text-align: start;
        align-self: self-start;
    }
}

// .conatiner_t-2-heading-AddNotes_TO_Popup{
//     min-width: 70%;
//     max-width: 70%;
//     text-align: start;
//     align-self: self-start;
// }
.conatiner_t-3-heading-AddNotes_TO_Popup{
    width: 10%;
    font-family: $font-4;
    font-size: 14px;
    color: gray;
}
.input-heading-AddNotes_TO_Popup{
    border: none;
    border-bottom: 1px solid #ff7878;
    width: 100%;
    padding: 2px 6px 2px 6px;
    border-radius: 4px;

    &:focus{
        outline: none;
        background: whitesmoke;
        padding: 4px 8px 4px 8px;
        border: none;
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }
   
}



//manage note - absolute
// .conatiner_p-manageNote-AddNotes_TO_Popup{
//     position: absolute;
//     left: 30px;
//     top: 3%;
// }
.conatiner_s-manageNote-AddNotes_TO_Popup{
    position: fixed;
    display: flex;
    flex-direction: row;
    // border: 1px solid brown;
    padding: 2px 4px 2px 4px ;
    border-radius: 6px;
    // background: rgb(255, 222, 176);
    z-index: 1000;
}
.button-manage-manageNote-AddNotes_TO_Popup{
    border: none;
    margin: 4px;
    padding: 6px 6px 4px 6px;
    border-radius: 8px;
    background-color: orangered;
    color: white;
    font-family: $font-6;
    
    &:hover{
        color: red;
        background-color: whitesmoke;
        transition: color 0.3s ease;
        transition: background-color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}
.button-cancel-manageNote-AddNotes_TO_Popup{
    border: none;
    margin: 4px;
    padding: 6px 6px 4px 6px;
    border-radius: 8px;
    background-color: rgb(199, 195, 193);
    color: white;
    font-family: $font-6;
    
    &:hover{
        color: rgb(243, 52, 52);
        background-color: whitesmoke;
        transition: color 0.3s ease;
        transition: background-color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}
//!delete
.button-delete-manageNote-AddNotes_TO_Popup{
    position: absolute;
    border: none;
    background: none;
    right: 100px;
    top: 20px;
}
.image-2-delete-manageNote-AddNotes_TO_Popup{
    position: fixed;
    font-size: 26px;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}



//Hover - get heading ideas
//?hover box
.conatiner_p-headingIdeas-AddNotes_TO_Popup{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.conatiner_t-hoverBox-headingIdeas-AddNotes_TO_Popup{
    display: flex;
    flex-direction: row;
    background: rgb(255, 201, 53);
    padding: 0px 7px 0px 7px;
    border-radius: 12px;
    margin-top: 12px;
}
.button-hoverBox-headingIdeas-AddNotes_TO_Popup{
    background: none;
    border: none;
    font-family: $font-4;
    color: white;
    font-weight: bold;
    font-size: 14px;
    margin-right: 4px;
}
.img-hoverBox-headingIdeas-AddNotes_TO_Popup{
    width: 25px;
    height: 25px;
}

@media (min-width: 0px) and (max-width: 799px){

    //manage note - absolute
    .conatiner_p-manageNote-AddNotes_TO_Popup{
        position: absolute;
        left: 10px;
        top: 0%;
    }

    //?popoup
    .conatiner_p-popup-headingIdeas-AddNotes_TO_Popup{
        position: absolute;
        background: white;
        z-index: 10000;
        width: 100%;
        height: 400px;
        padding: 6px;
        border-radius: 8px;
        box-shadow: 0 4px 200px rgb(255, 228, 214);
        top: 10%;
        overflow: scroll;
    }   
    .button-popup-headingIdeas-AddNotes_TO_Popup{
        border: none;
        background: whitesmoke;
        padding: 2px 4px 2px 4px;
        border-radius: 6px;
        margin: 4px;

        &:hover{
            background: rgb(255, 212, 195);
        }
        &:active{
            background: rgb(255, 200, 178);
        }
    }
}
@media (min-width: 800px){

    //manage note - absolute
    .conatiner_p-manageNote-AddNotes_TO_Popup{
        position: absolute;
        left: 30px;
        top: 3%;
    }

    //?popoup
    .conatiner_p-popup-headingIdeas-AddNotes_TO_Popup{
        position: absolute;
        background: white;
        z-index: 10000;
        width: 600px;
        padding: 6px;
        border-radius: 8px;
        box-shadow: 0 4px 200px rgb(255, 228, 214);
        margin-top: 220px;
    }   
    .button-popup-headingIdeas-AddNotes_TO_Popup{
        border: none;
        background: whitesmoke;
        padding: 2px 4px 2px 4px;
        border-radius: 6px;
        margin: 4px;

        &:hover{
            background: rgb(255, 212, 195);
        }
        &:active{
            background: rgb(255, 200, 178);
        }
    }
}

// //?popoup
// .conatiner_p-popup-headingIdeas-AddNotes_TO_Popup{
//     position: absolute;
//     background: white;
//     z-index: 10000;
//     width: 600px;
//     padding: 6px;
//     border-radius: 8px;
//     box-shadow: 0 4px 200px rgb(255, 228, 214);
//     margin-top: 220px;
// }   
// .button-popup-headingIdeas-AddNotes_TO_Popup{
//     border: none;
//     background: whitesmoke;
//     padding: 2px 4px 2px 4px;
//     border-radius: 6px;
//     margin: 4px;

//     &:hover{
//         background: rgb(255, 212, 195);
//     }
//     &:active{
//         background: rgb(255, 200, 178);
//     }
// }





























// .conatiner_p-AddNotes_TO{
//     margin: 6% 0% 4% 0%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// }
// .heading-1-TripDetails-AddNotes_TO{
//     font-family: $font-6;
//     font-size: 18px;
//     color: orangered;
// }

// .container_p-heading-tripDetails-AddNotes_TO{
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//     width: 80%;
//     margin-top: 3%;
//     margin-bottom: 14px;
//     // border: 1px solid green;
//     // background: rgb(249, 248, 248);
//     border-radius: 12px;
//     width: fit-content;
//     padding: 4px 8px 2px 8px;
//     cursor: pointer;

//     &:hover{
//         background-color: rgb(255, 238, 228);
//         transition: background-color 0.3s ease;
//     }
    
//     &:active{
//         background-color: rgb(232, 230, 230);
//         transition: background-color 0.3s ease;
//     }
// }
// .heading-TripDetails-AddNotes_TO{
//     font-family: $font-6;
//     font-size: 16px;
//     color: rgb(62, 61, 61);
// }

// .button-edit-tripDetails-AddNotes_TO{
//     border: none;
//     background: none;
// }
// .image-TripDetails-AddNotes_TO{
//     width: 20px;
//     height: 20px;
//     margin-left: 10px;

//     background: rgb(255, 237, 223);
//     border-radius: 8px;
//     box-shadow: 0 4px 200px rgb(242, 180, 180);

//     &:hover{
//         background: rgb(234, 189, 156);
//         border-radius: 8px;
//         box-shadow: 0 4px 200px rgb(242, 180, 180);
//         transition: background-color 0.3s ease;
//     }
//     &:active{
//         background: rgb(241, 239, 239);
//         border-radius: 8px;
//         box-shadow: 0 4px 10px rgb(244, 209, 161);
//         transition: background-color 0.3s ease;
//     }
// }


// //!Notes Display
// .container_p-displayNotes-AddNotes_TO{
//     width: 100%;
//     margin-top: 2%;
//     // border: 1px solid cornflowerblue;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// }

// .container_p-headings-displayNotes-AddNotes_TO{
//     display: flex;
//     flex-direction: row;
//     width: 70%;
//     // border: 1px solid yellowgreen;
//     flex-wrap: wrap;
//     justify-content: space-around;
// }
// .conatiner_t-active-notesHeading-displayNotes-AddNotes_TO{
//     margin: 0px 6px 12px 2px;
//     padding: 2px 6px 2px 6px;
//     border-radius: 8px;
//     background: rgb(246, 214, 189);
//     border: none;
//     display: flex;
//     flex-direction: row;
//     text-align: center;
//     align-items: center;

//     &:hover{
//         background-color: rgb(255, 229, 229);
//         transition: background-color 0.3s ease;
//     }
//     &:active{
//         background-color: rgb(255, 255, 255);
//         transition: background-color 0.3s ease;
//     }
// }
// .conatiner_t-unActive-notesHeading-displayNotes-AddNotes_TO{
//     margin: 0px 6px 12px 2px;
//     padding: 2px 6px 2px 6px;
//     border-radius: 10px;
//     background: rgb(253, 252, 252);
//     border: 0.4px solid whitesmoke;
//     display: flex;
//     flex-direction: row;
//     text-align: center;
//     align-items: center;

//     &:hover{
//         background-color: rgb(255, 229, 229);
//         transition: background-color 0.3s ease;
//     }
//     &:active{
//         background-color: rgb(255, 255, 255);
//         transition: background-color 0.3s ease;
//     }
// }
// .icon-1-notesHeading-displayNotes-AddNotes_TO{
//     margin-right: 6px;
//     font-size: 16px;
//     margin-top: -4px;
// }
// .heading-1-notesHeading-displayNotes-AddNotes_TO{
//     margin-right: 6px;
//     font-family: $font-content-2;
// }

// //!single notes
// .container_p-singleNote-displayNotes-AddNotes_TO{
//     // border: 1px solid red;
//     width: 100%;
//     margin-top: 2%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// }
// //?manage note - edit/delete
// .container_t-manage-singleNote-displayNotes-AddNotes_TO{
//     // border: 1px solid brown;
//     flex-direction: row;
//     align-items: center;
//     text-align: center;
//     justify-content: center;
//     width: 80%;
//     display: flex;
//     flex-direction: row;
// }
// .button-edit-singleNote-displayNotes-AddNotes_TO{
//     border: none;
//     background: none;
//     margin-right: 12px;
//     display: flex;
//     flex-direction: row;
//     padding: 1px 8px 1px 8px;
//     border-radius: 6px;
//     // background-color: whitesmoke;
//     background: rgb(236, 234, 234);

//     &:hover{
//         background-color: rgb(255, 229, 229);
//         transition: background-color 0.3s ease;
//     }
//     &:active{
//         background-color: rgb(255, 255, 255);
//         transition: background-color 0.3s ease;
//     }
// }
// .button-delete-singleNote-displayNotes-AddNotes_TO{
//     border: none;   
//     background: none;   
//     margin-right: 12px;
//     display: flex;
//     flex-direction: row;
//     padding: 1px 8px 1px 8px;
//     border-radius: 6px;
//     // background-color: whitesmoke;
//     background: rgb(236, 234, 234);

//     &:hover{
//         background-color: rgb(255, 229, 229);
//         transition: background-color 0.3s ease;
//     }
//     &:active{
//         background-color: rgb(255, 255, 255);
//         transition: background-color 0.3s ease;
//     }
// }
// .text-edit-singleNote-displayNotes-AddNotes_TO{

// }
// .text-delete-singleNote-displayNotes-AddNotes_TO{

// }
// .image-singleNote-displayNotes-AddNotes_TO{
//     width: 16px;
//     height: 16px;
//     margin-left: 10px;

//     background: rgb(255, 241, 238);
//     border-radius: 8px;
//     box-shadow: 0 4px 200px rgb(242, 180, 180);

//     &:hover{
//         background: rgb(234, 189, 156);
//         border-radius: 8px;
//         box-shadow: 0 4px 200px rgb(242, 180, 180);
//         transition: background-color 0.3s ease;
//     }
//     &:active{
//         background: rgb(241, 239, 239);
//         border-radius: 8px;
//         box-shadow: 0 4px 10px rgb(244, 209, 161);
//         transition: background-color 0.3s ease;
//     }
// }
