$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


//!App
@media (min-width: 0px) and (max-width: 799px){
    .popup_p-addNote-Entities_TO_Popup{
        width: 96%;
        height: 96%;
        position: fixed;
        top: 2%;
        // left: 10%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 8px 2px 2px 2px;
        box-shadow: 0 0 4px rgb(134, 179, 116);
        z-index: 10000;
        overflow: auto;
        display: flex;
        align-items: center;
        // text-align: center;
        // justify-content: center;
    }
    .popup_s-addNote-Entities_TO_Popup{
        width: 100%;
        height: 100%;
        top: 10%;
        // left: 20%;
        // border: 1px solid #000;
        // background: #ffdcdc;
        padding: 2px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border: none;
        z-index: 10010;
        overflow: auto;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // text-align: center;
    }

    //budget
    .popup_p-2-Entities_TO_Popup{
        width: 90%;
        // height: 60%;
        position: fixed;
        top: 12%;
        left: 4%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        box-shadow: 0 0 10px rgb(71, 124, 248);
        z-index: 10010;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .popup_p-3-Entities_TO_Popup{
        width: 90%;
        // height: 60%;
        position: fixed;
        top: 14%;
        left: 4%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        box-shadow: 0 0 4px rgb(253, 195, 124);
        z-index: 10010;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
}
//!website
@media (min-width: 800px){
    .popup_p-addNote-Entities_TO_Popup{
        width: 80%;
        height: 92%;
        position: fixed;
        top: 3%;
        left: 10%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        box-shadow: 0 0 4px rgb(134, 179, 116);
        z-index: 10000;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .popup_s-addNote-Entities_TO_Popup{
        width: 98%;
        height: 98%;
        top: 10%;
        left: 20%;
        // border: 1px solid #000;
        // background: #ffdcdc;
        padding: 20px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border: none;
        z-index: 10010;
        overflow: auto;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // text-align: center;
    }

    //budget
    .popup_p-2-Entities_TO_Popup{
        width: 50%;
        height: 60%;
        position: fixed;
        top: 12%;
        left: 26%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        box-shadow: 0 0 10px rgb(71, 124, 248);
        z-index: 10010;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .popup_p-3-Entities_TO_Popup{
        width: 40%;
        height: 60%;
        position: fixed;
        top: 14%;
        left: 30%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        box-shadow: 0 0 4px rgb(253, 195, 124);
        z-index: 10010;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
}


//!popup container
// .popup_p-addNote-Entities_TO_Popup{
//     width: 80%;
//     height: 92%;
//     position: fixed;
//     top: 3%;
//     left: 10%;
//     border: none;
//     border-radius: 8px;
//     background: #fff;
//     padding: 20px;
//     box-shadow: 0 0 4px rgb(134, 179, 116);
//     z-index: 10000;
//     overflow: auto;
//     display: flex;
//     align-items: center;
//     text-align: center;
//     justify-content: center;
// }

// .popup_p-2-Entities_TO_Popup{
//     width: 50%;
//     height: 60%;
//     position: fixed;
//     top: 12%;
//     left: 26%;
//     border: none;
//     border-radius: 8px;
//     background: #fff;
//     padding: 20px;
//     box-shadow: 0 0 10px rgb(71, 124, 248);
//     z-index: 10010;
//     overflow: auto;
//     display: flex;
//     align-items: center;
//     text-align: center;
//     justify-content: center;
// }
// .popup_p-3-Entities_TO_Popup{
//     width: 40%;
//     height: 60%;
//     position: fixed;
//     top: 14%;
//     left: 30%;
//     border: none;
//     border-radius: 8px;
//     background: #fff;
//     padding: 20px;
//     box-shadow: 0 0 4px rgb(253, 195, 124);
//     z-index: 10010;
//     overflow: auto;
//     display: flex;
//     align-items: center;
//     text-align: center;
//     justify-content: center;
// }

// .popup_s-addNote-Entities_TO_Popup{
//     width: 98%;
//     height: 98%;
//     top: 10%;
//     left: 20%;
//     // border: 1px solid #000;
//     // background: #ffdcdc;
//     padding: 20px;
//     // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//     border: none;
//     z-index: 10010;
//     overflow: auto;
//     display: flex;
//     flex-direction: column;
//     // align-items: center;
//     // text-align: center;
// }
.popup_s-addNote-Entities_TO_Popup::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.popup_s-addNote-Entities_TO_Popup::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.popup_s-addNote-Entities_TO_Popup::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  


//!Budget
.heading-1-budget-Entities_TO_Popup{
    font-size: 18px;
    // font-family: $font-6;
    font-family: 'Satisfy';
    letter-spacing: 2px;
    word-spacing: 0.4px;
    font-weight: bold;
    // color: rgb(242, 83, 83);
    color: rgba(14, 95, 234, 0.533);
    // margin-left: 6%;
    // margin-top: 1%;
}

//interval
.heading-1-timeInterval-Entities_TO_Popup{
    font-family: $font-6;
    font-size: 18px;
    margin: 0px 0px 12px 0px;
    color: gray;
}
.heading-2-timeInterval-Entities_TO_Popup{
    font-family: $font-6;
    font-size: 18px;
    margin: 30px 0px 12px 0px;
    color: gray;
}
.select-1-timeInterval-Entities_TO_Popup{
    padding: 2px 4px 2px 4px;
    border-radius: 4px;
    border: 2px solid rgb(224, 219, 219);
    background: rgb(255, 255, 255);
    font-family: $font-3;
}