$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//



//!popup container
//?1
.popup_p-2-addNote-ViewPrices_CH_Mobile{
    width: 90%;
    height: 90%;
    position: fixed;
    top: 4%;
    left: 4%;
    border: none;
    border-radius: 8px;
    background: #ffffff;
    padding: 4;
    box-shadow: 0 0 10px rgba(180, 212, 255, 0.819);
    // z-index: 11000;
    z-index: 10000;
    overflow: auto;
    // display: flex;
    // align-items: center;
    // text-align: center;
    // justify-content: center;
}
.popup_s-2-addNote-ViewPrices_CH{
    width: 98%;
    height: 98%;
    top: 10%;
    left: 20%;
    // border: 1px solid #000;
    // background: #ffdcdc;
    padding: 20px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10010;
    overflow: auto;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // text-align: center;
}
.popup_s-2-addNote-ViewPrices_CH::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.popup_s-2-addNote-ViewPrices_CH::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.popup_s-2-addNote-ViewPrices_CH::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  



//
.conatiner_t-content-bestDeals-ViewPrices_CH_Mobile{
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-left: 6px;
    text-align: start;
    align-items: start;
    // height: 200%;
    // background: lightcoral;
}