$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


.container_p-QuickTravelCategories{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4%;
    position: relative;
}

// .container_s-QuickTravelCategories{
//     width: 50%;
//     // border: 2px lightgray solid;
//     padding: 20px 20px 10px 20px;
//     border-radius: 12px;
//     display: flex;
//     flex-direction: column;
//     align-items: self-start;
//     text-align: start;
//     box-shadow: 0 0 4px rgba(218, 218, 218, 0.678); 
// }


@media (min-width: 0px) and (max-width: 499px){
   .container_s-QuickTravelCategories{
        width: 90%;
        margin-top: 4%;
        margin-bottom: 10%;
        // border: 2px lightgray solid;
        padding: 20px 20px 10px 20px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        text-align: start;
        box-shadow: 0 0 4px rgba(218, 218, 218, 0.678); 
    } 
}
@media (min-width: 500px) and (max-width: 1000px){
    .container_s-QuickTravelCategories{
        width: 86%;
        // border: 2px lightgray solid;
        padding: 20px 20px 10px 20px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        text-align: start;
        box-shadow: 0 0 4px rgba(218, 218, 218, 0.678); 
    }
}
@media (min-width: 1000px){
    .container_s-QuickTravelCategories{
        width: 60%;
        // border: 2px lightgray solid;
        padding: 20px 20px 10px 20px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        text-align: start;
        box-shadow: 0 0 4px rgba(218, 218, 218, 0.678); 
    }
}




.heading-1-UNI-QuickTravelCategories{
    color: rgb(140, 202, 7);
    letter-spacing: 0.4px;
    font-family: $font-6;
    margin-top: 5%;
}

//!#1 - Create Ai Itinerary
.container_p-CAI-QuickTravelCategories{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding: 4px 8px 4px 8px;
    border-radius: 16px;
    border: 1px solid rgb(214, 214, 214);
    position: relative;
    background: none;

    &:hover{
        box-shadow: 0 0 10px rgba(255, 181, 181, 0.678); 
        transition: box-shadow 0.3s ease;
    }
    &:active{
        box-shadow: 0 0 8px rgba(147, 103, 249, 0.3); 
        transition: box-shadow 0.3s ease;
    }
}
.img-start-QuickTravelCategories{
    width: 30px;
    height: 30px;
    margin-right: 12px;
}
.text-1-QuickTravelCategories{
    color: rgb(147, 3, 147);
    font-size: 18px;
    font-family: $font-content-1;
    letter-spacing: 0.4px;
}
.img-colorBomb-QuickTravelCategories{
    width: 50px;
    height: 50px;
    right: -8px;
    z-index: -1;
    position: absolute;
}

//!#2 - Travel Categories
.conatiner_p-TravelCategory-QuickTravelCategories{
    margin-top: 1%;
    // border: 1px solid yellowgreen;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.conatiner_p-button-TravelCategory-QuickTravelCategories{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin-top: 2%;
    margin-right: 12px;
    border-radius: 14px;
    background: rgb(255, 212, 195);
    padding: 2px 6px 2px 6px;
    font-size: 14px;
    border: none;

    &:hover{
        box-shadow: 0 0 4px rgba(158, 158, 158, 0.3); 
        background-color: rgb(255, 224, 212);
        transition: background-color 0.3s ease;
    }
    &:active{
        // box-shadow: 0 0 8px rgba(255, 202, 202, 0.528); 
        background-color: rgb(255, 165, 130);
        transition: background-color 0.3s ease;
    }
}
.text-1-TravelCategory-QuickTravelCategories{
    font-family: $font-6;
    margin-right: 12px;
}
.icon-1-TravelCategory-QuickTravelCategories{
    font-size: 18px;
}

//!#3 - Travel Modes
.conatiner_p-TravelModes-QuickTravelCategories{
    margin-top: 2%;
    // border: 1px solid yellowgreen;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.conatiner_p-button-TravelModes-QuickTravelCategories{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin-top: 2%;
    margin-right: 12px;
    border-radius: 14px;
    background: rgb(233, 233, 233);
    padding: 2px 10px 2px 10px;
    font-size: 14px;
    border: none;

    &:hover{
        box-shadow: 0 0 4px rgba(158, 158, 158, 0.3); 
        background-color: rgb(255, 224, 212);
        transition: background-color 0.3s ease;
    }
    &:active{
        // box-shadow: 0 0 8px rgba(255, 202, 202, 0.528); 
        background-color: rgb(255, 165, 130);
        transition: background-color 0.3s ease;
    }
}
.text-1-TravelModes-QuickTravelCategories{
    font-family: $font-6;
    margin-right: 12px;
}
.icon-1-TravelModes-QuickTravelCategories{
    font-size: 18px;
}