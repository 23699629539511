$image-heading-width: 1000px;
$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//


//heading decor
@media (min-width: 0px) and (max-width: 799px){
    .conatiner_p-Destinations-Overview_D_4{
        display: flex;
        flex-direction: row;
        // border: 1px solid rgb(74, 159, 12);
        justify-content: space-around;
        bottom: 0px;
        width: 100%;
        align-items: bottom;
        margin-top: 10%;
    }
    .conatiner_t-1-Images-Destinations-Overview_D_4{
        display: none;
    }
    .conatiner_t-2-heading-Destinations-Overview_D_4{
        // align-self:self-end;
        // margin-bottom: 20px;
        // margin-left: -50px;
        // align-self: baseline;
    } 
}
@media (min-width: 800px) and (max-width: 1099px){
    .conatiner_p-Destinations-Overview_D_4{
        display: flex;
        flex-direction: row;
        // border: 10px solid rgb(74, 159, 12);
        justify-content: space-around;
        bottom: 0px;
        width: 100%;
        align-items: bottom;
    }
    .conatiner_t-2-heading-Destinations-Overview_D_4{
        align-self:self-end;
        margin-bottom: 20px;
        margin-left: -50px;
    } 
}
@media (min-width: 1100px){
    .conatiner_p-Destinations-Overview_D_4{
        display: flex;
        flex-direction: row;
        // border: 1px solid rgb(74, 159, 12);
        justify-content: space-around;
        bottom: 0px;
        width: 70%;
        align-items: bottom;
    }
    .conatiner_t-2-heading-Destinations-Overview_D_4{
        align-self:self-end;
        margin-bottom: 20px;
        margin-left: -50px;
    } 
}

// .conatiner_p-Destinations-Overview_D_4{
//     display: flex;
//     flex-direction: row;
//     border: 1px solid rgb(74, 159, 12);
//     justify-content: space-around;
//     bottom: 0px;
//     width: 70%;
//     align-items: bottom;
// }

// .conatiner_p-Destinations-Overview_D_4{
//     display: flex;
//     flex-direction: row;
//     // border: 1px solid rgb(74, 159, 12);
//     justify-content: space-around;
//     bottom: 0px;
//     width: 70%;
//     align-items: bottom;
// }
// .conatiner_t-2-heading-Destinations-Overview_D_4{
//     align-self:self-end;
//     margin-bottom: 20px;
//     margin-left: -50px;
//     // border: 1px solid rgb(172, 175, 170);
//     // width: 100%;
//     // width: fit-content;
// } 
.conatiner_t-3-Image-Destinations-Overview_D_4{
    align-items:bottom;
    text-align: bottom; 
    display: flex;
    margin-bottom: 20px;
}
//images
.image-1-Destinations-Overview_D_4{
    width: 180px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
}
.image-2-Destinations-Overview_D_4{
    width: 86px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    margin: 2px;
}
.image-3-Destinations-Overview_D_4{
    width: 86px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    margin: 2px;
    align-self:self-end;
}
.image-4-Destinations-Overview_D_4{
    width: 120px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    margin: 2px;
    align-self:self-end;
    border: 1px solid rgb(74, 159, 12);
}

.heading-Destinations-Overview_D_4{
    text-align: bottom;
    bottom: 100px;
    border: 1px solid rgb(74, 159, 12);
}

.heading-1-Destinations-Overview_D_4{
    font-family: $font-6;
    font-size: 28px;
    // font-weight: bold;
    margin: 4%;
    width: 100%;
}
.heading-2-Destinations-Overview_D_4{
    font-family: $font-6;
    // font-weight: bold;
    font-size: 28px;
    color: orangered;
    margin: 4%;
}

//!Mobile
@media (min-width: 0px) and (max-width: 799px){
    .container_p-district-state-Overview_D_4{
        width: 100%;
        // border: 1px solid orange;
        border-radius: 8px;
        margin-top: 8%;
    }
    .container_s-districts-state-Overview_D_4{
        display: flex;
        // flex-wrap: wrap;
        // justify-content: space-around;
        overflow-x: scroll;
        flex-direction: row;
    }
    .card-district-state-Overview_D_4{
        // width: 40%;
        // background-color: lightgoldenrodyellow;
        padding: 1%;
        margin: 6%;
        width: 200px;
        // height: 220px;
    }
    .content-1-card-district-state-Overview_D_4{
        font-family: $font-content-1;
        font-size: 14px;
        margin: 2% 2% 0% -2%;
        width: 200px;
        //  border: 10px yellowgreen solid;
    }
}
//!Website
@media (min-width: 800px){
    .container_p-district-state-Overview_D_4{
        width: 80%;
        border: 1px solid orange;
        border-radius: 8px;
        margin-top: 4%;
    }
    .container_s-districts-state-Overview_D_4{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        // overflow-x: scroll;
        // flex-direction: row;
    }
    .card-district-state-Overview_D_4{
        // width: 40%;
        // background-color: lightgoldenrodyellow;
        padding: 1%;
        margin: 2%;
        width: 200px;
        // height: 220px;
    }
    .content-1-card-district-state-Overview_D_4{
        font-family: $font-content-1;
        font-size: 14px;
        margin: 2% 2% 0% -2%;
        // width: 200px;
        //  border: 10px yellowgreen solid;
    }
}


//!State
//?
// Districts
// .container_p-district-state-Overview_D_4{
//     width: 80%;
//     border: 1px solid orange;
//     border-radius: 8px;
//     margin-top: 4%;
// }
.heading-district-state-Overview_D_4{
    font-family: $font-4;
    font-size: 20px;
    font-weight: bold;
    margin: 2% 2% 2% 6%;
    color: rgb(0, 0, 0);
}
//container for the districts

// single district
// .card-district-state-Overview_D_4{
//     // width: 40%;
//     // background-color: lightgoldenrodyellow;
//     padding: 1%;
//     margin: 2%;
//     width: 200px;
//     // height: 220px;
// }
//image container
.conatiner_t-image-card-district-state-Overview_D_4{
    // width: 460px;
    // height: 220px;
    height: 260px;
    margin: -7px;
    box-shadow: 0 4px 8px rgba(93, 92, 92, 0.2);
}
//image
.image-card-district-state-Overview_D_4{
    width: 100%;
    height: 100%;
    object-fit: cover; 
    border-radius: 10px;
    position: relative;
}
.overvlay-card-district-state-Overview_D_4{
    z-index: 1;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.1) 100%);
    position: absolute;
    // border: 10px yellowgreen solid;
    width: 192px;
    height: 258px;
    border-radius: 8px;
    display: none;
}

.heading-1-card-district-state-Overview_D_4{
    font-family: $font-6;
    margin: 12% 2% 0% -2%;
    font-size: 20px;
    // border: 10px yellowgreen solid;
}
.text-1-card-district-state-Overview_D_4{
    font-family: $font-6;
    margin: 6% 2% 0% 2%;
    font-size: 16px;
    color: rgb(97, 94, 94);
    // border: 10px yellowgreen solid;
}
// .content-1-card-district-state-Overview_D_4{
//     font-family: $font-content-1;
//     font-size: 14px;
//     margin: 2% 2% 0% -2%;
//     width: 200px;
//     //  border: 10px yellowgreen solid;
// }




// $image-heading-width: 1000px;
// $font-heading-1: 'Oxygen';
// $font-heading-2: 'Finlandica';
// $font-content-1: 'Nunito Sans';
// $font-content-2: 'Rubik';
// $font-3: 'Quicksand';
// $font-4: 'Comfortaa';
// $font-5: 'Nanum Gothic';
// $font-6: 'Josefin Sans';
// //

// .conatiner_p-Destinations-Overview_D_4{
//     display: flex;
//     flex-direction: row;
//     // border: 1px solid rgb(74, 159, 12);
//     justify-content: space-around;
//     bottom: 0px;
//     width: 70%;
//     align-items: bottom;
// }
// .conatiner_t-2-heading-Destinations-Overview_D_4{
//     align-self:self-end;
//     margin-bottom: 20px;
//     margin-left: -50px;
//     // border: 1px solid rgb(172, 175, 170);
//     // width: 100%;
//     // width: fit-content;
// } 
// .conatiner_t-3-Image-Destinations-Overview_D_4{
//     align-items:bottom;
//     text-align: bottom; 
//     display: flex;
//     margin-bottom: 20px;
// }
// //images
// .image-1-Destinations-Overview_D_4{
//     width: 180px;
//     height: 150px;
//     object-fit: cover;
//     border-radius: 8px;
// }
// .image-2-Destinations-Overview_D_4{
//     width: 86px;
//     height: 100px;
//     object-fit: cover;
//     border-radius: 8px;
//     margin: 2px;
// }
// .image-3-Destinations-Overview_D_4{
//     width: 86px;
//     height: 100px;
//     object-fit: cover;
//     border-radius: 8px;
//     margin: 2px;
//     align-self:self-end;
// }
// .image-4-Destinations-Overview_D_4{
//     width: 120px;
//     height: 100px;
//     object-fit: cover;
//     border-radius: 8px;
//     margin: 2px;
//     align-self:self-end;
//     border: 1px solid rgb(74, 159, 12);
// }

// .heading-Destinations-Overview_D_4{
//     text-align: bottom;
//     bottom: 100px;
//     border: 1px solid rgb(74, 159, 12);
// }

// .heading-1-Destinations-Overview_D_4{
//     font-family: $font-6;
//     font-size: 28px;
//     // font-weight: bold;
//     margin: 4%;
//     width: 100%;
// }
// .heading-2-Destinations-Overview_D_4{
//     font-family: $font-6;
//     // font-weight: bold;
//     font-size: 28px;
//     color: orangered;
//     margin: 4%;
// }



// //!State
// //?
// // Districts
// .container_p-district-state-Overview_D_4{
//     width: 80%;
//     border: 1px solid orange;
//     border-radius: 8px;
//     margin-top: 4%;
// }
// .heading-district-state-Overview_D_4{
//     font-family: $font-4;
//     font-size: 24px;
//     font-weight: bold;
//     margin: 2%
// }
// //container for the districts
// .container_s-districts-state-Overview_D_4{
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-around;
    
// }
// // single district
// .card-district-state-Overview_D_4{
//     // width: 40%;
//     background-color: lightgoldenrodyellow;
//     padding: 1%;
//     margin: 2%;
// }
// //image container
// .conatiner_t-image-card-district-state-Overview_D_4{
//     width: 460px;
//     height: 220px;
//     margin: 2%
// }
// //image
// .image-card-district-state-Overview_D_4{
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     border-radius: 8px;
// }
// .heading-1-card-district-state-Overview_D_4{
//     font-family: $font-6;
//     margin: 4% 2% 2% 2%;
//     font-size: 18px;
// }
// .text-1-card-district-state-Overview_D_4{
//     font-family: $font-6;
//     margin: 2%;
//     color: rgb(73, 71, 71);
// }
// .content-1-card-district-state-Overview_D_4{
//     font-family: $font-3;
//     font-size: 15px;
//     margin: 2%;
// }