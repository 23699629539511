$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//

// .conatiner_p-About_D{
//     width: 100%;
//     // border: 1px solid yellowgreen;
//     align-self: center;
// }

//!Moible
@media (min-width: 0px) and (max-width: 799px){
    .conatiner_p-About_D{
        width: 100%;
        // border: 1px solid yellowgreen;
        align-self: center;
    }

    //*food
    .heading-food-About_D{
        align-self: center;
        font-size: 20px;
        font-family: $font-6;
        font-weight: bold;
        margin: 8% 4% 4% 6%;
    }
    .container_t-food-About_D{
        // width: 100%;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        overflow-y: hidden;
        // flex-wrap: wrap;
        // border: 1px solid goldenrod;
        // justify-content: space-around;
    }
    .card-food-About_D{
        // width: 100px;
        margin: 16px 12px 2px 6px;
        border-radius: 8px;
        padding: 2%;
        box-shadow: 0 4px 8px rgba(242, 175, 98, 0.4);
    }
    .content-2-Overview-About_D{
        align-self: center;
        font-size: 16px;
        font-family: $font-content-1;
        margin: 2% 2% 2% 0%;
        letter-spacing: 0.2px;
        width: 200px;
    }
    .content-2-2-Overview-About_D{
        align-self: center;
        font-size: 16px;
        font-family: $font-content-1;
        margin: 2% 2% 2% 0%;
        letter-spacing: 0.2px;
        // width: 200px;
    }
    .heading-attractions-Overview-About_D{
        align-self: center;
        font-size: 20px;
        font-family: $font-content-2;
        margin: 4% 4% 4% 8%;
    }
    .content-1-Overview-About_D{
        align-self: center;
        font-size: 16px;
        // font-family: $font-content-1;
        font-family: $font-3;
        margin: 2% 2% 2% 6%;
        letter-spacing: 0.6px;
        word-spacing: 0.6px;
        color: rgb(96, 94, 94);
        font-weight: 600;
        
        // background: rgb(255, 254, 249);
        box-shadow: rgba(184, 132, 73, 0.364) 0px 2px 8px 0px;
        padding: 1% 2% 1% 2%;
        border-radius: 6px;
    }
}
//!Web
@media (min-width: 800px){
    .conatiner_p-About_D{
        width: 80%;
        // border: 1px solid yellowgreen;
        align-self: center;
    }

    //*food
    .heading-food-About_D{
        align-self: center;
        font-size: 20px;
        font-family: $font-6;
        font-weight: bold;
        margin: 8% 4% 4% 6%;
    }
    .container_t-food-About_D{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border: 1px solid goldenrod;
        justify-content: space-around;
    }
    .card-food-About_D{
        width: 24%;
        margin: 16px 2px 2px 6px;
        border-radius: 8px;
        padding: 2%;
        box-shadow: 0 4px 8px rgba(255, 204, 147, 0.4);
    }
    .content-2-Overview-About_D{
        align-self: center;
        font-size: 16px;
        font-family: $font-content-1;
        margin: 2% 2% 2% 0%;
        letter-spacing: 0.2px;
    }
    .content-2-2-Overview-About_D{
        align-self: center;
        font-size: 16px;
        font-family: $font-content-1;
        margin: 2% 2% 2% 0%;
        letter-spacing: 0.2px;
        // width: 200px;
    }
    .heading-attractions-Overview-About_D{
        align-self: center;
        font-size: 20px;
        font-family: $font-content-2;
        margin: 4% 4% 4% 6%;
    }
    .content-1-Overview-About_D{
        align-self: center;
        font-size: 16px;
        // font-family: $font-content-1;
        font-family: $font-3;
        margin: 2% 2% 2% 6%;
        letter-spacing: 0.6px;
        word-spacing: 0.6px;
        color: rgb(96, 94, 94);
        font-weight: 600;
    }
}

//overview
.conatiner_p-Overview-About_D{
    // background: yellowgreen;
    // width: 100%;

}
.heading-0-Overview-About_D{
    align-self: center;
    font-size: 20px;
    font-family: $font-6;
    font-weight: bold;
    margin: 4%;
}
.heading-1-Overview-About_D{
    align-self: center;
    font-size: 20px;
    font-family: $font-content-2;
    margin: 4%;
    // background: yellowgreen;
}
.heading-history-Overview-About_D{
    align-self: center;
    font-size: 20px;
    font-family: $font-content-2;
    margin: 4% 4% 4% 6%;
}
// .heading-attractions-Overview-About_D{
//     align-self: center;
//     font-size: 20px;
//     font-family: $font-content-2;
//     margin: 4% 4% 4% 6%;
// }
.heading-climate-Overview-About_D{
    align-self: center;
    font-size: 20px;
    font-family: $font-content-2;
    margin: 4% 4% 4% 6%;
}
.heading-2-Overview-About_D{
    align-self: center;
    font-size: 18px;
    font-family: $font-heading-2;
    margin: 4% 2% 2% 0%;
    color: rgb(116, 5, 5);
    letter-spacing: 0.2px;
}
.heading-3-Overview-About_D{
    align-self: center;
    font-size: 18px;
    font-family: $font-heading-2;
    margin: 4% 2% 2% 0%;
    color: rgb(193, 126, 11);
    letter-spacing: 0.2px;
}
.heading-4-Overview-About_D{
    align-self: center;
    font-size: 18px;
    font-family: $font-heading-2;
    margin: 4% 2% 2% 0%;
    color: rgb(116, 5, 5);
    letter-spacing: 0.2px;
}
// .content-1-Overview-About_D{
//     align-self: center;
//     font-size: 16px;
//     // font-family: $font-content-1;
//     font-family: $font-3;
//     margin: 2% 2% 2% 6%;
//     letter-spacing: 0.6px;
//     word-spacing: 0.6px;
//     color: rgb(96, 94, 94);
//     font-weight: 600;
// }
// .content-2-Overview-About_D{
//     align-self: center;
//     font-size: 16px;
//     font-family: $font-content-1;
//     margin: 2% 2% 2% 0%;
//     letter-spacing: 0.2px;
// }

// //*food
// .heading-food-About_D{
//     align-self: center;
//     font-size: 20px;
//     font-family: $font-6;
//     font-weight: bold;
//     margin: 8% 4% 4% 6%;
// }
// .container_t-food-About_D{
//     width: 100%;
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     border: 1px solid goldenrod;
//     justify-content: space-around;
// }
// .card-food-About_D{
//     width: 24%;
//     margin: 16px 2px 2px 6px;
//     border-radius: 8px;
//     padding: 2%;
//     box-shadow: 0 4px 8px rgba(255, 204, 147, 0.4);
// }

//*must know things
.heading-mustKnow-About_D{
    align-self: center;
    font-size: 20px;
    font-family: $font-6;
    font-weight: bold;
    margin: 8% 4% 4% 6%;
}
//*travel modes
.heading-travelMode-About_D{
    align-self: center;
    font-size: 20px;
    font-family: $font-6;
    font-weight: bold;
    margin: 8% 4% 4% 6%;
}

//!headers

//?Overview
.conatiner_p-overview-header-About_D{
    // width: 90%;
    // border: 10px solid yellow;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 8%;

    width: 140%;
    margin-left: -22%;
}
.image-1-overview-header-About_D{
    width: 180px;
     border: 1px solid rgb(255, 231, 190);
    width: 33%;
    height: 160px;
    border-radius: 6px;
    margin: 4px;
    object-fit: cover;
    // box-shadow: 0 4px 8px rgba(93, 92, 92, 0.2);
    box-shadow: 0 4px 8px rgba(246, 255, 177, 0.4);

    height: 240px;
}

//?History
.conatiner_p-history-header-About_D{
    // width: 90%;
    // border: 10px solid yellow;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 8%;
    border-radius: 6px;
    width: 100%;
    height: 160px;
    overflow: hidden;
}
.image-1-history-header-About_D{
    width: 700px;
    // height: 160px;
    border: 1px solid rgb(255, 231, 190);
    border-radius: 6px;
    margin: 24px 4px 4px 4px;
    object-fit: cover;
    // box-shadow: 0 4px 8px rgba(93, 92, 92, 0.2);
    box-shadow: 0 4px 8px rgba(246, 255, 177, 0.4);

    height: 240px;
}
