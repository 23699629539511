$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

.conatiner_p-AddNotes_TO{
    margin: 2% 0% 4% 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.heading-1-TripDetails-AddNotes_TO{
    font-family: $font-6;
    font-size: 18px;
    color: orangered;
}

.container_p-heading-tripDetails-AddNotes_TO{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-top: 3%;
    margin-bottom: 14px;
    // border: 1px solid green;
    // background: rgb(249, 248, 248);
    border-radius: 12px;
    width: fit-content;
    padding: 4px 8px 2px 8px;
    cursor: pointer;

    &:hover{
        background-color: rgb(255, 238, 228);
        transition: background-color 0.3s ease;
    }
    
    &:active{
        background-color: rgb(232, 230, 230);
        transition: background-color 0.3s ease;
    }
}
.heading-TripDetails-AddNotes_TO{
    font-family: $font-6;
    font-size: 16px;
    color: rgb(62, 61, 61);
}

.button-edit-tripDetails-AddNotes_TO{
    border: none;
    background: none;
}
.image-TripDetails-AddNotes_TO{
    width: 20px;
    height: 20px;
    margin-left: 10px;

    background: rgb(255, 237, 223);
    border-radius: 8px;
    box-shadow: 0 4px 200px rgb(242, 180, 180);

    &:hover{
        background: rgb(234, 189, 156);
        border-radius: 8px;
        box-shadow: 0 4px 200px rgb(242, 180, 180);
        transition: background-color 0.3s ease;
    }
    &:active{
        background: rgb(241, 239, 239);
        border-radius: 8px;
        box-shadow: 0 4px 10px rgb(244, 209, 161);
        transition: background-color 0.3s ease;
    }
}


//!Notes Display
.container_p-displayNotes-AddNotes_TO{
    width: 100%;
    margin-top: 2%;
    // border: 1px solid cornflowerblue;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container_p-headings-displayNotes-AddNotes_TO{
    display: flex;
    flex-direction: row;
    width: 70%;
    // border: 1px solid yellowgreen;
    font-family: $font-4;
    flex-wrap: wrap;
    justify-content: center;
    // justify-content: space-around;
}
.conatiner_t-active-notesHeading-displayNotes-AddNotes_TO{
    margin: 0px 6px 12px 2px;
    padding: 2px 6px 2px 6px;
    border-radius: 8px;
    background: rgb(246, 214, 189);
    border: none;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;

    &:hover{
        background-color: rgb(255, 229, 229);
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(255, 255, 255);
        transition: background-color 0.3s ease;
    }
}
.conatiner_t-unActive-notesHeading-displayNotes-AddNotes_TO{
    margin: 0px 6px 12px 2px;
    padding: 2px 6px 2px 6px;
    border-radius: 10px;
    background: rgb(253, 252, 252);
    border: 0.4px solid whitesmoke;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;

    &:hover{
        background-color: rgb(255, 229, 229);
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(255, 255, 255);
        transition: background-color 0.3s ease;
    }
}
.icon-1-notesHeading-displayNotes-AddNotes_TO{
    margin-right: 6px;
    font-size: 16px;
    margin-top: -4px;
}
.heading-1-notesHeading-displayNotes-AddNotes_TO{
    margin-right: 6px;
    font-family: $font-content-2;
}

//!single notes
.container_p-singleNote-displayNotes-AddNotes_TO{
    // border: 1px solid red;
    width: 100%;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
//?manage note - edit/delete
.container_t-manage-singleNote-displayNotes-AddNotes_TO{
    // border: 1px solid brown;
    flex-direction: row;
    align-items: start;
    text-align: start;
    justify-content: start;
    width: 68%;
    display: flex;
    flex-direction: row;
}
.button-edit-singleNote-displayNotes-AddNotes_TO{
    border: none;
    background: none;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    padding: 1px 8px 1px 10px;
    border-radius: 6px;
    // background-color: whitesmoke;
    background: rgb(245, 242, 242);

    &:hover{
        background-color: rgb(255, 229, 229);
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(255, 255, 255);
        transition: background-color 0.3s ease;
    }
}
.button-delete-singleNote-displayNotes-AddNotes_TO{
    border: none;   
    background: none;   
    margin-right: 12px;
    display: flex;
    flex-direction: row;
    padding: 1px 8px 1px 10px;
    border-radius: 6px;
    // background-color: whitesmoke;
    background: rgb(245, 242, 242);

    &:hover{
        background-color: rgb(255, 229, 229);
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(255, 255, 255);
        transition: background-color 0.3s ease;
    }
}
.text-edit-singleNote-displayNotes-AddNotes_TO{

}
.text-delete-singleNote-displayNotes-AddNotes_TO{

}
.image-singleNote-displayNotes-AddNotes_TO{
    width: 16px;
    height: 16px;
    margin-left: 10px;

    background: rgb(223, 218, 218);
    border-radius: 8px;
    box-shadow: 0 4px 200px rgb(255, 255, 255);

    &:hover{
        background: rgb(244, 233, 225);
        border-radius: 8px;
        box-shadow: 0 4px 200px rgb(242, 180, 180);
        transition: background-color 0.3s ease;
    }
    &:active{
        background: rgb(241, 239, 239);
        border-radius: 8px;
        box-shadow: 0 4px 10px rgb(244, 209, 161);
        transition: background-color 0.3s ease;
    }
}

//?single note
.container_p-note-singleNote-displayNotes-AddNotes_TO{
    border: 2px solid rgb(246, 246, 246);
    border-radius: 6px;
    padding: 10px 10px 10px 10px;

    width: 70%;
    min-height: 10px;
    max-height: 500px;

    margin-top: 3%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    // overflow: hidden;
}
.container_t-note-singleNote-displayNotes-AddNotes_TO{
    border-radius: 4px;
    // margin: 8px 8px 8px 8px;
    // padding: 8px 8px 8px 8px;

    width: 98%;
    // height: 50%;
    min-height: 10px;
    max-height: 460px;
    border: none;
    
    font-size: 14px;
    font-family: $font-4;
    color: rgb(72, 72, 72);
    letter-spacing: 0.2px;
    
    align-items: start;
    text-align: start;
    overflow-x: hidden;
    overflow-y: scroll;
}
.container_t-note-singleNote-displayNotes-AddNotes_TO::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.container_t-note-singleNote-displayNotes-AddNotes_TO::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.container_t-note-singleNote-displayNotes-AddNotes_TO::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  