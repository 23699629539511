$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//



@media (min-width: 0px) and (max-width: 799px){

    .popup-background-1-TouristPlaces_VM {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 10000000;
    }


    //!popup container
    .popup_p-addNote-TouristPlaces_VM{
        width: 98%;
        height: 98%;
        top: 1%;
        left: 1%;
        position: fixed;
        border: none;
        border-radius: 8px;
        background: #fff;
        // padding: 20px;
        padding: 12px 2px 12px 2px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000000000000;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .popup_s-addNote-TouristPlaces_VM{
        width: 98%;
        height: 98%;
        // top: 10%;
        // left: 20%;
        // border: 1px solid #000;
        // background: #ffdcdc;
        padding: 2px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        // z-index: 10000000000;
        overflow-y: scroll;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // text-align: center;
    }
    //close button
    .button-close-popup-TouristPlaces_VM{
        font-size: 32px;
        border: none;
        background: none;
        position: absolute;
        right: 6%;
        top: 2%;

        &:hover{
            color: red;
            transition: color 0.3s ease;
        }
        &:active{
            color: orange;
            transition: color 0.3s ease;
        }
    }


    // //!popup container
    .popup_s-addNote-TouristPlaces_VM::-webkit-scrollbar {
        width: 5px;
        scrollbar-width: 0px;
        border-radius: 60px;
    }
    .popup_s-addNote-TouristPlaces_VM::-webkit-scrollbar-track-piece {
        background-color: #e0dfde;
    }
    .popup_s-addNote-TouristPlaces_VM::-webkit-scrollbar-thumb:vertical {
        height: 30px;
        width: 200px;
        background-color: #868584;
        border-radius: 60px;  
    }  

    //
    .container_t-heading-TouristPlaces_VM{
        // margin: 4px 2px 2px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 100%;
        // border: 1px solid rgb(207, 201, 201);
        // margin-left: 6.4%;
    }
    .container_p-ratings-TouristPlaces_VM{
        margin-top: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }
    .container_t-placeName-TouristPlaces_VM{
        // margin: 4px 2px 2px 10px;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        text-align: start;
        align-items: start;
        width: 100%;
        // border: 1px solid rgb(207, 201, 201);
        margin-left: 6.4%;
    }
    .heading-1-heading-TouristPlaces_VM{
        align-self: start;
        text-align: start;
        font-family: $font-6;
        font-size: 20px;
        color: brown;
        // color: rgb(88, 87, 87);
    }
    .heading-rating-heading-TouristPlaces_VM{
        margin: 0.2% 6px 0 0px;
    }
    .heading-2-heading-TouristPlaces_VM{
        font-family: $font-6;
        margin: 0px 6px 0px 0px;
        color: rgb(88, 87, 87);
    }
    .heading-3-heading-TouristPlaces_VM{
        font-family: $font-6;
        // margin: 6px 2px 2px 12px;
        color: rgb(88, 87, 87);
    }






    //* Images 
    .container_p-images-TouristPlaces_VM{
        width: 100%;
        // border: 1px solid greenyellow;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 1%;
    }
    //?conatiner 1
    .container_s-1-images-TouristPlaces_VM{
        width: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        // flex-direction: row;
        // overflow-y: hidden;
        // overflow-x: scroll;
        // border: 4px solid rgb(248, 255, 234);
        // border: 2px solid olivedrab;
        border-radius: 16px;
    }
    .container_s-2-images-TouristPlaces_VM{
        width: 98%;
        display: flex;
        flex-direction: row;
        overflow-y: hidden;
        overflow-x: scroll;
        // background: whitesmoke;
        border-radius: 8px;
    }
    .container_s-2-images-TouristPlaces_VM::-webkit-scrollbar {
        width: 20px;
        scrollbar-width: 4px;
        border-radius: 60px;
    }
    .container_s-2-images-TouristPlaces_VM::-webkit-scrollbar-track-piece {
        background-color: #ffffff;
    }
    .container_s-2-images-TouristPlaces_VM::-webkit-scrollbar-thumb:vertical {
        height: 4px;
        width: 200px;
        background-color: #868584;
        border-radius: 60px;  
    }  

    //?conatiner 2 and 3
    .scroll-images-TouristPlaces_VM{
        display: none;
        width: 4%;
        z-index: 4;
        align-items: center;
        text-align: center;
        align-self: center;
        font-size: 30px;
        border: none;
        background: none;

        &:hover{
            color: red;
            transition: color 0.3s ease;
            // background: whitesmoke;
            // border-radius: 50px;
            // padding: 2px 0px 0px 2px;
        }
        &:active{
            color: orange;
            transition: color 0.3s ease;
        }
    }


    .image-images-TouristPlaces_VM{
        margin: 8px;
        border-radius: 8px;
        // border: 2px solid olivedrab;
        // border: 4px solid rgb(248, 255, 234);
        // box-shadow: 1px 1px 4px rgb(119, 117, 117); 
        box-shadow: rgb(203, 203, 200) 0px 0px 5px;
        width: 600px; 
        height: 300px;
        object-fit: cover;
    }

    //?component
    .conatiner_p-components-TouristPlaces_VM{
        width: 100%;
        // background: whitesmoke;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 4%;
    }
    .conatiner_s-components-TouristPlaces_VM{
        width: 96%;
        // background: rgb(245, 245, 245);
        padding: 1% 2% 1% 2%;
        border-radius: 8px;
    }


    .mainContainer-TouristPlaces_VM{
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .popupOverlay-TouristPlaces_VM{
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #e6e6e6;
        z-index: -100000;
        opacity: 0.7;
    }

    //Active Navigation
    .container_p-Navigation-TouristPlaces_VM{
        display: flex;
        // flex-direction: column;
        flex-direction: row;
        flex-wrap: wrap;
        // background: rgb(255, 184, 158);
        width: 100%;
        border-radius: 8px;
        padding: 10px 0px 8px 0px;
        align-items: center;
        text-align: center;
        justify-content: center;
        // justify-content: space-around;
    }
    .button-active-Navigation-TouristPlaces_VM{
        font-size: 16px;
        font-weight: bold;
        font-family: $font-6;
        color: orangered;
        background: white;
        padding-bottom: 8px;
        border-bottom: 2px solid orange;
        border-top: none;
        border-right: none;
        border-left: none;
        margin: 12px 12px 0px 12px;
        // padding: 4px 6px 4px 6px;
        // border-radius: 10px;
        cursor: pointer;

        &:hover{
            color: #e17029;
            transition: color 0.3s ease;
            padding-bottom: 8px;
            border-bottom: 2px solid rgb(255, 0, 0);
        }
        &:active{
            color: #ff0202;
            transition: color 0.3s ease;
        }
    }
    .button-unActive-Navigation-TouristPlaces_VM{
        font-size: 16px;
        color: orangered;
        font-family: $font-6;
        border: none;
        background: none;
        cursor: pointer;
        border-top: none;
        border-right: none;
        border-left: none;
        margin: 12px 12px 0px 12px;

        &:hover{
            color: #e17029;
            transition: color 0.3s ease;
            padding-bottom: 8px;
            border-bottom: 2px solid rgb(255, 0, 0);
            // transition: border-bottom 0.2s ease;
        }
        &:active{
            color: #ff0202;
            transition: color 0.3s ease;
        }
    }


}