$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


//!popup container
//?1
.popup_p-2-addNote-Hotel_ViewMore_Mobile{
    width: 98%;
    height: 96%;
    position: fixed;
    top: 2%;
    left: 2%;
    border: none;
    border-radius: 8px;
    // background: #773939;
    padding: 2px;
    box-shadow: 0 0 10px rgba(34, 92, 137, 0.835);
    z-index: 10010;
    overflow: auto;
    // display: flex;
    // align-items: center;
    // text-align: center;
    // justify-content: center;
}
.popup_s-2-addNote-Hotel_ViewMore{
    width: 98%;
    height: 98%;
    top: 10%;
    left: 20%;
    // border: 1px solid #000;
    // background: #ffdcdc;
    padding: 20px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10020;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.popup_s-2-addNote-Hotel_ViewMore::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.popup_s-2-addNote-Hotel_ViewMore::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.popup_s-2-addNote-Hotel_ViewMore::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  

