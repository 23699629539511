$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//




//##2 - Image - Home Page
.container_p-Image_HomePage-Home{
  width: 100%;
  border: 1px salmon solid;
  text-align: center;
  margin-top: 2%;
}
.img_HomePage-Home{
  // width: 100%;
  // height: 300px;
  width: 500px;
  height: 300px;
  object-fit: fill;
  box-shadow: 0 0 8px rgba(55, 55, 55, 0.3); 
  border-radius: 8px;
}



.box1-header-Home{
    width: 100%;
    /* height: 100px; */
    border: 2px solid lightcoral;
    background: #FFF3DF;
    /* border-bottom: 1px lightcoral solid; */
    display: flex;
    flex-direction: row;
}
.logo-box1-Home{
  /* border: 1px solid gray; */
  margin: 0.5%;
  width: 10%;
  text-align: center;
  align-items: center;
  /* justify-content: space-around;
  display: flex; */
  cursor: pointer;
}
.knowMore-box1-Home{
  /* border: 1px solid rgb(232, 149, 149); */
  margin: 0.5%;
  width: 25%;

  text-align: center;
  align-items: center;
  display: flex;
  /* justify-content: space-around;
  cursor: pointer; */
}
.knowMore2-box1-Home{
  margin: 0.5%;
  width: 100%;
 /* border: 1px solid rgb(232, 149, 149); */
  text-align: center;
  align-items: center;
  justify-content: space-around;
  display: flex;
  /* cursor: pointer; */
}

.navLinks-box1-Home{
  /* border: 1px solid rgb(255, 0, 0); */
  margin: 0.5%;
  right: 0px;
  width: 60%;

  text-align: center;
  align-items: center;
  display: flex;
  justify-content:start;
 
  /* cursor: pointer; */
}
.navLinks2-box1-Home{
  /* border: 1px solid rgb(255, 0, 0); */
  margin: 0.5%;
  right: 0px;
  width: 70%;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  display: flex;

}

.navLink-button-box1-Home{
  border: none;
  background: none;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  transition: border-color 0.3s, box-shadow 0.3s;
  padding: 0.5% 1% 0.5% 1%;
}
.navLink-button-box1-Home:hover{
  border: orange 1px solid;
  border-radius: 11px;
  padding: 0.5% 1% 0.5% 1%;
  /* background-color: rgb(237, 237, 237); */
}
.navLinkTest-box1-Home{
  font-weight: normal;
  font-size: 16px;
  font-family: 'Space Grotesk';
  letter-spacing: 0.4px;
  font-weight: 500;
  color: #646464;
}
.navLinkTest2-box1-Home{
  font-weight: normal;
  font-size: 16px;
  font-family: 'Space Grotesk';
  letter-spacing: 0.4px;
  font-weight: 500;
  color: black;
}
.decideDestinationImage-Home{
  width: 18px;
  height: 18px;
  margin-left: 4px;
}
.knowMore-button-box1-Home{
  border: none;
  background: none;
  cursor: pointer;
  color: #999;
  font-weight: bold;
  font-size: 16px;
}
.logo-Home{
  width: 50px;
  height: 50px;
  border-radius: 10px;
  border: 2px rgb(255, 255, 255) solid;
  box-shadow: 0 0 8px rgba(55, 55, 55, 0.3); 
}

/* box 3 */
.box02-MainTripDivision-Home{
  margin-top: 1%;
  width: 100%;
  min-height: 100px;
  max-height: max-content;
  /* border: 2px solid lightcoral; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background: rgb(224, 224, 224); */
}
.box02-container-Home{
  /* margin-top: 1%; */
  width: 70%;
  /* min-height: 100px; */
  justify-content: start;
  max-height: max-content;
  /* border: 2px solid lightcoral; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background: rgb(224, 224, 224); */
}
.changeCountryCss-Home{
  border: none;
  border-radius: 8px;
  padding: 0.3% 0.8% 0.3% 0.8%;
}
.changeCountryCss-Home:hover{
 background-color: rgb(202, 202, 202);
}
.changeCountryCss-Home:active{
  background-color: rgb(234, 234, 234);
 }
.box02-IndiaFlag-Home{
  width: 20px;
  height: 20px;
}

/* box2 */
.box2-SearchHeading-Home{
  margin: 1%;
  /* margin-bottom: 3%; */
  color: rgb(209, 63, 10);
  font-weight: bold;
  font-size: 22px;
          /* font-family: 'Mitr', sans-serif; */
/* font-family: 'Montserrat', sans-serif; */
/* font-family: 'Poppins', sans-serif; */
font-family: 'Kodchasan', sans-serif;
/* font-family: 'Ysabeau Office', sans-serif; */
}
.box2-SearchHeading2-Home{
  margin: 1%;
  margin-bottom: 3%;
  color: rgb(189, 59, 11);
  /* font-weight: bold; */
  font-size: 20px;
          /* font-family: 'Mitr', sans-serif; */
font-family: 'Montserrat', sans-serif;
/* font-family: 'Poppins', sans-serif; */
/* font-family: 'Kodchasan', sans-serif; */
/* font-family: 'Ysabeau Office', sans-serif; */
}
.box2-Input-Home{
    /* margin-top: 1%; */
    width: 100%;
    min-height: 100px;
    max-height: max-content;
    /* border: 2px solid lightcoral; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background: rgb(224, 224, 224); */

}
.input-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  }
  .searchIcon2-Home{
    font-size: 22px;
    margin-left: 6px;
    margin-top: 2px;
    color: rgb(236,89,41);
  }
  .searchIcon-Home{
    font-size: 30px;
    /* width: 34px;
    height: 34px; */
    margin-left: -10%;
    color: orangered;
  }
.mainInput-Home {
    width: 600px;
    height: 40%;
    padding: 16px;
    border: none;
    border-radius: 15px;
    /* background-color: #f5f5f5; */
    font-family: 'Montserrat', sans-serif;
    border: rgb(224, 134, 0) 2px solid;
    background-color: none;
    font-size: 18px;
    color: #333;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .mainInput-Home:focus {
    outline: none;
    border-color: orange;
    border: orange 6px solid;
    box-shadow: 0 0 8px rgba(255, 221, 149, 0.3);
  }
  
  .mainInput-Home::placeholder {
    color: #aaa;
  }
  
  .mainInput-Home:disabled {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
  }
  .dropDownMenu-box1-Home{
    position: absolute;
    border: lightgrey solid 1px;
    border-radius: 8px;
    margin-top: 480px;
    /* margin-left: -2%; */
    z-index: 30;
    overflow: scroll;
    width: 600px;
    height: 400px;
    padding: 10px;
    background: white;
  }
  /* Hide the vertical scrollbar */
.dropDownMenu-box1-Home::-webkit-scrollbar {
    width: 0.5em; /* Adjust the width as needed */
  }
  
  /* Track */
  .dropDownMenu-box1-Home::-webkit-scrollbar-track {
    background-color: transparent; /* Set track color to transparent */
  }
  
  /* Handle */
  .dropDownMenu-box1-Home::-webkit-scrollbar-thumb {
    background-color: transparent; /* Set handle color to transparent */
    border-radius: 3px; /* Add some border-radius to make it look smoother */
  }

.stateNameDestination-dropDownMenu-box1-Home{
    background: none;
    border: none;
    /* background: lightgray; */
    margin-bottom: 2%;
    width: 100%;
    align-items: start;
    text-align: start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5%;
}
.stateNameDestination-dropDownMenu-box1-Home:hover{
    background: rgb(236, 236, 236);
    padding: 1.5%;
}
  .stateName-dropDownMenu-box1-Home{
    background: none;
    font-family: 'Nunito Sans', sans-serif;
    /* font-weight: normal; */
    font-size: 16px;
  }
  .destinations-dropDownMenu-box1-Home{
    font-family: 'Assistant', sans-serif;
    /* font-family: 'Nanum Gothic', sans-serif; */
    font-size: 15px;
    /* font-weight: 500; */
    color: rgb(108, 108, 108);
    margin-top: 3%;
  }
  .tagLine-dropDownMenu-box1-Home{
    color: gray;
    text-align: center;
    align-items: center;
    /* background-color: green; */
    height: 100%;
   
    /* margin-top: 2%; */
  }
  .tagLine-dropDownMenu2-box1-Home{
    color: gray;
    text-align: center;
    align-items: center;
    /* background-color: green; */
    height: 100%;
    margin-top: 2%;
  }

  .bucketListPopupStatus-Home{
    position: absolute;
    z-index: 100000000000000;
    border: 10px gree solid;
    height: 1000px;
    width: 1000px;
  }

 
