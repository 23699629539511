$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//



.mainConatiner-Ai_CreateItinerary{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}


//!Content Container
.conatiner_s-Content-Ai_CreateItinerary{
    display: flex;
    flex-direction: column;
    width: 100%;
    // border: 1px solid brown;
    border-radius: 8px;
    // padding: 4px 4px 4px 8px;
}


//*Heading
.conatiner_p-heading-Ai_CreateItinerary{
    width: 100%;
    // height: 20%;
    margin-top: 2%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    overflow: hidden;
    // border: 1px solid orangered;
    justify-content: space-around;
    // border-radius: 6px;
}
@media (min-width: 0px) and (max-width: 699px){
    .heading-1-heading-Ai_CreateItinerary{
        font-family: $font-6;
        font-size: 24px;
        font-weight: bold;
        // color: rgb(255, 81, 0);
        position: absolute;
        
        // box-shadow: rgb(255, 255, 255) 0px 8px 24px;
        box-shadow: rgba(237, 255, 195, 0.843) 0px 7px 29px 0px;
        
        // color: rgb(239, 16, 16);
        color: brown;
        background: rgb(255, 255, 255);

        // background: rgb(255, 255, 255);
        // color: rgb(186, 67, 8);
        
        border-radius: 6px;
        margin-top: 60px;
        margin-left: 30px;

        padding: 4px 8px 2px 8px;
        // margin-top: 18%;
        // margin-left: 15%;
        letter-spacing: 0.4px;
        white-space: nowrap;
    }
}
@media (min-width: 700px) and (max-width: 1200px){
    .heading-1-heading-Ai_CreateItinerary{
        font-family: $font-6;
        font-size: 36px;
        font-weight: bold;
        position: absolute;
        box-shadow: rgba(237, 255, 195, 0.843) 0px 7px 29px 0px;
        color: brown;
        background: rgb(255, 255, 255);
        border-radius: 6px;
        margin-top: 60px;
        margin-left: 60px;

        padding: 4px 8px 2px 8px;
        // margin-top: 18%;
        // margin-left: 15%;
        letter-spacing: 0.4px;
        white-space: nowrap;
    }
}

@media (min-width: 1200px){
    .heading-1-heading-Ai_CreateItinerary{
        font-family: $font-6;
        font-size: 36px;
        font-weight: bold;
        // color: rgb(255, 81, 0);
        position: absolute;
        
        // box-shadow: rgb(255, 255, 255) 0px 8px 24px;
        box-shadow: rgba(237, 255, 195, 0.843) 0px 7px 29px 0px;
        
        // color: rgb(239, 16, 16);
        color: brown;
        background: rgb(255, 255, 255);

        // background: rgb(255, 255, 255);
        // color: rgb(186, 67, 8);
        
        border-radius: 6px;
        margin-top: 60px;
        margin-left: 200px;

        padding: 4px 8px 2px 8px;
        // margin-top: 18%;
        // margin-left: 15%;
        letter-spacing: 0.4px;
        white-space: nowrap;
    }
}




.img-1-heading-Ai_CreateItinerary{
    // width: 100%;
    width: 700px;
    height: 200px;
    // height: 100%;
    object-fit: cover;
    // border: 1px solid orangered;
    border-radius: 8px;
    // position: absolute;
    z-index: -2;
    // box-shadow: 0 0 2px rgb(97, 97, 97); 
}



/*//* Itinerary Content */
.ItineraryContent-MainConatiner-Ai_CreateItinerary{
    width: 60%;
    border: 1px solid rgb(212, 212, 212);
    background: rgb(255, 254, 254);
    border-radius: 6px;
}

/*//* Map */
.ItineraryMap-MainConatiner-Ai_CreateItinerary{
    /* position: fixed; */
    border: 1px solid green;
    width: 40%;
    height: 600px;
}

/* Itinerary Content Inner Navigation */
.navigationButtons-MainConatiner-Ai_CreateItinerary{
    display: flex;
    flex-direction: row;
}

.activeButton-ItineraryResult-AiCreateItinerary{
    font-weight: bold;
}
.unActiveButton-ItineraryResult-AiCreateItinerary{
    font-weight: normal;
}



//*Navigation Buttons
.container_p-NavigationButtons-Ai_CreateItinerary{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 48px;
    margin-top: 2%;
}
.heading-Active-Day-Ai_CreateItinerary{
    padding: 4px 16px 4px 16px;
    background: white;
    border: none;
    color: orangered;
    border-bottom: 2px salmon solid;
    font-size: 18px;
    border-radius: 10px;
    margin-right: 20px;
    margin-top: 20px;
    font-family: $font-6;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 2px 0px;
}
.heading-unActive-Day-Ai_CreateItinerary{
    padding: 4px 16px 4px 16px;
    background: white;
    border: none;

    border-bottom: 2px rgb(180, 175, 175) solid;
    font-size: 18px;
    border-radius: 10px;
    margin-right: 20px;
    margin-top: 20px;
    font-family: $font-6;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 2px 0px;
}

// .button-createItinerary-Ai_CreateItinerary{
//     padding: 4px 16px 4px 16px;
//     background: white;
//     border: whitesmoke dotted 3px;
//     border-radius: 2px;
//     color: rgb(8, 71, 160);
//     font-size: 17px;
//     margin-top: 20px;
//     font-family: $font-6;

//     &:hover{
//         border-radius: 10px;
//         border: 2px rgb(240, 122, 19) dotted;
//         transition: border 0.3s ease;
//     }
//     &:active{
//         border: rgb(255, 0, 0) dotted 3px;
//         border-radius: 2px;
//         transition: border border-radius 0.3s ease;
//         transition: border-radius 0.3s ease;

//     }
// }


//!Map
@media (min-width: 0px) and (max-width: 699px){
    .container_p-map-Ai_CreateItinerary{
        width: 40%;
        position: relative;
        display: none;
    }
    .container_s-map-Ai_CreateItinerary{
        width: 38%;
        height: 96%;
        border-radius: 8px;
        right: 1%;
        top: 2%;
        position: fixed;
        box-shadow: 0 0 2px rgb(97, 97, 97); 
        overflow: hidden;
    }
    .continer_p-0-Ai_CreateItinerary{
        display: none;
    }
    .continer_p-1-Ai_CreateItinerary{
        width: 100%;
        // border: 1px solid rgb(157, 183, 106);
        // padding: 12px;
        border-radius: 8px;
    }
}
@media (min-width: 700px) and (max-width: 1200px){
    .container_p-map-Ai_CreateItinerary{
        width: 40%;
        position: relative;
        margin-left: 1%;
    }
    .container_s-map-Ai_CreateItinerary{
        width: 38%;
        height: 96%;
        border-radius: 8px;
        right: 0.4%;
        // left: 1%;
        top: 2%;
        position: fixed;
        box-shadow: 0 0 2px rgb(97, 97, 97); 
        overflow: hidden;
    }
    .continer_p-0-Ai_CreateItinerary{
        width: 1%;
        background: whitesmoke;
    }
    .continer_p-1-Ai_CreateItinerary{
        width: 60%;
        // border: 1px solid rgb(157, 183, 106);
        // padding: 12px;
        border-radius: 8px;
    }
}
@media (min-width: 1200px){
    .container_p-map-Ai_CreateItinerary{
    width: 40%;
    position: relative;
    }
    .container_s-map-Ai_CreateItinerary{
        width: 38%;
        height: 96%;
        border-radius: 8px;
        right: 1%;
        top: 2%;
        position: fixed;
        box-shadow: 0 0 2px rgb(97, 97, 97); 
        overflow: hidden;
    }
    .continer_p-0-Ai_CreateItinerary{
        width: 2%;
        margin-left: 1%;
        // border: 1px solid rgb(86, 86, 86);
        background: whitesmoke;
    }
    .continer_p-1-Ai_CreateItinerary{
        width: 54%;
        // border: 1px solid rgb(157, 183, 106);
        // padding: 12px;
        border-radius: 8px;
    }
}
