$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


// .conatiner_p-OtherAmenities{
//     display: flex;
//     flex-direction: column;
//     background: wheat;
// }

.conatiner_p-OtherAmenities{
    background-color: whitesmoke;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 4px 8px 12px 8px;
    border-radius: 4px;
    // position: absolute;
    z-index: 100;
}
.conatiner_s-OtherAmenities{
    display: flex;
    flex-direction: column;
    // border: 1px solid red;
    padding: 2px 4px 2px 4px;
    // margin-left: 420px;
    // margin-top: 54px;
    // border: 1px solid red;
    width: 100%;
    height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.conatiner_s-OtherAmenities::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.conatiner_s-OtherAmenities::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.conatiner_s-OtherAmenities::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  

//
.button-close-popup-OtherAmenities{
    font-size: 32px;
    border: none;
    background: none;
    margin-left: 12px;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}


//
//?text active and unactive
.title-1-active-amenity-Search_Tool{
    font-family: $font-6;
    color: black;
    font-weight: 500px;
    white-space: nowrap;
}
.title-1-unActive-amenity-Search_Tool{
    font-family: $font-6;
    color: rgb(110, 107, 107);
    white-space: nowrap;
}
//?Icons
.icon-1-amenity-Search_Tool{
    margin: 0px 6px 0px 6px;
}
.icon-2-amenity-Search_Tool{

}
.img-1-amenity-Search_Tool{
    width: 24px;
    height: 24px;
    margin: 0px 6px 0px 6px;
}

