$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


.Map_TO-Main-MapTO{
    width: 100%;
    /* height: 500px; */
    // padding: 1%;
    // border: 1px solid rgb(163, 109, 10);
}
.MainMainConatiner-MapTO{
    width: 100%;
    height: 90%;
    border-radius: 8px;
    box-shadow: 0 0 2px rgb(255, 28, 28);
    margin-top: -10%;
    // padding: 1%;
    // border: 1px dotted rgb(198, 21, 130);
}


.container_p-buttons-MapTO{
    z-index: 100000;
    position: absolute;
    display: flex;
    flex-direction: row;
    top: -4.6%;
    left: 60px;
    border-radius: 10px;
    padding: 4px 8px 4px 8px;
    background: rgba(99, 99, 99, 0.64);
    // opacity: 1px;
}
.button-fitAll-MapTO{
    // z-index: 100000;
    // position: absolute;
    margin-left: 8px;
    border-radius: 6px;
    padding: 2px 4px 2px 4px;
    font-family: $font-content-2;
    color: rgb(62, 62, 62);
    border: none;
    background: rgb(237, 237, 237);
    letter-spacing: 0.4px;

    &:hover{
        background-color: rgb(225, 225, 225);
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(245, 245, 245);
        transition: background-color 0.3s ease;
    }
}