$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//


.box2-2-Navigation-DecideDestination-temp{
    display: flex;
    flex-direction: column;
}
.temp-destinations{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

//!Main
.container_p-Destinations{
    width: 100%;
    margin: 4% 0px 4% 0px;
    // border: 1px solid red;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.container_s-Destinations{
    width: 99.98%;
    // border: 1px solid orchid;
    display: flex;
    flex-direction: row;
}

//! destinations | code-01
.conatiner_p-1-Destinations-Destinations{
    width: 70%;
    // background: rgb(255, 255, 241);
    padding: 2px 4px 2px 4px;   
}

.conatiner_p-navigationButtons-Destinations{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // justify-content: space-between;
    margin: 2% 0px 2% 0px;
}
.button-active-navigation-Destinations{
    padding: 4px 8px 4px;
    margin: 4px 8px 20px 8px;
    border-radius: 8px;
    border: none;
    background: none;
    font-family: $font-6;
    font-size: 15px;
    background: rgb(254, 241, 241);
    color: rgb(50, 48, 48);
    border-bottom: 4px solid rgb(248, 136, 96);

    transition: background 0.3s ease, border-bottom-color 0.3s ease;

    &:active {
        background: rgb(248, 136, 96);
        color: white;
        border-bottom-color: rgb(200, 100, 80); /* Darken border on click */
    }
}
.button-unActive-navigation-Destinations{
    padding: 4px 8px 4px;
    margin: 4px 8px 12px 8px;
    border-radius: 8px;
    border: none;
    background: none;
    font-family: $font-6;
    font-size: 14px;
    // font-weight: bold;
    background: rgb(254, 241, 241);
    color: rgb(45, 43, 43);
}

//* Destination Names
.container_p-DestinationNames-Destinations{
    width: 100%;
    // background: rgb(164, 239, 15);
    display: flex;
    flex-direction: row;
    // justify-content: center;
    flex-wrap: wrap;
}
.conatiner_s-active-DestinationName-Destinations{
    margin: 2% 1% 2% 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background: rgb(229, 225, 225);
    // background: rgb(250, 227, 227);
    border-radius: 8px;
    // border: 1px purple solid;
}
.conatiner_s-unActive-DestinationName-Destinations{
    margin: 2% 1% 2% 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px purple solid;
    border-radius: 8px;
}
.line-DestinationName-Destinations{
    border-bottom: 1px solid red;
    width: 50%;
    margin-top: 4px;
    align-self: flex-start;
    margin-left: 10%;
}
.button-active-DestinationName-Destinations{
    border: none;
    background: none;
    font-family: 'Lato';
    // font-family: $font-content-1;
    font-size: 16.6px;
    word-spacing: 0.3px;
    color: #000000;
    // font-weight: bold;

    padding: 4px 4px 4px 8px;
}
.button-unActive-DestinationName-Destinations{
    border: none;
    background: none;
    // font-family: $font-heading-2;
    font-family: 'Lato';
    // font-weight: bold;
    font-size: 16px;
    color: rgb(79, 78, 78);
    
    padding: 4px 4px 4px 8px;
}
.icon-Bookmark-Fill-Destinations{
    border: none;
    background: none;
    // margin: 3px 2px 2px 4px;
    
    padding: 5px 8px 4px 4px;
}
.icon-Bookmark-Empty-Destinations{
    border: none;
    background: none;
    // margin: 3px 2px 2px 4px;

    padding: 5px 8px 4px 4px;
}

//! line | code-02
.conatiner_p-2-line-Destinations{
    width: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: black;
}
.line-Destinations{
    height: 70%;
    border-right: 0.2px solid #e0dbdb;
}

//! Saved Destinations | code-03 
.conatiner_p-3-SavedDestinations-Destinations{
    width: 28%;
    // background: lightblue;
    padding: 2px 4px 2px 4px;
    display: flex;
    flex-direction: column;
}

.heading-1-savedDestination-Destinations{
    margin: 4px 0px 6px 0px;
    color: rgb(91, 88, 87);
    // font-family: 'Lato';
    font-family: $font-6;
    word-spacing: 0.4px;
    letter-spacing: 0.2px;
    margin-top: 8px;
    margin-bottom: 4px;
}
.conatiner_t-SavedDestinations-Destinations{
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 80%;
    // background: lightblue;
}
.conatiner_t-SavedDestinations-Destinations::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.conatiner_t-SavedDestinations-Destinations::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.conatiner_t-SavedDestinations-Destinations::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
} 


.conatiner_t-active-SavedDestination-Destinations{
    display: flex;
    flex-direction: row;
    margin: 6px 0px 4px 0px;
    // justify-content: space-around;
    padding: 4px 12px 4px 12px;
    border-radius: 8px;
    // background: rgb(239, 244, 244);
    background: rgb(235, 211, 171);
    border: none;
    align-items: center;
}
.conatiner_t-unActive-SavedDestination-Destinations{
    display: flex;
    flex-direction: row;
    margin: 6px 0px 4px 0px;
    // justify-content: space-around;
    padding: 4px 12px 4px 12px;
    border-radius: 8px;
    background: rgb(238, 240, 240);
    border: none;
    align-items: center;
}
.text-1-savedDestination-Destinations{
    margin: 2px;
    font-family: $font-content-2;
    font-size: 15px;
}
.icon-1-savedDestination-Destinations{
    margin: 2px;
    border: none;
    background: none;
}
