$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


@media (min-width: 0px) and (max-width: 799px){
    //Keywords
    .conatiner_p-keywords-Review_VM{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // align-items: center;
        // text-align: center;
        // justify-content: center;
        // border: 1px solid olivedrab;
        margin-left: 0.4%;
        width: 98%;
    }
    .heading-keywords-Review_VM{
        font-family: $font-content-2;
        font-size: 15px;
        margin: 12px 4px 12px 4px;
        // border: 1px solid olivedrab;
        color: gray;
        margin-top: 16px;
    }
}
@media (min-width: 800px){
    //Keywords
    .conatiner_p-keywords-Review_VM{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // align-items: center;
        // text-align: center;
        // justify-content: center;
        // border: 1px solid olivedrab;
        margin-left: 0.4%;
        width: 90%;
    }
    .heading-keywords-Review_VM{
        font-family: $font-content-2;
        font-size: 15px;
        margin: 12px;
        color: gray;
        margin-top: 16px;
    }
}

// //Keywords
// .conatiner_p-keywords-Review_VM{
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     // align-items: center;
//     // text-align: center;
//     // justify-content: center;
//     // border: 1px solid olivedrab;
//     margin-left: 0.4%;
//     width: 90%;
// }
// .heading-keywords-Review_VM{
//     font-family: $font-content-2;
//     font-size: 15px;
//     margin: 12px;
//     color: gray;
//     margin-top: 16px;
// }



@media (min-width: 0px) and (max-width: 799px){
    //Data
    .conatiner_p-data-Review_VM{
        // background: rgb(233, 233, 233);
        padding: 1% 2% 1% 2%;
        margin-top: 3%;
        text-align: start;
    }
    .conatiner_s-data-Review_VM{
        // background: rgb(228, 227, 227);
        // background: rgb(248, 244, 244);
        border-radius: 6px;
        margin: 28px 0px 12px 0px;
        // padding: 4px 20px 12px 20px;
    }
    .heading-1-data-Review_VM{
        font-family: $font-6;
    }
}
@media (min-width: 800px){
    //Data
    .conatiner_p-data-Review_VM{
        // background: rgb(233, 233, 233);
        padding: 1% 2% 1% 2%;
        margin-top: 3%;
        text-align: start;
    }
    .conatiner_s-data-Review_VM{
        // background: rgb(228, 227, 227);
        // background: rgb(248, 244, 244);
        border-radius: 6px;
        margin: 12px 0px 12px 0px;
        padding: 4px 20px 12px 20px;
    }
    .heading-1-data-Review_VM{
        font-family: $font-6;
    }
}

// //Data
// .conatiner_p-data-Review_VM{
//     // background: rgb(233, 233, 233);
//     padding: 1% 2% 1% 2%;
//     margin-top: 3%;
//     text-align: start;
// }
// .conatiner_s-data-Review_VM{
//     // background: rgb(228, 227, 227);
//     // background: rgb(248, 244, 244);
//     border-radius: 6px;
//     margin: 12px 0px 12px 0px;
//     padding: 4px 20px 12px 20px;
// }
// .heading-1-data-Review_VM{
//     font-family: $font-6;
// }

//?reviewer info
.conatiner_p-reviewer-data-Review_VM{
    display: flex;
    flex-direction: row;
    margin: 8px 0px 4px 0px;
    border-left: 4px lightgrey solid;
    padding-left: 10px;
    margin-left: 2.8%;
    margin-top: 2%;
}
.heading-1-data-Review_VM{
    margin-left: 2%;
    font-size: 14px;
}
.link-1-data-Review_VM{
    margin-left: 2%;
    font-size: 14px;
    color: rgb(11, 59, 132);
}
.icon-1-data-Review_VM{
    margin-left: 10px;
}
.heading-1-reviewData-data-Review_VM{
    margin-left: 12px;
    margin-top: -1px; 
    color: gray;
    font-family: $font-5;
    font-size: 15px;
}

//?content
.heading-2-data-Review_VM{
    // background: white;
    background: whitesmoke;
    color: rgb(56, 54, 54);
    // border: 1px solid olive;
    align-items: self-start;
    text-align: start;
    padding: 10px;
    margin: 10px;
    border-radius: 12px;
    // font-family: $font-5;
    font-family: $font-heading-2;
    font-size: 17px;
    letter-spacing: 0.4px;
    word-spacing: 0.6px;
    line-height: 1.5;  
}

//?meta data
.conatiner_p-metaData-data-Review_VM{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: center;
    // border: 1px solid olivedrab;
    // padding-bottom: 20px;
}
.conatiner_s-metaData-data-Review_VM{
    font-family: $font-content-2;
    font-size: 14px;
    margin: 12px;
    color: gray;
}