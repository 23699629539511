$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//

.conatiner_p-TravelCategories{
    // display: flex;
    // flex-direction: column-reverse;
    // flex-wrap: wrap;
    width: 100%;

    background: rgb(238, 194, 194);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 10px;
    padding: 2px;
    justify-content: space-around;
    // margin: 2%;
    margin-bottom: 12%;
    // align-self: flex-start;
}


.button-active-TravelCategories-TravelCategories{
    padding: 3px 10px 3px 10px;
    background: rgb(242, 83, 83);
    margin: 10px 4px;
    margin-left: 22px;
    margin-right: 22px;
    border-radius: 10px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}
.button-unActive-TravelCategories-TravelCategories{
    padding: 3px 10px 3px 10px;
    background: white;
    margin: 10px 4px;
    margin-left: 22px;
    margin-right: 22px;
    border-radius: 10px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}
.heading-active-TravelCategories-TravelCategories{
    color: white;
    font-family: $font-6;
    font-size: 16px;
    margin-right: 12px;
    margin-top: 2px;
}
.heading-unActive-TravelCategories-TravelCategories{
    font-family: $font-6;
    font-size: 16px;
    margin-right: 12px;
    margin-top: 2px;
}

.img-active-TravelCategories-TravelCategories{
    width: 24px;
    height: 24px;
}
.img-unActive-TravelCategories-TravelCategories{
    width: 24px;
    height: 24px;
}

















