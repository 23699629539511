.conatiner_p-Map-Map_D{
    width: 100%;
    height: 600px;
    border-radius: 8px;
    filter: saturate(130%);
    filter:contrast(120%);
    // filter:hue(130deg);
    border: 4px solid lightgray;
    box-shadow: 0 4px 8px rgba(93, 92, 92, 0.2);
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    // box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    // box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.2);
}