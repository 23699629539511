//
.conatiner_p-PlanTripHome{
    display: flex;
    flex-direction: column;
}


@media (min-width: 0px) and (max-width: 899px){
    .conatiner_p-PlanTripHeader-PlanTripHome{
       display: none;
    }
    .conatiner_p-mobile-PlanTripHeader-PlanTripHome{
        position: fixed;
        width: 100%;
        z-index: 1000;
        
        // background: rgb(195, 100, 100);
        // position: absolute;
        bottom: 0;
    }
}
@media (min-width: 900px) and (max-width: 1399px){
    .conatiner_p-PlanTripHeader-PlanTripHome{
        position: fixed;
        width: 100%;
        z-index: 1000;
    }
    .conatiner_p-mobile-PlanTripHeader-PlanTripHome{
       display: none;
    }
}
@media (min-width: 1400px){
    .conatiner_p-PlanTripHeader-PlanTripHome{
        position: fixed;
        width: 100%;
        z-index: 1000;
    }
    .conatiner_p-mobile-PlanTripHeader-PlanTripHome{
        display: none;
    }
}

.conatiner_p-OtherComponents-PlanTripHome{
    // margin-top: -6%;
    // border: 10px solid whitesmoke;
}

.component-CreateItinerary-PlanTripHome{
    background: whitesmoke;
}