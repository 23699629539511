/* saved plans */
.savedPlans-Heading-Home{
    font-family: 'Comic Sans MS', cursive; /* Use a playful, cartoonish font */
    font-size: 24px; /* Adjust font size */
    color: #ff6347; /* Choose a fun text color */
    text-align: center; /* Center the text horizontally */
    margin-bottom: 20px; /* Add some space below the heading */
}
.SavedPlans-MainContainer-Home{
    width: 100%;
    /* border: 2px solid orange; */
    background-color: rgb(247, 247, 247);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center horizontally */
}
.SavedPlans-LoginConatiner-Home{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-x: scroll;
    overflow-y: hidden;
    background-color: antiquewhite;
    margin: 2% 0% 2% 0%;
    padding: 2% 0% 2% 0%;;
}