
$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


// .container_t-1-imgHead-places-TP_Top10{
//     width: 20%;
//     display: flex;
//     flex-direction: column;
//     // border: 4px rgb(113, 72, 200) solid;
// }
// .container_t-2-main-places-TP_Top10{
//     width: 86%;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
// }

//!Mobile
@media (min-width: 0px) and (max-width: 799px){
    .heading-bestTime-TP_Top10{
        font-size: 16px;
        font-weight: bold;
        font-family: $font-heading-1;  
        margin: 4px 0px 0px 0px; 
    }
    .container_t-1-imgHead-places-TP_Top10{
        // width: 20%;
        display: flex;
        flex-direction: column;
        // border: 4px rgb(113, 72, 200) solid;
    }
    .container_t-2-main-places-TP_Top10{
        margin-top: 4%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    //*inside card elements css
    .scroll-button-left-TP_Top10{
        width: 5%;
        height: 100%;
        font-size: 40px;
        align-items: center;
        text-align: center;
        color: rgb(91, 91, 91);
        background: none;
        border: none;
        display: none;
    }
    .scroll-button-left-TP_Top10:hover{
        color: black;
    }
    .scroll-button-right-TP_Top10{
        width: 5%;
        height: 100%;
        font-size: 40px;
        align-items: center;
        text-align: center;
        color: rgb(91, 91, 91);
        background: none;
        border: none;
        display: none;
    }
    .scroll-button-right-TP_Top10:hover{
        color: black;
    }
} 
//!Web
@media (min-width: 800px){
    .heading-bestTime-TP_Top10{
        font-size: 16px;
        font-weight: bold;
        font-family: $font-heading-1;  
        margin: 4px 0px 0px 0px; 
    }
    .container_t-1-imgHead-places-TP_Top10{
        width: 20%;
        display: flex;
        flex-direction: column;
        // border: 4px rgb(113, 72, 200) solid;
    }
    .container_t-2-main-places-TP_Top10{
        width: 86%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    //*inside card elements css
    .scroll-button-left-TP_Top10{
        width: 5%;
        height: 100%;
        font-size: 40px;
        align-items: center;
        text-align: center;
        color: rgb(91, 91, 91);
        background: none;
        border: none;
    }
    .scroll-button-left-TP_Top10:hover{
        color: black;
    }
    .scroll-button-right-TP_Top10{
        width: 5%;
        height: 100%;
        font-size: 40px;
        align-items: center;
        text-align: center;
        color: rgb(91, 91, 91);
        background: none;
        border: none;
    }
    .scroll-button-right-TP_Top10:hover{
        color: black;
    }
}


// .container_t-image-places-TP_Top10{
//     width: 180px;
//     height: 180px;
//     border-radius: 200px;
//     border: 4px rgb(239, 239, 239) solid;
//     object-fit: cover;
// }
// .heading-bestTime-TP_Top10{
//     font-size: 16px;
//     font-weight: bold;
//     font-family: $font-heading-1;  
//     margin: 4px 0px 0px 0px; 
// }
.image-places-TP_Top10{
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    // border-radius: 200px;
    // border: 4px rgb(239, 239, 239) solid;
    object-fit: cover;
    border-radius: 8px;
}
.image-bestTime-TP_Top10{
    width: 240px;
    height: 220px;
    border-radius: 200px;
    border: 4px rgb(239, 239, 239) solid;
}

.card-place-TP_Top10{
    width: 100%;
    margin: 2%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 1%;
    // height: 50%;
    // border: 4px rgb(192, 236, 0) solid;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

//*inside card elements css
// .scroll-button-left-TP_Top10{
//     width: 5%;
//     height: 100%;
//     font-size: 40px;
//     align-items: center;
//     text-align: center;
//     color: rgb(91, 91, 91);
//     background: none;
//     border: none;
// }
// .scroll-button-left-TP_Top10:hover{
//     color: black;
// }
// .scroll-button-right-TP_Top10{
//     width: 5%;
//     height: 100%;
//     font-size: 40px;
//     align-items: center;
//     text-align: center;
//     color: rgb(91, 91, 91);
//     background: none;
//     border: none;
// }
// .scroll-button-right-TP_Top10:hover{
//     color: black;
// }
.star-card-TP_Top10{
    font-size:16px; 
    text-align:center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}
.reviewCount-card-TP_Top10{
    font-size:18px; 
    color: brown;
    font-family: $font-content-2;
}
.container_t-icons-hotels-card-TP_Top10{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 16px;
    margin: 4px 2px 2px 2px;
    background-color: rgb(238, 238, 238);
    border-radius: 8px;
}
.text-about-hotels-card-TP_Top10 {
    /* background-color: rgb(236, 236, 236); */
    border-radius: 8px;
    margin: 6px 2px 2px 2px;
    color: rgb(65, 65, 65);
    height: 100px;
    overflow: hidden;
    // white-space: nowrap;
    text-overflow: ellipsis;
}

.icon-hotels-card-TP_Top10{
    font-family: $font-content-2;
    margin: 2px;
    padding: 2px;
    // color: gray;
    color: rgb(64, 63, 63);
}
// .container_t-Content-places-TP_Top10{
//     width: 90%;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     overflow-x: scroll;
//     overflow-y: hidden;
// }

@media (min-width: 0px) and (max-width: 699px){
    .container_t-Content-places-TP_Top10{
        width: 99%;
        display: flex;
        flex-direction: row;
        align-items: start;
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .container_t-image-places-TP_Top10{
        // width: 140px;
        // height: 135px;
        width: 140px;
        height: 140px;
        border-radius: 200px;
        border: 4px rgb(239, 239, 239) solid;
        object-fit: cover;
    }
}
@media (min-width: 700px) and (max-width: 1200px){
    .container_t-Content-places-TP_Top10{
        width: 78%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: scroll;
        overflow-y: hidden;
        // border: red 1px solid;
        margin-left: 20px;
    }
    .container_t-image-places-TP_Top10{
        // width: 140px;
        // height: 135px;
        width: 120px;
        height: 120px;
        border-radius: 200px;
        border: 4px rgb(239, 239, 239) solid;
        object-fit: cover;
    }
}
@media (min-width: 1200px) and (max-width: 1400px){
    .container_t-Content-places-TP_Top10{
        width: 86%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: scroll;
        overflow-y: hidden;
        // border: rgb(52, 14, 220) 10px solid;
    }
    .container_t-image-places-TP_Top10{
        // width: 140px;
        // height: 135px;
        width: 180px;
        height: 180px;
        border-radius: 200px;
        border: 4px rgb(239, 239, 239) solid;
        object-fit: cover;
    }
}
@media (min-width: 1400px){
    .container_t-Content-places-TP_Top10{
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .container_t-image-places-TP_Top10{
        // width: 140px;
        // height: 135px;
        width: 180px;
        height: 180px;
        border-radius: 200px;
        border: 4px rgb(239, 239, 239) solid;
        object-fit: cover;
    }
}

.image-place-card-TP_Top10{
    object-fit: cover;
    width: 220px;
    height: 180px;
    margin-top: 0px;
    // border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.heading-placeName-card-TP_Top10{
    // font-family: $font-heading-2;
    // font-family: $font-content-1;
    font-family: $font-6;
    font-size: 18px;
    margin: 12px 4px 8px 4px;
}
.text-placeName-card-TP_Top10{
    font-family: $font-heading-2;
    margin: 4px;
}
.content-overview-card-TP_Top10{
    font-family: $font-8;
    font-size: 13px;
    color: rgb(114, 113, 113);
    // margin: 3%;
    width: 100%;
    text-align: start;
    margin: 10px 4px 4px 4px;
    word-spacing: 0.1px;
    // display: flex;
    // align-items: start;
    // justify-content: start;
    // border: 4px rgb(239, 239, 239) solid;
}
.text-card-TP_Top10{
    color: rgb(133, 133, 133);
    // font-family: $font-content-2;
    font-family: $font-5;
    margin: 8px 4px 8px 4px;
    font-size: 16px;
    margin-top: 10px;
}
.container_t-hashtags-card-TP_Top10{
    display: flex;
    flex-direction: row;
    // border: 4px rgb(239, 239, 239) solid;
    display: flex;
    justify-content: space-around;
    margin: 8px 4px 4px 4px;
    flex-wrap: wrap;
    
}
.heading-1-hashtags-card-TP_Top10{
    font-family: $font-6;
    color: rgb(139, 60, 0);
    font-size: 15px;
    margin-top: 4px;
}