$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

.input-inputDate-Search_Destinations_HTR_Mobile{
    padding: 3px 8px 5px 8px; /* Padding inside the input */
    border: 1px solid #ffc7c7; /* Border color */
    border-radius: 8px; /* Rounded corners */
    font-size: 14px; /* Font size */
    color: #333; /* Text color */
    background-color: #ffffff; /* Background color */
    outline: none; /* Remove default outline on focus */
    transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for focus state */
    width: 120%;
}

.input-inputDate-Search_Destinations_HTR_Mobile:focus{
    border-color: #dfffae; /* Border color on focus */
    box-shadow: 0 0 5px #b9eb9d; /* Box shadow on focus */
}

.input-inputDate-Search_Destinations_HTR_Mobile::placeholder{
    color: lightgray;
}


//
.input-inputDate-Search_Destinations_HTR{
    padding: 3px 8px 5px 8px; /* Padding inside the input */
    border: 1px solid #ffc7c7; /* Border color */
    border-radius: 8px; /* Rounded corners */
    font-size: 14px; /* Font size */
    color: #333; /* Text color */
    background-color: #ffffff; /* Background color */
    outline: none; /* Remove default outline on focus */
    transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for focus state */
    width: 146%;
}

.input-inputDate-Search_Destinations_HTR:focus{
    border-color: #dfffae; /* Border color on focus */
    box-shadow: 0 0 5px #b9eb9d; /* Box shadow on focus */
}

.input-inputDate-Search_Destinations_HTR::placeholder{
    color: lightgray;
}


//
.conatiner_p-searchOptions-Search_Destinations_HTR{
    position: absolute;
    width: 200%;
    // border: 1px solid orchid;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-left: -30%;
    height: 240px;
    padding: 4px;
    border-radius: 8px;
    z-index: 1000;
    background: whitesmoke;
}
.conatiner_s-searchOptions-Search_Destinations_HTR{
    width: 100%;
    // border: 1px solid orchid;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 4px;
    border-radius: 8px;
    z-index: 1000;
    background: white;
}
.conatiner_s-searchOptions-Search_Destinations_HTR::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.conatiner_s-searchOptions-Search_Destinations_HTR::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.conatiner_s-searchOptions-Search_Destinations_HTR::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  

.button-searchOptions-Search_Destinations_HTR{
    width: 80%;
    margin-bottom: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;    
    border: none;
    background: none;
    border-radius: 8px;


    &:hover{
        background-color: whitesmoke;
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(255, 230, 230);
        transition: background-color 0.3s ease;
    }
}
.container_t-texts-searchOptions-Search_Destinations_HTR{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 12px 6px 6px 6px;
}
.text-1-searchOptions-Search_Destinations_HTR{
    font-family: $font-6;
    margin-right: 8px;
    margin-top: 6px;
    font-size: 18px;
    color: rgb(95, 92, 92); 
}
.text-2-searchOptions-Search_Destinations_HTR{
    font-family: $font-6;
    margin-right: 8px;
    margin-top: 2px;
    font-size: 17px;
    color: rgb(177, 172, 172); 
}
.text-3-searchOptions-Search_Destinations_HTR{
    font-family: $font-5;
    margin-right: 8px;
    margin-top: 6px;
    font-size: 15px;
    color: rgb(86, 83, 83); 
}

.button-close-popup-Search_Destinations_HTR{
    font-size: 32px;
    border: none;
    background: none;
    position: absolute;
    right: -30px;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}

.conatiner_t-searchOptions-Search_Destinations_HTR{

}
