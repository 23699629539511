$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//



.continer_p-1-Hotels_Mobile{
    width: 99%;
    // width: 96%;
    // border: 1px solid rgb(157, 183, 106);
    // padding: 12px;
    border-radius: 8px;
}
.continer_p-2-Hotels_Mobile{
    width: 90%;
    // border: 3px solid rgb(5, 99, 134);
    position: relative;
    // display: flex;
    // align-items: center;
    // text-align: center;
    // justify-content: center;
}
.component-map-Hotels_Mobile{
    width: 98%;
    height: 100%;
    
    // width: 100px;
    // height: 100px;

    border-radius: 8px;
    right: 1%;
    top: 1%;
    position: fixed;
    box-shadow: 0 0 2px rgb(97, 97, 97); 
    // border: 3px solid rgb(54, 5, 134);
    overflow: hidden;
}
.container_p-navigation_map_content-Hotels_Mobile{
    display: flex;
    flex-direction: row;
}
//navigation
.button-view_hotels-Hotels_Mobile{
    border: none;
    background: none;
    margin-top: 1%;
    margin-left: 2%;
}
.img-view_hotels-Hotels_Mobile{
    width: 140px;
    // height: 80px;
    border-radius: 8px;
}

.button-view_hotels-2-Hotels_Mobile{
    color: rgb(241, 151, 61);
    margin: 2px;
    padding: 4px 10px 2px 10px;
    border-radius: 10px;
    border: none;
    background: none;
    background: rgb(255, 255, 255);
    border: 2px solid rgb(238, 235, 235);
    font-size: 16px;
    font-family: $font-6;
    white-space: nowrap;
    z-index: 10;

    &:hover{
        // box-shadow: rgba(237, 255, 195, 0.843) 0px 7px 7px 0px;
        box-shadow: 0 0 3px rgba(255, 0, 0, 0.5);
        border: 2px solid rgb(255, 255, 255);
        border-radius: 6px;
        transition: box-shadow 0.3s ease;
        transition: border 0.3s ease;
    }
    &:active{
        box-shadow: 0 0 3px rgba(255, 141, 10, 0.5);
        border: 2px solid rgb(255, 255, 255);
        transition: box-shadow 0.3s ease;
        transition: border 0.3s ease;
        border-radius: 6px;
    }
}


//close button
.button-close-Hotels_Mobile{
    background: none;
    border: none;
    font-size: 32px;
    position: absolute;
    // bottom: 600px;
    z-index: 10000;
    top: 12px;
    right: 0px;
    // top: -360px;
    // right: 40px;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}