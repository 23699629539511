$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';



//!Mobile
@media (min-width: 0px) and (max-width: 799px){
    .heading-bestTime-TP_Top10{
        font-size: 16px;
        font-weight: bold;
        font-family: $font-heading-1;  
        margin: 4px 0px 0px 0px; 
    }
    .container_t-1-imgHead-places-Hotel_Top10{
        // width: 20%;
        display: flex;
        flex-direction: column;
        // border: 4px rgb(113, 72, 200) solid;
    }
    .container_t-2-main-places-Hotel_Top10{
        margin-top: 4%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    //*inside card elements css
    .scroll-button-left-Hotel_Top10{
        width: 5%;
        height: 100%;
        font-size: 40px;
        align-items: center;
        text-align: center;
        color: rgb(91, 91, 91);
        background: none;
        border: none;
        display: none;
    }
    .scroll-button-left-Hotel_Top10:hover{
        color: black;
    }
    .scroll-button-right-Hotel_Top10{
        width: 5%;
        height: 100%;
        font-size: 40px;
        align-items: center;
        text-align: center;
        color: rgb(91, 91, 91);
        background: none;
        border: none;
        display: none;
    }
    .scroll-button-right-Hotel_Top10:hover{
        color: black;
    }
    .container_t-Content-places-Hotel_Top10{
        width: 99%;
        // width: 600px;
        display: flex;
        flex-direction: row;
        align-items: start;
        overflow-x: scroll;
        overflow-y: hidden;
    }
} 
//!Web
@media (min-width: 800px){
    .heading-bestTime-TP_Top10{
        font-size: 16px;
        font-weight: bold;
        font-family: $font-heading-1;  
        margin: 4px 0px 0px 0px; 
    }
    .container_t-1-imgHead-places-Hotel_Top10{
        width: 20%;
        display: flex;
        flex-direction: column;
        // border: 4px rgb(113, 72, 200) solid;
    }
    .container_t-2-main-places-Hotel_Top10{
        width: 86%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    //*inside card elements css
    .scroll-button-left-Hotel_Top10{
        width: 5%;
        height: 100%;
        font-size: 40px;
        align-items: center;
        text-align: center;
        color: rgb(91, 91, 91);
        background: none;
        border: none;
    }
    .scroll-button-left-Hotel_Top10:hover{
        color: black;
    }
    .scroll-button-right-Hotel_Top10{
        width: 5%;
        height: 100%;
        font-size: 40px;
        align-items: center;
        text-align: center;
        color: rgb(91, 91, 91);
        background: none;
        border: none;
    }
    .scroll-button-right-Hotel_Top10:hover{
        color: black;
    }
    .container_t-Content-places-Hotel_Top10{
        width: 90%;
        // width: 600px;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: scroll;
        overflow-y: hidden;
    }
}


// .container_t-1-imgHead-places-Hotel_Top10{
//     width: 20%;
//     display: flex;
//     flex-direction: column;
//     // border: 4px rgb(113, 72, 200) solid;
// }
// .container_t-2-main-places-Hotel_Top10{
//     width: 80%;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
// }

.container_t-image-places-Hotel_Top10{
    width: 140px;
    height: 135px;
    border-radius: 200px;
    border: 1px rgb(239, 239, 239) solid;
    object-fit: cover;
    filter: brightness(0.8);
}
.heading-bestTime-Hotel_Top10{
    font-size: 16px;
    font-weight: bold;
    font-family: $font-heading-1;  
    margin: 4px 0px 0px 0px; 
}
.image-places-Hotel_Top10{
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    // border-radius: 200px;
    // border: 4px rgb(239, 239, 239) solid;
    object-fit: cover;
    border-radius: 8px;
}
.image-bestTime-aboutDestination{
    width: 240px;
    height: 220px;
    border-radius: 200px;
    border: 4px rgb(239, 239, 239) solid;
}

.card-place-Hotel_Top10{
    width: 140%;
    margin: 2%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 1%;
    // height: 50%;
    // border: 4px rgb(192, 236, 0) solid;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

//*inside card elements css
.scroll-button-left-Hotel_Top10{
    width: 5%;
    height: 100%;
    font-size: 40px;
    align-items: center;
    text-align: center;
    color: rgb(91, 91, 91);
    background: none;
    border: none;
}
.scroll-button-left-Hotel_Top10:hover{
    color: black;
}
.scroll-button-right-Hotel_Top10{
    width: 5%;
    height: 100%;
    font-size: 40px;
    align-items: center;
    text-align: center;
    color: rgb(91, 91, 91);
    background: none;
    border: none;
}
.scroll-button-right-Hotel_Top10:hover{
    color: black;
}
.star-card-Hotel_Top10{
    font-size:20px; 
    text-align:center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}
.reviewCount-card-Hotel_Top10{
    font-size:18px; 
    color: rgb(138, 84, 7);
    font-family: $font-content-2;
}
.container_t-icons-hotels-card-Hotel_Top10{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 16px;
    margin: 4px 2px 2px 2px;
    background-color: rgb(238, 238, 238);
    border-radius: 8px;
}
.text-about-hotels-card-Hotel_Top10 {
    /* background-color: rgb(236, 236, 236); */
    border-radius: 8px;
    margin: 6px 2px 2px 2px;
    color: rgb(65, 65, 65);
    height: 60px;
    overflow: hidden;
    // white-space: nowrap;
    text-overflow: ellipsis;
}

.icon-hotels-card-Hotel_Top10{
    font-family: $font-content-2;
    margin: 2px;
    padding: 2px;
    font-size: 13px;
    // color: gray;
    color: rgb(64, 63, 63);
}
// .container_t-Content-places-Hotel_Top10{
//     width: 90%;
//     // width: 600px;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     overflow-x: scroll;
//     overflow-y: hidden;
// }
.image-place-card-Hotel_Top10{
    object-fit: cover;
    width: 280px;
    height: 210px;
    margin-top: 0px;
    // border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.heading-placeName-card-Hotel_Top10{
    // font-family: $font-heading-2;
    font-family: $font-content-1;
    font-size: 17px;
    margin: 4px;
}
.text-placeName-card-Hotel_Top10{
    font-family: $font-heading-2;
    margin: 4px;
}
.content-overview-card-Hotel_Top10{
    font-family: $font-heading-2;
    font-size: 14px;
    color: rgb(89, 89, 89);
    // margin: 3%;
    width: 100%;
    text-align: start;
    margin: 10px 4px 4px 4px;
    word-spacing: 0.1px;
    // display: flex;
    // align-items: start;
    // justify-content: start;
    // border: 4px rgb(239, 239, 239) solid;
}
.text-card-Hotel_Top10{
    color: brown;
    // color: rgb(138, 84, 7);
    font-family: $font-content-2;
    margin: 8px 4px 8px 4px;
    font-size: 17px;
}
.container_t-hashtags-card-Hotel_Top10{
    display: flex;
    flex-direction: row;
    // border: 4px rgb(239, 239, 239) solid;
    display: flex;
    justify-content: space-around;
    color: rgb(139, 60, 0);
    margin: 8px 4px 4px 4px;
    font-weight: bold;
}