$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


.conatiner_p-AddTravellers_TO{
    margin: 4% 0% 4% 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 1px solid green;
}
.container_p-heading-tripDetails-AddTravellers_TO{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-bottom: 8px;
    // border: 1px solid green;
}
.heading-TripDetails-AddTravellers_TO{
    font-family: $font-6;
    font-size: 16px;
    margin-left: 4px;
    color: rgb(62, 61, 61);
}
.button-edit-tripDetails-AddTravellers_TO{
    border: none;
    background: none;
}
.image-TripDetails-AddTravellers_TO{
    width: 16px;
    height: 16px;
    margin-left: 10px;

    background: rgb(255, 237, 223);
    border-radius: 8px;
    box-shadow: 0 4px 200px rgb(242, 180, 180);

    &:hover{
        background: rgb(234, 189, 156);
        border-radius: 8px;
        box-shadow: 0 4px 200px rgb(242, 180, 180);
        transition: background-color 0.3s ease;
    }
    &:active{
        background: rgb(241, 239, 239);
        border-radius: 8px;
        box-shadow: 0 4px 10px rgb(244, 209, 161);
        transition: background-color 0.3s ease;
    }
}

//!
// .container_p-data-travellers-AddTravellers_TO{
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     margin-top: 2%;
//     display: flex;
//     justify-content: center;
//     // justify-content: start;
//     justify-content: space-around;
//     flex-direction: row;
//     height: fit-content;
//     min-width: 30%;
//     max-width: 70%;
//     align-items: center;
//     text-align: center;
//     // border: 1px solid green;
// }

@media (min-width: 0px) and (max-width: 799px){
    .container_p-data-travellers-AddTravellers_TO{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 2%;
        display: flex;
        justify-content: center;
        // justify-content: start;
        justify-content: space-around;
        flex-direction: row;
        height: fit-content;
        min-width: 30%;
        max-width: 100%;
        align-items: center;
        text-align: center;
        // border: 1px solid green;
    }
}
@media (min-width: 800px){
    .container_p-data-travellers-AddTravellers_TO{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 2%;
        display: flex;
        justify-content: center;
        // justify-content: start;
        justify-content: space-around;
        flex-direction: row;
        height: fit-content;
        min-width: 30%;
        max-width: 70%;
        align-items: center;
        text-align: center;
        // border: 1px solid green;
    }
}

.container_p-travellers-AddTravellers_TO{
    display: flex;
    flex-direction: row;
    margin: 2px 10px 20px 2px;
    padding: 2px 2px 2px 2px;
    // border: 1px solid green;
    border-radius: 4px;
    background-color: rgb(250, 248, 248);
}
.heading-1-travellers-AddTravellers_TO{
    margin: 3px 4px 0px 4px;
    font-family: $font-6;
    color: grey;
}
.heading-2-travellers-AddTravellers_TO{
    margin: 0px 4px 0px 4px;
    font-size: 14px;
    font-family: $font-4;
    font-style: italic;
    color: rgb(135, 131, 131);
}

//?travellers status
.heading-maybe-travellersStatus-AddTravellers_TO{
    color: rgb(251, 193, 87);
    margin: 0px 4px 0px 4px;
    font-size: 16px;
    font-family: $font-7;
    font-style: italic;
}
.heading-notSure-travellersStatus-AddTravellers_TO{
    color: rgb(255, 120, 120);
    margin: 0px 4px 0px 4px;
    font-size: 16px;    
    font-family: $font-7;
    font-style: italic;
}
.heading-sure-travellersStatus-AddTravellers_TO{
    color:rgb(158, 209, 20);
    margin: 0px 4px 0px 4px;
    font-size: 16px;
    font-family: $font-7;
    font-style: italic;
}

.heading-3-travellers-AddTravellers_TO{
    border: none;
    background: none;
    margin: -2px 4px 0px 4px;
}

.heading-1-noTravellers-AddTravellers_TO{
    font-family: $font-content-1;
    font-size: 18px;
    color: lightsalmon;        
}


//!popup

@media (min-width: 0px) and (max-width: 799px){
    //!popup container
    .popup_p-addNote-AddTravellers_TO {
        width: 86%;
        height: 60%;
        position: fixed;
        top: 14%;
        left: 6%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 2px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 100000;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
}
@media (min-width: 800px){
    //!popup container
    .popup_p-addNote-AddTravellers_TO {
        width: 50%;
        height: 60%;
        position: fixed;
        top: 14%;
        left: 26%;
        border: none;
        border-radius: 8px;
        background: #fff;
        padding: 2px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 100000;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
}

//!popup container
// .popup_p-addNote-AddTravellers_TO {
//     width: 50%;
//     height: 60%;
//     position: fixed;
//     top: 14%;
//     left: 26%;
//     border: none;
//     border-radius: 8px;
//     background: #fff;
//     padding: 20px;
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//     z-index: 100000;
//     overflow: auto;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     text-align: center;
//     justify-content: center;
// }

.button-active-popup-AddTravellers_TO{
    margin: 8px 12px 4px 12px;
    padding: 2px 8px 2px 8px;
    font-size: 14px;
    white-space: nowrap;
    border-radius: 8px;
    font-family: $font-content-1;

    background-color: #4CAF50; /* Green color when active */
    color: white;
    border: 1px solid #4CAF50;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle box shadow when active */
}
.button-unActive-popup-AddTravellers_TO{
    margin: 8px 12px 4px 12px;
    padding: 2px 8px 2px 8px;
    font-size: 14px;
    white-space: nowrap;
    border-radius: 8px;
    font-family: $font-content-1;

    background-color: #ffffff; /* Light grey color when inactive */
    color: #333;
    border: 1px solid #ffffff;
    box-shadow: 0 0 5px rgba(15, 14, 14, 0.1); /* Subtle box shadow when inactive */
}
.text-addStaus-popup-AddTravellers_TO{
    font-size: 12px;
    color: rgb(255, 165, 165);
    bottom: 10px;
    margin: 6px 0px 4px 0px;
}
.button-save-popup-AddTravellers_TO{
    margin: 8px 12px 4px 12px;
    padding: 2px 100px 2px 100px;
    font-size: 14px;
    white-space: nowrap;
    border-radius: 8px;
    font-family: $font-4;
    margin-top: 30px;
    letter-spacing: 0.8px;
    background-color: #edece7; /* Green color when active */
    color: rgb(0, 0, 0);
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle box shadow when active */

    &:hover{
        color: red;
        background-color: whitesmoke;
        transition: color 0.3s ease;
        transition: background-color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}
.button-close-popup-AddTravellers_TO{
    font-size: 18px;
    border: none;
    background: none;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}
.text-note-popup-AddTravellers_TO{
    font-size: 12px;
    color: rgb(255, 165, 165);
    position: absolute;
    bottom: 10px;
}