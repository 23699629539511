$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

.active-tripOverview{
    font-weight: bold;
}
.notActive-tripOverview{
    font-weight: lighter;
}

@media (min-width: 0px) and (max-width: 799px){
    //*
    .conatiner_p-TripOverview{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        text-align: center;
        background: rgb(249, 249, 249);
    }
    .conatiner_s-TripOverview{
        display: flex;
        flex-direction: column;
        width: 100%;
        width: 90%; 
        // border: 1px orange solid;
        border-radius: 6px;
        padding: 2%;
        background: white;
    }
}
@media (min-width: 800px){
    //*
    .conatiner_p-TripOverview{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        text-align: center;
        background: rgb(249, 249, 249);
    }
    .conatiner_s-TripOverview{
        display: flex;
        flex-direction: column;
        width: 100%;
        width: 80%; 
        // border: 1px orange solid;
        border-radius: 6px;
        padding: 2%;
        background: white;
    }
}

// //*
// .conatiner_p-TripOverview{
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     height: 100%;
//     align-items: center;
//     text-align: center;
//     background: rgb(249, 249, 249);
// }
// .conatiner_s-TripOverview{
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     width: 80%; 
//     // border: 1px orange solid;
//     border-radius: 6px;
//     padding: 2%;
//     background: white;
// }


// .container-Image-TripOverview{
//     display: flex;
//     width: 100%;
//     justify-content: center;
//     align-items: center;

//     margin-top: 1%;
//     margin-bottom: 4%;
// }
// .image-tripOverview-TripOverview{
//     width: 700px;
//     height: 200px;   
//     border-radius: 8px;
//     box-shadow: rgba(108, 107, 106, 0.444) 0px 1px 2px;
//     object-fit: cover;
// }


.conatiner_p-Heading-TripOverview{
    // border: 1px solid orange;
    background-color: rgb(248, 248, 248);
    box-shadow: rgba(244, 69, 10, 0.444) 0px 1px 2px;
    padding: 2px 0px 6px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
//!Heading
.conatiner_p-TripName-TripOverview{
    display: flex;
    flex-direction: row;
    // border: 1px solid orange;
    margin: 6px 0px 6px 0px;
    text-align: center;
    align-items: center;
    margin-bottom: 2%;
}
.heading-Heading-TripOverview{
    font-family: $font-6;
    font-size: 20px;
    margin-right: 6px;
}
.container_t-editButton-TripOverview{
    border: none;
    background: none;
}
.image-edit-1-TripOverview{
    width: 20px;
    height: 20px;
    margin-left: 10px;

    text-align: center;
    align-items: center;

    &:hover{
        background: rgb(255, 237, 223);
        border-radius: 8px;
        box-shadow: 0 4px 200px rgb(242, 180, 180);
    }
    &:active{
        background: rgb(241, 239, 239);
        border-radius: 8px;
        box-shadow: 0 4px 10px rgb(244, 209, 161);
    }
}

//!Share
.conatiner_p-ShareTrip-TripOverview{
    // border: 1px solid orange;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 2%;
}
.heading-ShareTrip-TripOverview{
    font-size: 14px;
    font-family: $font-4;
    font-weight: bold;
    color: orangered;
}
.container_t-shareButton-TripOverview{
    border: none;
    background: none;
}
.image-share-1-TripOverview{
    width: 16px;
    height: 16px;  
    margin-left: 12px;
}

.container_p-copyShareLink-TripOverview{
    position: absolute;
    border: 2px solid rgb(252, 168, 196);
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    margin-left: 2px;
    margin-top: -6px;
    // padding: 2%;
}
.button-copyShareLink-TripOverview{
    border: none;
    background: none;
    font-size: 24px;
    color: palevioletred;
}
.text-1-copyShareLink-TripOverview{
    color: lightgray;
}

//!Destination and District Name(s) 
.heading-DestinationName-TripOverview{
    color: rgb(139, 135, 135);
    font-family: $font-8;
}
.container_t-districtNames-TripOverview{
    display: flex;
    flex-direction: row;
    margin-top: 4%;
}
.heading-DistrictName-TripOverview{
    margin: 0px 5px 0px 5px;
    font-family: $font-5;
    font-size: 14px;
    color: rgb(117, 114, 114);
}



//!Not login - create itinerary
.conatiner_p-notLogin-TripOverview{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: white;
    cursor: pointer;
    // margin-top: 4%;
}
.image-notLogin-TripOverview{
    width: 40%;
    height: 40%;
    border-radius: 8px;
}
.button-heading-1-TripOverview{
    position: absolute;
    font-size: 34px;
    color: rgb(255, 0, 0);
    font-family: $font-6;
    font-weight: bold;
    border: none;
    background: none;
    background: white;
}




// /* collective css for other containers too */
// .MC-TripHeading-TripOverview{
//     margin-top: 4%;
//     border-radius: 1px orange dotted;
//     border-radius: 6px;
//     padding: 1%;
// }
// .MC-AddTripDetails-TripOverview{
//     margin-top: 4%;
//     border-radius: 1px orange dotted;
//     border-radius: 6px;
//     padding: 1%;
// }
// .MC-AddTravellers-TripOverview{
//     margin-top: 4%;
//     border-radius: 1px orange dotted;
//     border-radius: 6px;
//     padding: 1%;
// }
// .MC-AddNotes-TripOverview{
//     margin-top: 4%;
//     border-radius: 1px orange dotted;
//     border-radius: 6px;
//     padding: 1%;
// }
.MC-SavedPlaceNavigation-TripOverview{
    margin-top: 4%;
    border-radius: 1px orange dotted;
    border-radius: 6px;
    padding: 1%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
// .MC-SavedPlacesData-TripOverview{
//     margin-top: 4%;
//     border-radius: 1px orange dotted;
//     border-radius: 6px;
//     padding: 1%;
// }
// .MC-ItineraryData-TripOverview{
//     margin-top: 4%;
//     border-radius: 1px orange solid;
//     border-radius: 6px;
//     padding: 1%;
// }

/*  */
.MainConatiner-SavedPlaces_Map-TripOverview{
    width: 100%;
    // height: 600px;
    // padding: 1%;
    padding-top: 1%;
    padding-right: 1%;
    display: flex;
    flex-direction: row;
    // border: 1px solid rgb(204, 202, 202);
    border-radius: 8px;
}

@media (min-width: 0px) and (max-width: 799px){

    //image    
    .container-Image-TripOverview{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    
        margin-top: 1%;
        margin-bottom: 4%;
    }
    .image-tripOverview-TripOverview{
        width: 300px;
        height: 200px;   
        border-radius: 8px;
        box-shadow: rgba(108, 107, 106, 0.444) 0px 1px 2px;
        object-fit: cover;
    }

    /*//*Itinerary */
    .MainConatiner-Itinerary-TripOverview{
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    /*//*Saved Places */
    .MainConatiner-SavedPlaces-TripOverview{
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    /*//*Map */
    .MainConatiner-Map-TripOverview{
        width: 100%;
        height: 400px;
        margin-top: 60px;
        // padding: 1%;
        display: flex;
        flex-direction: row;
        // border: 1px solid rgb(239, 155, 0);
    }
    .MainConatiner-Fixed-Map-TripOverview{
        width: 34%;
        height: 600px;
        display: flex;
        flex-direction: row;
        position: fixed; /* or position: sticky; */
        top: 13%;
        right: 4%; /* Assuming the map is on the right side */
        height: 100vh; /* Full height of the viewport */
        z-index: 100; /* Adjust the z-index to ensure it is above other content */
    }
}
@media (min-width: 800px){

    //image    
    .container-Image-TripOverview{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    
        margin-top: 1%;
        margin-bottom: 4%;
    }
    .image-tripOverview-TripOverview{
        width: 700px;
        height: 200px;   
        border-radius: 8px;
        box-shadow: rgba(108, 107, 106, 0.444) 0px 1px 2px;
        object-fit: cover;
    }
    
    /*//*Itinerary */
    .MainConatiner-Itinerary-TripOverview{
        width: 60%;
        display: flex;
        flex-direction: row;
    }

    /*//*Saved Places */
    .MainConatiner-SavedPlaces-TripOverview{
        width: 60%;
        display: flex;
        flex-direction: row;
    }

    /*//*Map */
    .MainConatiner-Map-TripOverview{
        width: 40%;
        // padding: 1%;
        display: flex;
        flex-direction: row;
        // border: 1px solid rgb(239, 155, 0);
    }
    .MainConatiner-Fixed-Map-TripOverview{
        width: 34%;
        height: 600px;
        display: flex;
        flex-direction: row;
        position: fixed; /* or position: sticky; */
        top: 13%;
        right: 4%; /* Assuming the map is on the right side */
        height: 100vh; /* Full height of the viewport */
        z-index: 1000; /* Adjust the z-index to ensure it is above other content */
    }
}


// /*//*Itinerary */
// .MainConatiner-Itinerary-TripOverview{
//     width: 60%;
//     display: flex;
//     flex-direction: row;
// }

// /*//*Saved Places */
// .MainConatiner-SavedPlaces-TripOverview{
//     width: 60%;
//     display: flex;
//     flex-direction: row;
// }

// /*//*Map */
// .MainConatiner-Map-TripOverview000{
//     width: 40%;
//     // padding: 1%;
//     display: flex;
//     flex-direction: row;
//     // border: 1px solid rgb(239, 155, 0);
// }

// .MainConatiner-Map-TripOverview {
//     width: 46%;
//     display: flex;
//     flex-direction: row;
//     position: sticky; /* or position: sticky; */
//     top: 0;
//     right: 0; /* Assuming the map is on the right side */
//     height: 100vh; /* Full height of the viewport */
//     z-index: 100; /* Adjust the z-index to ensure it is above other content */
// }
// .MainConatiner-Fixed-Map-TripOverview{
//     width: 34%;
//     height: 600px;
//     display: flex;
//     flex-direction: row;
//     position: fixed; /* or position: sticky; */
//     top: 13%;
//     right: 4%; /* Assuming the map is on the right side */
//     height: 100vh; /* Full height of the viewport */
//     z-index: 1000; /* Adjust the z-index to ensure it is above other content */
// }
