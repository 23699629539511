$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//
// .conatiner_p-question-About_D{
//     width: 80%;
//     background-color: rgb(241, 244, 248);
//     border-radius: 8px;
//     padding: 4% 2% 2% 4%;
// }
@media (min-width: 0px) and (max-width: 799px){
    .conatiner_p-question-About_D{
        width: 100%;
        background-color: rgb(255, 254, 252);
        border-radius: 8px;
        padding: 4% 2% 2% 4%;
    }
}
@media (min-width: 800px){
    .conatiner_p-question-About_D{
        width: 80%;
        background-color: rgb(241, 244, 248);
        border-radius: 8px;
        padding: 4% 2% 2% 4%;
    }
}
//header image
.conatiner_p-qna-header-About_D{
    // width: 90%;
    // border: 10px solid yellow;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    // margin-top: 2%;
    border-radius: 6px;

    width: 100%;
    height: 180px;
    overflow: hidden;
}
.image-1-qna-header-About_D{
    width: 700px;
    // height: 160px;
    border: 1px solid rgb(255, 231, 190);
    border-radius: 6px;
    margin: 4px;
    object-fit: cover;
    // box-shadow: 0 4px 8px rgba(93, 92, 92, 0.2);
    box-shadow: 0 4px 8px rgba(246, 255, 177, 0.4);

    height: 240px;
}

.heading-0-question-About_D{
    align-self: center;
    font-size: 20px;
    font-family: $font-6;
    font-weight: bold;
    margin: 6% 2% 2% 6%;
    display: flex;
    text-align: center;
    justify-content: center;
    color: rgb(203, 120, 12);
    // background-color: rgb(218, 220, 222);
}
.heading-question-About_D{
    align-self: center;
    font-size: 19px;
    font-family: $font-heading-2;
    margin: 4% 2% 2% 6%;
    letter-spacing: 0.4px;
}
.content-1-question-About_D{
    align-self: center;
    font-size: 16px;
    // font-family: $font-content-1;
    font-family: $font-3;
    margin: 2% 2% 2% 6%;
    letter-spacing: 0.6px;
    word-spacing: 0.6px;
    color: rgb(96, 94, 94);
    font-weight: 600    ;
}