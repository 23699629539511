$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


.container_p-Footer{
    width: 100%;
    /* height: 100px; */
    border-top: 2px solid lightcoral;
    background: #f39a00;
    padding: 2% 0% 2% 0%;
    /* border-bottom: 1px lightcoral solid; */
    display: flex;
    flex-direction: column;
    margin-top: 6%;
    align-items: center;
    justify-content: center;
}


@media (min-width: 800px) {
    .container_s-Footer{
        display: flex;
        flex-direction: row;
        width: 100%;
        // border: 2px solid rgb(141, 12, 192);  
        align-items: center;
        text-align: center;
        justify-content: center;
        justify-content: space-around;
        text-align: start;
    }
}
@media (max-width: 799px) {
    .container_s-Footer{
        display: flex;
        flex-direction: column;
        width: 100%;
        // border: 2px solid rgb(128, 156, 240);
        align-items: center;
        text-align: start;
        justify-content: center;
        justify-content: space-around;
    }
}


.container-1-Footer{
    width: fit-content;//22%;
    margin-right: 2%;
    // border: 1px solid rgb(255, 255, 255);
    // border-radius: 10px;
    // padding: 1%;
    // margin-left: 12%;
}
.container-2-Footer{
    width: fit-content;//20%;
    margin-right: 2%;
    //  border: 1px solid rgb(255, 255, 255);
    /* border: 1px solid orange; */
    // border-radius: 10px;
    // padding: 1%;
}
.container-3-Footer{
    width: fit-content;//20%;
    margin-right: 2%;
    //  border: 1px solid rgb(255, 255, 255);
    /* border: 1px solid orange; */
    // border-radius: 10px;
    // padding: 1%;
}

.ul-1-Footer{
    margin-left: 9%;
}
.heading-1-Content-Footer{
    font-weight: normal;
    font-family: $font-content-1;
    letter-spacing: 0.4px;
    font-size: 14px;
}
.heading-2-Content-Footer{
    font-weight: normal;
    font-family: $font-content-1;
    // font-family: $font-6;
    letter-spacing: 0.4px; 
    margin-bottom: 2px;
    margin-top: 12px;
    font-size: 14px;
}

.content-Footer-Home3{
    font-family: $font-6;
    // font-family: $font-content-1;
    color: #ffebeb;
    font-weight: normal;
    letter-spacing: 0.6px;
    font-size: 14px;
}
.button-1-Footer{
    font-family: $font-6;
    // font-family: $font-content-1;
    color: #fdfdfd;
    font-weight: normal;
    letter-spacing: 0.6px;
    border: none;
    background: none;
    font-size: 14px;
    margin: 1px 0px 4px 0px;
    align-self: self-start;
    text-align: start;
    white-space: nowrap;
    // padding-bottom: 1px;

    &:hover{
        border-bottom: 1px solid rgb(30, 134, 238);
        transition: border-bottom 0.3s ease;
        // letter-spacing: 0.8px;
    }
    &:active{
        border-bottom: 1px solid rgb(255, 42, 0);
        transition: border-bottom 0.3s ease;
        letter-spacing: 0.3px;
        transition: letter-spacing 0.3s ease;
    }
}
.button-2-Footer{
    font-family: $font-6;
    color: #fdfdfd;
    font-weight: normal;
    letter-spacing: 0.6px;
    border: none;
    background: none;
    font-size: 14px;
    margin: 12px 0px 0px 0px;
    // padding-bottom: 1px;
    transition: border-bottom 0.3s ease;

    &:hover{
        border-bottom: 1px solid rgb(30, 134, 238);
        transition: border-bottom 0.3s ease;
        // letter-spacing: 0.8px;
    }
    &:active{
        border-bottom: 1px solid rgb(255, 42, 0);
        transition: border-bottom 0.3s ease;
        letter-spacing: 0.3px;
        transition: letter-spacing 0.3s ease;
    }
}

//social media profiles
.container_p-mediaIcons-Footer{
    display: flex;
    flex-direction: row;
    // border: white 1px solid;
    margin-top: 4%;
    margin-left: 0%;

    a{
        color: rgb(0, 0, 0);
        margin-right: 6px;
        transition: color 0.3s ease;
        font-size: 18px;

        &:hover{
            color: rgb(0, 143, 209);
        }
        &:active{
            color: red;
        }
    }
}
.SocialMediaLink-SocialMediaIcons{
    text-decoration: none;
    color: black;
    font-size: large;
    margin-left: 6%;
}
.SocialMediaLink-SocialMediaIcons:hover{
    color: rgb(255, 115, 0);
}
//Share
.container_p-share-Footer{
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 4%;
}



//! copyright
.container4-Footer-Footer{
    display: flex;
    flex-direction: row;  
    align-items: center;  
    width: 100%;
    text-align: center;
    justify-content: center;
    /* border: 2px solid rgb(128, 156, 240); */
    background: rgb(255, 255, 255);
    margin-top: 4%;
}
.logo-Home-Footer-Footer{
    width: 30px;
    height: 30px;
    border-radius: 10px;
    margin-right: 34px;
    border: 2px rgb(255, 224, 224) solid;
    box-shadow: 0 0 8px rgba(55, 55, 55, 0.3); 
}
.container_p-copyrightText-Footer{
    font-size: 14px;
    font-family: $font-6;
}