$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
//
//!main

.container_p-places-top10-Overview_D_Top10{
    width: 100%;
    // border: 4px rgb(198, 134, 30) solid;
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 12px;
}
.container_s-places-top10-Overview_D_Top10{
    width: 600px;
    height: 220px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 8% 0% 0% 0%;
    // border: 4px rgb(239, 239, 239) solid;
}
.heading-places-Overview_D_Top10{
    font-size: 28px;
    position: absolute;
    font-family: $font-heading-1;  
    margin: 4px 0px 0px 0px; 
    color: rgb(255, 255, 255);
    background-color: rgb(237, 79, 0);
    font-weight: bold;
    padding: 0px 8px 0px 8px;
    word-spacing: 0.4px;
    margin-top: 80px;
    // margin-left: -150px;
    // left: 30%;
    // bottom: 1%;
}
// //!Famous Places
// .container_p-places-Overview_D_Top10{
//     width: 100%;
//     height: 20%;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-around;
//     // background-color: rgb(237, 207, 217);
//     align-items: center;
//     overflow: hidden;
//     margin-top: 4%;
// }

//!Mobile
@media (min-width: 0px) and (max-width: 799px){
    //!Famous Places
    .container_p-places-Overview_D_Top10{
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        // background-color: rgb(237, 207, 217);
        align-items: center;
        overflow: hidden;
        margin-top: 4%;
    }
}
@media (min-width: 800px){
    //!Famous Places
    .container_p-places-Overview_D_Top10{
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        // background-color: rgb(237, 207, 217);
        align-items: center;
        overflow: hidden;
        margin-top: 4%;
    }
}

.container_t-1-imgHead-places-Overview_D_Top10{
    width: 20%;
    display: flex;
    flex-direction: column;
    // border: 4px rgb(113, 72, 200) solid;
}
.container_t-2-main-places-Overview_D_Top10{
    width: 86%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.container_t-image-places-Overview_D_Top10{
    width: 190px;
    height: 180px;
    border-radius: 200px;
    border: 4px rgb(239, 239, 239) solid;
}
.image-places-Overview_D_Top10{
    width: 100%;
    height: 70%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    // border-radius: 200px;
    // border: 4px rgb(239, 239, 239) solid;
    object-fit: cover;
    border-radius: 8px;
}
.image-bestTime-aboutDestination{
    width: 240px;
    height: 220px;
    border-radius: 200px;
    border: 4px rgb(239, 239, 239) solid;
}

.card-place-Overview_D_Top10{
    width: 140%;
    margin: 2%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 1%;
    // height: 50%;
    // border: 4px rgb(192, 236, 0) solid;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

//*inside card elements css
.scroll-button-left-Overview_D_Top10{
    width: 5%;
    height: 100%;
    font-size: 60px;
    align-items: center;
    text-align: center;
    color: rgb(91, 91, 91);
    background: none;
    border: none;
}
.scroll-button-left-Overview_D_Top10:hover{
    color: black;
}
.scroll-button-right-Overview_D_Top10{
    width: 5%;
    height: 100%;
    font-size: 60px;
    align-items: center;
    text-align: center;
    color: rgb(91, 91, 91);
    background: none;
    border: none;
}
.scroll-button-right-Overview_D_Top10:hover{
    color: black;
}
.star-card-Overview_D_Top10{
    font-size:24px; 
    text-align:center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}
.reviewCount-card-Overview_D_Top10{
    font-size:24px; 
    color: brown;
    font-family: $font-content-2;
}
.container_t-icons-hotels-card-Overview_D_Top10{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 16px;
    margin: 4px 2px 2px 2px;
    background-color: rgb(238, 238, 238);
    border-radius: 8px;
}
.text-about-hotels-card-Overview_D_Top10 {
    /* background-color: rgb(236, 236, 236); */
    border-radius: 8px;
    margin: 6px 2px 2px 2px;
    color: rgb(65, 65, 65);
    height: 100px;
    overflow: hidden;
    // white-space: nowrap;
    text-overflow: ellipsis;
}

.icon-hotels-card-Overview_D_Top10{
    font-family: $font-content-2;
    margin: 2px;
    padding: 2px;
    // color: gray;
    color: rgb(64, 63, 63);
}
.container_t-Content-places-Overview_D_Top10{
    width: 90%;
    // width: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;

    // border: 10px solid red;
    // border: 4px rgb(37, 94, 185) solid;
    // flex-wrap: nowrap; /* Add this line */

}
.image-place-card-Overview_D_Top10{
    object-fit: cover;
    width: 340px;
    height: 280px;
    margin-top: 0px;
    // border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.heading-placeName-card-Overview_D_Top10{
    // font-family: $font-heading-2;
    font-family: $font-content-1;
    font-size: 22px;
    margin: 4px;
}
.text-placeName-card-Overview_D_Top10{
    font-family: $font-heading-2;
    margin: 4px;
}
.content-overview-card-Overview_D_Top10{
    font-family: $font-heading-2;
    font-size: 17px;
    color: rgb(89, 89, 89);
    // margin: 3%;
    width: 100%;
    text-align: start;
    margin: 10px 4px 4px 4px;
    word-spacing: 0.1px;
    // display: flex;
    // align-items: start;
    // justify-content: start;
    // border: 4px rgb(239, 239, 239) solid;
}
.text-card-Overview_D_Top10{
    color: rgb(7, 70, 159);
    font-family: $font-content-2;
    margin: 8px 4px 8px 4px;
    font-size: 20px;
}
.container_t-hashtags-card-Overview_D_Top10{
    display: flex;
    flex-direction: row;
    // border: 4px rgb(239, 239, 239) solid;
    display: flex;
    justify-content: space-around;
    color: rgb(139, 60, 0);
    margin: 8px 4px 4px 4px;
    font-weight: bold;
}