$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//



//!Navigation 2
.container_p-BookDirect-Search_Tool_2{
    display: flex;
    flex-direction: row;
    margin-top: 1%;
    align-items: center;
    text-align: center;
}
.conatiner_s-2-BookDirect-Search_Tool_2{
    margin-left: 3%;
}
.heading-1-BookDirect-Search_Tool_2{
    color: rgb(79, 76, 76);
    font-family: $font-3;
    font-size: 16px;
    // margin-top: 4%;
    margin-left: 6px;
    letter-spacing: 0.4px;
}
.heading-2-BookDirect-Search_Tool_2{
    color: rgb(212, 63, 0);
    font-family: $font-6;
    font-size: 15px;
    letter-spacing: 0.2px;
}
.conatiner_s-active-navigation_2-Search_Tool_2{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;

    font-family: $font-6;
    // background: rgb(255, 70, 70);
    background: whitesmoke;
    border: none;
    border-top: none;
    border-left: none;
    border-right: none;
    // border-bottom: none;
    // margin: 12px 20px 2px 0px;
    padding: 4px 8px 4px 8px;
    border-radius: 10px;

    padding-bottom: 4px;
    border-bottom: 2px solid rgb(247, 0, 0);

    &:hover{
        // background-color: orange;
        background-color: rgb(255, 238, 238);
        transition: background-color 0.3s ease;
        border-radius: 8px;
    }
    &:active{
        background-color: rgb(255, 221, 221);
        transition: background-color 0.3s ease;
    }
}
.conatiner_s-unActive-navigation_2-Search_Tool_2{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;

    font-family: $font-6;
    // background: rgb(255, 70, 70);
    background: whitesmoke;
    border: none;
    border-top: none;
    border-left: none;
    border-right: none;
    // border-bottom: none;
    // margin: 12px 20px 2px 0px;
    padding: 4px 8px 4px 8px;
    border-radius: 10px;

    padding-bottom: 4px;
    // border-bottom: 2px solid rgb(247, 0, 0);

    &:hover{
        // background-color: orange;
        background-color: rgb(255, 238, 238);
        transition: background-color 0.3s ease;
        border-radius: 8px;
    }
    &:active{
        background-color: rgb(255, 221, 221);
        transition: background-color 0.3s ease;
    }
}
.heading-1-active-navigation_1-Search_Tool_2{
    font-family: $font-6;
    font-size: 16px;
    // color: rgb(0, 0, 0);
    color: orangered;
    background: white;
    border: none;
    // margin: 12px 20px 2px 0px;
    padding: 1px 8px 1px 8px;
    border-radius: 6px;
}
.heading-1-unActive-navigation_1-Search_Tool_2{
    font-family: $font-6;
    font-size: 16px;
    color: rgb(77, 76, 76);
}
.img-1-navigation_1-Search_Tool_2{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.icon-1-BookDirect-Search_Tool_2{
    margin-left: 12px;
    font-size: 16px;
}


//!Navigation 3
// .container_p-navigation_3-Search_Tool_2{
//     margin-top: 4%;
//     width: 100%;
//     border: 1px solid olive;
// }

//*container 1
// .conatiner_p-SortData-navigation_3-Search_Tool_2{
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     text-align: center;
//     border: 1px solid burlywood;
// }


//*container 2
@media (min-width: 0px) and (max-width: 849px){
    .conatiner_p-2-0-navigation_3-Search_Tool_2_Hotel_PT{
        background: rgb(255, 255, 255);
        box-shadow: 0 0 8px rgba(188, 185, 185, 0.989);
        padding: 4px 8px 4px 8px;
        border-radius: 8px;
        position: absolute;
        z-index: 10;
        width: 80%;
    }
}
@media (min-width: 850px){
    .conatiner_p-2-0-navigation_3-Search_Tool_2_Hotel_PT{
        background: rgb(255, 255, 255);
        box-shadow: 0 0 8px rgba(188, 185, 185, 0.989);
        padding: 4px 8px 4px 8px;
        border-radius: 8px;
        position: absolute;
        z-index: 10;
        width: 50%;
    }
}
// .conatiner_p-2-0-navigation_3-Search_Tool_2_Hotel_PT{
//     background: rgb(255, 255, 255);
//     box-shadow: 0 0 8px rgba(188, 185, 185, 0.989);
//     padding: 4px 8px 4px 8px;
//     border-radius: 8px;
//     position: absolute;
//     z-index: 10;
//     width: 50%;
// }
.conatiner_p-2-navigation_3-Search_Tool_2{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // border: 1px solid plum;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 6px 6px 6px 2px;
}
//*2.1
//other containers

//?Main Container
.conatiner_t-2-1-navigation_3-Search_Tool_2{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    // width: 100%;
    width: fit-content;
    background: whitesmoke;

    margin-right: 30px;
    margin-left: 6px;
    margin-top: 12px;
    padding: 4px 12px 4px 12px;
    border-radius: 10px;
}
//description
.heading-1-active-amenity-Search_Tool{
    font-size: 14px;
    color: rgb(150, 145, 145);
    font-family: $font-6;
    margin-right: 10px;
    white-space: nowrap;
}
//?Button Conatiner
.button-active-Amenity-Search_Tool{
    background-color: white;
    // border: none;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;

    border: 1px salmon solid;
    // box-shadow: 0 0 2px rgba(149, 147, 147, 0.989);
    padding: 2px 8px 2px 8px;
    margin: 2px 8px 2px 8px;

    &:hover{
        border: 1px rgb(255, 53, 31) solid;
    }
    &:active{
        background: rgb(255, 224, 224);
    }
}
.button-unActive-Amenity-Search_Tool{
    background-color: white;
    border: none;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;

    border: 1px rgb(255, 255, 255) solid;
    padding: 2px 8px 2px 8px;
    margin: 2px 8px 2px 8px;

    &:hover{
        border: 1px rgb(255, 221, 177) solid;
    }
    &:active{
        background: rgb(255, 224, 224);
    }
}
//?text active and unactive
.title-1-active-amenity-Search_Tool{
    font-family: $font-6;
    color: black;
    // font-weight: bold;
    white-space: nowrap;
    font-size: 16px;
}
.title-1-unActive-amenity-Search_Tool{
    font-family: $font-6;
    color: rgb(110, 107, 107);
    white-space: nowrap;
    font-size: 16px;
}
//?hover active and unactive
.conatiner_p-hover-active-amenity-Search_Tool{
    background-color: rgb(234, 234, 234);
    border: none;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;

    box-shadow: 0 0 10px rgba(111, 109, 109, 0.1);
    padding: 2px 4px 2px 4px;
    margin: 2px 8px 2px 8px;
}
.conatiner_p-hover-unActive-amenity-Search_Tool{
    background-color: white;
    border: none;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;

    box-shadow: 0 0 10px rgba(111, 109, 109, 0.1);
    padding: 2px 4px 2px 4px;
    margin: 2px 8px 2px 8px;
}
.title-1-hover-amenity-Search_Tool{
    font-family: $font-6;
    color: black;
    font-weight: 500px;
}
.conatiner_p-hover-otherAmenities-amenity-Search_Tool{
    position: absolute;
    padding: 2px 4px 2px 4px;
    margin-left: 360px;
    margin-top: -100px;
    z-index: 100;
}

//?Icons
.icon-1-amenity-Search_Tool{
    margin: 0px 6px 0px 6px;
    font-size: 22px;
}
.icon-2-amenity-Search_Tool{
    font-size: 18px;
    color: red;
}
.img-1-amenity-Search_Tool{
    width: 24px;
    height: 24px;
    margin: 0px 6px 0px 6px;
}
//*2.2
.conatiner_t-2-2-navigation_3-Search_Tool_2{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 100%;
}
.conatiner_p-hover-ratings-navigation_3-Search_Tool_2{
    position: absolute;
    display: flex;
    flex-direction: row;
    background: rgb(255, 209, 209);
    margin-left: 100px;
    padding: 4px 4px 4px 4px;
    border: none;
    border-radius: 8px;
}

//2.3
.conatiner_t-2-3-navigation_3-Search_Tool_2{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 100%;
}

//*container 3
.container_p-umderKm-Search_Tool_2{
    margin-top: 4%;
}
.container_s-umderKm-Search_Tool_2{
    display: flex;
    flex-direction: row;
}
//?Button Conatiner
.button-active-umderKm-Search_Tool_2{
    background-color: rgb(244, 240, 240);
    border: none;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;

    // border: 1px salmon solid;
    box-shadow: 0 0 2px rgba(211, 206, 206, 0.989);
    padding: 4px 8px 4px 8px;
    margin: 2px 8px 2px 8px;
}
.button-unActive-umderKm-Search_Tool_2{
    background-color: white;
    border: none;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;

    padding: 4px 8px 4px 8px;
    margin: 2px 8px 2px 8px;
}



//!Navigation 3
//
.conatiner_p-SortData-navigation_3-Search_Tool_2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 4%;
    // border: 1px solid lightseagreen;
}

//*#2
.conatiner_t-1-navigation_3-Search_Tool_2{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    // padding: 12px 8px 12px 8px;
    // border-radius: 6px;
    // background: rgb(255, 246, 247);

    // &:hover{
    //     background: rgb(246, 117, 25);
    // }
}
.heading-1-navigation_3-Search_Tool_2{
    font-family: $font-6;
    color: rgb(0, 0, 0);
    border: none;
    padding: 5px 8px 4px 8px;
    border-radius: 8px;
    font-size: 18px;
    background: rgb(240, 237, 237);
    color: rgb(67, 65, 64);
    box-shadow: 0 0 8px rgba(255, 239, 239, 0.989);


    &:hover{
        background: whitesmoke;
    }
}
.icon-1-sortData-navigation_3-Search_Tool_2{
    margin-left: 14px;
    font-size: 18px;
    color: rgb(211, 137, 0);
}

//*#2
.conatiner_t-2-navigation_3-Search_Tool_2{
    display: flex;
    flex-direction: row;
}
.conatiner_t-save_revert-navigation_3-Search_Tool_2{
    display: flex;
    flex-direction: row;
}
//?
.button-save-navigation_3-Search_Tool_2{
    background-color: rgb(255, 90, 90);
    border: none;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;

    // border: 1px salmon solid;
    box-shadow: 0 0 4px rgba(255, 178, 178, 0.989);
    padding: 0px 10px 0px 10px;
    margin: 2px 8px 2px 8px;

    &:hover{
        background-color: orange;
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(155, 206, 53);
        transition: background-color 0.3s ease;
    }
}
.heading-1-save-navigation_3-Search_Tool_2{
    font-size: $font-6;
    color: white;
    font-size: 18px;
    margin-top: -4px;
}
.icon-1-save-navigation_3-Search_Tool_2{
    margin-left: 12px;
    font-size: 24px;
    color: whitesmoke;
}
//?
.button-revertChanges-navigation_3-Search_Tool_2{
    background-color: white;
    // border: none;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;

    border: 1px rgb(99, 97, 96) solid;
    // box-shadow: 0 0 2px rgba(149, 147, 147, 0.989);
    padding: 2px 10px 2px 10px;
    margin: 2px 8px 2px 8px;

    &:hover{
        border: 2px solid rgb(99, 97, 96);
        transition: border 0.3s ease;
    }
    &:active{
        background-color: rgb(155, 206, 53);
        transition: background-color 0.3s ease;
    }
}
.heading-1-revertChanges-navigation_3-Search_Tool_2{
    font-family: $font-5;
    color: rgb(64, 62, 62);
    font-size: 16px;
}
.icon-1-revertChanges-navigation_3-Search_Tool_2{
    margin-left: 8px;
    font-size: 18px;
}

//*#3
.buttton-clearAll-Navigation_3-Search_Tool_2{
    font-size: 16px;
    border: none;
    background: none;
    // background: whitesmoke;
    margin-top: 2%;
    padding: 2px 6px 2px 6px;
    border-radius: 12px;
    font-family: $font-7;
    color: brown;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}
