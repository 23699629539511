$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


.container_p-Itinerary_TO_Components{
    margin: 2% 0% 1% 0%;
    background-color: white;
    display: flex;
    border-radius: 12px;
    padding: 1% 1% 1% 1%;
    display: flex;
    flex-direction: column;
    border: 2px solid rgb(225, 221, 220);
}
.container_s-Itinerary_TO_Components{
    display: flex;
    flex-direction: column;
    // align-items: self-start;
    // text-align: start;
}

.text-index-Itinerary_TO_Components{
    position: absolute;
    // background: brown;
    color: rgb(101, 101, 101);//white;
    padding: 0px 4px 1px 4px;
    border-radius: 50px;
    // right: 0px;
    // bottom: 0px;
    // margin-top: -20px;
    font-size: 14px;
    font-weight: bold;
    top: 10px;
    left: -6px;
}

.text-placeName-Itinerary_TO_Components{
    border: 2px solid whitesmoke;
    // background: whitesmoke;
    // border-bottom: 1px solid rgb(255, 209, 209);
    color: rgb(156, 0, 0);
    // width: 460px;
    width: 90%;
    // width: fit-content;
    padding: 6px 6px 6px 6px;
    margin: 12px 6px 4px 8px;
    border-radius: 4px;
    text-align: start;
    align-items: self-start;
    font-family: $font-3;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.6px;
}