$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//



@media (min-width: 0px) and (max-width: 799px){
    .conatiner_p-SavedPlacesCI{
        width: 100%;
        // height: 100%;
        margin-top: -2px;
        padding: 4px 0px 0px 0px;
        // background: rgb(157, 155, 155);
    }
    .conatiner_s-SavedPlacesCI{
        width: 98%;
        height: 100%;
        border-radius: 6px;
        padding: 4px;
        // overflow-y: scroll;
        // overflow-x: hidden;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: -2px;
        background: rgb(255, 255, 255);
    }
}
@media (min-width: 800px){
    .conatiner_p-SavedPlacesCI{
        width: 100%;
        height: 100%;
        margin-top: -2px;
        padding: 4px 0px 0px 0px;
    }
    .conatiner_s-SavedPlacesCI{
        width: 98%;
        height: 100%;
        border-radius: 6px;
        padding: 4px;
        // overflow-y: scroll;
        // overflow-x: hidden;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: -2px;
    }
}


// .conatiner_p-SavedPlacesCI{
//     width: 100%;
//     height: 100%;
//     margin-top: -2px;
//     padding: 4px 0px 0px 0px;
// }
// .conatiner_s-SavedPlacesCI{
//     width: 98%;
//     height: 100%;
//     border-radius: 6px;
//     padding: 4px;
//     // overflow-y: scroll;
//     // overflow-x: hidden;
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     justify-content: space-around;
//     margin-top: -2px;
// }
.conatiner_s-SavedPlacesCI::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.conatiner_s-SavedPlacesCI::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.conatiner_s-SavedPlacesCI::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  

.conatiner_p-card-SavedPlacesCI{
    width: 100%;
    border: 1px solid red;
    margin: 8px;
    border-radius: 8px;
    // padding: 4px 4px 4px 4px;

    display: flex;
    flex-direction: column;
    // background: white;
}

.card-TouristPlace-SavedPlacesCI{
    margin: 2%;

    // width: 230px;
    border: rgb(215, 212, 212) 2px solid;
    border-radius: 10px;
    margin: 4px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 16px;
    background-color: none;
    background:transparent;
    align-items: start;
    cursor:auto;

    text-align: start;
}
.image-Card-TouristPlace-SavedPlacesCI{
    overflow: hidden;
    width: 100%;
    height: 190px;
    object-fit: cover;
    // margin-top: 0.5%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-radius: 10px;
}
.heading-placeName-TouristPlace-SavedPlacesCI{
    font-family: $font-6;
    margin: 8px 2px 2px 12px;
    display: flex;
    align-self: flex-start;
}
.heading-2-placeName-TouristPlace-SavedPlacesCI{
    font-family: $font-6;
    margin: 0px 0px 0px 4px;
    color: rgb(88, 87, 87);
}
.heading-3-placeName-TouristPlace-SavedPlacesCI{
    font-family: $font-6;
    // margin: 6px 2px 2px 12px;
    color: brown;
}
.heading-4-card-duration-TouristPlace-SavedPlacesCI{
    font-family: $font-content-1;
    margin: 4px 0px 8px 14px;
    color: rgb(159, 154, 154);
    font-size: 16px;
}
.container_t-2-placeName-TouristPlace-SavedPlacesCI{
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // text-align: center;
}
.heading-4-card-placeName-TouristPlace-SavedPlacesCI{
    font-family: $font-4;
    margin: 0px 0px 0px 30px;
    color: rgb(126, 46, 46);
    font-size: 14px;
    // display: flex;
    // align-self: flex-end;
}


.line-card-SavedPlacesCI{
    border-top: 0.4px dotted #ddd5d5;
    width: 90%;
    // margin-top: 4%;
    margin: 10px 0px 8px 0px;
}

.button-viewMore-card-SavedPlacesCI{
    border-radius: 8px;
    font-family: $font-content-2;
    letter-spacing: 0.4px;
    word-spacing: 0.4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    color: #c47003;
    background: none;
    border: none;
    padding: 4px 100px 4px 100px;
    margin: 4px 0px 10px 0px;
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Transition for smooth effect */
    text-shadow: 1px 1px 6px rgb(255, 220, 167); 


    &:hover {
        // background-color: darken(#ebaa65, 10%);
        // color: white;
        // color: rgb(0, 0, 0);
        color: #db7f06;
        text-shadow: 1px 1px 10px rgb(243, 191, 114); 
        // font-family: $font-5;
        font-family: $font-content-2;
        font-weight: 16px;
    }

    &:active {
        // background-color: lighten(#ff9203, 10%);
        background-color: lighten(#e6d6bd, 10%);
    } 
}