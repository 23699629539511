
$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

.Itinerary_TO-Main-Itinerary_TO{
    width: 100%;
    height: 100%;
    padding: 1%;
    border: 1px solid rgb(65, 150, 220);
}


//not found
.img-notFound-Itinerary_TO{
    border-radius: 8px;
    width: 300px;
    height: 200px;
    object-fit: cover;
    margin-top: 8px;
    // margin-left: -50%;
    // background: greenyellow;
    // width: 100%;
}
.text-1-notFound-Itinerary_TO{
    font-size: 18px;
    color: brown;
    font-family: $font-6;
    // margin-left: -44%;
}


.conatiner_p-Itinerary_TO{
    width: 99%;
    height: 100%;
    // padding-right: 2%;
    // margin-top: -2px;
    // padding: 4px 0px 0px 0px;
    // border: 4px dotted rgb(180, 148, 148);
}
.conatiner_s-Itinerary_TO{
    width: 98%;
    height: 100%;
    border-radius: 6px;
    // border: 4px dotted rgb(255, 255, 255);
    // padding: 4px;
    //?
    // overflow-y: scroll;
    // overflow-x: hidden;
    //?
    // display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    // justify-content: space-around;
    // margin-top: -2px;
}
// .conatiner_p-Days-Itinerary_TO::-webkit-scrollbar {
//     height: 4px;
//     scrollbar-width: 0px;
//     border-radius: 60px;
// }
// .conatiner_p-Days-Itinerary_TO::-webkit-scrollbar-track-piece {
//     background-color: #e6e3e0;
// }
// .conatiner_p-Days-Itinerary_TO::-webkit-scrollbar-thumb:horizontal {
//     height: 2px;
//     // width: 200px;
//     background-color: #ffd0a0;
//     border-radius: 60px;  
// }  


@media (min-width: 0px) and (max-width: 799px){
    //? Itinerary Navigation
    .conatiner_p-Navigation-Itinerary_TO{
        display: flex;
        flex-direction: row;
        background-color: rgb(255, 255, 255);
        width: fit-content;
        flex-wrap: wrap;
        // width: 70%;
        // justify-content: space-around;
        border-radius: 8px;
        padding: 6px 0px 6px 0px;
        margin-left: 4px;
        margin-top: 6%;
    }
}
@media (min-width: 800px){
    //? Itinerary Navigation
    .conatiner_p-Navigation-Itinerary_TO{
        display: flex;
        flex-direction: row;
        background-color: rgb(255, 255, 255);
        width: fit-content;
        flex-wrap: wrap;
        // width: 70%;
        // justify-content: space-around;
        border-radius: 8px;
        padding: 6px 0px 6px 0px;
        margin-left: 4px;
    }
}

// //? Itinerary Navigation
// .conatiner_p-Navigation-Itinerary_TO{
//     display: flex;
//     flex-direction: row;
//     background-color: rgb(255, 255, 255);
//     width: fit-content;
//     flex-wrap: wrap;
//     // width: 70%;
//     // justify-content: space-around;
//     border-radius: 8px;
//     padding: 6px 0px 6px 0px;
//     margin-left: 4px;
// }
.button-active-Navigation-Itinerary_TO{
    display: flex;
    flex-direction: row;
    background: none;
    font-family: $font-6;
    font-size: 16px;
    background: white;
    // background-color: rgb(255, 229, 229);
    // border: 1px solid rgb(235, 235, 235);
    // border-radius: 6px;
    // padding: 8px 12px 8px 12px;
    border-radius: 8px;
    padding: 4px 6px 4px 8px;
    margin: 0px 6px 0px 0px;
    border-bottom: 2px solid orangered;
    border-top: none;
    border-right: none;
    border-left: none;
    // border-radius: 6px;
    color: rgb(53, 53, 53);
    margin-left: 4px;
    transition: background-color 0.3s ease;

    font-weight: bold;
    
    &:hover{
        background-color: beige;
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(255, 96, 96);
        transition: background-color 0.3s ease;
        color: white;
    }
}
.button-unActive-Navigation-Itinerary_TO{
    display: flex;
    flex-direction: row;
    background: none;
    font-family: $font-6;
    font-size: 16px;
    background: white;
    border-radius: 8px;
    padding: 4px 6px 4px 8px;
    margin: 0px 6px 0px 0px;
    // border-bottom: none;
    // border-top: none;
    // border-right: none;
    // border-left: none;
    border: 1px solid rgb(235, 235, 235);
    text-align: center;
    height: fit-content;
    // border-radius: 6px;
    color: rgb(53, 53, 53);
    margin-left: 4px;
    transition: background-color 0.3s ease;
    
    &:hover{
        background-color: beige;
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(255, 96, 96);
        transition: background-color 0.3s ease;
        color: white;
    }
}

//!
.heading-Active-Day-Itinerary_TO{
    font-family: $font-6;
    font-weight: bold;
    color: brown;
    white-space: nowrap;
    font-size: 16px;
    padding: 6px 6px 6px 6px;
    border-radius: 8px;
}
.heading-unActive-Day-Itinerary_TO{
    font-family: $font-6;
    color: rgb(131, 127, 127);
    white-space: nowrap;
    font-size: 16px;
    padding: 6px 6px 6px 6px;
    border-radius: 8px;
}

//!Days Container
.conatiner_p-Days-Itinerary_TO{
    display: flex;
    flex-direction: row;
    // border: gray 1px solid;
    margin: 0px 0% 4% 0%;
    overflow-y: hidden;
    overflow-x: scroll;
    padding-bottom: 3%;
    background: white;
    padding: 0px 0px 4px 0px;
    border-radius: 6px;
}
.popup_s-addNote-Entities_TO_Popup::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.popup_s-addNote-Entities_TO_Popup::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.popup_s-addNote-Entities_TO_Popup::-webkit-scrollbar-thumb:horizontal {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  

.conatiner_s-Days-Itinerary_TO{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 10px;
    // border: rgb(214, 214, 214) 1px solid;
    border: none;
    border-radius: 6px;
    padding: 8px 8px 8px 8px;
    background: none;
}