$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


//Question
.conatiner_t-question-PromptAndOverview{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    margin-top: 10px;
    margin-left: 12px;
}
.text-1-question-PromptAndOverview{
    color: brown;
    font-size: 28px;
    font-family: $font-3;
    font-weight: bold;
    margin-right: 14px;
}
.text-2-question-PromptAndOverview{
    color: rgb(239, 16, 16);
    // rgb(239, 16, 16)
    font-size: 32px;
    font-family: $font-6;
    margin-top: 6px;
    text-align: start;
}

.text-1-heading-PromptAndOverview{
    color: rgb(100, 97, 97);
    font-size: 20px;
    margin-left: 50px;
    margin-top: 20px;
    font-family: $font-4;
    border-bottom: 8px solid sandybrown;
    // padding-bottom: -80px;
    width: fit-content;
}
// .button-read_M_L-PromptAndOverview{
//     background-color: transparent;
//     color: #3b3c3d;
//     border: none; 
//     font-size: 16px; 
//     font-family: $font-3;
//     font-weight: bold; 
//     cursor: pointer; 
//     padding: 5px 10px; 
//     transition: color 0.3s ease; 

//     &:hover{
//         color: #68481e;
//         text-decoration: underline;
//     }
// }

/* Button styling */
.button-read_M_L-PromptAndOverview {
    background-color: transparent;
    color: #0061c3;
    border: none;
    font-size: 14px;
    font-family: $font-3;
    letter-spacing: 1px;
    font-weight: bold;
    cursor: pointer;
    padding: 5px 10px 5px 0px;
    transition: color 0.3s ease;

    &:hover {
        color: #68481e;
        text-decoration: underline;
    }
}

/* Animation for expanding and collapsing content */
.content-read-more-less {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
}

/* When expanded, show content */
.content-read-more-less.expanded {
    max-height: 500px; /* Set a max height that fits your content size */
    opacity: 1;
}




//!
//? Rich Text - End
.content-1-data-PromptAndOverview{
    // background: white;
    width: 90%;
    background: rgb(255, 255, 255);
    color: rgb(56, 54, 54);
    // border: 1px solid olive;
    align-items: self-start;
    text-align: start;
    padding: 10px;
    margin: 6px 10px 10px 40px;
    border-radius: 12px;
    font-family: $font-6;
    // font-family: $font-heading-2;
    font-size: 16px;
    letter-spacing: 0.4px;
    word-spacing: 0.6px;
    line-height: 1.6;  
}


/* Adding styles for ul and li elements */
.content-1-data-PromptAndOverview ul {
    padding-left: 20px; /* Adjust as needed */
    margin-bottom: 20px; /* Adjust as needed */
}
.content-1-data-PromptAndOverview ol {
    padding-left: 20px; /* Adjust as needed */
    margin-bottom: 20px; /* Adjust as needed */
    font-family: $font-6;
}

.content-1-data-PromptAndOverview li {
    margin-bottom: 10px; /* Space between list items */
    line-height: 1.6; /* Adjust for spacing within list items */
    // font-family: $font-6;
    // color: rebeccapurple;
    // font-weight: normal;
    // font-family: $font-4;
}

.content-1-data-PromptAndOverview h1 {
    // font-family: $;
    font-size: 17px;
    margin-top: 8px;
}

.content-1-data-PromptAndOverview h2 {
    // font-family: $;
    font-size: 17px;
    color: rgb(52, 51, 51);
    margin-top: 8px;
    margin-bottom: 6px;
    font-family: $font-heading-1;
    letter-spacing: 0.8px;
}

.content-1-data-PromptAndOverview h3 {
    // font-family: $;
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 8px;
    font-family: $font-6;
    letter-spacing: 0.8px;
    font-weight: normal;
    // color: gray;
    text-decoration: underline;
}
//? Rich Text - End




.loading-1-data-PromptAndOverview{
    background: linear-gradient(90deg, #484848, #ff9c9c, #bababa); /* Gradient colors */
    background-size: 200% 100%; /* Makes the gradient move */
    animation: loading 2s infinite;
    color: transparent; /* Hides text content */
    -webkit-background-clip: text; /* Shows gradient only on text */
    background-clip: text; /* For non-webkit browsers */
    font-size: 16px;
    font-family: $font-4;
    margin-left: 6%;
    font-weight: bold;
  }
  
  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  


