$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//


.Map-Map_HowToReach{
    width: 600px;
    height: 800px;
}

.container_p-Cards-Air-Map_HowToReach_Mobile{
    margin: 4px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background: blueviolet;
}
.card-Air-Map_HowToReach_Mobile{
    margin: 8px;
    padding: 4% 4px 4% 4px;
    border-radius: 6px;
    width: 160px;
    box-shadow: 0 4px 8px rgba(93, 92, 92, 0.2);
    border: none;
    background: rgb(255, 255, 255);
    text-align: start;
    align-items: self-start;
}
.text-1-air-Map_HowToReach_Mobile{
    font-family: $font-6;
    margin: 4px;
    font-size: 16px;
} 
.text-2-air-Map_HowToReach_Mobile{
    font-family: $font-4;
    margin: 6% 0px 4% 0px;
} 
.text-3-air-Map_HowToReach_Mobile{
    margin: 4% 0px 4% 0px;
    display: flex;
    text-align: start;
    align-items: self-start;
    justify-content: start;
} 
.text-3-1-air-Map_HowToReach_Mobile{
    font-family: $font-content-1;
    color: rgb(128, 124, 124);
} 
.text-4-air-Map_HowToReach_Mobile{
    font-family: $font-heading-1;
    color: #7b7979;
    margin: 4% 0px 4% 0px;
    // background: rgb(233, 228, 228);
}
.text-5-air-Map_HowToReach_Mobile{
    font-family: $font-content-1;
    color: rgb(192, 90, 0);
    margin: 6% 0px 4% 0px;
} 