$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
//

@media (min-width: 0px) and (max-width: 299px){
    .heading-SeasonExplore-Overview_D_3{
        font-size: 18px;
        position: absolute;
        font-family: $font-heading-1;  
        margin: 4px 0px 0px 0px; 
        color: rgb(255, 255, 255);
        background-color: rgb(237, 79, 0);
        font-weight: bold;
        padding: 0px 8px 0px 8px;
        word-spacing: 0.4px;
        margin-top: 60px;
        margin-left: 10px;
        width: 160px;
        flex-wrap: wrap;
    }
}
@media (min-width: 300px) and (max-width: 399px){
    .heading-SeasonExplore-Overview_D_3{
        font-size: 18px;
        position: absolute;
        font-family: $font-heading-1;  
        margin: 4px 0px 0px 0px; 
        color: rgb(255, 255, 255);
        background-color: rgb(237, 79, 0);
        font-weight: bold;
        padding: 0px 8px 0px 8px;
        word-spacing: 0.4px;
        margin-top: 80px;
        margin-left: 20px;
        // width: 3px;
        flex-wrap: wrap;
    }
}
@media (min-width: 400px) and (max-width: 799px){
    .heading-SeasonExplore-Overview_D_3{
        font-size: 20px;
        position: absolute;
        font-family: $font-heading-1;  
        margin: 4px 0px 0px 0px; 
        color: rgb(255, 255, 255);
        background-color: rgb(237, 79, 0);
        font-weight: bold;
        padding: 0px 8px 0px 8px;
        word-spacing: 0.4px;
        margin-top: 80px;
        margin-left: 20px;
        width: fit-content;
        flex-wrap: wrap;
    }
}

//!App
@media (min-width: 0px) and (max-width: 799px){
    //!Explore Season - 001
    .conatiner_p-exploreSeason-Overview_D_3{
        width: 100%;
        // box-shadow: 0 4px 8px rgba(255, 197, 197, 0.4);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 2% 0% 0% 0%;
        // border: 2px solid purple;
        margin-top: 10%;
    }
    .container_t-1-SeasonExplore-Overview_D_3{
        // width: 360px;
        width: 100%;
        height: 140px;
        align-self: flex-start;
        // margin-left: 3%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        // margin-left: 8%;
    }
    .image-SeasonExplore-Overview_D_3{
        background-image: url('https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/about_destination_3.png');
        background-size: cover;
        background-position: center bottom; /* Adjust this to position the image */
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
    }
    .conatiner_t-buttons-SeasonExplore-Overview_D_3{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        margin: 4% 0% 0% 0%;
        width: 100%;
        // background-color: rgb(78, 49, 13);
    }
    .button-active-exploreSeason-Overview_D-3{
        border: none;
        background-color: rgb(255, 224, 196);
        padding: 8px 16px 8px 16px;
        font-family: $font-content-2;
        word-spacing: 0.5px;
        border-radius: 4px;
        font-size: 16px;
        color: rgb(60, 59, 59);
        margin: 8px;
    }
    .button-unActive-exploreSeason-Overview_D-3{
        border: none;
        background-color: rgb(240, 240, 240);
        padding: 8px 16px 8px 16px;
        font-family: $font-heading-2;
        word-spacing: 0.5px;
        border-radius: 4px;
        font-size: 17px;
        color: rgb(60, 59, 59);
        margin: 8px;
    }

    //
    .container_t-2-SeasonExplore-Overview_D_3{
        width: 100%;
        box-shadow: 0 4px 8px rgba(137, 134, 134, 0.4);
        margin: 2% 0% 0% 0%;
        border-radius: 8px;
        padding: 3% 5% 8% 5%;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
    .heading-content-SeasonExplore-Overview_D_3{
        font-family: $font-4;    
        color: rgb(207, 63, 11);
        // word-spacing: 0.2px;
        font-size: 21px;
        font-weight: bold;
        margin: 2% 0% 0% 0%;
    }
    .content-SeasonExplore-Overview_D_3{
        margin-top: 4%;
        padding: 4px;
        background-color: rgb(240, 240, 240);
        border-radius: 8px;
        width: 99%;
    }
}
//!Mobile
@media (min-width: 800px){
    //!Explore Season - 001
    .conatiner_p-exploreSeason-Overview_D_3{
        width: 80%;
        // box-shadow: 0 4px 8px rgba(255, 197, 197, 0.4);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 2%;
        // border: 2px solid purple;
        margin-top: 10%;
    }
    .heading-SeasonExplore-Overview_D_3{
        font-size: 24px;
        position: absolute;
        font-family: $font-heading-1;  
        margin: 4px 0px 0px 0px; 
        color: rgb(255, 255, 255);
        background-color: rgb(237, 79, 0);
        font-weight: bold;
        padding: 0px 8px 0px 8px;
        word-spacing: 0.4px;
        margin-top: 100px;
        margin-left: 20px;
    }
    .container_t-1-SeasonExplore-Overview_D_3{
        width: 460px;
        height: 160px;
        align-self: flex-start;
        margin-left: 3%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        margin-left: 8%;
    }
    .image-SeasonExplore-Overview_D_3{
        background-image: url('https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/about_destination_3.png');
        background-size: cover;
        background-position: center bottom; /* Adjust this to position the image */
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
    }
    .conatiner_t-buttons-SeasonExplore-Overview_D_3{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 4% 3% 3% 3%;
        width: 80%;
        // background-color: rgb(78, 49, 13);
    }
    .button-active-exploreSeason-Overview_D-3{
        border: none;
        background-color: rgb(255, 224, 196);
        padding: 8px 16px 8px 16px;
        font-family: $font-content-2;
        word-spacing: 0.5px;
        border-radius: 4px;
        font-size: 16px;
        color: rgb(60, 59, 59);
    }
    .button-unActive-exploreSeason-Overview_D-3{
        border: none;
        background-color: rgb(240, 240, 240);
        padding: 8px 16px 8px 16px;
        font-family: $font-heading-2;
        word-spacing: 0.5px;
        border-radius: 4px;
        font-size: 17px;
        color: rgb(60, 59, 59);
    }

    //
    .container_t-2-SeasonExplore-Overview_D_3{
        width: 84%;
        box-shadow: 0 4px 8px rgba(137, 134, 134, 0.4);
        margin: 2%;
        border-radius: 8px;
        padding: 3% 5% 8% 5%;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
    .heading-content-SeasonExplore-Overview_D_3{
        font-family: $font-4;    
        color: rgb(207, 63, 11);
        // word-spacing: 0.2px;
        font-size: 21px;
        font-weight: bold;
        margin: 2%;
    }
    .content-SeasonExplore-Overview_D_3{
        padding: 4px;
        background-color: rgb(240, 240, 240);
        border-radius: 8px;
        width: 76%;
    }
}



// .container_t-1-SeasonExplore-Overview_D_3{
//     width: 460px;
//     height: 160px;
//     align-self: flex-start;
//     margin-left: 3%;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//     margin-left: 8%;
// }
// .container_t-2-SeasonExplore-Overview_D_3{
//     width: 84%;
//     box-shadow: 0 4px 8px rgba(137, 134, 134, 0.4);
//     margin: 2%;
//     border-radius: 8px;
//     padding: 3% 5% 8% 5%;
//     display: flex;
//     flex-direction: column;
//     text-align: center;
//     align-items: center;

// }

// .heading-SeasonExplore-Overview_D_3{
//     font-size: 24px;
//     position: absolute;
//     font-family: $font-heading-1;  
//     margin: 4px 0px 0px 0px; 
//     color: rgb(255, 255, 255);
//     background-color: rgb(237, 79, 0);
//     font-weight: bold;
//     padding: 0px 8px 0px 8px;
//     word-spacing: 0.4px;
//     margin-top: 100px;
//     margin-left: 20px;
// }
// .image-SeasonExplore-Overview_D_3{
//     background-image: url('https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/about_destination_3.png');
//     background-size: cover;
//     background-position: center bottom; /* Adjust this to position the image */
//     border-radius: 8px;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//     width: 100%;
//     height: 100%;
// }



// .heading-content-SeasonExplore-Overview_D_3{
//     font-family: $font-4;    
//     color: rgb(207, 63, 11);
//     // word-spacing: 0.2px;
//     font-size: 21px;
//     font-weight: bold;
//     margin: 2%;
// }
// .conatiner_t-buttons-SeasonExplore-Overview_D_3{
//     display: flex;
//     flex-direction: row;
//     justify-content: space-around;
//     margin: 4% 3% 3% 3%;
//     width: 80%;
//     // background-color: rgb(78, 49, 13);
// }
// .button-active-exploreSeason-Overview_D-3{
//     border: none;
//     background-color: rgb(255, 224, 196);
//     padding: 8px 16px 8px 16px;
//     font-family: $font-content-2;
//     word-spacing: 0.5px;
//     border-radius: 4px;
//     font-size: 16px;
//     color: rgb(60, 59, 59);
// }
// .button-unActive-exploreSeason-Overview_D-3{
//     border: none;
//     background-color: rgb(240, 240, 240);
//     padding: 8px 16px 8px 16px;
//     font-family: $font-heading-2;
//     word-spacing: 0.5px;
//     border-radius: 4px;
//     font-size: 17px;
//     color: rgb(60, 59, 59);
// }
// .content-SeasonExplore-Overview_D_3{
//     padding: 4px;
//     background-color: rgb(240, 240, 240);
//     border-radius: 8px;
//     width: 76%;
// }
.container_s-content-seasonExplore-Overview_D{
    margin: 4%;
    // width: 60%;
    text-align: start;
}
.heading-content-seasonExplore-Overview_D{
    font-family: $font-content-2;
    font-size: 15px;
}
.heading-2-content-seasonExplore-Overview_D{
    font-family: $font-content-2;
    color: rgba(174, 97, 9, 0.886);
    font-size: 15px;
}
.heading-3-content-seasonExplore-Overview_D{
    font-family: $font-content-2;
    color: rgba(208, 13, 13, 0.886);
    font-size: 15px;
}
.text-content-seasonExplore-Overview_D{
    font-family: $font-3;
    font-weight: 600;
    font-size: 14px;
    margin-top: 4%;
    word-spacing: 0.4px;
    letter-spacing: 0.4px;
    color: rgb(65, 64, 64);
}

//!month wise data - 002


//!App
@media (min-width: 0px) and (max-width: 799px){
    .conatiner_p-monthWise-Overview_D_3{
        width: 100%;
        // box-shadow: 0 4px 8px rgba(255, 197, 197, 0.4);
        // border: 2px solid red;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 2%;
        margin-top: 10%;
    }
    .container_t-1-monthWise-Overview_D_3{
        width: 100%;
        height: 140px;
        align-self: flex-start;
        // margin-left: 7%;
    }
    .image-monthWise-Overview_D_3{
        background-image: url('https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/about_destination_9.png');
        background-size: cover;
        background-position: center bottom; /* Adjust this to position the image */
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
    }

    //
    .container_t-2-monthWise-Overview_D_3{
        width: 100%;
        // box-shadow: 0 4px 8px rgba(137, 134, 134, 0.4);
        margin-top: 2%;
        border-radius: 8px;
        padding: 3% 5% 8% 0%;
        display: flex;
        flex-direction: row;
        // background: rebeccapurple;
        overflow-x: scroll;
        // overflow-y: hidden;
        // text-align: center;
        // align-items: center;
        // flex-wrap: wrap;
        // justify-content: space-around;
    }
    .container_t-cards-monthWise-Overview_D_3{
        border: 1px lightgray solid;
        border-radius: 8px;
        padding: 4px;
        margin-right: 8px;
        width: 160px;
        text-align: start;
        // background: rebeccapurple;
    }
}
//!Mobile
@media (min-width: 800px){
    .conatiner_p-monthWise-Overview_D_3{
        width: 70%;
        // box-shadow: 0 4px 8px rgba(255, 197, 197, 0.4);
        // border: 2px solid red;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 2%;
        margin-top: 10%;
    }
    .container_t-1-monthWise-Overview_D_3{
        width: 460px;
        height: 160px;
        align-self: flex-start;
        margin-left: 7%;
    }
    .image-monthWise-Overview_D_3{
        background-image: url('https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/about_destination_9.png');
        background-size: cover;
        background-position: center bottom; /* Adjust this to position the image */
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
    }

    //
    .container_t-2-monthWise-Overview_D_3{
        width: 100%;
        // box-shadow: 0 4px 8px rgba(137, 134, 134, 0.4);
        margin: 2%;
        border-radius: 8px;
        padding: 3% 5% 8% 5%;
        display: flex;
        flex-direction: row;
        // text-align: center;
        // align-items: center;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .container_t-cards-monthWise-Overview_D_3{
        border: 1px lightgray solid;
        border-radius: 8px;
        padding: 4px;
        margin: 8px;
        width: 28%;
        text-align: start;
    }
}


// .conatiner_p-monthWise-Overview_D_3{
//     width: 70%;
//     // box-shadow: 0 4px 8px rgba(255, 197, 197, 0.4);
//     border: 2px solid red;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     text-align: center;
//     margin: 2%;
//     margin-top: 10%;
// }

// .container_t-1-monthWise-Overview_D_3{
//     width: 460px;
//     height: 160px;
//     align-self: flex-start;
//     margin-left: 7%;
// }
// .container_t-2-monthWise-Overview_D_3{
//     width: 100%;
//     // box-shadow: 0 4px 8px rgba(137, 134, 134, 0.4);
//     margin: 2%;
//     border-radius: 8px;
//     padding: 3% 5% 8% 5%;
//     display: flex;
//     flex-direction: row;
//     // text-align: center;
//     // align-items: center;
//     flex-wrap: wrap;
//     justify-content: space-around;
// }

// .container_t-cards-monthWise-Overview_D_3{
//     border: 1px lightgray solid;
//     border-radius: 8px;
//     padding: 4px;
//     margin: 8px;
//     width: 28%;
//     text-align: start;
// }
.heading-monthWise-Overview_D_3{
    font-size: 24px;
    position: absolute;
    font-family: $font-heading-1;  
    margin: 4px 0px 0px 0px; 
    color: rgb(255, 255, 255);
    background-color: rgb(237, 79, 0);
    font-weight: bold;
    padding: 0px 8px 0px 8px;
    word-spacing: 0.4px;
    margin-top: 100px;
    margin-left: 20px;
}
// .image-monthWise-Overview_D_3{
//     background-image: url('https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/about_destination_9.png');
//     background-size: cover;
//     background-position: center bottom; /* Adjust this to position the image */
//     border-radius: 8px;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//     width: 100%;
//     height: 100%;
// }

.heading-monthName-monthWise-Overview_D_3{
    background-color: rgb(239, 239, 239);
    padding: 8px;
    border-radius: 6px;
    font-size: 14px;
    letter-spacing: 0.3px;
    font-family: $font-3;
    font-weight: bold;
}
.heading-2-monthName-monthWise-Overview_D_3{
    border-radius: 6px;
    font-size: 12px;
    color: rgb(90, 88, 88);
    // font-family: $font-5;
    font-family: $font-content-1;
    margin: 2%;
}
.heading-3-monthName-monthWise-Overview_D_3{
    background-color: rgb(239, 239, 239);
    padding: 4px;
    border-radius: 6px;
    font-size: 15px;
}
.content-monthName-monthWise-Overview_D_3{
    margin: 4px;
    font-size: 14px;
}
.content-2-monthName-monthWise-Overview_D_3{
    margin: 4%;
    font-size: 15px;
    font-family: $font-4;
}
.content-3-monthName-monthWise-Overview_D_3{
    margin: 4px;
    font-size: 12px;
    font-family: $font-3;
    font-weight: 600;
    color: rgb(90, 88, 88);
}

