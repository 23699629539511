@media (min-width: 800px){
.contactUs-mainContainer{
    width: 30%;
    /* height: 60%; */
    /* height: 300px; */
    /* display: flex; */
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px black solid;
    margin-top: 10%;
    margin-left: 34%;
    padding: 4% 0% 4% 0%;
    /* overflow: wrap; */
    word-wrap: break-word;
}
.contactUs-lineCss{
    color: orangered;
    font-weight: bold;
}
.contactUs-backButton{
    /* position: absolute; */
    padding: 2% 3% 0% 3%;

    width: fit-content;


    background: rgb(223, 221, 221);
    color: black;
    
    border-radius: 8px;                                
    
    margin-top: 10%;
    font-size: 10px;
    margin-left: 44%;

}
}

@media only screen and (min-width: 0px) and (max-width: 799px) {     
    .contactUs-mainContainer{
        width: 60%;
        /* height: 60%; */
        /* height: 300px; */
        /* display: flex; */
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1px rgb(13, 112, 136) solid;
        margin-top: 10%;
        margin-left: 20%;
        padding: 4% 0% 4% 0%;
        /* overflow: wrap; */
        word-wrap: break-word;
    }
    .contactUs-lineCss{
        color: orangered;
        font-weight: bold;
    }
    .contactUs-backButton{
        /* position: absolute; */
        padding: 2% 3% 0% 3%;
    
        width: fit-content;
    
    
        background: rgb(223, 221, 221);
        color: black;
        
        border-radius: 8px;                                
        
        margin-top: 10%;
        font-size: 10px;
        /* margin-left: 44%; */
    
    }             
}