$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


.continer_p-0-HowToReach_Main{
    width: 4%;
    // border: 1px solid rgb(86, 86, 86);
    background: rgba(246, 255, 224, 0.394);
}


//!Heading 
.conatiner_p-heading-HowToReach_Main{
    width: 100%;
    // height: 20%;
    margin-top: 1%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    overflow: hidden;
    // border: 1px solid orangered;
    justify-content: space-around;
}
.conatiner_s-2-heading-HowToReach_Main{
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    // overflow: hidden;
    //  border: 1px solid orangered;
}
.heading-1-heading-HowToReach_Main{
    font-family: $font-6;
    font-size: 36px;
    font-weight: bold;
    // color: rgb(255, 81, 0);
    position: absolute;
    
    // box-shadow: rgb(255, 255, 255) 0px 8px 24px;
    box-shadow: rgba(237, 255, 195, 0.843) 0px 7px 29px 0px;
    
    color: rgb(255, 255, 255);
    background: rgb(118, 177, 10);

    // background: rgb(255, 255, 255);
    // color: rgb(186, 67, 8);
   
    margin-top: 100px;
    margin-left: 40px;

    padding: 4px 2px 2px 2px;
    // margin-top: 18%;
    // margin-left: 15%;
    letter-spacing: 0.4px;
    white-space: nowrap;
}
.img-1-heading-HowToReach_Main{
    // width: 100%;
    width: 700px;
    height: 200px;
    // height: 100%;
    object-fit: cover;
    // border: 1px solid orangered;
    border-radius: 8px;
    // position: absolute;
    z-index: -2;
    // box-shadow: 0 0 2px rgb(97, 97, 97); 
}
.img-2-heading-HowToReach_Main{
    // width: 100%;
    // width: 600px;
    width: 100%;
    height: 200px;
    // height: 100%;
    object-fit: cover;
    // border: 1px solid orangered;
    border-radius: 8px;
    // position: absolute;
    z-index: -2;
    // box-shadow: 0 0 2px rgb(97, 97, 97); 
}
.conatiner_t-noLocationSearched-HowToReach_Main{
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    margin: 0px 4px 4px 4px;
}
.text-1-noLocationSearched-HowToReach_Main{
    font-family: $font-6;
    align-items: center;
    align-self: center;
}

//!
.container_p-HowToReach_Main{
    display: flex;
    flex-direction: column;
    // border: 4px solid rgb(246, 228, 72);
    width: 100%;
    margin-top: 6%;
    align-items: center;
    // margin-left: 26px;
}
.container_s-HowToReach_Main{
    // background: rgb(251, 250, 250);
    border-radius: 16px;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-around;
    width: fit-content;
    // box-shadow: 0 0 2px #b4b0b0; 
    box-shadow: rgb(183, 215, 131) 0px 0px 5px;
}

//*heading
//container 1
.container_s-1-HowToReach_Main{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    align-self: start;
    // background-color: white;
    // width: fit-content;
    // margin-bottom: 20px;
    // border: 2px solid rgb(58, 96, 0);
    width: 30%;
    // margin-top: 10px;
    height: 100%;
    padding: 10px 0px 16px 0px;
    // background: rgb(255, 246, 246);
    border-radius: 16px;   
}
.img-searchHotel-HowToReach_Main{
    width: 30px;
    height: 30px;
    margin-right: 26px;
}
.heading-1-searchHotel-HowToReach_Main{
    color: rgb(132, 181, 34);
    font-size: 22px;
    font-family: $font-6;
    // margin-left: 24px;
    margin-top: 24px;
}

//container 2
.container_s-2-HowToReach_Main{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px 0px 16px 0px;
    // flex-wrap: wrap;
    // justify-content: space-between;
    background: rgb(255, 234, 234);
    border-radius: 12px;
    // border: 2px solid rgb(58, 96, 0);
    width: 66%;
}
.container_t-1-HowToReach_Main{
    display: flex;
    flex-direction: row;
    // flex-direction: column;
    // flex-wrap: wrap;
    // width: 44%;
    width: 90%;
    // justify-content: space-around;
    align-items: center;
    text-align: center;
    // border: #ef6700 1px solid;
}
.container_t-2-HowToReach_Main{
    display: flex;
    flex-direction: row;
    // flex-direction: column;
    flex-wrap: wrap;
    // width: 44%;
    width: 90%;
    // justify-content: space-around;
    align-items: center;
    text-align: center;
    // border: #ef6700 1px solid;
    margin-top: 20px;
    // margin-left: 10px;
}
.container_t-3-HowToReach_Main{
    width: 100%;
    // margin-top: 20px;
    // margin-left: 18px;
    // border: #01e8c9 1px solid;
}

//button
.button-active-HowToReach_Main{
    font-weight: normal;
    font-family: $font-6;
    font-weight: bold;
    // background-color: rgb(244, 93, 38);
    // color: white;
    background: none;
    color: rgb(124, 124, 124);
    font-size: 16px;
    // background: none;
    border: none;
    margin-right: 20px;
    // margin-top: 10px;
    // padding: 1px 6px 2px 6px;
    height: fit-content;
    // border-radius: 6px;
    letter-spacing: 0.4px;
    white-space: nowrap;

    padding-bottom: 4px;
    border-bottom: 2px solid orange;

    &:hover{
        color: rgb(255, 133, 89);
        transition: color 0.3s ease;
    }
    &:active{
        color: rgb(237, 14, 14);
        transition: color 0.3s ease;
    }
}
.button-unactive-HowToReach_Main{
    font-weight: normal;
    font-family: $font-6;
    font-weight: bold;
    color: rgb(124, 124, 124);
    font-size: 16px;
    background: none;
    border: none;
    margin-right: 20px;
    // margin-top: 10px;
    // padding: 1px 6px 2px 6px;
    height: fit-content;
    letter-spacing: 0.4px;
    white-space: nowrap;

    // padding-bottom: 4px;
    // border-bottom: 2px solid orange;

    // &:hover{
    //     background-color: #eeadad;
    //     transition: background-color 0.3s ease;
    //     padding: 1px 6px 2px 6px;
    //     border-radius: 10px;
    //     // letter-spacing: 0.4px;
    //     color: rgb(255, 255, 255);
    // }
    // &:active{
    //     background-color: rgb(237, 14, 14);
    //     transition: background-color 0.3s ease;
    // }
    &:hover{
        color: rgb(255, 133, 89);
        transition: color 0.3s ease;
    }
    &:active{
        color: rgb(237, 14, 14);
        transition: color 0.3s ease;
    }
}


//*Destinations name
.heading-1-destinationName-HowToReach_Main{
    font-family: $font-3;
    font-size: 18px;
    color: brown;
    letter-spacing: 0.3px;
    margin-top: 4%;
    width: 100%;
    background: rgb(245, 244, 244);
    align-items: center;
    align-self: center;
    text-align: center;
}

//Travel Modes Content
.container_p-ModesContent-HowToReach_Main{
    border: 1px solid orange;
    border-radius: 8px;
    margin-top: 4%;
    padding: 6px;
}
.container_s-ModesContent-HowToReach_Main{

}