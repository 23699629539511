$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//



// .savedPlans-MainContainer-SavedPlans{
//     width: 70%;
//     display: flex;
//     flex-direction: row;
//     overflow-x: scroll;
//     overflow-y: hidden;
//     background-color: antiquewhite;
//     margin: 2% 0% 2% 0%;
// }

.container_p-SavedPlans{
    width: 100%;
    // background: rgb(241, 241, 241);
    // border: 12px solid rgb(115, 7, 7);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 7%;
}
// .container_s-SavedPlans{
//     width: 70%;
// }

@media (min-width: 0px) and (max-width: 499px){
    .container_s-SavedPlans{
        width: 100%;
    // border: 1px solid olivedrab;
    // display: flex;
    // align-items: center;
    }
}

@media (min-width: 500px) and (max-width: 1000px){
    .container_s-SavedPlans{
        width: 100%;
    // border: 1px solid olivedrab;
    // display: flex;
    // align-items: center;
    }
}

@media (min-width: 1000px){
    .container_s-SavedPlans{
        width: 70%;
    // border: 1px solid olivedrab;
    // display: flex;
    // align-items: center;
    }
}



//!text #1
.conatiner-text-SavedPlans{
    width: 100%;
    display: flex;
    // align-items: center;
    text-align: start;
    // justify-content: center;
}
.heading-1-SavedPlans{
    font-family: $font-6;
    font-size: 26px;
    color: rgb(255, 68, 0);
    letter-spacing: 0.6px;
    word-spacing: 0.4px;
    // margin-left: 5.6%;
    margin-left: 7%;
    // background: lightgoldenrodyellow;
}

//!saved trip plan navigation #2
.container_t-navigation-tripStatus-SavedPlans{
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    // width: 80%;
    margin-left: 7%;
    // background: lightgoldenrodyellow;
}
.button-active-navigation-tripStatus-SavedPlans{
    padding: 1px 16px 4px 16px;
    border-radius: 12px;
    border: none;
    background: rgb(243, 247, 247);
    margin-right: 12px;
    font-family: $font-heading-3;
    // margin-top: -4px;

    &:hover{
        background-color: rgb(225, 225, 225);
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(255, 244, 243);
        transition: background-color 0.3s ease;
    }
}
.button-unActive-navigation-tripStatus-SavedPlans{
    padding: 4px 16px 4px 16px;
    border-radius: 12px;
    border: none;
    background: rgb(243, 247, 247);
    margin-right: 12px;
    font-family: $font-6;

    &:hover{
        background-color: rgb(231, 231, 231);
        transition: background-color 0.3s ease;
    }
    &:active{
        background-color: rgb(255, 244, 243);
        transition: background-color 0.3s ease;
    }
}

//!saved trips cards #3
.container_p-savedTrips-SavedPlans{
    // display: flex;
    // flex-direction: row;
    margin-top: 2%;
    padding: 8px;
    // border: 2px lightgray solid;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.container_s-savedTrips-SavedPlans{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 86%;
}
.container_s-savedTrips-SavedPlans::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}
.container_t-savedTrips-SavedPlans{
    display: flex;
    flex-direction: column;
    // border: 1px red solid;
    border: none;
    background: none;
    margin-right: 12px;
    padding: 4px;
    border-radius: 8px;
    // background: rgb(247, 247, 247);
    align-items: self-start;
    text-align: start;
    height: fit-content;
    position: relative;
}
.img_1-savedTrips-SavedPlans{
    width: 260px;
    height: 180px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(182, 180, 180, 0.389); 
    margin-left: -1px;
}
.img-delete-SavedPlans{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 4%;
    bottom: 4%;
    border: none;
    background: none;
    // z-index: 1;
    // background: white;

    &:hover{
        box-shadow: 0 0 8px rgba(182, 180, 180, 0.824); 
        width: 22px;
        height: 22px;
    }
    &:active{
        box-shadow: 0 0 8px rgba(255, 0, 0, 0.548); 
    }
}


//?#1
.text-1-tripName-savedTrips-SavedPlans{
    // margin-left: 4px;
    margin-top: 10px;
    font-size: 16px;
    font-family: $font-6;
    // color: rgb(133, 82, 5);
    word-break: break-all;
    color: rgb(0, 0, 0);
}

//?#2
.container_t-metaInfo-savedTrips-SavedPlans{
    // background: lightcyan;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 8px 0px 4px 0px;
    width: 100%;
}
.text-1-metaInfo-savedTrips-SavedPlans{
    font-family: $font-6;
    margin-right: 6px;
    margin-bottom: 6px;
    padding: 4px 8px 4px 0px;
    border-radius: 6px;
    border: none;
    color: rgb(94, 94, 94);
    background: rgb(244, 244, 244);
}

//?#3
.container_t-createOn-savedTrips-SavedPlans{
    display: flex;
    flex-direction: column;
    // align-items: center;
    // text-align: center;
}
.text-1-createOn-savedTrips-SavedPlans{
    font-family: $font-5;
    color: rgb(178, 178, 178);
    font-size: 13px;
    // margin-left: 6px;
    margin-right: 4px;
    white-space: nowrap;
}
.text-2-createOn-savedTrips-SavedPlans{
    font-family: $font-4;
    color: gray;
    font-size: 12px;
    white-space: nowrap;
    // margin-left: 6px;
    margin-top: 4px;
}

/* styles.css */
.placeholder-container {
    display: flex;
    gap: 20px;
    padding: 20px;
  }
  
  .placeholder-box {
    width: 240px;
    height: 200px;
    background-color: #e0e0e0;
    border-radius: 8px;
    position: relative;
  }
  
  .placeholder-text {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: #c0c0c0;
    width: 60%;
    height: 20px;
    border-radius: 4px;
  }
  



/* styles.css */
//!loading spinner

// <div className="loading-container">
//     <div className="loading-spinner"></div>
// </div>
// .loading-container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100vh;
//     background-color: rgba(255, 255, 255, 0.8);
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     z-index: 9999;
//   }
  
//   .loading-spinner {
//     border: 8px solid #f3f3f3;
//     border-top: 8px solid #3498db;
//     border-radius: 50%;
//     width: 50px;
//     height: 50px;
//     animation: spin 2s linear infinite;
//   }
  
//   @keyframes spin {
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
//   }
  