$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

.Main-PlanTripHeader{
    // position: fixed;
    width: 100%;
    // height: 6%;
    // border: 1px rgb(71, 109, 234) solid;
    border-radius: 8px;
    background: white;
}


.container_t-1_1-logo-PlanTripHeader{
    border: none;
    background: none;
    position: absolute;
    top: 10px;
    left: 10px;
}
.img-logo-site-1-PlanTripHeader{
    border-radius: 8px;
    width: 36px;
    height: 36px;
    margin-left: 14px;
}



@media (min-width: 0px) and (max-width: 799px){
    .container_p-PlanTripHeader{
        z-index: 100;
        /* position: fixed; */
        width: 100%;
        // background-color: rgba(157, s, 17, 0.742);
        // border-bottom: 1px rgb(207, 206, 206) solid;
        display: flex;
        flex-direction: column;
        /* height: 60px; */
        align-items: center;
        text-align: center;
        justify-content: center;
        background: none;
        // background: rgb(244, 242, 242);
    }
    .container_s-PlanTripHeader{
        display: flex;
        flex-direction: row;
        width: fit-content;
        background: rgb(225, 191, 191);
        box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.2);
        // border: 2px rgb(161, 220, 74) solid;
        // border: 2px rgb(197, 199, 192) solid;
        margin: 2px 0px 12px 0px;
        padding: 6px 12px 4px 12px;
        border-radius: 22px;
        // border: none;
        // box-shadow: 0 0 3px rgba(255, 196, 196, 0.798);
    
        padding-bottom: 6px;
        border-bottom: 2px solid rgb(255, 227, 227);
    }
    .line-divide-PlanTripHeader{
        border-left: 1px solid lightgrey;
        height: 20px;
        align-self: center;
    }
}
@media (min-width: 800px){
    .container_p-PlanTripHeader{
        z-index: 100;
        /* position: fixed; */
        width: 100%;
        background-color: rgb(255, 255, 255);
        // border-bottom: 1px rgb(207, 206, 206) solid;
        display: flex;
        flex-direction: column;
        /* height: 60px; */
        align-items: center;
        text-align: center;
        justify-content: center;
        // background: rgb(244, 242, 242);
    }
    .container_s-PlanTripHeader{
        display: flex;
        flex-direction: row;
        width: fit-content;
        // background: rgb(255, 255, 255);
        // border: 2px rgb(161, 220, 74) solid;
        // border: 2px rgb(197, 199, 192) solid;
        margin: 2px 0px 4px 0px;
        padding: 4px 12px;
        border-radius: 22px;
        // border: none;
        // box-shadow: 0 0 3px rgba(255, 196, 196, 0.798);
    
        padding-bottom: 6px;
        border-bottom: 2px solid rgb(255, 227, 227);
    }
    .line-divide-PlanTripHeader{
        border-left: 1px solid lightgrey;
        height: 20px;
        align-self: center;
    }
}

// .container_p-PlanTripHeader{
//     z-index: 100;
//     /* position: fixed; */
//     width: 100%;
//     background-color: rgb(203, 18, 18);
//     // border-bottom: 1px rgb(207, 206, 206) solid;
//     display: flex;
//     flex-direction: column;
//     /* height: 60px; */
//     align-items: center;
//     text-align: center;
//     justify-content: center;
//     // background: rgb(244, 242, 242);
// }
// .container_s-PlanTripHeader{
//     display: flex;
//     flex-direction: row;
//     width: fit-content;
//     // background: rgb(255, 255, 255);
//     // border: 2px rgb(161, 220, 74) solid;
//     // border: 2px rgb(197, 199, 192) solid;
//     margin: 2px 0px 4px 0px;
//     padding: 4px 12px;
//     border-radius: 22px;
//     // border: none;
//     // box-shadow: 0 0 3px rgba(255, 196, 196, 0.798);

//     padding-bottom: 6px;
//     border-bottom: 2px solid rgb(255, 227, 227);
// }
// .line-divide-PlanTripHeader{
//     border-left: 1px solid lightgrey;
//     height: 20px;
//     align-self: center;
// }

.button-active-feature-PlanTripHeader{
    padding: 4px 12px 4px 8px;
    border-radius: 16px;
    // background: rgb(243, 243, 243);
    background: rgb(255, 255, 255);
    box-shadow: 0 0 3px rgba(255, 165, 165, 0.798);
    border: none;
    display: flex;
    flex-direction: row;
    margin: 0px 8px 0px 8px;
    // height: 36px;
    text-align: center;
    align-items: center;

    border-bottom: 2px solid rgb(255, 0, 0);
}
.button-unActive-feature-PlanTripHeader{
    padding: 4px 12px 4px 8px;
    border-radius: 16px;
    background: rgb(240, 236, 236);
    background: rgb(255, 255, 255);
    box-shadow: 0 0 1px rgba(255, 196, 196, 0.798);
    // border: 1px rgb(169, 169, 169) solid;   
    border: none;
    display: flex;
    flex-direction: row;
    margin: 0px 8px 0px 8px;
    // height: 36px;
    text-align: center;
    align-items: center;
    cursor: pointer;
    border-bottom: 2px solid rgb(190, 190, 190);
}

// .img-active-feature-PlanTripHeader{
//     height: 24px;
//     width: 24px;
//     margin-right: 8px;
//     // background: whitesmoke;
//     // border: 1px rgb(255, 148, 148) solid;
//     // border-radius: 100px;
//     // padding: 2px;
//     // border-bottom: 1px solid rgb(255, 255, 255);
//     // background: white;
//     // padding-bottom: 4px;
//     // border-radius: 16px;
//     // box-shadow: 0 0 4px rgb(237, 239, 237);
// }
// .img-unActive-feature-PlanTripHeader{
//     height: 24px;
//     width: 24px;
//     margin-right: 8px;
//     // background: whitesmoke;
//     // border: 1px whitesmoke solid;
//     // border-radius: 100px;
//     // padding: 4px;
//     // padding-bottom: 4px;
//     // border-bottom: 1px solid rgb(255, 52, 41);
//     // border-bottom-left-radius: 10px;
//     // border-bottom-right-radius: 10px;
// }


// .heading-active-feature-PlanTripHeader{
//     font-family: $font-6;
//     font-size: 18px;
//     padding-left: 2px;
//     padding-right: 2px;
//     cursor: pointer;
// }
// .heading-unActive-feature-PlanTripHeader{
//     font-family: $font-6;
//     font-size: 18px;
//     padding-right: 2px;
//     padding-left: 2px;
//     cursor: pointer;
// }

@media (min-width: 0px) and (max-width: 349px){
    .heading-active-feature-PlanTripHeader{
        // font-family: $font-6;
        // font-size: 12px;
        // padding-left: 2px;
        // padding-right: 2px;
        // cursor: pointer;
        display: none;
    }
    .heading-unActive-feature-PlanTripHeader{
        // font-family: $font-6;
        // font-size: 18px;
        // padding-right: 2px;
        // padding-left: 2px;
        // cursor: pointer;
        display: none;
    }
    .img-active-feature-PlanTripHeader{
        height: 30px;
        width: 30px;
        // margin-right: 8px;
    }
    .img-unActive-feature-PlanTripHeader{
        height: 24px;
        width: 24px;
        // margin-right: 8px;
    }
}
@media (min-width: 350px) and (max-width: 499px){
    .heading-active-feature-PlanTripHeader{
        font-family: $font-6;
        font-size: 14px;
        padding-left: 2px;
        padding-right: 2px;
        cursor: pointer;
        font-weight: bold;
        color: red;
    }
    .heading-unActive-feature-PlanTripHeader{
        // font-family: $font-6;
        // font-size: 18px;
        // padding-right: 2px;
        // padding-left: 2px;
        // cursor: pointer;
        display: none;
    }
    .img-active-feature-PlanTripHeader{
        height: 30px;
        width: 30px;
        // margin-right: 8px;
    }
    .img-unActive-feature-PlanTripHeader{
        height: 22px;
        width: 22px;
        // margin-right: 8px;
    }
}
@media (min-width: 500px) and (max-width: 799px){
    .heading-active-feature-PlanTripHeader{
        font-family: $font-6;
        font-size: 16px;
        padding-left: 2px;
        padding-right: 2px;
        cursor: pointer;
        font-weight: bold;
        color: red;
        // display: none;
    }
    .heading-unActive-feature-PlanTripHeader{
        font-family: $font-6;
        font-size: 16px;
        padding-right: 2px;
        padding-left: 2px;
        cursor: pointer;
        display: none;
    }
    .img-active-feature-PlanTripHeader{
        height: 36px;
        width: 36px;
        // margin-right: 8px;
    }
    .img-unActive-feature-PlanTripHeader{
        height: 26px;
        width: 26px;
        // margin-right: 8px;
    }
}
@media (min-width: 800px){
    .heading-active-feature-PlanTripHeader{
        font-family: $font-6;
        font-size: 18px;
        padding-left: 2px;
        padding-right: 2px;
        cursor: pointer;
    }
    .heading-unActive-feature-PlanTripHeader{
        font-family: $font-6;
        font-size: 18px;
        padding-right: 2px;
        padding-left: 2px;
        cursor: pointer;
    }
    .img-active-feature-PlanTripHeader{
        height: 24px;
        width: 24px;
        margin-right: 8px;
        // background: whitesmoke;
        // border: 1px rgb(255, 148, 148) solid;
        // border-radius: 100px;
        // padding: 2px;
        // border-bottom: 1px solid rgb(255, 255, 255);
        // background: white;
        // padding-bottom: 4px;
        // border-radius: 16px;
        // box-shadow: 0 0 4px rgb(237, 239, 237);
    }
    .img-unActive-feature-PlanTripHeader{
        height: 24px;
        width: 24px;
        margin-right: 8px;
        // background: whitesmoke;
        // border: 1px whitesmoke solid;
        // border-radius: 100px;
        // padding: 4px;
        // padding-bottom: 4px;
        // border-bottom: 1px solid rgb(255, 52, 41);
        // border-bottom-left-radius: 10px;
        // border-bottom-right-radius: 10px;
    }
}



//
.conatiner_p-line-PlanTripHeader{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
}
.line-PlanTripHeader{
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 4px;
    // margin-bottom: 2px;
    // border-bottom: 2px solid rgb(255, 89, 0);
    // width: 60%;
}




//*Login
.conatiner_p-login-PlanTripHeader{
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}
.heading-login-PlanTripHeader{
    font-family: $font-6;
    font-size: 20px;
    color: orangered;
}
.container_t-login-PlanTripHeader{
    margin-left: 30px;
}

.conatiner_p-2-login-PlanTripHeader{
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}
.container_t-2-login-PlanTripHeader{
    font-size: 40px;
}
















// $font-heading-1: 'Oxygen';
// $font-heading-2: 'Finlandica';
// $font-content-1: 'Nunito Sans';
// $font-content-2: 'Rubik';
// $font-3: 'Quicksand';
// $font-4: 'Comfortaa';
// $font-5: 'Nanum Gothic';
// $font-6: 'Josefin Sans';
// //?new
// $font-7: 'Roboto';
// $font-8: 'Open Sans';
// $font-9: 'Raleway';
// $font-10: 'Ubuntu';
// $font-11: 'Merriweather';
// $font-12: 'Playfair Display';
// $font-13: 'Source Sans Pro';
// //

// .Main-PlanTripHeader{
//     // position: fixed;
//     width: 100%;
//     // height: 6%;
//     // border: 1px rgb(71, 109, 234) solid;
//     border-radius: 8px;
//     background: white;
// }



// .container_p-PlanTripHeader{
//     z-index: 10000000000000;
//     /* position: fixed; */
//     width: 100%;
//     // background-color: whitesmoke;
//     // border-bottom: 1px rgb(207, 206, 206) solid;
//     display: flex;
//     flex-direction: column;
//     /* height: 60px; */
//     align-items: center;
//     text-align: center;
//     justify-content: center;
//     // background: rgb(244, 242, 242);
// }
// .container_s-PlanTripHeader{
//     display: flex;
//     flex-direction: row;
//     width: fit-content;
//     // background: rgb(255, 255, 255);
//     // border: 2px rgb(161, 220, 74) solid;
//     // border: 2px rgb(197, 199, 192) solid;
//     margin: 6px 0px 16px 0px;
//     padding: 4px 12px;
//     border-radius: 22px;
//     // border: none;
//     box-shadow: 0 0 3px rgba(255, 196, 196, 0.798);
// }
// .line-divide-PlanTripHeader{
//     border-left: 1px solid lightgrey;
//     height: 20px;
//     align-self: center;
// }

// .button-active-feature-PlanTripHeader{
//     padding: 4px 8px 4px 8px;
//     border-radius: 16px;
//     // background: none;
//     background: whitesmoke;
//     // border: 1px rgb(255, 159, 14) solid;
//     border: none;
//     display: flex;
//     flex-direction: row;
//     margin: 0px 8px 0px 8px;
//     // height: 36px;
//     text-align: center;
//     align-items: center;
// }
// .button-unActive-feature-PlanTripHeader{
//     padding: 4px 8px 4px 8px;
//     border-radius: 16px;
//     background: rgb(240, 236, 236);
//     // border: 1px rgb(169, 169, 169) solid;   
//     border: none;
//     display: flex;
//     flex-direction: row;
//     margin: 0px 8px 0px 8px;
//     // height: 36px;
//     text-align: center;
//     align-items: center;
// }

// .img-active-feature-PlanTripHeader{
//     height: 30px;
//     width: 30px;
//     margin-right: 8px;
//     // background: whitesmoke;
//     // border: 1px rgb(255, 148, 148) solid;
//     // border-radius: 100px;
//     // padding: 2px;
//     border-bottom: 2px solid rgb(255, 52, 41);
//     // padding-bottom: 4px;
// }
// .img-unActive-feature-PlanTripHeader{
//     height: 30px;
//     width: 30px;
//     margin-right: 8px;
//     // background: whitesmoke;
//     // border: 1px whitesmoke solid;
//     // border-radius: 100px;
//     // padding: 4px;
//     // padding-bottom: 4px;
//     // border-bottom: 1px solid rgb(255, 52, 41);
//     // border-bottom-left-radius: 10px;
//     // border-bottom-right-radius: 10px;
// }
// .heading-active-feature-PlanTripHeader{
//     font-family: $font-6;
//     font-size: 20px;
//     // color: white;
//     color: rgb(255, 52, 41);
//     border-bottom: 2px solid rgb(255, 52, 41);
//     padding-bottom: 4px;
//     border-radius: 6px;
//     padding-left: 2px;
//     padding-right: 2px;
// }
// .heading-unActive-feature-PlanTripHeader{
//     font-family: $font-6;
//     font-size: 20px;
// }

// //
// .conatiner_p-line-PlanTripHeader{
//     display: flex;
//     align-items: center;
//     text-align: center;
//     justify-content: center;
//     width: 100%;
// }
// .line-PlanTripHeader{
//     display: flex;
//     align-items: center;
//     text-align: center;
//     margin-top: -10px;
//     border-bottom: 2px solid rgb(210, 226, 156);
//     width: 30%;
// }