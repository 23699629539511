$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

/* Topic Selector */
.mainContainer-TopicSelector-TopicSelector_CI{
    width: 100%;
    padding: 1%;
    border: 1px lightpink dotted;
}

.TouristPlace-TopicSelector-TopicSelector_CI{
    display: flex;
    flex-direction: row;
    margin: 2%;
    overflow-y: hidden;
    overflow-x: scroll;
}
.Notes_Checklist-TopicSelector-TopicSelector_CI{
    display: flex;
    flex-direction: row;
    margin: 2%;
    overflow-y: hidden;
    overflow-x: scroll;
}
.DayInfo-TopicSelector-TopicSelector_CI{
    display: flex;
    flex-direction: row;
    margin: 2%;
    overflow-y: hidden;
    overflow-x: scroll;
}

// .button-saveChanges-Itinerary_CI{
//     font-family: $font-6;
//     font-size: 18px;
//     border: none;
//     background: red;
//     color: white;

//     &:hover{
//         background: rgb(255, 61, 61);
//     }
//     &:active{
//         background: rgb(155, 79, 79);
//     }
// }

@media (min-width: 0px) and (max-width: 799px){
    .button-saveChanges-Itinerary_CI{
        font-family: $font-6;
        font-size: 18px;
        border: none;
        background: red;
        color: white;
        position: fixed;
        bottom: 10%;
        z-index: 100;

        &:hover{
            background: rgb(255, 61, 61);
        }
        &:active{
            background: rgb(155, 79, 79);
        }
    }
}
@media (min-width: 800px){
    .button-saveChanges-Itinerary_CI{
        font-family: $font-6;
        font-size: 18px;
        border: none;
        background: red;
        color: white;

        &:hover{
            background: rgb(255, 61, 61);
        }
        &:active{
            background: rgb(155, 79, 79);
        }
    }
}

.icon-dot-saveChanges-Itinerary_CI{
    color: red;
    left: -0.4%;
    top: 16%;
    // top: 10px;
    position: fixed;
    font-size: 60px;
    border: none;
    background: none;
}

//!Heading
.conatiner_p-Heading-CreateItinerary-Itinerary_CI{
    width: 100%;
    align-items: center;
    text-align: center;
    // border: 1px solid gray;
    margin-bottom: 2%;
}

@media (min-width: 0px) and (max-width: 799px){
    .text-Heading-CreateItinerary-Itinerary_CI{
        font-family: $font-6;
        font-size: 24px;
        color: rgb(131, 50, 7);
        word-spacing: 0.4px;
        letter-spacing: 1.4px;
    }
}
@media (min-width: 800px){
    .text-Heading-CreateItinerary-Itinerary_CI{
        font-family: $font-7;
        font-size: 24px;
        color: rgb(57, 56, 56);
        word-spacing: 0.4px;
        letter-spacing: 1.4px;
    }
}

// .text-Heading-CreateItinerary-Itinerary_CI{
//     font-family: $font-6;
//     font-size: 24px;
//     color: rgb(57, 56, 56);
//     word-spacing: 0.4px;
//     letter-spacing: 1.4px;
// }

/* !needs to be shifted to the respective css file */
/* Topic Details */
.component-Entities_TD-Itinerary_CI{
    margin: 1% 0% 1% 0%;
    background-color: rgb(255, 255, 255);
    display: flex;
    border-radius: 10px;
    padding: 1% 1% 1% 1%;
    display: flex;
    flex-direction: column;
}
.ItineraryDayInfoRow-Itinerary_CI{
    margin: 1%;
    background-color: rgb(173, 211, 61);
    display: flex;
    border-radius: 10px;
    padding: 2%;
}

.ItineraryBudgetInfoRow-Itinerary_CI{
    margin: 1%;
    background-color: rgb(23, 128, 188);
    display: flex;
    border-radius: 10px;
    padding: 2%;
}

.ItinerarySimpleNotesRow-Itinerary_CI{
    margin: 1%;
    background-color: rgb(23, 128, 188);
    display: flex;
    border-radius: 10px;
    padding: 2%;
}

.ItineraryChecklistRow-Itinerary_CI{
    margin: 1%;
    background-color: rgb(128, 20, 183);
    display: flex;
    border-radius: 10px;
    padding: 2%;
}

.ItineraryTripDocumentsRow-Itinerary_CI{
    margin: 1%;
    background-color: rgb(0, 0, 0);
    display: flex;
    border-radius: 10px;
    padding: 2%;
}