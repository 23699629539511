// import plantrip global css
@use '../../../CSS - Plant Trip Global/Manage/_Index_PlanTrip' as ns;

.testingVariable{
    font-size: 26px;
    color: ns.$primary-color;
    font-style: ns.$font-stack;
} 

$color-1: rgb(196, 19, 19);  

.testButton{
    @include ns.button-styles($color-1);
} 

.button-active-mainNavigation-AboutDestination{
    @include ns.secondary-button-plantrip;
}

.button-unActive-mainNavigation-AboutDestination{
    @include ns.primary-button-plantrip
}

//main

$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';

//!header
.container_p-header-AboutDestination{
    margin-top: 0.6%;
    width: 100%;
    display: flex;
    justify-content: center;
    // border: 1px solid orchid;
}
.container_s-header-AboutDestination{
    width: 100%;
    // width: 100%;
    display: flex;
    justify-content: center;
    // border: 4px solid rgb(26, 125, 206);
}

//!navigation
// .container_p-navigationMain-AboutDestination{
//     margin-top: 4%;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     // border: 2px solid green;
// }
// .container_s-navigationMain-AboutDestination{
//     // width: 54%;
//     // border: 2px solid green;
//     display: flex;
//     align-items: center;
//     justify-content: space-around;
//     display: flex;
//     flex-wrap: wrap;
// }

//!Mobile
@media (min-width: 0px) and (max-width: 799px){
    .container_p-navigationMain-AboutDestination{
        margin-top: 4%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 2px solid green;
        // background: rgb(255, 250, 250);
    }
    .container_s-navigationMain-AboutDestination{
        // width: 54%;
        // border: 2px solid green;
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: column;
        // justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }
}
//!Website
@media (min-width: 800px){
    .container_p-navigationMain-AboutDestination{
        margin-top: 4%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 2px solid green;
    }
    .container_s-navigationMain-AboutDestination{
        // width: 54%;
        // border: 2px solid green;
        display: flex;
        align-items: center;
        justify-content: space-around;
        display: flex;
        flex-wrap: wrap;
    }
}

//button
.button-active-mainNavigation-AboutDestination {
    background-color: #ffe6cf;
    color: #000000;
    border: 2.2px solid #f37100;
    border-radius: 16px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin: 6px 10px 8px 8px;

    &:hover {
        background-color: darken(#ffe2ce, 10%);
    }

    &:active {
        background-color: lighten(#ffffff, 10%);
    }
}

.button-unactive-mainNavigation-AboutDestination {
    background-color: #ffffff;
    color: #282827;
    border: 2.2px solid #f37100;
    border-radius: 16px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin: 10px 10px 8px 8px;

    &:hover {
        background-color: darken(#ffd3b3, 10%);
    }

    &:active {
        background-color: lighten(#ffffff, 10%);
    }
}
.container_t-navigationText-AboutDestination{
    // font-family: 'Quicksand';
    font-family: $font-6;
    font-size: 16px;
    // font-weight: bold;
    margin: 1px 4px 1px 4px;
}
.img-navigationText-AboutDestination{
    width: 26px;
    margin: 1px 8px 1px 8px;
    filter: brightness(0%); /* Makes the entire image black */

}

//!footer spaces
.footerSpace-AboutDestination{
    height: 300px;
    // background-color: darken(#ffd3b3, 10%);

}

//!components------------------- 

//!overview
.container_p-component-overview-AboutDestination{
    width: 100%;
    margin-top: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: solid rgb(251, 7, 7) 1px;
}
//?depricated
// .container_s-component-overview-AboutDestination{
//     width: 80%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border: solid gray 1px;
//     overflow: hidden;
// }

//!about
.container_p-component-about-AboutDestination{
    width: 100%;
    display: flex;
    margin-top: 4%;
    justify-content: center;
    // border: 12px solid yellowgreen;

}
// .container_s-component-about-AboutDestination{
//     width: 70%;
//     display: flex;
//     justify-content: center;
//     // border: 20px solid yellowgreen;
// }
@media (min-width: 0px) and (max-width: 799px){
    .container_s-component-about-AboutDestination{
        width: 94%;
        display: flex;
        justify-content: center;
        // border: 20px solid yellowgreen;
    }
}
@media (min-width: 800px){
    .container_s-component-about-AboutDestination{
        width: 70%;
        display: flex;
        justify-content: center;
        // border: 20px solid yellowgreen;
    }
}

//!qna
.container_p-component-qna-AboutDestination{
    width: 100%;
    display: flex;
    margin-top: 4%;
    justify-content: center;
}
// .container_s-component-qna-AboutDestination{
//     width: 70%;
//     display: flex;
//     justify-content: center;
// }
@media (min-width: 0px) and (max-width: 799px){
    .container_s-component-qna-AboutDestination{
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
@media (min-width: 800px){
    .container_s-component-qna-AboutDestination{
        width: 70%;
        display: flex;
        justify-content: center;
    }
}

//!reach
// .container_p-component-reach-AboutDestination{
//     width: 100%;
//     display: flex;
//     margin-top: 4%;
//     justify-content: center;
//     // border: 20px solid rgb(55, 81, 2);
// }
// .container_s-component-reach-AboutDestination{
//     width: 70%;
//     display: flex;
//     justify-content: center;
//     flex-direction: row;
//     // border: 20px solid yellowgreen;
//     justify-content: space-around;
// }
// .component-reach-AboutDestination{
//     width: 58%;  
//     // border: 2px solid rgb(224, 205, 35);
// } 
// .component-reachMap-AboutDestination{
//     width: 38%;
//     // border: 2px solid rgb(2, 59, 193);
// } 

@media (min-width: 0px) and (max-width: 899px){
    .container_p-component-reach-AboutDestination{
        width: 100%;
        display: flex;
        margin-top: 4%;
        justify-content: center;
        // border: 20px solid rgb(55, 81, 2);
    }
    .container_s-component-reach-AboutDestination{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: row;
        // border: 20px solid yellowgreen;
        justify-content: space-around;
    }
    .component-reach-AboutDestination{
        width: 90%;  
        // border: 2px solid rgb(224, 205, 35);
    } 
    .component-reachMap-AboutDestination{
        width: 38%;
        // border: 2px solid rgb(2, 59, 193);
    } 
}
@media (min-width: 900px){
    .container_p-component-reach-AboutDestination{
        width: 100%;
        display: flex;
        margin-top: 4%;
        justify-content: center;
        // border: 20px solid rgb(55, 81, 2);
    }
    .container_s-component-reach-AboutDestination{
        width: 70%;
        display: flex;
        justify-content: center;
        flex-direction: row;
        // border: 20px solid yellowgreen;
        justify-content: space-around;
    }
    .component-reach-AboutDestination{
        width: 58%;  
        // border: 2px solid rgb(224, 205, 35);
    } 
    .component-reachMap-AboutDestination{
        width: 38%;
        // border: 2px solid rgb(2, 59, 193);
    } 
}