$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


//!Buttons
.container_p-Questions-AskQuestions{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    // overflow-y: hidden;
    // overflow-x: scroll;
    // border: 1px solid gainsboro;
    margin-top: 6%;
    width: 100%;
}
.text-active-Question-AskQuestions{
    color: rgb(0, 0, 0);
    margin: 8px;
    // font-weight: bold;
    padding: 8px 10px 8px 10px;
    border-radius: 10px;
    border: none;
    background: rgb(247, 247, 245);
    font-size: 18px;
    font-family: $font-6;
    box-shadow: rgba(250, 233, 205, 0.932) 0px 2px 2px 0px;
    // white-space: nowrap;
    
    &:hover{
        // box-shadow: rgba(237, 255, 195, 0.843) 0px 7px 29px 0px;
        box-shadow: 0 0 3px rgba(237, 117, 117, 0.5);
        border-radius: 6px;
        transition: box-shadow 0.3s ease;
    }
    &:active{
        box-shadow: rgba(212, 121, 9, 0.843) 0px 7px 29px 0px;
        transition: box-shadow 0.3s ease;
    }
}
.text-unActive-Question-AskQuestions{
    color: rgb(104, 103, 102);
    margin: 8px;
    padding: 8px 10px 8px 10px;
    border-radius: 10px;
    border: none;
    background: none;
    // background: whitesmoke;
    border: 2px solid whitesmoke;
    font-size: 18px;
    font-family: $font-6;
    // white-space: nowrap;

    &:hover{
        // box-shadow: rgba(237, 255, 195, 0.843) 0px 7px 7px 0px;
        box-shadow: 0 0 3px rgba(237, 117, 117, 0.5);
        border-radius: 6px;
        transition: box-shadow 0.3s ease;
    }
    &:active{
        box-shadow: 0 0 3px rgba(255, 0, 0, 0.5);
        transition: box-shadow 0.3s ease;
    }

}


//!Questions - Answers
.conatiner_p-answer-Ask_Questions{
    // border: 2px solid rgb(255, 235, 225);
    box-shadow: rgba(255, 173, 59, 0.825) 0px 1px 2px 0px;

    padding: 8px;
    border-radius: 8px;
}

.conatiner_s-asnwer-Ask_Questions{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: safe;
    justify-content: start;
}
.text-1-answer-AskQuestions{
    font-family: $font-6;
    color: rgb(20, 105, 157);
    margin-left: 10px;
    font-size: 18px;
}
//? Rich Text - start 
.content-1-answer-AskQuestions{
    // background: white;
    align-self: flex-start;
    text-align: start;
    justify-self: self-start;
    width: 90%;
    background: rgb(255, 255, 255);
    color: rgb(56, 54, 54);
    // border: 1px solid olive;
    align-items: self-start;
    text-align: start;
    padding: 10px;
    // margin-top: 6px;
    // margin: 6px 10px 10px 40px;
    border-radius: 12px;
    font-family: $font-6;
    // font-family: $font-heading-2;
    font-size: 16px;
    letter-spacing: 0.4px;
    word-spacing: 0.6px;
    line-height: 1.6;  
}

/* Adding styles for ul and li elements */
.content-1-answer-AskQuestions ul {
    padding-left: 20px; /* Adjust as needed */
    margin-bottom: 20px; /* Adjust as needed */
}
.content-1-answer-AskQuestions ol {
    padding-left: 20px; /* Adjust as needed */
    margin-bottom: 20px; /* Adjust as needed */
    font-family: $font-6;
}

.content-1-answer-AskQuestions li {
    margin-bottom: 10px; /* Space between list items */
    line-height: 1.6; /* Adjust for spacing within list items */
    // font-family: $font-6;
    // color: rebeccapurple;
    // font-weight: normal;
    // font-family: $font-4;
}

.content-1-answer-AskQuestions h1 {
    // font-family: $;
    font-size: 17px;
    margin-top: 8px;
}

.content-1-answer-AskQuestions h2 {
    // font-family: $;
    font-size: 17px;
    color: rgb(52, 51, 51);
    margin-top: 8px;
    margin-bottom: 6px;
    font-family: $font-heading-1;
    letter-spacing: 0.8px;
}

.content-1-answer-AskQuestions h3 {
    // font-family: $;
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 8px;
    font-family: $font-6;
    letter-spacing: 0.8px;
    font-weight: normal;
    // color: gray;
    text-decoration: underline;
}
//? Rich Text - End

//?askquestion
.conatiner_s-button-askQuestion-AskQuestions{
    align-items: center;
    display: flex;
    justify-content: center;
}
.button-askQuestion-AskQuestions{
    color: rgb(241, 151, 61);
    margin: 8px;
    padding: 5px 10px 4px 10px;
    border-radius: 10px;
    border: none;
    background: none;
    // background: whitesmoke;
    border: 2px solid rgb(238, 235, 235);
    font-size: 16px;
    font-family: $font-6;
    white-space: nowrap;

    &:hover{
        // box-shadow: rgba(237, 255, 195, 0.843) 0px 7px 7px 0px;
        box-shadow: 0 0 3px rgba(255, 0, 0, 0.5);
        border: 2px solid rgb(255, 255, 255);
        border-radius: 6px;
        transition: box-shadow 0.3s ease;
        transition: border 0.3s ease;
    }
    &:active{
        box-shadow: 0 0 3px rgba(255, 141, 10, 0.5);
        border: 2px solid rgb(255, 255, 255);
        transition: box-shadow 0.3s ease;
        transition: border 0.3s ease;
        border-radius: 6px;
    }
}

//popup
.text-1-askQuestion-AskQuestions{
    font-family: $font-6;
    color: rgb(250, 135, 135);
}
.input-askQuestion-AskQuestions{
    padding: 8px 8px 8px 8px; /* Padding inside the input */
    border: 1px solid #ffc7c7; /* Border color */
    border-radius: 8px; /* Rounded corners */
    font-size: 14px; /* Font size */
    color: #333; /* Text color */
    background-color: #ffffff; /* Background color */
    outline: none; /* Remove default outline on focus */
    transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for focus state */
    width: 100%;
    height: 120px;
    margin-top: 4%;
    font-family: $font-4;
    letter-spacing: 0.3px;
    color: gray;
}
.input-askQuestion-AskQuestions:focus{
    border-color: none; /* Border color on focus */
    box-shadow: 0 0 5px wheat; /* Box shadow on focus */
}
.input-askQuestion-AskQuestions::placeholder{
    color: lightgray;
}
.input-askQuestion-AskQuestions::-webkit-scrollbar {
    width: 5px;
    scrollbar-width: 0px;
    border-radius: 60px;
}
.input-askQuestion-AskQuestions::-webkit-scrollbar-track-piece {
    background-color: #e0dfde;
}
.input-askQuestion-AskQuestions::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    width: 200px;
    background-color: #868584;
    border-radius: 60px;  
}  

.button-save-AskQuestions{
    // margin: 8px 12px 4px 12px;
    padding: 4px 40px 4px 40px;
    font-size: 14px;
    white-space: nowrap;
    border-radius: 8px;
    font-family: $font-4;
    margin-top: 10px;
    letter-spacing: 0.8px;
    // background-color: #edece7; /* Green color when active */
    background: none;
    color: rgb(0, 0, 0);
    border: none;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1); /* Subtle box shadow when active */
    font-weight: bold;

    &:hover{
        color: red;
        box-shadow: 0 0 4px rgba(49, 49, 49, 0.1); /* Subtle box shadow when active */
        transition: color 0.3s ease;
        transition: background-color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}


.button-close-AskQuestions{
    font-size: 22px;
    border: none;
    background: none;
    // position: fixed;
    // right: 21%;
    margin-top: 8%;

    &:hover{
        color: red;
        transition: color 0.3s ease;
    }
    &:active{
        color: orange;
        transition: color 0.3s ease;
    }
}