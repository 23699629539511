$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';


//
.container_p-navigation_CI-CreateItinerary_Mobile{
    // background: yellowgreen;
    padding: 20px 0px 0px 0px;
    // width: 100%;
    width: fit-content;
    margin-left: 2%;
    // background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.container_s-navigation_CI-CreateItinerary_Mobile{
    // background: yellowgreen;
    // width: 100%;
    background: white;
    width: fit-content;
    border-radius: 6px;
    padding: 4px 8px 4px 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.button-active-navigation_CI-CreateItinerary_Mobile{
    border: none;
    padding: 4px 12px 4px 12px;
    margin: 4px;
    border-radius: 6px;
    font-weight: bold;
    font-family: $font-4;
    background: rgb(224, 7, 7);
    color: white;
}
.button-unActive-navigation_CI-CreateItinerary_Mobile{
    border: none;
    padding: 4px 12px 4px 12px;
    margin: 4px;
    border-radius: 6px;
    // font-weight: bold;
    font-family: $font-4;
    color: rgb(224, 7, 7);
    background: none;
    font-weight: bold;
}