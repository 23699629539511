$primary-color: #487008;
$secondary-color: #28a745;
$accent-color: #ffc107;
$font-stack: Helvetica, Arial, sans-serif;
$body-bg: #f8f9fa;

@mixin button-styles($color) {
    background-color: $color;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


// .testButton{
//     @include button-styles($accent-color);
// }