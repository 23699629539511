$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


@media (min-width: 0px) and (max-width: 799px){
    .conatiner_p-data-About_VM{
        width: 100%;
        // background: whitesmoke;
        // box-shadow: rgb(255, 211, 100) 0px 0px 5px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 20px 0px 20px 0px;
        margin-top: -4%;
    }
    .content-1-data-About_VM{
        // background: white;
        width: 100%;
        color: rgb(56, 54, 54);
        // border: 1px solid olive;
        align-items: self-start;
        text-align: start;
        // padding: 10px;
        // margin: 24px 10px 10px 10px;
        border-radius: 12px;
        font-family: $font-6;
        // font-family: $font-heading-2;
        font-size: 16px;
        letter-spacing: 0.4px;
        word-spacing: 0.6px;
        line-height: 1.6;  
    }
}
@media (min-width: 800px){
    .conatiner_p-data-About_VM{
        width: 100%;
        // background: whitesmoke;
        box-shadow: rgb(255, 211, 100) 0px 0px 5px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 20px 0px 20px 0px;
        margin-top: -4%;
    }
    .content-1-data-About_VM{
        // background: white;
        width: 90%;
        color: rgb(56, 54, 54);
        // border: 1px solid olive;
        align-items: self-start;
        text-align: start;
        padding: 10px;
        margin: 24px 10px 10px 10px;
        border-radius: 12px;
        font-family: $font-6;
        // font-family: $font-heading-2;
        font-size: 16px;
        letter-spacing: 0.4px;
        word-spacing: 0.6px;
        line-height: 1.6;  
    }
}

// .conatiner_p-data-About_VM{
//     width: 100%;
//     // background: whitesmoke;
//     box-shadow: rgb(255, 211, 100) 0px 0px 5px;
//     border-radius: 12px;
//     display: flex;
//     align-items: center;
//     text-align: center;
//     justify-content: center;
//     padding: 20px 0px 20px 0px;
//     margin-top: -4%;
// }
// .content-1-data-About_VM{
//     // background: white;
//     width: 90%;
//     color: rgb(56, 54, 54);
//     // border: 1px solid olive;
//     align-items: self-start;
//     text-align: start;
//     padding: 10px;
//     margin: 24px 10px 10px 10px;
//     border-radius: 12px;
//     font-family: $font-6;
//     // font-family: $font-heading-2;
//     font-size: 16px;
//     letter-spacing: 0.4px;
//     word-spacing: 0.6px;
//     line-height: 1.6;  
// }
/* Adding styles for ul and li elements */
.content-1-data-About_VM ul {
    padding-left: 20px; /* Adjust as needed */
    margin-bottom: 20px; /* Adjust as needed */
}
.content-1-data-About_VM ol {
    padding-left: 20px; /* Adjust as needed */
    margin-bottom: 20px; /* Adjust as needed */
    font-family: $font-6;
}

.content-1-data-About_VM li {
    margin-bottom: 10px; /* Space between list items */
    line-height: 1.6; /* Adjust for spacing within list items */
    // font-family: $font-6;
    // color: rebeccapurple;
    // font-weight: normal;
    // font-family: $font-4;
}

.content-1-data-About_VM h1 {
    // font-family: $;
    font-size: 17px;
    margin-top: 8px;
}

.content-1-data-About_VM h2 {
    // font-family: $;
    font-size: 17px;
    color: rgb(52, 51, 51);
    margin-top: 8px;
    margin-bottom: 6px;
    font-family: $font-heading-1;
    letter-spacing: 0.8px;
}

.content-1-data-About_VM h3 {
    // font-family: $;
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 8px;
    font-family: $font-6;
    letter-spacing: 0.8px;
    font-weight: normal;
    // color: gray;
    text-decoration: underline;
}