$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//

.container_p-input-Search_Locations{
    // border: 1px solid burlywood;
    // margin-top: 8%;
    align-items: start;
    text-align: start;
    width: 100%;
}
.input-inputDate-Search_Locations{
    padding: 6px 8px 5px 8px; /* Padding inside the input */
    border: 1px solid #ffc7c7; /* Border color */
    border-radius: 8px; /* Rounded corners */
    font-size: 14px; /* Font size */
    color: #333; /* Text color */
    background-color: #ffffff; /* Background color */
    outline: none; /* Remove default outline on focus */
    transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for focus state */
    width: 100%;
    font-family: $font-6;
}

.input-inputDate-Search_Locations:focus{
    border-color: #cececd; /* Border color on focus */
    box-shadow: 0 0 5px #cacaca; /* Box shadow on focus */
}


.conatiner_p-searchOptions-Search_Locations{
    position: absolute;
    width: 160%;
    // border: 1px solid orchid;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-left: -10%;
    height: 320px;
    padding: 4px;
    border-radius: 8px;
    z-index: 1000;
    background: whitesmoke;
}

.container_p-Content-Search_Locations{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
}
.text-1-name-Search_Locations{
    font-family: $font-6;
    color: rgb(0, 0, 0);
    margin-right: 4px;
    margin-bottom: -3px;
    margin-top: 2px;
}
.text-2-category-Search_Locations{
    font-family: $font-3;
    color: rgb(69, 69, 69);
    // margin-left: 6px;
    margin-right: 4px;
    // margin-top: -2px;
    margin-top: 2px;
}
.text-3-category-Search_Locations{
    font-family: $font-5;
    color: rgb(69, 69, 69);
    // margin-left: 6px;
    margin-top: 2px;
}