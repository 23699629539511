$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//


.container_p-SearchTool{
    width: 100%;
    // background: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 4%;
}


// .container_s-SearchTool{
//     width: 60%;
//     // border: 2px lightgray solid;
//     border-radius: 12px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     // justify-content: center;
// }

@media (min-width: 0px) and (max-width: 499px){
    .container_s-SearchTool{
        width: 100%;
        // border: 2px lightgray solid;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
    }
    .conatiner_p-meta_details-searchTools{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 92%;
        // border: 1px solid lightblue;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-top: 2%;
    }
    .container_p-searchInput-SearchTool{
        width: 84%;
        margin-top: 2%;
        background: white;
        // background: rgb(184, 33, 33);
        padding: 4px 20px 4px 20px;
        border-radius: 10px;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 18px;
        box-shadow: rgba(160, 160, 160, 0.418) 0px 0px 6px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // position: relative;
        height: 40px;
    }
    .input-searchInput-SearchTool{
        // padding: 8px 8px 8px 8px; /* Padding inside the input */
        // border: 1px solid #ffc7c7;
        border: none;
        border-radius: 8px; 
        font-size: 16px; 
        color: #333; 
        background-color: #ffffff; 
        // background: rgb(139, 132, 132);
        outline: none; 
        transition: border-color 0.3s, box-shadow 0.3s; 
        width: 120%;
        // width: 200%;
        height: 100%;
        // font-family: $font-6;
        font-family: $font-3;
        font-weight: normal;
        font-weight: bold;
        letter-spacing: 0.4px;
        color: rgb(122, 81, 10);
    }
    .input-searchInput-SearchTool:focus{
        border-color: none;  
    }
    .input-searchInput-SearchTool::placeholder{
        color: rgb(192, 192, 192);
        font-family: $font-6;
        font-weight: normal;
    }
 }
 @media (min-width: 500px) and (max-width: 1000px){
    .container_s-SearchTool{
        width: 88%;
        // border: 2px lightgray solid;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
    }
    .conatiner_p-meta_details-searchTools{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 72%;
        // border: 1px solid lightblue;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-top: 2%;
    }
    .container_p-searchInput-SearchTool{
        width: 64%;
        margin-top: 2%;
        background: white;
        // background: rgb(184, 33, 33);
        padding: 4px 20px 4px 20px;
        border-radius: 10px;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 18px;
        box-shadow: rgba(160, 160, 160, 0.418) 0px 0px 6px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // position: relative;
        height: 40px;
    }
    .input-searchInput-SearchTool{
        // padding: 8px 8px 8px 8px; /* Padding inside the input */
        // border: 1px solid #ffc7c7;
        border: none;
        border-radius: 8px; 
        font-size: 16px; 
        color: #333; 
        background-color: #ffffff; 
        outline: none; 
        transition: border-color 0.3s, box-shadow 0.3s; 
        // width: 200%;
        width: 160%;
        height: 100%;
        // font-family: $font-6;
        font-family: $font-3;
        font-weight: normal;
        font-weight: bold;
        letter-spacing: 0.4px;
        color: rgb(122, 81, 10);
    }
    .input-searchInput-SearchTool:focus{
        border-color: none;  
    }
    .input-searchInput-SearchTool::placeholder{
        color: rgb(192, 192, 192);
        font-family: $font-6;
        font-weight: normal;
    }
 }
 @media (min-width: 1000px){
    .container_s-SearchTool{
        width: 50%;
        // border: 2px lightgray solid;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
    }
    .conatiner_p-meta_details-searchTools{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 66%;
        // border: 1px solid lightblue;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-top: 2%;
    }
    .container_p-searchInput-SearchTool{
        width: 64%;
        margin-top: 2%;
        background: white;
        // background: rgb(184, 33, 33);
        padding: 4px 20px 4px 20px;
        border-radius: 10px;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 18px;
        box-shadow: rgba(160, 160, 160, 0.418) 0px 0px 6px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // position: relative;
        height: 40px;
    }
    .input-searchInput-SearchTool{
        // padding: 8px 8px 8px 8px; /* Padding inside the input */
        // border: 1px solid #ffc7c7;
        border: none;
        border-radius: 8px; 
        font-size: 16px; 
        color: #333; 
        background-color: #ffffff; 
        outline: none; 
        transition: border-color 0.3s, box-shadow 0.3s; 
        // width: 200%;
        width: 200%;
        height: 100%;
        // font-family: $font-6;
        font-family: $font-3;
        font-weight: normal;
        font-weight: bold;
        letter-spacing: 0.4px;
        color: rgb(122, 81, 10);
    }
    .input-searchInput-SearchTool:focus{
        border-color: none;  
    }
    .input-searchInput-SearchTool::placeholder{
        color: rgb(192, 192, 192);
        font-family: $font-6;
        font-weight: normal;
    }
}



.img-mountain-search-SearchTool{
    // position: absolute;
    width: 160px;
    height: 60px;
    margin-left: -20px;
}
.img-stars-search-SearchTool{
    // position: absolute;
    width: 50px;
    height: 50px;
}
.img-search-search-SearchTool{
    // position: absolute;
    width: 30px;
    height: 30px;
    // margin-left: -40px;
    // margin-top: -2px;
}


.conatiner_p-searchDestination-SearchTool{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2%;
}
.button-active-searchDestination-SearchTool{
    background-color: rgb(255, 255, 255);
    padding: 4px 8px 4px 8px;
    border-radius: 10px;
    color: orangered;
    border: none;
    margin-right: 16px;
    font-family: $font-content-2;
    box-shadow: 0 0 8px rgba(195, 195, 195, 0.3); 
    
    &:hover{
        color: rgb(255, 138, 96);
        transition: color 0.3s ease;
    }
    &:active{
        color: red;
        transition: color 0.2s ease;
    }
}
.button-unactive-searchDestination-SearchTool{
    // background-color: rgb(255, 255, 255);
    box-shadow: 0 0 8px rgba(221, 221, 221, 0.3); 
    color: gray;
    background: none;
    padding: 4px 8px 4px 8px;
    border-radius: 10px;
    border: none;
    margin-right: 16px;
    font-family: $font-content-2;

    &:hover{
        color: rgb(255, 138, 96);
        transition: color 0.3s ease;
    }
    &:active{
        color: red;
        transition: color 0.2s ease;
    }
}


// .conatiner_p-meta_details-searchTools{
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     width: 66%;
//     // border: 1px solid lightblue;
//     align-items: center;
//     text-align: center;
//     justify-content: center;
//     margin-top: 2%;
// }
.button-meta_details-searchTools{
    border: none;
    color: rgb(156, 156, 156);
    background-color: rgb(248, 248, 248);
    padding: 4px 8px 4px 8px;
    border-radius: 10px;
    font-family: $font-6;
    letter-spacing: 0.8px;
    font-size: 11px;
    font-weight: normal;
    margin-top: 12px;
    margin-right: 6px;
}

.container_p-SearchText-SearchTool{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.heading-search-SearchTool{
    font-family: $font-heading-3;
    // font-family: $font-heading-6;
    color: rgb(255, 68, 0);
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 2px;
    position: relative;
}

//other states
.container_p-OtherStates-SearchTool{
    display: flex;
    flex-direction: row;
    margin-bottom: -2px;
    align-items: center;
    text-align: center;
    margin-top: 2%;
    // background-color: antiquewhite;
    width: 60%;
}
.container_s-india-SearchTool{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 1px 6px 1px 6px;
    border-radius: 8px;
    background: rgb(244, 244, 244);
}

.text-1-OtherStates-SearchTool{
    font-family: $font-6;    
    color: #5b5959;
    font-size: 13px;
}
.text-2-OtherStates-SearchTool{
    font-family: $font-4;
    margin-left: 4px;
    font-size: 12px;
    color: grey;
    margin-left: 12px;
}

.img-OtherStates-SearchTool{
    width: 18px;
    height: 18px;
    margin-left: 4px;
}


// .container_p-searchInput-SearchTool{
//     width: 64%;
//     margin-top: 2%;
//     background: white;
//     // background: rgb(184, 33, 33);
//     padding: 4px 20px 4px 20px;
//     border-radius: 10px;
//     // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 18px;
//     box-shadow: rgba(160, 160, 160, 0.418) 0px 0px 6px;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     // position: relative;
//     height: 40px;
// }
// .input-searchInput-SearchTool{
//     // padding: 8px 8px 8px 8px; /* Padding inside the input */
//     // border: 1px solid #ffc7c7;
//     border: none;
//     border-radius: 8px; 
//     font-size: 16px; 
//     color: #333; 
//     background-color: #ffffff; 
//     outline: none; 
//     transition: border-color 0.3s, box-shadow 0.3s; 
//     // width: 200%;
//     width: 200%;
//     height: 100%;
//     // font-family: $font-6;
//     font-family: $font-3;
//     font-weight: normal;
//     font-weight: bold;
//     letter-spacing: 0.4px;
//     color: rgb(122, 81, 10);
// }

// .input-searchInput-SearchTool:focus{
//     border-color: none;  
// }
// .input-searchInput-SearchTool::placeholder{
//     color: rgb(192, 192, 192);
//     font-family: $font-6;
//     font-weight: normal;
// }

@media (min-width: 0px) and (max-width: 499px){
    .container_p-popup-searchDestinations-searchTool{
        display: flex;
        position: absolute;
        flex-direction: column;
        margin-top: 10%;
        // border: 1px solid olivedrab;
        z-index: 4px;
        // min-width: 0%;
        // max-width: 34%;
        width: 80%;
        height: 60%;
        overflow-y: scroll;
        text-align: start;
        align-items: self-start;
        margin-left: -3%;
        background: white;
        box-shadow: rgb(128, 128, 128) 0px 0px 6px;
        border: none;
        padding: 8px 6px 4px 6px;
        border-radius: 6px;
        z-index: 2;
    }
}

@media (min-width: 500px) and (max-width: 1000px){
    .container_p-popup-searchDestinations-searchTool{
        display: flex;
        position: absolute;
        flex-direction: column;
        margin-top: 5%;
        // border: 1px solid olivedrab;
        z-index: 4px;
        // min-width: 0%;
        // max-width: 34%;
        width: 46%;
        height: 60%;
        overflow-y: scroll;
        text-align: start;
        align-items: self-start;
        margin-left: -1%;
        background: white;
        box-shadow: rgb(128, 128, 128) 0px 0px 6px;
        border: none;
        padding: 8px 6px 4px 6px;
        border-radius: 6px;
        z-index: 2;
    }
}

@media (min-width: 1000px){
    .container_p-popup-searchDestinations-searchTool{
        display: flex;
        position: absolute;
        flex-direction: column;
        margin-top: 3.4%;
        // border: 1px solid olivedrab;
        z-index: 4px;
        // min-width: 0%;
        // max-width: 34%;
        width: 34%;
        text-align: start;
        align-items: self-start;
        margin-left: -1%;
        background: white;
        box-shadow: rgb(128, 128, 128) 0px 0px 6px;
        border: none;
        padding: 8px 6px 4px 6px;
        border-radius: 6px;
        z-index: 2;
    }
}

// .container_p-popup-searchDestinations-searchTool{
//     display: flex;
//     position: absolute;
//     flex-direction: column;
//     margin-top: 3.4%;
//     // border: 1px solid olivedrab;
//     z-index: 4px;
//     // min-width: 0%;
//     // max-width: 34%;
//     width: 34%;
//     text-align: start;
//     align-items: self-start;
//     margin-left: -1%;
//     background: white;
//     box-shadow: rgb(128, 128, 128) 0px 0px 6px;
//     border: none;
//     padding: 8px 6px 4px 6px;
//     border-radius: 6px;
//     z-index: 2;
// }
.container_s-popup-searchDestinations-searchTool{
    padding: 4px 8px 4px 8px;
    margin: 0px 0px 6px 0px;
    border: none;
    background: rgb(246, 243, 243);
    display: flex;
    flex-direction: row;
    // width: 100%;
    flex-wrap: wrap;

    &:hover{
        border-radius: 8px;
        background-color: rgb(255, 234, 234);
    }
    &:active{
        background-color: rgb(255, 194, 194); 
        transition: background-color 0.3s ease;
    }
}
.text-1-popup-searchDestinations-searchTool{
    font-family: $font-6;
    color: rgb(0, 0, 0);
    font-size: 16px;
}
.text-2-popup-searchDestinations-searchTool{
    font-family: $font-6;
    color: rgb(98, 98, 98); 
    font-size: 16px;
}
.text-3-popup-searchDestinations-searchTool{
    font-family: $font-6;
    color: rgb(159, 157, 157);
    font-size: 16px;
}