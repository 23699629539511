$font-heading-1: 'Oxygen';
$font-heading-2: 'Finlandica';
$font-heading-3: 'Concert One';
$font-content-1: 'Nunito Sans';
$font-content-2: 'Rubik';
$font-3: 'Quicksand';
$font-4: 'Comfortaa';
$font-5: 'Nanum Gothic';
$font-6: 'Josefin Sans';
//?new
$font-7: 'Roboto';
$font-8: 'Open Sans';
$font-9: 'Raleway';
$font-10: 'Ubuntu';
$font-11: 'Merriweather';
$font-12: 'Playfair Display';
$font-13: 'Source Sans Pro';
//



.popup-background-1-feedback_1{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.652);
    z-index: 100000000000000000000;
    // opacity: 0.6;
}
.popup_p-2-addNote-feedback_1{
    width: 60%;
    height: 80%;
    position: fixed;
    top: 4%;
    left: 20%;
    border: none;
    border-radius: 8px;
    background: #ffffff;
    padding: 20px;
    box-shadow: 0 0 12px rgba(92, 92, 93, 0.835);
    z-index: 1000000000000000000000;
    overflow: auto;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    text-align: center;
}

.container_p-feedback_1{
    margin-top: 4%;
    width: 100%;
    display: flex;
    align-items: center;
}
.container_p-giveFeedback-Feedback_1{
    width: 80%;
    display: flex;
    flex-direction: column;
}



//!
.text-1-Feedback_1{
    font-family: $font-content-2;
    color: rgb(6, 91, 195);
    margin-bottom: 6%;
}
.heading-1-Feedback_1{
    color: gray;
    font-family: $font-6;
    margin-top: 8px;
}
.textArea-1-Feedback_1{
    color: gray;
    font-family: $font-6;
    margin-top: 4px;
}
.text-1-Feedback_1{
    color: black;
    font-family: $font-6;
    letter-spacing: 0.4px;
}
.line-1-Feedback_1{
    border-bottom: 0.4px rgb(234, 234, 234) solid;
    width: 40%;
    margin-top: 4%;
    margin-bottom: 4%;
    align-self: center;
    text-align: center;
}
.container_p-slider-Feedback_1{
    width: 90%;
    margin-top: 4%;
    align-self: center;
    text-align: center;
}
.container_p-buttons-Feedback_1{
    margin-top: 8px;
}
.button-unActive-recommend-Feedback_1{
    background: whitesmoke;
    padding: 4px 8px 4px 8px;
    border-radius: 8px;
    border: none;
    transition: background-color 0.3s ease;
    transition: font-weight 0.3s ease;
    font-family: $font-4;
    margin-right: 6px;
    margin-bottom: 4px;
    margin-top: 4px;
    transition: background-color 0.3s ease;
    transition: color 0.3s ease;
    font-size: 12px;

    &:hover{
        background-color: #ffebeb;
        transition: background-color 0.3s ease;
    }
    &:active{
        color:  rgb(0, 126, 194);
        background-color: white;
    }
}
.button-active-recommend-Feedback_1{
    background: whitesmoke;
    color: rgb(21, 120, 196);
    padding: 4px 8px 4px 8px;
    border-radius: 8px;
    border: none;
    transition: background-color 0.3s ease;
    transition: font-weight 0.3s ease;
    font-family: $font-4;
    margin-right: 6px;
    margin-bottom: 4px;
    margin-top: 4px;
    transition: background-color 0.3s ease;
    transition: color 0.3s ease;
    font-weight: bold;
    font-size: 12px;

    &:hover{
        background-color: #ffebeb;
        transition: background-color 0.3s ease;
    }
    &:active{
        color: rgb(0, 29, 98);
        background-color: white;
    }
}

.textArea-1-Feedback_1 {
    width: 80%;
    max-width: 500px;
    height: 60px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 12px;
    font-family: $font-4;
    resize: vertical;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s, box-shadow 0.3s;
    align-self: center;
    text-align: start;
}
.textArea-1-Feedback_1:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}
.button-submit-Feedback_1 {
    background-color: #007bff;
    color: white;
    padding: 4px 6px;
    width: fit-content;
    border: none;
    border-radius: 8px;
    margin-top: 12px;
    font-size: 16px;
    cursor: pointer;
    align-self: center;
    text-align: center;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .button-submit-Feedback_1:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .button-submit-Feedback_1:active {
    background-color: #004494;
    transform: translateY(0);
  }
  
.button-submit-Feedback_1:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

//
.heading-1-vision-Feedback_1{
    color: rgb(128, 81, 0);
    font-family: $font-6;
    margin-top: 8px;
    position: relative;
}
.text-vision-Feedback_1{
    cursor: pointer;
    margin-left: 4px;

    &:hover{
        color: brown;
    }
    &:active{
        color: rgb(27, 103, 180);
    }
}
.container_p-vision-Feedback_1{
    border: 1px solid rgb(24, 113, 185);
    width: 60%;
    font-family: $font-6;
    border-radius: 8px;
    padding: 4px;
    position: absolute;
    background: white;
    top: -100px;
    left: 120px;
    text-align: start;
}

.container_p-feedbackGiven-Feedback_1{
    width: 100%;
    background: rgb(255, 245, 247);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 20px 0px 20px 0px;
}
.button-feedbackGiven-Feedback_1{
    width: 40%;
    border: none;
    background: orangered;
    padding: 8px 12px 8px 12px;
    border-radius: 6px;
    font-family: $font-6;
    font-size: 18px;
    color: rgb(255, 255, 255);

    transition: background-color 0.3s ease;
    transition: color 0.3s ease;

    &:hover{
        background-color: rgb(255, 155, 170);
        transition: background-color 0.3s ease;
        transition: color 0.3s ease;
    }
    &:active{
        background-color: red;
        color: white;
        transition: background-color 0.3s ease;
        transition: color 0.3s ease;
    }
}
  

.text-afterFeedback-Feedback_1{

}
.feedback-thank-you {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 20px;
  width: 70%;
}

.thank-you-heading {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.feedback-note {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}

.email-link {
  color: #007bff;
  text-decoration: none;
//   margin-left: 4px;
}

.email-link:hover {
  text-decoration: underline;
}
